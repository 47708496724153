const buildFormTableData = (dataValues) => {
  const data = !dataValues.dataList ? [] : dataValues.dataList.map((form) => {
    return {
      Values: [
        form.value,
        'VasionFileIcon',
        form.name,
        'VasionEllipsisIcon',
      ],
      ValueType: 'String',
    }
  })
  const tableData = {
    Columns: dataValues.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

const buildFormTableDataNoEllips = (dataValues) => {
  const data = !dataValues.dataList ? [] : dataValues.dataList.map((form) => {
    return {
      Values: [
        form.name,
        form.value,
        form.WorkflowID,
      ],
      ValueType: 'String',
    }
  })
  const tableData = {
    Columns: dataValues.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

export {
  buildFormTableData,
  buildFormTableDataNoEllips,
}
