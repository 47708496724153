<template>
  <div id="general-body">
    <div class="setup-row">
      <VasionInput
        id="parent-folder-name"
        v-model="parentFolderName"
        title="PARENT FOLDER NAME"
        name="parent-folder-name"
        placeholder="Enter Name..."
        inputType="top-white"
        :required="true"
        @change="markRequiredNameInputComplete()"
        @input="markRequiredNameInputComplete(); emitParentFolderIsDirty(); emitRequiredInputsCompleted();"
      />

      <VasionDropList
        id="drive-location"
        v-slot="slotItem"
        v-model="driveStorageConfig"
        title="DRIVE LOCATION"
        name="drive-location"
        type="plain-list"
        :dataArray="drives"
        valueName="value"
        displayName="name"
        :width="'330'"
        :required="true"
        :isDisabled="markRequiredFieldDisabled()"
        @change="markRequiredDriveLocationInputComplete()"
        @input="markRequiredDriveLocationInputComplete(); emitParentFolderIsDirty(); emitRequiredInputsCompleted();"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>
    <!-- CONFIGURE FOLDER -->
    <div id="configure-folder" class="collapsible-section">
      <h2 class="subheader">
        Configure Folder
      </h2>

      <VasionButton
        id="configure-folder-collapse-section"
        name="configure-folder-collapse-section"
        class="collapse-section"
        :icon="showConfigureFolder ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
        title="Collapse Section"
        @vasionButtonClicked="toggleCollapsibleSection('configure-folder')"
      />

      <div v-show="showConfigureFolder">
        <div class="setup-row">
          <VasionCheckbox
            id="auto-versioning"
            name="auto-versioning"
            class="checkbox-spacing"
            :checked="autoVersioning"
            @change="toggleCheckbox('autoVersioning');
                     emitParentFolderIsDirty();"
          >
            Auto Versioning
          </VasionCheckbox>

          <VasionCheckbox
            id="containg-pii-data"
            name="containg-pii-data"
            class="checkbox-spacing"
            :checked="containsPIIData"
            @change="toggleCheckbox('containsPIIData');
                     emitParentFolderIsDirty();"
          >
            Contains PII Data
          </VasionCheckbox>
        </div>

        <div v-show="showEncryptFolder" class="setup-row">
          <VasionCheckbox
            id="encrypt-folder"
            name="encrypt-folder"
            class="checkbox-spacing"
            :checked="encryptFolder"
            @change="toggleEncryptCheckbox();
                     emitParentFolderIsDirty(); emitRequiredInputsCompleted();"
          >
            Encrypt Folder
          </VasionCheckbox>
        </div>

        <div v-if="encryptFolder && !isEncrypted" class="setup-row">
          <VasionInput
            id="password"
            v-model="password"
            title="PASSWORD"
            name="password"
            :class="{'error': !passwordsMatch, 'success': passwordsMatch}"
            type="password"
            placeholder="Enter Password..."
            inputType="top-white"
            :required="true"
            @input="checkThatPasswordsMatch(); emitParentFolderIsDirty(); emitRequiredInputsCompleted();"
          />

          <VasionInput
            id="confirm-password"
            v-model="confirmPassword"
            title="CONFIRM PASSWORD"
            name="confirm-password"
            :class="{'error': !passwordsMatch, 'success': passwordsMatch}"
            type="password"
            placeholder="Confirm Password..."
            inputType="top-white"
            :required="true"
            @input="checkThatPasswordsMatch(); emitParentFolderIsDirty(); emitRequiredInputsCompleted();"
          />
        </div>
        <div v-if="showPasswordForDecrypt && !encryptFolder" class="setup-row">
          <VasionInput
            id="password"
            v-model="decryptPassword"
            title="PASSWORD"
            name="password"
            type="password"
            placeholder="Enter Password..."
            inputType="top-white"
            :required="true"
            @input="emitRequiredInputsCompleted();"
          />
        </div>
        <div id="read-only-row" :class="['setup-row', {'read-only-row': encryptFolder}]">
          <VasionCheckbox
            id="read-only"
            name="read-only"
            class="checkbox-spacing"
            :checked="readOnly"
            @change="toggleCheckbox('readOnly'); emitParentFolderIsDirty();"
          >
            Read Only
          </VasionCheckbox>
        </div>

        <div v-show="readOnly && localUserList && localUserList.length > 0 && tableUserData.Columns" id="read-only-configurations">
          <div id="search-user-group-row" class="setup-row">
            <div id="user-group">
              <VasionButton
                id="users-button"
                :classProp="calculatedClass('users')"
                @vasionButtonClicked="updateSelectedButton('users');"
              >
                Users
              </VasionButton>

              <VasionButton
                id="groups-button"
                :classProp="calculatedClass('groups')"
                @vasionButtonClicked="updateSelectedButton('groups');"
              >
                Groups
              </VasionButton>
            </div>

            <div v-if="selectedButtonName === 'users'" id="user-search">
              <VasionInput
                id="user-search-bar"
                key="users"
                v-model="userInput"
                v-debounce:300ms="listUsers"
                name="user-search-bar"
                class="search-field"
                placeholder="Search Users..."
                :width="'312'"
                inputType="search-white"
              />
            </div>

            <div v-if="selectedButtonName === 'groups'" id="group-search">
              <VasionInput
                id="group-search-bar"
                key="groups"
                v-model="groupInput"
                v-debounce:300ms="listGroups"
                name="group-search-bar"
                class="search-field"
                placeholder="Search Groups..."
                :width="'312'"
                inputType="search-white"
              />
            </div>
          </div>

          <div class="setup-row">
            <div
              v-show="localUserList
                && localUserList.length > 0
                && tableUserData.Columns
                && selectedButtonName === 'users'"
              id="parent-user-folder-table"
              class="grid-div vasion-html-table-default tables"
            >
              <VasionTable
                v-if="tableUserData.Rows && tableUserData.Rows.Values.length > 0"
                :headerColumns="tableUserData.Columns.Values"
                :tableRows="tableUserData.Rows.Values"
                :supportSorting="true"
                :disableShiftSelect="true"
                @vasion-column-sort="sortUserColumns"
                @vasion-row-checked="handleChecklistChange"
              />
            </div>

            <div
              v-show="localGroupList
                && localGroupList.length > 0
                && tableGroupData.Columns
                && selectedButtonName === 'groups'"
              id="parent-group-folder-table"
              class="grid-div vasion-html-table-default tables"
            >
              <VasionTable
                v-if="tableGroupData.Rows && tableGroupData.Rows.Values.length > 0"
                :headerColumns="tableGroupData.Columns.Values"
                :tableRows="tableGroupData.Rows.Values"
                :supportSorting="true"
                :disableShiftSelect="true"
                @vasion-column-sort="sortGroupColumns"
                @vasion-row-checked="handleChecklistChange"
              />
            </div>
          </div>
        </div>
        <div class="setup-row">
          <VasionCheckbox
            id="enable-indexing"
            name="enable-indexing"
            class="checkbox-spacing"
            :checked="isIndexingEnabled"
            @change="toggleIsIndexingEnabled(); emitParentFolderIsDirty();"
          >
            Enable Full Text Search
          </VasionCheckbox>
          <VasionCheckbox
            id="enable-override-global-frequency"
            name="enable-override-global-frequency"
            class="checkbox-spacing"
            :checked="isOverrideGlobalFrequencyEnabled"
            @change="toggleIsOverrideGlobalFrequencyEnabled(); emitParentFolderIsDirty();"
          >
            Override Global Indexing Frequency
          </VasionCheckbox>
        </div>
        <AutomationEngineSharedHeader
          v-show="isOverrideGlobalFrequencyEnabled"
          ref="frequencyComponent"
          :use-for-scheduling-only="true"
          name-label="''"
          @dirty="emitParentFolderIsDirty();"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { tableSorter } from '@/store/helperModules/common.module'
import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';

export default {
  name: 'ParentFolderGeneral',
  components: {
    AutomationEngineSharedHeader,
  },
  props: {
    defaultDriveLocationId: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data: function () {
    return {
      autoVersioning: false,
      checkboxes: {
        users: [],
        groups: [],
      },
      columns: {
        Values: [
          'Name',
          '_VasionCheckBox_{{ Modify }}',
          '_VasionCheckBox_{{ Print }}',
          '_VasionCheckBox_{{ Email }}',
          '_VasionCheckBox_{{ Delete }}',
          '_VasionCheckBox_{{ Export }}',
          '_VasionCheckBox_{{ Launch }}',
        ],
        ValueType: 'String',
      },
      confirmPassword: '',
      containsPIIData: false,
      decryptPassword: '',
      driveStorageConfig: null,
      drives: [],
      encryptFolder: false,
      fullPage: true,
      groupInput: '',
      showEncryptFolder: true,
      isEncrypted: false,
      isIndexingEnabled: false,
      isOverrideGlobalFrequencyEnabled: false,
      isRequiredDriveLocationInputComplete: false,
      isRequiredNameInputComplete: false,
      loadDriveListFinished: false,
      loaderBackgroundColor: '#ece7ec',
      loaderColor: '#ff4702',
      localGroupList: null,
      localUserList: null,
      makeReadOnly: false,
      parentFolderName: '',
      password: '',
      passwordsMatch: false,
      readOnly: false,
      selectedButtonName: 'users',
      showConfigureFolder: true,
      showPasswordForDecrypt: false,
      sortGroupTableBy: '',
      sortTableGroupAscending: true,
      sortTableUserAscending: true,
      sortUserTableBy: '',
      tableGroupData: {},
      tableUserData: {},
      userInput: '',
    }
  },
  computed: {
    groupList() { return this.$store.state.security.groupList },
    parentFolderPermissionsConfig() { return this.$store.state.admin.parentFolderPermissions },
    userList() { return this.$store.state.security.userList },
  },
  watch: {
      parentFolderPermissionsConfig: {
        handler: function () {
          this.listUsers()
          this.listGroups()
        },
        deep: true,
      },
  },
  async created() {
    await this.loadIndexSchedule()

    const defaultEncryptionActive = await this.$store.dispatch('common/getConfig', 'GlobalFolderEncryption')
    if (defaultEncryptionActive.toLowerCase() === 'true') {
      this.showEncryptFolder = false
    }
  },
  methods: {
    async buildGeneralSaveDataJSON() {
      const { frequencyComponent } = this.$refs
      const payload = {
        containsPIIData: this.containsPIIData,
        driveStorageConfig: this.driveStorageConfig,
        encryptFolder: this.encryptFolder,
        parentFolderName: this.parentFolderName,
        decryptPassword: this.decryptPassword,
        password: this.password,
        readOnly: this.readOnly,
        autoVersioning: this.autoVersioning,
        userReadOnlyFolderSecurity: this.buildReadOnlySecurityList(this.localUserList),
        groupReadOnlyFolderSecurity: this.buildReadOnlySecurityList(this.localGroupList),
        folderIndexSchedule: {
          folderID: !this.$route.params.folderId ? 0 : this.$route.params.folderId,
          isDisabled: !this.isIndexingEnabled,
          scheduleDetails: await frequencyComponent.getValues(),
        },
      }

      return payload
    },
    buildReadOnlySecurityList(entityArray) {
      if (!entityArray) {
        return entityArray
      }

      return entityArray.map((entity) => {
        const newElement = {
          entityID: entity.ID === undefined ? entity.UserId : entity.ID,
          folderID: 0,
          canModify: entity.checkboxes ? entity.checkboxes[1].value : false,
          canPrint: entity.checkboxes ? entity.checkboxes[2].value : false,
          canEmail: entity.checkboxes ? entity.checkboxes[3].value : false,
          canDelete: entity.checkboxes ? entity.checkboxes[4].value : false,
          canExport: entity.checkboxes ? entity.checkboxes[5].value : false,
          canViewNative: entity.checkboxes ? entity.checkboxes[6].value : false,
        }

        return newElement
      })
    },
    calculatedClass(selectedButton) { return this.selectedButtonName === selectedButton ? 'text-selected' : 'text' },
    checkThatPasswordsMatch() { this.passwordsMatch = this.password && this.password === this.confirmPassword },
    emitParentFolderIsDirty() {
      this.$emit('markAsDirty', true)
    },
    emitRequiredInputsCompleted() {
      if (this.isRequiredNameInputComplete
        && this.isRequiredDriveLocationInputComplete
        && !this.encryptFolder) {
        this.$emit('markRequiredInputsComplete', true)
      } else if (this.isRequiredNameInputComplete
        && this.isRequiredDriveLocationInputComplete
        && this.encryptFolder
        && this.passwordsMatch) {
        this.$emit('markRequiredInputsComplete', true)
      } else {
        this.$emit('markRequiredInputsNotComplete', true)
      }
      if (this.isEncrypted) {
        if ((this.decryptPassword && !this.encryptFolder) || (!this.decryptPassword && this.encryptFolder)) {
          this.$emit('markRequiredInputsComplete', false)
        } else {
          this.$emit('markRequiredInputsNotComplete', true)
        }
      }
      if (this.isRequiredNameInputComplete) {
        this.$emit('setParentFolderName', this.parentFolderName)
      }
    },
    async filteredValues(usersOrGroups, text) {
      const input = text.toLowerCase()
      if (!input) { return usersOrGroups }

      const returnList = usersOrGroups.filter(userOrGroup => {
        const name = this.selectedButtonName === 'users' ? userOrGroup.Username.toLowerCase() : userOrGroup.Name.toLowerCase()
        const foundName = name.indexOf(input) > -1

        return foundName
      })

      return returnList
    },
    async handleChecklistChange(options, row, column, value) {
      this.emitParentFolderIsDirty()
      if (this.selectedButtonName === 'users') {
        this.localUserList[row].checkboxes[column].value = value
      } else if (this.selectedButtonName === 'groups') {
        this.localGroupList[row].checkboxes[column].value = value
      }
    },
    async listGroups(input) {
      if (!this.loadDriveListFinished) {
        return
      }

      if (this.selectedButtonName === 'groups' && input) {
        this.groupInput = input
        this.localGroupList = await this.filteredValues(this.groupList, input)
      } else {
        this.localGroupList = this.groupList
      }

      if (this.parentFolderPermissionsConfig &&
          (this.parentFolderPermissionsConfig.assignedUsers.length > 0 || this.parentFolderPermissionsConfig.assignedGroups.length > 0)) {
        this.localGroupList = this.localGroupList.filter(group => {
          return this.parentFolderPermissionsConfig.assignedGroups.includes(group.ID)
        })
      }

      this.refreshGroupTable()
    },
    async listUsers(input) {
      if (!this.loadDriveListFinished) {
        return
      }

      if (this.selectedButtonName === 'users' && input) {
        this.userInput = input
        this.localUserList = await this.filteredValues(this.userList, input)
      } else {
        this.localUserList = this.userList
      }

      if (this.parentFolderPermissionsConfig &&
          (this.parentFolderPermissionsConfig.assignedUsers.length > 0 || this.parentFolderPermissionsConfig.assignedGroups.length > 0)) {
        this.localUserList = this.localUserList.filter(user => {
          return this.parentFolderPermissionsConfig.assignedUsers.includes(user.UserId)
        })
      }

      this.refreshUserTable()
    },
    async loadDriveList() {
      const [, , driveList] = await Promise.all([
        this.loadGroupTable(),
        this.loadUserTable(),
        this.$store.dispatch('storage/getConfiguredDriveTypes', false),
      ])

      this.drives = driveList.map((element) => {
        const result = {
          name: element.storageDisplayName,
          value: element.storageConfigId,
        }

        return result
      })

      this.loadDriveListFinished = true
    },
    loadGroupCheckBoxes() {
      if (this.checkboxes.groups.length > 0) {
        this.checkboxes.groups.forEach((el, i) => {
          this.localGroupList[i].checkboxes = el
        })
      }
    },
    async loadGroupTable() {
      await this.$store.dispatch('security/loadGroupList')
      if (!this.localGroupList) {
        this.localGroupList = this.groupList
      }
    },
    async loadIndexSchedule() {
      if (!this.$route.params.folderId) {
        this.isIndexingEnabled = true
        this.isOverrideGlobalFrequencyEnabled = false
        return
      }

      const folderIndexSchedule = await this.$store.dispatch('search/loadFolderIndexSchedule', this.$route.params.folderId)
      this.isIndexingEnabled = !folderIndexSchedule.isDisabled

      if (folderIndexSchedule.schedulerPerformType === 0) {
        this.isOverrideGlobalFrequencyEnabled = false
      } else {
        this.isOverrideGlobalFrequencyEnabled = true
        const { frequencyComponent } = this.$refs
        frequencyComponent.setValues(folderIndexSchedule)
      }
    },
    loadUserCheckBoxes() {
      if (this.checkboxes.users.length > 0) {
        this.checkboxes.users.forEach((el, i) => {
          this.localUserList[i].checkboxes = el
        })
      }
    },
    async loadUserTable() {
      await this.$store.dispatch('security/loadUserList')
      if (!this.localUserList) {
        this.localUserList = this.userList
      }
    },
    markRequiredDriveLocationInputComplete() {
      this.isRequiredDriveLocationInputComplete = true
    },
    markRequiredFieldDisabled() {
      return this.makeReadOnly
    },
    markRequiredNameInputComplete() {
      if (this.parentFolderName.length > 0) {
        this.isRequiredNameInputComplete = true
      } else {
        this.isRequiredNameInputComplete = false
      }
    },
    async refreshGroupTable() {
      const data = {
        dataList: this.localGroupList,
        columns: this.columns,
      }

      this.tableGroupData = await this.$store.dispatch('security/setParentFolderGroupTableData', data)
    },
    async refreshUserTable() {
      const data = {
        dataList: this.localUserList,
        columns: this.columns,
      }
      this.tableUserData = await this.$store.dispatch('security/setParentFolderUserTableData', data)
    },
    setValues(values) {
      if (values) {
        this.parentFolderName = values.vaultName
        this.containsPIIData = values.piiData
        this.encryptFolder = values.encrypted
        this.isEncrypted = values.encrypted
        this.readOnly = values.readOnly
        this.password = values.encryptionPassword
        this.driveStorageConfig = {value: values.storageConfigId}
        this.userReadOnlyFolderSecurity = values.userReadOnlyFolderSecurity
        this.groupReadOnlyFolderSecurity = values.groupReadOnlyFolderSecurity
        this.autoVersioning = values.autoVersioning

        this.markRequiredNameInputComplete()
        this.markRequiredDriveLocationInputComplete()
        this.makeReadOnly = true;
        this.checkboxes.users = values.userReadOnlyFolderSecurity.map(user => {
          return [
                  user.entityName,
                  { value: user.canModify },
                  { value: user.canPrint },
                  { value: user.canEmail },
                  { value: user.canDelete },
                  { value: user.canExport },
                  { value: user.canViewNative },
                ]
        })
        this.checkboxes.groups = values.groupReadOnlyFolderSecurity.map(group => {
          return [
                  group.entityName,
                  { value: group.canModify },
                  { value: group.canPrint },
                  { value: group.canEmail },
                  { value: group.canDelete },
                  { value: group.canExport },
                  { value: group.canViewNative },
                ]
        })
      } else {
        this.parentFolderName = ''
        this.containsPIIData = false
        this.encryptFolder = false
        this.readOnly = false
        this.password = ''
        //preselect the Drive Location field according to the selected folder from the Tree View
        if (this.defaultDriveLocationId >= 0) {
          this.driveStorageConfig = this.drives.find(a => a.value === this.defaultDriveLocationId)
          this.markRequiredDriveLocationInputComplete()
        } else {
          this.driveStorageConfig = null
        }
        this.userReadOnlyFolderSecurity = {}
        this.groupReadOnlyFolderSecurity = {}
        this.autoVersioning = false
      }
      this.loadUserCheckBoxes()
      this.listUsers()
      this.loadGroupCheckBoxes()
      this.listGroups()
      this.emitRequiredInputsCompleted()
    },
    async sortGroupColumns(options) {
      switch (options.columnName) {
        case 'Name':
          this.sortGroupTableBy = 'Name'
          break
        default:
          break
      }
      this.sortTableGroupAscending = !options.ascending
      this.localGroupList.sort(tableSorter(this.sortGroupTableBy, this.sortTableGroupAscending))
      this.refreshGroupTable()
    },
    async sortUserColumns(options) {
      switch (options.columnName) {
        case 'Name':
          this.sortUserTableBy = 'Username'
          break
        default:
          break
      }
      this.sortTableUserAscending = !options.ascending
      this.localUserList.sort(tableSorter(this.sortUserTableBy, this.sortTableUserAscending))

      this.refreshUserTable()
    },
    toggleCheckbox(id) { this[id] = !this[id] },
    toggleCollapsibleSection() { this.showConfigureFolder = !this.showConfigureFolder },
    toggleEncryptCheckbox() {
      if (this.isEncrypted) {
        this.showPasswordForDecrypt = true
      }
      this.encryptFolder = !this.encryptFolder
    },
    toggleIsIndexingEnabled() {
      this.isIndexingEnabled = !this.isIndexingEnabled
    },
    toggleIsOverrideGlobalFrequencyEnabled() {
      this.isOverrideGlobalFrequencyEnabled = !this.isOverrideGlobalFrequencyEnabled
      if (!this.isOverrideGlobalFrequencyEnabled) {
        const { frequencyComponent } = this.$refs
        frequencyComponent.setValues()
      }
    },
    updateSelectedButton(newSelectedButton) { this.selectedButtonName = newSelectedButton },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .setup-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 6px 0;

    ::v-deep .vasion-input {
      width: 310px;
      margin-right: 16px;
    }
  }

  .read-only-row {
    margin-top: 25px;
  }

  .collapsible-section {
    float: left;
    margin-top: 20px;
    width: 100%;

    .subheader {
      @include SubHeadline;
      width: 200px;
      display: block;
      float: left;
      margin-top: 5px;
    }
  }

  .checkbox-spacing {
    width: 310px;
    margin: 0 16px 16px 0;
  }

  #user-group {
    width: 100%;
  }

  .grid-div {
    height: calc(75vh - 220px);
    overflow: auto;
    box-sizing: content-box;
  }

  #read-only-configurations {
    margin-bottom: 25px;
  }
</style>
