<template>
  <div />
</template>

<script>
/* eslint-disable  prefer-arrow-callback */
/* eslint-disable  no-floating-decimal */

import { uuid } from 'vue-uuid';
import { fabric } from 'fabric';

export default {
  // Gets us the provider property from the parent <my-canvas> component.
  inject: ['provider', 'reference'],
  props: {
    image: {
      type: Object,
      required: false,
      default: null,
    },
    largeDefaultLoadSize: {
      required: false,
      type: Boolean,
      default: false,
    },
    pageNumber: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      actionStarted: false,
      activeEventFunctions: [],
      annotationsDrawn: false,
      debug: false,
      localImage: this.image,
      nullAnnotationConfig: {
        type: '',
        color: '',
        id: '',
        subType: '',
      },
      position: {
        xClick: 0,
        yClick: 0,
        height: 0,
        width: 0,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
      readyToDraw: false,
      zoomFactorX: 0,
      zoomFactorY: 0,
    }
  },
  computed: {
    annotationConfig() { return this.$store.state.document.annotation },
    annotations() { return this.$store.state.workflow.signatureZoneAnnotations },
    deletingOnInputs() { return this.$store.state.digitalSignature.deletingOnInputs },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  watch: {
    annotationConfig: async function (val) {
      const { canvas } = this.provider
      if (canvas) {
        await this.removeActiveEventFunctions()
        this.clearSelectedZoneConfig()
        switch (val.type) {
          case 'aipZone':
            await this.initializeZoneCreate()
            break
          default:
            await this.initializeAnnotationSelect()
        }
      }
    },
    pageNumber: async function () {
      this.localImage.PageNumber = this.image.PageNumber
      this.$store.dispatch('document/addOneToRefreshKey')
    },
    readyToDraw: async function () {
      if (this.readyToDraw && !this.annotationsDrawn) {
        await this.drawAllAnnotations()
        await this.initializeAnnotationSelect()
        this.readyToDraw = false
      }
    },
    zoomValue() {
      this.setZoomValue()
    },
  },
  created() {
    this.readyToDraw = true
    this.setBackgroundImage()
  },
  methods: {
    activeObject() { return this.provider.canvas.getActiveObject() },
    annotationMouseDown() {
      if (this.debug) { console.log('annotation:move-resize mouse:down') }
      if (this.annotationConfig.type !== '') {
        return false;
      }

      this.actionStarted = true
      if (this.activeObject()) {
        const activeObject = this.activeObject()
        activeObject.bringToFront()
        this.setAssociateUserConfig(activeObject.toObject())
      }

      return true
    },
    async annotationMouseUp() {
      if (this.debug) { console.log('annotation:move-resize mouse:up') }
      if (!this.actionStarted || this.annotationConfig.type !== '') {
        return false;
      }

      await this.saveAllAnnotations()

      return true
    },
    async annotationSelectionCleared() {
      this.clearSelectedZoneConfig()
    },
    // This function needs to have a name that is unique to this context file
    // because it will be fired off by global "keyup" event listener.
    async checkDeleteKeyAndDeleteAIPZone(event) {
      if (this.debug) { console.log('annotation:delete') }
      const { canvas } = this.provider
      const key = event.keyCode || event.charCode
      if (this.activeObject() && (key === 8 || key === 46) && !this.deletingOnInputs) { // KeyCodes are for delete and backspace
        canvas.remove(this.activeObject());
        this.clearSelectedZoneConfig(true)
        await this.saveAllAnnotations(true)
      }
    },
    clearSelectedZoneConfig(previousWasDeleted) {
      this.$store.dispatch('capture/setSelectedAIPZone', {
        uniqueId: '',
        subType: '',
        previousWasDeleted: previousWasDeleted,
      })
    },
    async drawAllAnnotations() {
      if (!this.annotations || this.annotations.length === 0) return 0
      const { canvas, context } = this.provider

      // Set the zoom factor based on the current size vs. the original localImage size
      // TODO: This still needs some work for smaller screens
      this.zoomFactorX = canvas.width / this.localImage.Width
      this.zoomFactorY = canvas.height / this.localImage.Height

      // Clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height)
      canvas.clear();
      // Loop through the annotations.Values and draw each one
      this.annotations.forEach((annotation) => {
        switch (annotation.AnnType) {
        case 'Rectangle':
          this.renderRectangle(annotation, canvas)
          break
        default:
          break
        }
      })
      this.annotationsDrawn = true
      return 1
    },
    extendFabricObject(objectToExtend, uniqueId, subType) {
      return (function (toObject) {
        return function () {
          return fabric.util.object.extend(toObject.call(this), {
            id: uniqueId || '',
            subType: subType || '',
          });
        };
      }(objectToExtend.toObject))
    },
    getFillColorStringFromSubtype(subtype) {
      switch (subtype) {
        case 'Check Zone':
          return 'rgba(0, 128, 0, 0.2)'
        case 'OMR Zone':
          return 'rgba(254, 172, 0, 0.2)'
        case 'Text Zone':
          return 'rgba(227, 0, 0, 0.2)'
        case 'Barcode Zone':
          return 'rgba(0, 163, 68, 0.2)'
        default:
          return 'rgba(254, 172, 0, 0.2)'
      }
    },
    async initializeAnnotationSelect() {
      const { canvas } = this.provider
      await this.removeActiveEventFunctions()

      canvas.on('mouse:down', this.annotationMouseDown, false)
      this.activeEventFunctions.push('mouse:down')
      canvas.on('mouse:up', this.annotationMouseUp, false)
      this.activeEventFunctions.push('mouse:up')
      canvas.on('selection:cleared', this.annotationSelectionCleared, false)
      this.activeEventFunctions.push('selection:cleared')

      document.addEventListener('keyup', this.checkDeleteKeyAndDeleteAIPZone, false)
    },
    async initializeZoneCreate() {
      const { canvas } = this.provider
      canvas.defaultCursor = 'crosshair'
      await this.removeActiveEventFunctions()

      canvas.on('mouse:down', this.zoneCreateMouseDown, false)
      this.activeEventFunctions.push('mouse:down')
      canvas.on('mouse:move', this.zoneCreateMouseMove, false)
      this.activeEventFunctions.push('mouse:move')
      canvas.on('mouse:up', this.zoneCreateMouseUp, false)
      this.activeEventFunctions.push('mouse:up')
    },
    async removeActiveEventFunctions() {
      const { canvas } = this.provider
      this.activeEventFunctions.forEach(item => canvas.off(item))
      this.activeEventFunctions = []

      document.removeEventListener('keyup', this.checkDeleteKeyAndDeleteAIPZone);
    },
    async renderRectangle(annotation, canvas) {
      const rectangle = new fabric.Rect({
        width: annotation.width,
        height: annotation.height,
        left: annotation.left,
        top: annotation.top,
        fill: this.getFillColorStringFromSubtype(annotation.subType),
        stroke: 'rgba(0, 107, 227, 0.2)',
        strokeWidth: 1,
      })
      rectangle.hasRotatingPoint = false
      rectangle.toObject = this.extendFabricObject(rectangle, annotation.UniqueID, annotation.subType)

      canvas.add(rectangle)
    },
    async saveAllAnnotations() {
      const { canvas } = this.provider
      const all = canvas.toObject()

      const saveObject = {
        FilePath: this.localImage.FilePath,
        PageNumber: this.localImage.PageNumber,
        DocumentId: this.localImage.DocumentId,
        ImageSource: this.localImage.ImageSource,
        Width: this.localImage.Width,
        Height: this.localImage.Height,
        OriginalDPI: this.localImage.OriginalDPI,
        OriginalHeight: this.localImage.OriginalHeight,
        OriginalWidth: this.localImage.OriginalWidth,
        PageDPI: this.localImage.PageDPI,
        Annotations: {
          Values: [],
        },
      }
      const localAnnotations = []
      all.objects.forEach(function (o, index) {
        let type = ''
        let properties = {}
        let name = ''

        switch (o.type) {
          case 'rect':
            type = 'Rectangle'

            if (o.subType === 'Check Zone') {
              properties = {
                RECT_COLOR_A: '64',
                RECT_COLOR_R: '0',
                RECT_COLOR_G: '128',
                RECT_COLOR_B: '0',
              }
              name = 'Check Zone'
            } else {
              properties = {
                RECT_COLOR_A: '75',
                RECT_COLOR_R: '254',
                RECT_COLOR_G: '172',
                RECT_COLOR_B: '0',
              }
              name = `${type}`
            }
            break
          default:
            type = 'UNKNOWN'
            break
        }
        properties.subType = o.subType

        localAnnotations.push({
          Users: [],
          Groups: [],
          Name: name,
          AnnType: type,
          Rotate: 0,
          Print: true,
          Visible: true,
          Locked: false,
          Rank: index,
          UniqueID: o.id,
          PosX: Number(o.left),
          PosY: Number(o.top),
          Height: Number(o.height) * Number(o.scaleY),
          Width: Number(o.width) * Number(o.scaleX),
          Properties: properties,
        })
      })

      saveObject.Annotations.Values = localAnnotations
      if (this.debug) { console.log(saveObject) }
      await this.$store.dispatch('document/saveRedaction', saveObject)
    },
    setAssociateUserConfig(obj) {
      this.$store.dispatch('capture/setSelectedAIPZone', {
        uniqueId: obj.id,
        subType: obj.subType,
      })
    },
    setBackgroundImage() {
      const { canvas } = this.provider
      if (canvas) {
        canvas.setBackgroundImage(this.image.ImageSource, canvas.renderAll.bind(canvas), {
          backgroundImageStretch: false,
          scaleX: canvas.width / this.image.Width,
          scaleY: canvas.height / this.image.Height,
        })
      } else {
        this.$nextTick(() => {
          this.setBackgroundImage()
        })
      }
    },
    setZoomValue() {
      const { canvas } = this.provider
      if (canvas) {
        canvas.setHeight((canvas.getHeight() / canvas.getZoom()) * this.zoomValue); // Divide by the previous zoom to get the original height
        canvas.setWidth((canvas.getWidth() / canvas.getZoom()) * this.zoomValue); // Divide by the previous zoom to get the original width
        canvas.setZoom(this.zoomValue); // Now set the new zoom value
        canvas.renderAll();
      }
    },
    async zoneCreateMouseDown(options) {
      if (this.debug) { console.log('zone:create mouse:down') }
      if (this.annotationConfig.type === '') {
        return false;
      }

      this.actionStarted = true
      this.position.left = options.e.offsetX / this.zoomValue
      this.position.top = options.e.offsetY / this.zoomValue

      return true
    },
    zoneCreateMouseMove(options) {
      if (this.debug) { console.log('zone:create mouse:move') }
      if (!this.actionStarted || this.annotationConfig.type === '') {
        return false;
      }

      this.position.bottom = options.e.offsetY / this.zoomValue
      this.position.right = options.e.offsetX / this.zoomValue
      this.position.width = Math.abs(this.position.right - this.position.left) // This is meant to handle right to left drag
      this.position.height = Math.abs(this.position.bottom - this.position.top)

      return true
    },
    async zoneCreateMouseUp() {
      if (this.debug) { console.log('zone:create mouse:up') }
      if (!this.actionStarted || this.annotationConfig.type === '') {
        return false;
      }

      const { canvas } = this.provider

      if (this.actionStarted) {
        this.actionStarted = false;
      }

      // TODO: Set the color based on this.annotationConfig.subType
      const rectangle = new fabric.Rect({
        width: this.position.width,
        height: this.position.height,
        left: this.position.left,
        top: this.position.top,
        fill: this.getFillColorStringFromSubtype(this.annotationConfig.subType),
        stroke: 'rgba(0, 107, 227, 0.2)',
        strokeWidth: 1,
      })

      rectangle.hasRotatingPoint = false
      rectangle.toObject = this.extendFabricObject(rectangle, uuid.v1().toString(), this.annotationConfig.subType)

      canvas.add(rectangle)
      canvas.setActiveObject(rectangle)

      canvas.defaultCursor = 'default'
      await this.saveAllAnnotations()
      await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)
      this.setAssociateUserConfig(this.activeObject().toObject())

      return true
    },
  },
}
</script>
