import { parseISO, format } from 'date-fns'

const buildTableData = (dataValues) => {
  const data = !dataValues.jobList ? [] : dataValues.jobList.map((job) => {
    return {
      Values: [
        job.schedulerID,
        job.name,
        job.schedulerTypeName,
        job.performType,
        format(parseISO(job.createdDate), 'MMM dd, yyyy'),
        job.runTimeString,
        'VasionEllipsisIcon',
      ],
      ValueType: 'String',
    }
  })
  const tableData = {
    Columns: dataValues.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

export {
  buildTableData,
}
