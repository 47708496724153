<template>
  <div class="list-selection-main-div md-layout md-gutter">
    <div class="md-layout-item">
      <table>
        <tr>
          <td>
            <label class="vasion-page-subheader">{{ availableText }}</label>
          </td>
          <td class="filter-column">
            <VasionInput
              v-if="showFilter"
              :id="`add-filter-input-${uniqueId}`"
              v-model="initiatingFilterString"
              class="search-padding"
              name="filter-multi-select"
              placeholder="Filter..."
              inputType="search-gray"
            />
          </td>
          <td class="alignRight add-all-button-column">
            <VasionButton :id="`btnAddAll-${uniqueId}`" :classProp="'primary-light'" @vasionButtonClicked="addAll()">
              Add All
            </VasionButton>
          </td>
        </tr>
      </table>
      <div class="border">
        <ul :id="availableText.toLowerCase().replace(/\s/g, '-')" class="">
          <li
            v-for="attributeField in filteredAvailableInitiatingFields"
            :key="attributeField.value"
            :class="{ 'selected': attributeField.selected }"
            @click="selectDisplayField(attributeField)"
          >
            {{ attributeField.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="selection-buttons">
      <div
        class="arrow-button"
        :class="{'arrow-button-disabled': sendToSelectedDisabled}"
        :disabled="sendToSelectedDisabled"
        @click="sendToSelected()"
      >
        <VasionArrowRightIcon />
      </div>
      <div
        class="arrow-button"
        :class="{'arrow-button-disabled': sendToAvailableDisabled}"
        :disabled="sendToAvailableDisabled"
        @click="sendToAvailable()"
      >
        <VasionArrowLeftIcon />
      </div>
    </div>
    <div class="md-layout-item">
      <table>
        <tr>
          <td>
            <label class="vasion-page-subheader">{{ selectedText }}</label>
          </td>
          <td class="alignRight filter-column">
            <VasionInput
              v-if="showFilter"
              :id="`remove-filter-input-${uniqueId}`"
              v-model="permittedFilterString"
              class="search-padding"
              name="filter-multi-select"
              placeholder="Filter..."
              inputType="search-gray"
            />
          </td>
          <td class="alignRight remove-all-button-column">
            <VasionButton :id="`btnRemoveAll-${uniqueId}`" :classProp="'primary-light'" @vasionButtonClicked="removeAll()">
              Remove All
            </VasionButton>
          </td>
        </tr>
      </table>
      <div class="border">
        <ul :id="selectedText.toLowerCase().replace(/\s/g, '-')" class="">
          <li
            v-for="attributeField in filteredAvailablePermittedFields"
            :key="attributeField.value"
            :class="{ 'selected': attributeField.selected }"
            @click="deSelectField(attributeField)"
          >
            <div class="">
              <table>
                <tr>
                  <td>
                    {{ attributeField.name }}
                  </td>
                  <td class="alignRight ordering-arrows">
                    <VasionArrowUpIcon
                      v-if="allowFieldOrdering"
                      class="vasionIconButton"
                      title="Move Up"
                      :disabled="shouldDisableReorder(attributeField, true)"
                      :class="{ 'vasion-icon-disabled': shouldDisableReorder(attributeField, true) }"
                      @click.stop="moveDisplayField(attributeField, true)"
                    />
                    <VasionArrowDownIcon
                      v-if="allowFieldOrdering"
                      class="vasionIconButton"
                      title="Move Down"
                      :class="{ 'vasion-icon-disabled': shouldDisableReorder(attributeField, false) }"
                      :disabled="shouldDisableReorder(attributeField, false)"
                      @click.stop="moveDisplayField(attributeField, false)"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VasionListSelection',
  components: {
  },
  props: {
    allowFieldOrdering: {
      type: Boolean,
      default: true,
      required: false,
    },
    availableFields: {
      type: Array,
      default: () => [],
      required: true,
    },
    availableLabelText: {
      type: String,
      default: '',
      required: false,
    },
    selectedFields: {
      type: Array,
      default: () => [],
      required: true,
    },
    selectedLabelText: {
      type: String,
      default: '',
      required: false,
    },
    showFilter: {
      type: Boolean,
      default: true,
      required: false,
    },
    uniqueId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      initiatingFilterString: '',
      permittedFilterString: '',
    }
  },
  computed: {
    availableText() {
      return this.availableLabelText ? this.availableLabelText : 'Available Fields'
    },
    filteredAvailableInitiatingFields() {
      return this.localAvailableFields.filter((field) => {
        return (field.name.toLowerCase().includes(this.lowerInitiatingFilterString) || field.selected)
      })
    },
    filteredAvailablePermittedFields() {
      return this.localSelectedFields.filter((field) => {
        return (field.name.toLowerCase().includes(this.lowerPermittedFilterString) || field.selected)
      })
    },
    localAvailableFields: {
      get: function () {
        return this.availableFields
      },
      set: function (newValue) {
        this.$emit('update:availableFields', newValue)
      },
    },
    localSelectedFields: {
      get: function () {
        return this.selectedFields
      },
      set: function (newValue) {
        this.$emit('update:selectedFields', newValue)
      },
    },
    lowerInitiatingFilterString() { return !this.initiatingFilterString ? '' : this.initiatingFilterString.toLowerCase() },
    lowerPermittedFilterString() { return !this.permittedFilterString ? '' : this.permittedFilterString.toLowerCase() },
    selectedText() { return this.selectedLabelText ? this.selectedLabelText : 'Selected Fields' },
    sendToAvailableDisabled() { return !this.filteredAvailablePermittedFields.some(e => e.selected === true) },
    sendToSelectedDisabled() { return !this.filteredAvailableInitiatingFields.some(e => e.selected === true) },
  },
  methods: {
    addAll() {
      this.filteredAvailableInitiatingFields.forEach((element) => {
        this.localSelectedFields.push(element)
      })

      this.localAvailableFields = this.localAvailableFields.filter((availableElement) => {
        return this.filteredAvailableInitiatingFields.filter(f => f.value === availableElement.value).length === 0
      })
      this.$emit('update:addAll')
    },
    deSelectField(attributeField) {
      this.localSelectedFields = this.localSelectedFields.map((element) => {
        if (element.value === attributeField.value) {
         element.selected = element.selected ? undefined : true
        }
        return element
      })
    },
    filterTextChanged(filterValue) {
      this.filterString = filterValue
    },
    moveDisplayField(attributeField, moveUp) {
      if (this.shouldDisableReorder(attributeField, moveUp)) {
        return;
      }
      const index = this.localSelectedFields.findIndex((item) => item.value === attributeField.value);
      const newIndex = moveUp === true ? (index - 1) : (index + 1);

      const newArray = this.localSelectedFields.filter((element) => {
        return element.value !== attributeField.value
      })

      newArray.splice(newIndex, 0, attributeField)
      this.localSelectedFields = newArray;
    },
    removeAll() {
      this.filteredAvailablePermittedFields.forEach((element) => {
        this.localAvailableFields.push(element)
      })

      this.sortFieldsAlphabetically(this.localAvailableFields)

      this.localSelectedFields = this.localSelectedFields.filter((selectedElement) => {
        return this.filteredAvailablePermittedFields.filter(f => f.value === selectedElement.value).length === 0
      })

      this.$emit('update:removeAll')
    },
    selectDisplayField(attributeField) {
      this.localAvailableFields = this.localAvailableFields.map((element) => {
        if (element.value === attributeField.value) {
         element.selected = element.selected ? undefined : true
        }
        return element
      })
    },
    sendToAvailable() {
      const newLocalSelectedFields = []
      for (let i = 0; i < this.localSelectedFields.length; i += 1) {
        if (this.localSelectedFields[i].selected === true) {
          this.localSelectedFields[i].selected = false
          this.localAvailableFields.push(this.localSelectedFields[i])
        } else {
          newLocalSelectedFields.push(this.localSelectedFields[i])
        }
      }
      this.sortFieldsAlphabetically(this.localAvailableFields)
      this.localSelectedFields = newLocalSelectedFields
    },
    sendToSelected() {
      const newLocalAvailableFields = []
      for (let i = 0; i < this.localAvailableFields.length; i += 1) {
        if (this.localAvailableFields[i].selected === true) {
          this.localAvailableFields[i].selected = false
          this.localSelectedFields.push(this.localAvailableFields[i])
        } else {
          newLocalAvailableFields.push(this.localAvailableFields[i])
        }
      }
      this.localAvailableFields = newLocalAvailableFields
    },
    shouldDisableReorder(attributeField, moveUp) {
      const index = this.localSelectedFields.findIndex((item) => item.value === attributeField.value);
      const arrayLength = this.localSelectedFields.length;

      return index < 0
        || (moveUp === true && index === 0)
        || (moveUp === false && index === (arrayLength - 1))
    },
    sortFieldsAlphabetically(fields) {
      fields.sort((a, b) => {
        const x = a.name.toLowerCase();
        const y = b.name.toLowerCase();
        if (x < y) { return -1 }
        if (x > y) { return 1 }
        return 0
      })
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .md-layout-item {
    overflow-x: hidden;
  }

  .arrow-button{
    margin-bottom: 7px;
    background-color: $grey-75;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
  }

  .arrow-button-disabled {
    cursor: auto;
    background-color: $grey-50;
  }

  .arrow-button-disabled svg {
    fill: $grey-300;
  }

  .selection-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  ul{
    margin: 4px;
    overflow-y: scroll;
    height: 300px;
  }

  li{
    user-select: none;
    padding: 8px;
    font-weight: 500;
    cursor: pointer;
    margin: 4px 0px 4px 0px;
    border-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  li table {
    table-layout: fixed;
  }

  li tr td {
    width: 100%;
  }

  li tr td ~ td {
    width: 70px;
    float: right;
  }

  .selected{
    background-color: $orange-50;
    color: $orange-400;
  }

  .border{
    border: solid 1px;
    border-radius: 8px;
    border-color: $grey-200;
  }

  .vasionIconButton {
    margin-left: 10px;
  }

  table {
    width: 100%;
  }

  .alignRight {
    text-align: right;
  }

  .list-selection-main-div {
    width: 100%;
    height: 400px;
  }

  .filter-column {
    width: 150px;
    max-width: 150px !important;
  }

  .remove-all-button-column {
    width: 120px !important;
  }

  .add-all-button-column {
    width: 105px !important;
  }

  .ordering-arrows{
    width: 70px;
    height: 20px;
  }

</style>
