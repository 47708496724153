<template>
  <!-- eslint-disable-next-line vue/no-template-shadow -->
  <div slot-scope="{ field }" :class="{'thing': !isInDocumentView, 'top-space': isInDocumentView}">
    <div v-if="field.type === 1" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        :title="field.name"
        :isDisabled="field.isDisabled || isInSignatureMode"
        width="100%"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(filterValue)"
        @input="emitDirty"
      />
    </div>
    <div v-if="field.type === 2" class="dropdown">
      <VasionDropList
        v-slot="slotItem"
        ref="fieldDropList"
        v-model="selectedItem"
        :required="required"
        :dataArray="field.dropdownValues"
        :isDisabled="field.isDisabled || isInSignatureMode"
        :title="field.name"
        :type="useMultiSelectLists ? 'check-list' : 'plain-list'"
        :valueName="useMultiSelectLists ? 'value' : 'name'"
        :class="{'data-view-input-style': !isInDocumentView}"
        displayName="name"
        width="100%"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(selectedItem)"
        @input="emitDirty"
        v-on="$listeners"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>
    <div v-if="field.type === 3 && !isInSignatureMode" class="date">
      <label class="vasion-input-label-top">{{ field.name }}</label>
      <span v-if="required" class="vasion-required-indicator">*</span>
      <md-datepicker
        :id="field.value"
        v-model="filterValue"
        :class="{'data-view-input-style': !isInDocumentView}"
        :name="field.value"
        :md-model-type="String"
        :disabled="field.isDisabled && isInSignatureMode"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(filterValue)"
        @md-closed="emitDirty"
      >
        <div id="clear-overlay" @click="startLoading" /> <!-- Needed to show the loader at the correct time -->
      </md-datepicker>
    </div>
    <div v-if="field.type === 3 && isInSignatureMode" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :isDisabled="true"
        :title="field.name"
      />
    </div>
    <div v-if="field.type === 6" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :isDisabled="field.isDisabled || isInSignatureMode"
        :title="field.name"
        specialType="number"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(filterValue)"
        @input="emitDirty"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
    <div v-if="field.type === 7" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        specialType="url"
        :isDisabled="field.isDisabled || isInSignatureMode"
        :title="field.name"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(filterValue)"
        @input="emitDirty"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
    <div v-if="field.type === 8" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :isDisabled="field.isDisabled || isInSignatureMode"
        :title="field.name"
        specialType="email"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(filterValue)"
        @input="emitDirty"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
    <div v-if="(field.type === 9) && filterValue !== ''" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :isDisabled="field.isDisabled || isInSignatureMode"
        :readonly="true"
        :title="field.name"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(filterValue)"
        @input="emitDirty"
      />
    </div>
    <div v-if="(field.type === 10) && filterValue !== ''" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :isDisabled="field.isDisabled || isInSignatureMode"
        :readonly="true"
        :title="field.name"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(filterValue)"
        @input="emitDirty"
      />
    </div>
    <div v-if="field.type === 11" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :isDisabled="field.isDisabled || isInSignatureMode"
        :title="field.name"
        specialType="currency"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(filterValue)"
        @input="emitDirty"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
    <div v-if="field.type === 12" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :isDisabled="field.isDisabled || isInSignatureMode || field.filterValue.length > 0"
        :title="field.name"
        specialType="email"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(filterValue)"
        @input="emitDirty"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
    <div v-if="field.type === 13">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-tall"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        :isDisabled="field.isDisabled || isInSignatureMode"
        :title="field.name"
        specialType="textarea"
        :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty(filterValue)"
        @input="emitDirty"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
  </div>
</template>

<script>
export default {
    name: 'AttributeFieldFilterItem',
    props: {
      field: {
        type: Object,
        required: true,
      },
      forPrompt: {
        type: Boolean,
        required: false,
        default: false,
      },
      isInDocumentView: {
        type: Boolean,
        required: false,
        default: false,
      },
      showErrorState: {
        type: Boolean,
        required: false,
        default: false,
      },
      useMultiSelectLists: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        filterValue: '',
        isDirty: false,
        selectedItem: [],
      }
    },
    computed: {
      isInSignatureMode() { return this.$route.name !== 'SignatureEditDocument' && this.$store.state.mainViews.activeSubApp === 'signatures' },
      required() { return this.field.required === true },
    },
    watch: {
      field: {
        handler: function () {
          this.updateCurrentValue()
        },
        deep: true,
      },
      filterValue: function () {
        this.$emit('filterupdated', { name: this.field.value, type: this.field.type, value: this.filterValue })
      },
      selectedItem: function () {
        let selectedValue
        if (this.useMultiSelectLists) {
          selectedValue = this.selectedItem
        } else {
          selectedValue = this.selectedItem && this.selectedItem.name ? [this.selectedItem.name] : ['']
        }
        this.$emit('filterupdated', {
          name: this.field.value,
          type: this.field.type,
          value: selectedValue,
        })
      },
    },
    async created() {
      this.$material.locale.dateFormat = 'MM/dd/yyyy'
      await this.updateCurrentValue()
      this.populateMultiSelectDropList()
      this.$emit('clean')
    },
    methods: {
      checkIfRequiredAndEmpty(value) {
        if (this.forPrompt && this.field.required) {
          if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) {
            this.$emit('isRequiredAndEmpty', { name: this.field.name, empty: true })
          } else {
            this.$emit('isRequiredAndEmpty', { name: this.field.name, empty: false })
          }
        }
      },
      emitDirty() {
        this.isDirty = true
        this.$emit('dirty')
        this.$emit('isLoading')
      },
      emitIsValid() {
        this.$emit('isValid')
      },
      emitNotValid() {
        this.$emit('notValid')
      },
      populateMultiSelectDropList() {
        if (this.useMultiSelectLists) {
          const control = this.$refs.fieldDropList
          if (control) {
            control.selectedArrayLocal = this.selectedItem
          }
        }
      },
      startLoading() {
        this.emitDirty()
        document.querySelector('#Date_Field > button').click()
      },
      async updateCurrentValue() {
        if (this.field && this.field.filterValue) {
          if (this.filterValue !== this.field.filterValue) {
            this.filterValue = this.field.filterValue
          }

          if (this.field.type === 2 && this.field.dropdownValues && Array.isArray(this.filterValue) && this.filterValue.length > 0) {
            if (this.useMultiSelectLists) {
              this.selectedItem = this.filterValue.map((value) => {
                const foundItem = this.field.dropdownValues.find((element) => {
                  return element.name === value
                })
                return {
                  name: foundItem?.name,
                  value: foundItem?.value,
                }
              })
            } else {
              const foundItem = this.field.dropdownValues.find((element) => {
                return element.name === this.filterValue[0]
              })

              if (foundItem && this.selectedItem !== foundItem) {
                this.selectedItem = foundItem
              }
            }
          }
        }
      },
    },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .date {
    width: 200px;
    margin-top: -15px;
  }
  .date > .vasion-input-label-top,
  .date > .vasion-required-indicator {
    top: 20px;
  }

  .input-tall {
    height: 120px;
  }

  .thing {
    width: 100%;
  }

  @media(max-width: $tablet){
    .thing{
      width: 100%;
    }
  }

  .top-space {
    margin: 15px 0 0 0;
    width: 100%;
  }

  #clear-overlay {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    z-index: 5;
  }
  #clear-overlay:hover {
    cursor: pointer;
  }

  .data-view-input-style {
    padding-bottom: 15px;
  }
  .date > .data-view-input-style {
    padding-bottom: 0px;
  }

</style>
