/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const checkIfFieldIsUsedInAForm = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Admin/FieldIsUsedInAForm', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const checkIfUserCanAccessEForm = async (apiToken, id) => {
  const axios = createAxiosInstance(apiToken)
  const payload = {
    Value: id,
  }
  try {
    const { data } = await axios.post('v1/Document/UserCanAccessEForm', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getAllFieldHeaders = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Admin/GetAllFieldHeaders')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getCreateTempDocsByEformID = async (apiToken, id) => {
  const axios = createAxiosInstance(apiToken)
  const payload = {
    Value: id,
  }
  try {
    const { data } = await axios.post('v1/Document/GetCreateTempDocsByEFormID', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getCreateTempDocsByFormID = async (apiToken, formId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/GetCreateTempDocsByFormID', { Value: formId })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getEform = async (apiToken, formId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Document/LoadEForm', { EFormID: formId, IncludeUserSecurity: true, IncludeFields: true })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getEFormDocTemplateFields = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/GetEFormDocTemplateFields', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getEFormExternalSettings = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Admin/GetEFormExternalSettings')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getEFormFieldsFromFormIOJson = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/GetEFormFieldsFromFormIOJson', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getEFormsByFormID = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/LoadAllEFormsByFormID', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getFormBuilderConfigByFormID = async (apiToken, formID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Document/GetFormIOFieldSchema?Value=${formID}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveEForm = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SaveEForm', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveEFormDocTemplateDefaultMapping = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SaveEFormDocTemplateDefaultMapping', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveEFormDocTemplateMapping = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SaveEFormDocTemplateMapping', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveEFormExternalSettings = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Admin/SaveEFormExternalSettings', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveNewField = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Admin/AddFieldToForm', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const updateFieldInEForms = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Admin/UpdateFieldInEForms', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

export {
  checkIfFieldIsUsedInAForm,
  checkIfUserCanAccessEForm,
  getAllFieldHeaders,
  getCreateTempDocsByEformID,
  getCreateTempDocsByFormID,
  getEform,
  getEFormDocTemplateFields,
  getEFormFieldsFromFormIOJson,
  getEFormsByFormID,
  getEFormExternalSettings,
  getFormBuilderConfigByFormID,
  saveEForm,
  saveEFormDocTemplateDefaultMapping,
  saveEFormExternalSettings,
  saveEFormDocTemplateMapping,
  saveNewField,
  updateFieldInEForms,
}
