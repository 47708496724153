<template>
  <div class="temp-space">
    <div class="signature-box">
      <VueSignaturePad
        ref="signaturePad"
        width="100%"
        height="231px"
        :options="{ onEnd }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Draw',
  data: function () {
    return {
      sampleImage: '',
    }
  },
  methods: {
    closeDialog() { this.$emit('closeDialog') },
    onEnd() { this.$emit('hasSignature') },
    saveSignature() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.sampleImage = data
      return data
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .signature-box {
    display: inline-block;
    border: 1px solid $grey-100;
    border-radius: 3px;
  }

  .temp-space {
    display: flex;
    flex-direction: column;
  }
</style>
