<template>
  <div class="vasion-ellipsis-menu" @mouseleave="emit('close')">
    <div
      v-for="(button, index) in buttonConfig.buttons"
      :id="button.name.toLowerCase()"
      :key="index"
      @click="emit(button)"
    >
      <div v-if="button.isHorizontalRule" class="horizontal-rule" />
      <div v-else class="ellipsis-button-outer">
        <VasionButton
          v-if="shouldButtonShow(button, rowItem)"
          :id="button.id"
          :name="button.id"
          :title="button.name"
          :icon="button.icon"
          :iconFill="button.iconFill"
          :isDisabled="button.isDisabled"
          class="ellipsis-button"
        />
        <span v-if="shouldButtonShow(button, rowItem)">{{ button.name }}</span>
      </div>
      <div v-if="button.divider && !button.hideButton" class="divider">
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VasionEllipsisMenu',
  props: {
    buttonConfig: {
      type: Object,
      required: true,
      validator: function (config) {
        let isValid = true
        if (!config || !config.buttons || config.buttons.length === 0) {
          isValid = false
        }

        if (isValid) {
          config.buttons.forEach(button => {
            isValid = button.id || button.id !== ''
          })
        }

        return isValid
      },
    },
    rowItem: {
      type: Object,
      required: false,
      default: null
    },
  },
  methods: {
    emit(button) {
      if (!button.isDisabled) {
        this.$emit('ellipsis-button-clicked', { buttonId: button.id, item: this.rowItem })
      }
    },
    shouldButtonShow(button, item) {
      let shouldShow = true
      shouldShow = button.name && button.name !== ''

      // Check user permissions
      shouldShow = shouldShow && (!button.permissionGetter || this.hasPermission(button.permissionGetter))

      // Check custom logic
      if (shouldShow && button.customVisibilityFunc && button.customVisibilityFunc instanceof Function) {
        const payload = {
          button: button,
          item: item,
        }
        return button.customVisibilityFunc(payload)
      }
      if (button.id === 'edit-signer' && item.disableButton) {
        shouldShow = false
      }
      if (button.hideButton) {
        shouldShow = false
      }

      return shouldShow
    },
    hasPermission(getter) {
      return this.$store.getters[getter]
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .horizontal-rule {
    width: 280px;
    border-top: 1px solid $grey-75;
    position: relative;
    right: 7px;
    margin: 8px 0px;
  }

  .vasion-ellipsis-menu {
    @include Text;
    width: 280px;
    padding: 7px;
    position: absolute;
    right: 30px;
    background: $white;
    margin: 0 auto;
    text-align: left;
    z-index: 1000;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 $box-shadow;
    margin-top: -35px;
    .ellipsis-button-outer {
      margin: 0px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        background-color: $plumb-50;
      }
    }
  .divider {
    width: calc(100% + 14px);
    height: 1px;
    margin: -10px 0px 10px -7px;
    background-color: $grey-500;
  }
  }
</style>
