<template>
  <div v-if="$route.name !== 'plainForm'" class="vasion-drawer calc-height" :class="{'vasion-drawer-mini': !mainNavExpanded}">
    <div class="side-nav-content">
      <div class="link-box" :class="{'link-box-mini': !mainNavExpanded}">
        <transition name="fade">
          <router-link
            v-if="mainNavExpanded"
            id="logo"
            to="/vasionHome"
            class="height-fix"
          >
            <VasionLogo class="logo" />
          </router-link>
        </transition>
        <transition name="fade">
          <router-link
            v-if="!mainNavExpanded"
            id="logo"
            to="/vasionHome"
            class="height-fix-mini"
          >
            <VasionLogoMini class="logo" />
          </router-link>
        </transition>
      </div>
      <div id="side-nav--overlay" />
      <div id="side-nav" class="side-nav">
        <!-- ## Admin APP ## -->
        <div v-if="activeSubApp === 'admin' && showAdminApp">
          <!-- There are some hardcoded False values below to intentionally hide list items that are not finished for now -->
          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <!-- Search Setup Sub Nav -->
            <li v-if="isAdminUser" id="usage-dashboard-button" @click="switchActiveSubSideNav('usageDashboard' , 'TheUsageDashboard')">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'usage-dashboard'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionDashboardIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Usage Dashboard
                </span>
              </span>
            </li>

            <li
              v-if="canAccessAdminSearch"
              id="search-menu-button"
              class=""
              @click="toggleSearchSetup()"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showSearchSetup" />
                  <VasionArrowDropDownRight16Icon v-show="!showSearchSetup" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionSearchIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Search Setup
                </span>
              </span>
            </li>
            <!-- FT and Object Search are always shown because a User may just have "Refresh Search" rights -->
            <li
              v-show="showSearchSetup"
              id="search-setup-form-search-button"
              class=""
              @click="switchActiveSubSideNav('search-setup-attribute-search', 'AdminAttributeSearchList')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'search-setup-attribute-search'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                {{ $formsLabel }} Search</span></span>
            </li>
            <li
              v-show="showSearchSetup"
              id="search-setup-full-text-search-button"
              class=""
              @click="switchActiveSubSideNav('search-setup-full-text-search', 'AdminFullTextSearchList')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'search-setup-full-text-search'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                Full Text Search</span></span>
            </li>
            <li
              v-show="showSearchSetup && canConfigureSearches"
              id="search-setup-global-search-button"
              class=""
              @click="switchActiveSubSideNav('search-setup-global-search', 'AdminGlobalSearchList')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'search-setup-global-search'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                Global Search</span></span>
            </li>
            <li
              v-show="showSearchSetup && canConfigureSearches"
              id="search-setup-link-search-button"
              class=""
              @click="switchActiveSubSideNav('search-setup-linked-search', 'AdminLinkedSearchList')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'search-setup-linked-search'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                Linked Search</span></span>
            </li>
            <li
              v-show="showSearchSetup && canAccessAdmin"
              id="search-setup-link-search-button"
              class=""
              @click="switchActiveSubSideNav('search-setup-object-edit-and-replace', 'AdminObjectEditAndReplace')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'search-setup-linked-search'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                {{ $formsLabel }} Edit / Replace</span></span>
            </li>

            <!-- Storage Setup Sub Nav -->

            <li
              v-if="canAccessAdminStorage"
              id="storage-menu-button"
              class=""
              @click="toggleStorageSetup()"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showStorageSetup" />
                  <VasionArrowDropDownRight16Icon v-show="!showStorageSetup" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionFoldersStackedIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Storage Setup
                </span>
              </span>
            </li>
            <li
              v-show="showStorageSetup"
              id="storage-parent-folders-button"
              class=""
              @click="switchActiveSubSideNav('storage-parent-folders', 'ParentFolders')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'storage-parent-folders'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                Parent Folders</span></span>
            </li>
            <li
              v-show="showStorageSetup"
              id="storage-cloud-storage-button"
              class=""
              @click="switchActiveSubSideNav('storage-cloud-storage', 'CloudStorageSetup')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'storage-cloud-storage'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                Cloud Storage</span></span>
            </li>
            <li
              v-show="showStorageSetup"
              id="storage-custom-folder-view-button"
              class=""
              @click="switchActiveSubSideNav('storage-custom-folder-view', 'CustomFolderViewConfig')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'storage-custom-folder-view'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                Custom Folder Views</span></span>
            </li>

            <!-- Security & Permissions Sub Nav -->

            <li
              v-if="canAccessAdminSecurity"
              id="security-menu-button"
              class=""
              @click="toggleSecurityAndPermissions()"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showSecurityAndPermissions" />
                  <VasionArrowDropDownRight16Icon v-show="!showSecurityAndPermissions" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionSecurityIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Security & Permissions
                </span>
              </span>
            </li>

            <li
              v-show="showSecurityAndPermissions && canAccessUserConfiguration"
              id="security-users-button"
              class=""
              @click="switchActiveSubSideNav('security-users', 'Users')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'security-users'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                User Management</span></span>
            </li>
            <li
              v-show="showSecurityAndPermissions && canAccessGroupConfiguration"
              id="security-groups-button"
              class=""
              @click="switchActiveSubSideNav('security-groups', 'Groups')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'security-groups'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                Group Management</span></span>
            </li>
            <li
              v-show="showSecurityAndPermissions && isAdminUser"
              id="security-password-settings-button"
              @click="switchActiveSubSideNav('security-password-settings', 'PasswordSettings')"
            >
              <span
                class="vasion-side-nav-li-background"
                :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded,
                         'vasion-active-list-item': active === 'security-password-settings'}"
              >
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  Password Settings
                </span>
              </span>
            </li>
            <li
              v-show="showSecurityAndPermissions && isAdminUser"
              id="security-two-factor-auth-button"
              class="vasion-side-nave-li-background"
              @click="switchActiveSubSideNav('security-two-factor-auth', 'TwoFactorAuth')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'security-two-factor-auth'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                Two-Factor Authentication</span></span>
            </li>
            <li
              v-show="showSecurityAndPermissions && isAdminUser"
              id="security-feature-flags-button"
              class=""
              @click="switchActiveSubSideNav('security-feature-flags', 'FeatureFlags')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'security-feature-flags'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                Feature Flags</span></span>
            </li>

            <!-- Integrations Sub Nav -->

            <li
              v-if="isAdminUser"
              id="integration-menu-button"
              class=""
              @click="toggleIntegrations()"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showIntegrations" />
                  <VasionArrowDropDownRight16Icon v-show="!showIntegrations" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionIntegrationIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Integrations
                </span>
              </span>
            </li>

            <li v-show="showIntegrations" id="integration-authentication-button" @click="switchActiveSubSideNav('integration-authentication', 'Authentication')">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'integration-authentication'}"><span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                Authentication</span></span>
            </li>
          </ul>
        </div>

        <!-- ## Reports APP ## -->

        <div v-if="activeSubApp === 'reports' && showReportsApp">
          <div v-if="canConfigureReports" class="start-action-div">
            <VasionButton
              id="new-report"
              :classProp="'secondary-transparent'"
              :isFullWidth="true"
              @vasionButtonClicked="newReportClick"
            >
              <span class="vasion-button-plus">+</span>NEW REPORT
            </VasionButton>
          </div>
          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <!-- All Reports Sub Nav -->
            <li
              v-if="canAccessReports || canAccessCustomReports"
              id="all-reports-button"
              class=""
              @click="toggleAllReports()"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showAllReports" />
                  <VasionArrowDropDownRight16Icon v-show="!showAllReports" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionAllReports class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  All Reports
                </span>
              </span>
            </li>
            <li
              v-show="showAllReports && canRunAuditReport"
              id="audit-report-button"
              class=""
              @click="switchActiveSideNav('report-audit')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'report-audit'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  Audit
                </span>
              </span>
            </li>
            <li
              v-show="showAllReports && canRunDocumentReport"
              id="document-report-button"
              class=""
              @click="switchActiveSideNav('report-document')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'report-document'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  Document
                </span>
              </span>
            </li>
            <li
              v-show="showAllReports && canRunUserReport"
              id="user-report-button"
              class=""
              @click="switchActiveSideNav('report-user')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'report-user'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  User
                </span>
              </span>
            </li>
            <li
              v-show="showAllReports && canRunWorkflowReport"
              id="workflow-report-button"
              class=""
              @click="switchActiveSideNav('report-workflow')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'report-workflow'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  Workflow
                </span>
              </span>
            </li>
            <li
              v-for="(report, index) in customReports"
              v-show="showAllReports && canAccessCustomReports"
              :id="report.sName"
              :key="index"
              class=""
              @click="switchActiveSideNav(`report-custom-${index}`)"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === `report-custom-${index}`}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  {{ report.sName }}
                </span>
              </span>
            </li>

            <!-- Saved Audit Reports -->

            <li v-if="auditReports && auditReports.length > 0 && canRunAuditReport" id="saved-audit-reports-button" @click="toggleSavedAuditReports()">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showSavedAuditReports" />
                  <VasionArrowDropDownRight16Icon v-show="!showSavedAuditReports" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionReleaseIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Saved Audit Reports
                </span>
              </span>
            </li>
            <li
              v-for="(report) in auditReports"
              v-show="showSavedAuditReports"
              :key="report.iID"
              class=""
              @click="switchActiveSideNav(`saved-audit-report-${report.iID}`)"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === `saved-audit-report-${report.iID}`}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  {{ report.sName }}
                </span>
              </span>
            </li>

            <!-- Saved Workflow Reports Sub Nav -->
            <li
              v-if="hasSavedWorkflowReports && canAccessSavedWorkflowReports"
              id="saved-workflow-reports-button"
              class=""
              @click="toggleSavedWorkflowReports()"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showSavedWorkflowReports" />
                  <VasionArrowDropDownRight16Icon v-show="!showSavedWorkflowReports" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionSavedReports class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Saved Workflow Reports
                </span>
              </span>
            </li>
            <li
              v-for="(report) in savedWorkflowReports"
              v-show="showSavedWorkflowReports"
              :key="report.iID"
              class=""
              @click="switchActiveSideNav(`saved-workflow-report-${report.iID}`)"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === `saved-workflow-report-${report.iID}`}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  {{ report.sName }}
                </span>
              </span>
            </li>

            <!-- Scheduled Reports Sub Nav -->

            <li
              v-if="canAccessScheduledReports"
              id="scheduled-reports-button"
              class=""
              @click="toggleScheduledReports()"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showScheduledReports" />
                  <VasionArrowDropDownRight16Icon v-show="!showScheduledReports" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionReleaseIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Scheduled Reports
                </span>
              </span>
            </li>
            <li v-show="showScheduledReports && canAccessScheduledReports" id="workflow-report-button" @click="switchActiveSideNav('report-all-scheduled')">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'report-all-scheduled'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  All Scheduled Reports
                </span>
              </span>
            </li>
            <div v-if="canAccessScheduledReports">
              <li
                v-for="(report) in schedulerList"
                v-show="showScheduledReports"
                :key="report.schedulerID"
                class=""
                @click="switchActiveSideNav(`report-scheduled-${report.schedulerID}`)"
              >
                <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === `report-scheduled-${report.schedulerID}`}">
                  <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                    {{ report.name }}
                  </span>
                </span>
              </li>
            </div>
          </ul>
        </div>

        <!-- ## Forms APP ## -->

        <div v-if="activeSubApp === 'forms' && showFormsApp">
          <div class="start-action-div">
            <VasionButton
              v-if="canConfigureForms"
              id="new-form"
              :classProp="'secondary-transparent'"
              :isFullWidth="true"
              :isDisabled="$route.name === 'NewForm'"
              @vasionButtonClicked="newFormClick"
            >
              <span class="vasion-button-plus">+</span>Create {{ $formsLabel }}
            </VasionButton>
          </div>
          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <li v-if="canConfigureForms" id="all-forms-button" @click="toggleAllForms(); checkIfFormsCanChangeFirst('all-forms')">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'all-forms'}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showAllForms" />
                  <VasionArrowDropDownRight16Icon v-show="!showAllForms" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionFilesLabeled class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  All {{ $formsLabelPlural }}
                </span>
              </span>
            </li>
            <li
              v-for="(form, index) in formList"
              v-show="showAllForms"
              :id="form.name"
              :key="index"
              class=""
              @click="checkIfFormsCanChangeFirst(`configure-form-${form.value}`, form.value)"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === `configure-form-${form.value}`}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  {{ form.name }}
                </span>
              </span>
            </li>
            <li id="my-forms-button" @click="toggleMyForms(); checkIfFormsCanChangeFirst('my-forms')">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'my-forms'}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showMyForms" />
                  <VasionArrowDropDownRight16Icon v-show="!showMyForms" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionFileIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  My {{ $layoutsLabelPlural }}
                </span>
              </span>
            </li>
            <li
              v-for="(form, index) in myFormList"
              v-show="showMyForms"
              :id="form.name"
              :key="`sideNav${index}`"
              class=""
              @click.stop.prevent="goToMyForm(`my-form-${form.value}`, form)"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === `my-form-${form.value}`}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  {{ form.name }}
                </span>
              </span>
            </li>

            <!-- Field Mapping Sub Nav -->

            <li v-if="isAdminUser" id="form-external-view-button" @click="switchActiveSideNav('external-view');">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'external-view'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionWeb class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  External View
                </span>
              </span>
            </li>
            <!-- Field Mapping Sub Nav -->

            <li v-if="canConfigureLookups" id="forms-field-mapping-button" @click="switchActiveSideNav('field-mapping'); toggleFieldMappingApp()">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'field-mapping'}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="fieldMappingList" />
                  <VasionArrowDropDownRight16Icon v-show="!fieldMappingList" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionNodeMultiple class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Field Mapping
                </span>
              </span>
            </li>
            <li v-show="fieldMappingList && canConfigureLookups" id="DatabaseLookups-app" @click="switchActiveSubSideNav('database-lookup-app', 'FormsDatabaseLookups')">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'database-lookup-app'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  Database Lookups
                </span>
              </span>
            </li>
          </ul>
        </div>

        <!-- ## Automation APP ## -->

        <div v-if="activeSubApp === 'automation' && (showAutomationApp)">
          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <li
              v-if="canConfigureScheduler || automationVisibility.addToWorkflow"
              id="automation-engine-add-to-workflow-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-add-to-workflow', 'AddToWorkflow')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-add-to-workflow'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionAddToWorkflowIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Add To Workflow
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureScheduler || automationVisibility.contentMove"
              id="automation-engine-content-move-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-content-move', 'AutomationEngineContentMove')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-content-move'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionContentMoveIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Content Move
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureScheduler || automationVisibility.databaseLookups"
              id="automation-engine-database-lookups-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-database-lookups', 'AutomationEngineDatabaseLookups')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-database-lookups'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionDatabaseLookupIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Database Lookups
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureScheduler || automationVisibility.fullTextUpdate"
              id="automation-engine-full-text-search-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-full-text-search', 'AutomationEngineFullTextUpdate')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-full-text-search'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionFullTextUpdateIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Full Text Update
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureScheduler || automationVisibility.importExport"
              id="automation-engine-import-export-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-import-export', 'ImportExport')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-import-export'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionImportExportIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Import / Export
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureScheduler || automationVisibility.dataImport"
              id="automation-engine-data-import-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-data-import', 'DataImport')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-data-import'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionCloudDownloadIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Data Import
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureScheduler || automationVisibility.scheduledReports"
              id="automation-engine-scheduled-reports-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-scheduled-reports', 'ScheduledReports')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-scheduled-reports'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionScheduledReportsIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Scheduled Reports
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureScheduler || automationVisibility.scheduledOcr"
              id="automation-engine-scheduled-ocr-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-scheduled-ocr', 'ScheduledOcr')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-scheduled-ocr'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionMediaEditingScanDocumentIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Scheduled OCR
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureScheduler || automationVisibility.scheduledAip"
              id="automation-engine-scheduled-aip-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-scheduled-aip', 'ScheduledAip')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-scheduled-aip'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionScheduledAip class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Scheduled AIP
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureScheduler || automationVisibility.barcodeRecognition"
              id="automation-engine-barcode-recognition-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-barcode-recognition', 'BarcodeRecognition')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-barcode-recognition'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionBarcodeIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Barcode Recognition
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureScheduler || automationVisibility.retention"
              id="automation-engine-retention-button"
              class=""
              @click="switchActiveSubSideNav('automation-engine-retention', 'Retention')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'automation-engine-retention'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionRetentionIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Retention
                </span>
              </span>
            </li>
          </ul>
        </div>

        <!-- ## Templates APP ## -->

        <div v-if="activeSubApp === 'templates' && showTemplatesApp">
          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <li
              v-if="canConfigureEmailTemplates"
              id="email-templates-button"
              class=""
              @click="toggleEmailTemplates(); switchActiveSubSideNav('emailTemplates', 'AllEmailTemplates')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'all-emails'}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showAllEmailTemplates" />
                  <VasionArrowDropDownRight16Icon v-show="!showAllEmailTemplates" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionEmailIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Email Templates
                </span>
              </span>
            </li>
            <li
              v-for="(emailTemplate, index) in emailTemplateList"
              v-show="showAllEmailTemplates"
              :id="emailTemplate.sName"
              :key="index"
              class=""
              @click="switchActiveSideNav(`configure-email-template-${emailTemplate.iID}`)"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === `configure-email-template-${emailTemplate.iID}`}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  {{ emailTemplate.sName }}
                </span>
              </span>
            </li>
          </ul>
          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <li
              v-if="isAdminUser"
              id="document-templates-button"
              class=""
              @click="toggleDocumentTemplates(); switchActiveSubSideNav('documentTemplates', 'DocumentTemplates')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'document-templates'}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showDocumentTemplates" />
                  <VasionArrowDropDownRight16Icon v-show="!showDocumentTemplates" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionDocumentTemplateIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Document Templates
                </span>
              </span>
            </li>
            <li
              v-for="(documentTemplate, index) in documentTemplatesList"
              v-show="showDocumentTemplates"
              :id="documentTemplate.name"
              :key="index"
              class=""
              @click="switchActiveSideNav(`editDocumentTemplate-${documentTemplate.documentTemplateID}`)"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === `editDocumentTemplate-${documentTemplate.documentTemplateID}`}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  {{ documentTemplate.name }}
                </span>
              </span>
            </li>
          </ul>
        </div>

        <!-- ## Workflow APP ## -->

        <div v-if="activeSubApp === 'workflow' && showWorkflowApp">
          <div class="start-action-div">
            <VasionButton
              v-if="isWorkflowAdminUser"
              id="new-workflow"
              :classProp="'secondary-transparent'"
              :isFullWidth="true"
              @vasionButtonClicked="newWorkflowClick"
            >
              <span class="vasion-button-plus">+</span>Create Workflow
            </VasionButton>
          </div>
          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <li
              v-if="isWorkflowAdminUser"
              id="workflows-list"
              class=""
              @click="toggleAllWorkflows(); switchActiveSideNav('workflow')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'workflow-designer'}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showAllWorkflows" />
                  <VasionArrowDropDownRight16Icon v-show="!showAllWorkflows" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionWorkflowIcon2 class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  All Workflows
                </span>
              </span>
            </li>
            <li
              v-for="(workflow, index) in allWorkflowList"
              v-show="showAllWorkflows"
              :id="workflow.sName"
              :key="`workflow-${index}`"
              class=""
              @click="switchActiveSideNav(`configure-workflow-${workflow.iID}`)"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === `configure-workflow-${workflow.iID}`}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  {{ workflow.sName }}
                </span>
              </span>
            </li>

            <!-- ## Workflow Tasks ## -->
            <li
              v-if="isWorkflowAdminUser"
              id="workflow-tasks"
              class=""
              @click="toggleWorkflowTasks(); switchActiveSideNav('workflow-tasks')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'workflow-tasks'}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showWorkflowTasks" />
                  <VasionArrowDropDownRight16Icon v-show="!showWorkflowTasks" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionCheckmarkBox class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Workflow Tasks
                </span>
              </span>
            </li>
            <li
              v-for="(task, index) in workflowTaskList"
              v-show="showWorkflowTasks"
              :id="task.sName"
              :key="`task-${index}`"
              class=""
              @click="switchActiveSideNav(`configure-task-${task.iID}`)"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === `configure-task-${task.iID}`}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  {{ task.sName }}
                </span>
              </span>
            </li>
          </ul>
        </div>

        <!-- ## Storage APP ## -->

        <div v-if="activeSubApp === 'storage' && showStorageApp">
          <StorageSideNav />
        </div>

        <!-- ## Signature APP ## -->

        <div v-if="activeSubApp === 'signature' && showSignatureApp">
          <!-- ## Hiding the list items until we know what should be here ## -->
          <!-- ## These can still be manually accessed through the router ## -->
          <div v-if="canCreateSignatureDocument" class="start-action-div">
            <VasionButton
              :classProp="'secondary-transparent'"
              :isFullWidth="true"
              :isDisabled="$route.name === 'NewDocument'"
              @vasionButtonClicked="newDocumentClick()"
            >
              <span class="vasion-button-plus">+</span>NEW DOCUMENT
            </VasionButton>
            <VasionEllipsisMenu
              v-if="showSignatureDocEllipsisMenu"
              v-click-outside="closeNewSignatureDocMenu"
              :buttonConfig.sync="signatureDocEllipsisButtonConfig"
              :rowItem="{}"
              :style="signatureDocEllipsisStyle"
              @ellipsis-button-clicked="signatureDocEllipsisButtonClicked"
              @close="closeNewSignatureDocMenu"
            />
          </div>

          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <li id="all-Signatures-menu-button" class="" @click="switchActiveSubSideNav('signatureInbox', 'TheSignatureInbox')">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'signatureInbox'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionInboxIcon class="vasion-side-nav-li-icon" /></span><span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Inbox</span></span>
            </li>
            <li id="all-Signatures-menu-button" class="" @click="switchActiveSubSideNav('signatureCompleted', 'TheSignatureCompleted')">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'signatureCompleted'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionDoneAllIcon class="vasion-side-nav-li-icon" /></span><span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Completed</span></span>
            </li>
            <li
              v-if="canCreateSignatureDocument"
              id="favorite-Signatures-menu-button"
              class=""
              @click="switchActiveSubSideNav('signatureSent', 'TheSignatureSent')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'signatureSent'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionSendIcon class="vasion-side-nav-li-icon" /></span><span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Sent</span></span>
            </li>
            <li v-if="canCreateSignatureDocument" id="Recalled-Signatures-menu-button" @click="switchActiveSubSideNav('signatureRecalled', 'TheSignatureRecalled')">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'signatureRecalled'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionActionsBlock class="vasion-side-nav-li-icon" /></span><span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Recalled</span></span>
            </li>
            <!-- <li class="" id="favorite-Signatures-menu-button" @click="switchActiveSubSideNav('signatureDrafts', 'TheSignatureDrafts')">
                <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'signatureDrafts'}">
                  <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionEditIcon class="vasion-side-nav-li-icon" /></span><span class="vasion-disable-pointer-event" v-if="mainNavExpanded">
                  Drafts</span></span>
              </li>
              <li class="" id="favorite-Signatures-menu-button" @click="switchActiveSubSideNav('signatureDelete', 'TheSignatureDeleted')">
                <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'signatureDelete'}">
                  <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionDeleteIcon class="vasion-side-nav-li-icon" /></span><span class="vasion-disable-pointer-event" v-if="mainNavExpanded">
                  Delete</span></span>
              </li> -->
            <li
              v-if="canCreateSignatureDocument"
              id="favorite-Signatures-menu-button"
              class=""
              @click="switchActiveSubSideNav('signatureTemplates', 'TheSignatureTemplates')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'signatureTemplates'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionGestureIcon class="vasion-side-nav-li-icon" /></span><span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  Templates</span></span>
            </li>
          </ul>
        </div>

        <!-- ## Capture APP ## -->

        <div v-if="activeSubApp === 'capture' && showCaptureApp">
          <!-- ## Nothing here until we know what should be here ## -->
          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <li id="capture-scanner-button" class="" @click="switchActiveSubSideNav('capture-scanner', 'StartScan')">
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'capture-scanner'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionScannerIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Scanner
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureCaptureProfiles"
              id="capture-profiles"
              class=""
              @click="switchActiveSubSideNav('capture-profiles', 'CaptureProfiles')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'capture-profiles'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionCaptureProfilesIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Capture Profiles
                </span>
              </span>
            </li>
            <li
              v-if="canConfigureAIP"
              id="capture-advanced-image-processing-button"
              class=""
              @click="switchActiveSubSideNav('capture-advanced-image-processing', 'AdvancedImageProcessing')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'capture-advanced-image-processing'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionAipIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Adv Image Processing
                </span>
              </span>
            </li>
            <li
              v-if="isAdminUser"
              id="capture-barcode-configuration"
              class=""
              @click="switchActiveSubSideNav('capture-barcode-configuration', 'BarcodeConfiguration')"
            >
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-active-list-item': active === 'capture-barcode-configuration'}">
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionBarcodeIcon class="vasion-side-nav-li-icon" />
                </span>
                <span class="vasion-disable-pointer-event">
                  Barcode Configuration
                </span>
              </span>
            </li>
          </ul>
        </div>

        <!-- ## User Settings ## -->
        <div v-if="activeSubApp === 'profile'">
          <!-- There are some hardcoded False values below to intentionally hide list items that are not finished for now -->
          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <li id="form-builder-button" class="" @click="switchActiveSubSideNav('user-settings-signature', 'SignatureSettings')">
              <span class="vasion-side-nav-li-background non-arrow-row" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'user-settings-signature'}"><span class="vasion-icon-bar vasion-disable-pointer-event">
                <VasionSignatureIcon class="vasion-side-nav-li-icon" /></span><span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                Saved Signature</span></span>
            </li>
          </ul>
        </div>

        <!-- ## Workspace APP ## -->
        <div v-if="activeSubApp === 'workspace' && showWorkspaceApp">
          <div class="start-action-div">
            <VasionButton
              id="start-new-workflow"
              :classProp="'secondary-transparent'"
              :isFullWidth="true"
              @vasionButtonClicked="startNewWorkflow"
            >
              <span class="vasion-button-plus">+</span>START WORKFLOW
            </VasionButton>
          </div>
          <ul class="vasion-ul-with-icons vasion-list-unstyled">
            <!-- My Tasks Sub Nav -->
            <li id="my-tasks-button" class="" @click="toggleMyTasks()">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showMyTasks" />
                  <VasionArrowDropDownRight16Icon v-show="!showMyTasks" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionViewListIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  My Tasks
                </span>
              </span>
            </li>
            <li
              v-show="showMyTasks"
              id="my-tasks-pending-button"
              class=""
              @click="switchActiveSideNav('my-tasks-pending')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'my-tasks-pending'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  Pending
                </span>
              </span>
            </li>
            <li
              v-show="showMyTasks"
              id="my-tasks-completed-button"
              class=""
              @click="switchActiveSideNav('my-tasks-completed')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'my-tasks-completed'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  Completed
                </span>
              </span>
            </li>

            <!-- My Requests Sub Nav -->

            <li id="my-requests-button" class="" @click="toggleMyRequests()">
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded}">
                <span class="vasion-disable-pointer-event">
                  <VasionArrowDropDown16Icon v-show="showMyRequests" />
                  <VasionArrowDropDownRight16Icon v-show="!showMyRequests" />
                </span>
                <span class="vasion-icon-bar vasion-disable-pointer-event">
                  <VasionSendIcon class="vasion-side-nav-li-icon" />
                </span>
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event">
                  My Requests
                </span>
              </span>
            </li>
            <li
              v-show="showMyRequests"
              id="my-requests-pending-button"
              class=""
              @click="switchActiveSideNav('my-requests-pending')"
            >
              <span class="vasion-side-nav-li-background " :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'my-requests-pending'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  Pending
                </span>
              </span>
            </li>
            <li
              v-show="showMyRequests"
              id="my-requests-completed-button"
              class=""
              @click="switchActiveSideNav('my-requests-completed')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'my-requests-completed'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  Completed
                </span>
              </span>
            </li>
            <li
              v-show="showMyRequests"
              id="my-requests-recent-documents-button"
              class=""
              @click="switchActiveSideNav('my-requests-recent-documents')"
            >
              <span class="vasion-side-nav-li-background" :class="{'vasion-side-nav-li-background-mini': !mainNavExpanded, 'vasion-active-list-item': active === 'my-requests-recent-documents'}">
                <span v-if="mainNavExpanded" class="vasion-disable-pointer-event plain-text-row">
                  Recent Documents
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <md-dialog
        id="start-workflow-select-dialog"
        class="dialog-window"
        :md-click-outside-to-close="false"
        :md-active.sync="showStartWorkflowManager"
      >
        <StartWorkflowManager :viewMode="false" @closeStartWorkflow="showStartWorkflowManager = false" />
      </md-dialog>

      <VasionSnackbar
        id="workflow-started-snack"
        :showSnackbarBool.sync="showSnackbarBool"
        :snackbarImage="snackbarImage"
        :snackbarSubTitle="snackbarSubTitle"
        :snackbarTitle="snackbarTitle"
      />
    </div>
  </div>
</template>

<script>

import { AdminSideNavOptions } from '@/enums/adminSideNavOptions'

import VasionSnackbar from '@/components/shared/VasionSnackbar.vue';
import StartWorkflowManager from '@/components/workflow/StartWorkflowManager.vue'
import StorageSideNav from '@/components/storage/StorageSideNav.vue';

export default {
    name: 'TheMainSideNav',
    components: {
      StartWorkflowManager,
      StorageSideNav,
      VasionSnackbar,
    },
    data: function () {
      return {
        activeSideNav: '',
        fieldMappingList: false,
        showAllEmailTemplates: true,
        showAllForms: false,
        showAllReports: true,
        showAllWorkflows: false,
        showAutomationEngine: false,
        showCaptureSetup: false,
        showDocumentTemplates: false,
        showFieldMapping: false,
        showIntegrations: false,
        showMyForms: false,
        showMyRequests: true,
        showMyTasks: true,
        showSavedAuditReports: false,
        showSavedWorkflowReports: false,
        showScheduledReports: false,
        showSearchSetup: false,
        showSecurityAndPermissions: false,
        showSignatureDocEllipsisMenu: false,
        showSnackbarBool: false,
        showStartWorkflowManager: false,
        showStorageSetup: false,
        showWorkflowTasks: false,
        signatureDocEllipsisButtonConfig: {
          buttons: [
            {
              id: 'send-for-signature',
              name: 'Send for Signature',
              icon: 'VasionSignerIcon',
              iconFill: '#707273',
            },
            {
              id: 'signature-template',
              name: 'Signature Template',
              icon: 'VasionLayoutIcon',
              iconFill: '#707273',
            },
          ],
        },
        signatureDocEllipsisStyle: 'top: 50px; left: 3px; width: 252px;',
        snackbarImage: false,
        snackbarSubTitle: '',
        snackbarTitle: '',
        userAssignPayload: {},
        userAssignToWorkflow: 0,
      }
    },
    computed: {
      active() {
        return this.$store.state.mainViews.mainNav
      },
      activeSubApp() { return this.$store.state.mainViews.activeSubApp },
      allWorkflowList() { return this.$store.state.workflow.workflowAdminList },
      areFormsDirty() { return this.$store.state.forms.areFormsDirty },
      auditReports() { return this.$store.state.reporting.auditReports },
      automationVisibility() { return this.$store.state.automationEngine.automationVisibility },
      canAccessAdmin() { return this.$store.getters['common/canAccessAdmin'] },
      canAccessAdminCapture() { return this.$store.getters['common/canAccessAdminCapture'] },
      canAccessAdminProcessing() { return this.$store.getters['common/canAccessAdminProcessing'] },
      canAccessAdminSearch() { return this.$store.getters['common/canAccessAdminSearch'] },
      canAccessAdminSecurity() { return this.$store.getters['common/canAccessAdminSecurity'] },
      canAccessAdminStorage() { return this.$store.getters['common/canConfigureFolders'] },
      canAccessCustomReports() { return this.$store.getters['common/canAccessCustomReports'] },
      canAccessFormsApp() { return this.$store.getters['common/canAccessFormsApp_RolesOnly'] },
      canAccessGroupConfiguration() { return this.$store.getters['common/canAccessGroupConfiguration'] },
      canAccessReports() { return this.$store.getters['common/canAccessReports_RolesOnly'] },
      canAccessSavedWorkflowReports() { return this.$store.getters['common/canRunWorkflowReport'] },
      canAccessScheduledReports() { return this.$store.getters['common/canAccessScheduledReports'] },
      canAccessUserConfiguration() { return this.$store.getters['common/canAccessUserConfiguration'] },
      canCreateSignatureDocument() { return this.$store.getters['common/canCreateSignatureDocument'] },
      canConfigureAIP() { return this.$store.getters['common/canConfigureAIP'] },
      canConfigureCaptureProfiles() { return this.$store.getters['common/canConfigureCaptureProfiles'] },
      canConfigureForms() { return this.$store.getters['common/canConfigureAttributeForms'] },
      canConfigureEmailTemplates() { return this.$store.getters['common/canConfigureEmailTemplates'] },
      canConfigureLookups() { return this.$store.getters['common/canConfigureLookups'] },
      canConfigureReports() { return this.$store.getters['common/canConfigureReports'] },
      canConfigureScheduler() { return this.$store.getters['common/canConfigureScheduler'] },
      canConfigureSearches() { return this.$store.getters['common/canConfigureSearches'] },
      canRunAuditReport() { return this.$store.getters['common/canRunAudit'] },
      canRunDocumentReport() {
        return this.$store.getters['common/canRunDocumentReport']
          && this.$store.state.reporting.documentReports
          && this.$store.state.reporting.documentReports.length > 0
      },
      canRunUserReport() {
        return this.$store.getters['common/canRunUserReport']
          && this.$store.state.reporting.userReports
          && this.$store.state.reporting.userReports.length > 0
      },
      canRunWorkflowReport() { return this.$store.getters['common/canRunWorkflowReport'] },
      customReports() { return this.$store.state.reporting.customReports },
      documentTemplatesList() { return this.$store.state.common.documentTemplates },
      emailTemplateList() { return this.$store.state.common.emailTemplates },
      formList() { return this.$store.state.attributeForm.forms },
      hasSavedWorkflowReports() { return this.savedWorkflowReports && this.savedWorkflowReports.length > 0 },
      isAdminUser() { return this.$store.getters['common/isAdminUser'] },
      isForm() { return this.$store.state.attributeForm.isForm },
      isFormAdminUser() { return this.$store.getters['common/isFormAdminUser'] },
      isWorkflowAdminUser() { return this.$store.getters['common/isWorkflowAdminUser'] },
      isWorkflowDirty() { return this.$store.state.workflow.isWorkflowDirty },
      localAdminSideNavOptions() { return AdminSideNavOptions },
      mainNavExpanded() { return this.$store.state.mainViews.mainNavExpanded },
      myFormList() { return this.$store.state.attributeForm.myForms },
      savedAuditReports() { return this.$store.state.reporting.savedAuditReports },
      savedWorkflowReports() { return this.$store.state.reporting.savedWorkflowReports },
      schedulerList() { return this.$store.state.automationEngine.schedulerList },
      selectedWorkflow() { return this.$store.state.workflow.selectedWorkflow },
      showAdminApp() { return this.$store.getters['common/showAdminApp'] },
      showAutomationApp() {
        return this.$store.getters['common/APP_ACCESS_AUTOMATION']
          && (this.userHasSchedulers || this.$store.getters['common/canAccessAutomationApp_RolesOnly'])
      },
      showCaptureApp() { return this.$store.getters['common/showCaptureApp'] },
      showFormsApp() {
        return this.$store.getters['common/APP_ACCESS_FORMS']
          && ((this.myFormList && this.myFormList.length > 0) || this.$store.getters['common/canAccessFormsApp_RolesOnly'])
      },
      showReportsApp() { return this.$store.getters['common/showReportsApp'] },
      showSignatureApp() { return this.$store.getters['common/showSignatureApp'] },
      showStorageApp() { return this.$store.getters['common/showStorageApp'] },
      showTemplatesApp() { return this.$store.getters['common/showTemplatesApp'] },
      showWorkflowApp() { return this.$store.getters['common/showWorkflowApp'] },
      showWorkspaceApp() { return this.$store.getters['common/showWorkspaceApp'] },
      startWorkflowPathData() { return this.$store.state.workflow.startWorkflowPathData },
      startWorkflowSuccess() { return this.$store.state.workflow.startWorkflowSuccess },
      suppressDefaultApp() { return this.$store.state.common.suppressDefaultApp },
      userHasSchedulers() { return this.$store.state.common.userHasSchedulers },
      userId() { return this.$store.state.common.userID },
      workflowTaskList() {
        return [
          {
            iID: 0,
            sName: 'Tasks',
          },
          {
            iID: 1,
            sName: 'Tasklists',
          },
        ]
      },
    },
    watch: {
      $route(to) {
        if (to.name === 'MyForm') {
          this.showMyForms = true;
        }
      },
      active() {
        if (this.active.includes('configure-workflow-') && !this.showAllWorkflows) {
          this.showAllWorkflows = !this.showAllWorkflows
        }
      },
      activeSubApp() {
        this.showAllWorkflows = false
      },
    },
    async created() {
      const payload = {
        schedulerTypes: 1223,
        orderBy: 0,
        ascendingOrder: true,
      }
      await Promise.all([
        this.$store.dispatch('reporting/loadReports'),
        this.$store.dispatch('reporting/getAllSavedWorkflowReports', true),
        this.$store.dispatch('workflow/getWorkflowAdminList'),
        this.$store.dispatch('automationEngine/getSchedulerList', payload),
      ])

      this.showScheduledReportsOnStart()
      this.snackbarImage = true
      if (this.$route.name === 'MyForm') this.showMyForms = true

      this.$store.subscribeAction((action) => {
        if (action.type == 'workflow/setStartWorkflowSuccess') {
          if (action.payload === 0) {
            this.showSnackbar(false)
          } else if (action.payload === 1) {
            this.showSnackbar(true)
          }
        }
      })
    },
    methods: {
      checkIfFormsCanChangeFirst(location, value) {
        if (!this.areFormsDirty) {
          this.switchActiveSideNav(location)
        } else {
          this.$store.dispatch('forms/setDesiredPendingRoute', { location, value })
        }
      },
      closeNewSignatureDocMenu() { this.showSignatureDocEllipsisMenu = false },
      getCurrentSubMenu(location) {
        let subMenu = ''
        if (location.startsWith('report-')) {
          subMenu = location.substring(7)
          if (subMenu === 'user' || subMenu === 'document') {
            subMenu = `generic/${subMenu}`
          }
          if (subMenu === 'all-scheduled') {
            subMenu = 'scheduledReports'
          }
        } else {
          switch (location) {
            case AdminSideNavOptions.Processing:
              subMenu = this.$store.state.bpa.bpaSideNav
              break;
            case AdminSideNavOptions.Capture:
              subMenu = this.$store.state.capture.captureSideNav
              break;
            case AdminSideNavOptions.DigitalSignature:
              subMenu = this.$store.state.digitalSignature.dsSideNav
              this.switchActiveSubSideNav('signatureInbox', 'TheSignatureInbox')
              break;
              case AdminSideNavOptions.Integration:
              subMenu = this.$store.state.integration.integrationSideNav
              break;
            case AdminSideNavOptions.Search:
              subMenu = this.$store.state.search.searchSideNav
              break;
            case AdminSideNavOptions.Security:
              subMenu = this.$store.state.security.securitySideNav
              break;
            case AdminSideNavOptions.Storage:
              subMenu = this.$store.state.storage.storageSideNav
              break;
            default:
              subMenu = ''
              break;
          }
        }

        if (subMenu) {
          subMenu = `/${subMenu}`
        } else {
          subMenu = ''
        }

        return subMenu
      },
      goToMyForm(location, data) {
        this.switchActiveSideNav(location)
        this.$store.dispatch('forms/clearEform')
        this.$router.push({ name: 'MyForm', params: { formID: data.value, formName: data.name } })
      },
      newDocumentClick() {
        this.closeNewSignatureDocMenu()
        this.showSignatureDocEllipsisMenu = true
      },
      newDocument(isTemplate) {
        const data = {
          filename: '',
          filepath: '',
          totalPages: 0,
          annotationsObject: null,
          assignedUserSignatureList: null,
          isTemplate,
          editTemplateId: 0,
        }
        this.$store.dispatch('digitalSignature/setActiveDocument', data)
        this.$store.dispatch('digitalSignature/setSignatureTempData', {})
        this.$router.push({ name: 'NewDocument' })
      },
      newFormClick() {
        this.$router.push({ name: 'NewForm' })
      },
      newReportClick() {
        this.$store.dispatch('reporting/setSelectedReportConfig', 0)
        this.$store.dispatch('mainViews/changeMainNav', '')
        this.$router.push({ name: 'TheReportConfig' })
      },
      async newWorkflowClick() {
        this.$store.dispatch('workflow/setNewWorkflowClicked', true)
        if (!this.isWorkflowDirty) {
          await this.$store.dispatch('workflow/selectWorkflow', 0)
          this.$router.push({ name: 'WorkflowAdmin' })
        }
      },
      showScheduledReportsOnStart() {
        if (!this.canAccessReports && !this.canAccessCustomReports) {
          this.showScheduledReports = true
        }
        return this.showScheduledReports
      },
      showSnackbar(condition) {
        if (condition) {
          this.$store.dispatch('mainViews/changeMainNav', this.active)
          this.$store.dispatch('workflow/getWorkflowPendingTasks')
        }
        const workflowMessage = condition ? 'Workflow successfully started!' : 'Unable to Start the Workflow.'
        const formMessage = condition ? 'Form Submitted Successfully' : 'Unable to Submit the Form.'
        this.snackbarTitle = this.isForm ? formMessage : workflowMessage
        this.snackbarSubTitle = condition ? 'You can view the progress under "My Requests"' : 'Please try again or contact your administrator'

        this.snackbarImage = condition
        this.showSnackbarBool = true;
        this.$store.dispatch('attributeForm/setIsForm', false)
      },
      signatureDocEllipsisButtonClicked(payload) {
        switch (payload.buttonId) {
          case 'send-for-signature':
            this.newDocument(false)
            break;
          case 'signature-template':
            this.newDocument(true)
            break;
          default:
            break;
        }
        this.closeNewSignatureDocMenu()
      },
      async startNewWorkflow() {
        this.$store.dispatch('attributeForm/setIsForm', false)
        this.showStartWorkflowManager = true
      },
      async switchActiveSideNav(location) {
        this.$store.dispatch('mainViews/changeMainNav', location)
        let topLocation = location
        if (location === 'external-view') {
          this.$router.push({ name: 'ExternalView' })
        }

        if (topLocation.startsWith('report-custom-')) {
          const reportIndex = location.substring(14)
          this.$store.dispatch('reporting/setSelectedRunCustomReport', this.customReports[reportIndex].sName)
          this.$router.push({ name: 'TheCustomReport' })
          return
        }

        if (topLocation.startsWith('saved-workflow-report-')) {
          const reportId = location.substring(22)
          this.$router.push({ name: 'TheSavedWorkflowReport', params: { reportId: reportId } })
          return
        }

        if (topLocation.startsWith('saved-audit-report-')) {
          const reportId = location.substring(19)
          this.$router.push({ name: 'TheSavedAuditReport', params: { reportId: reportId } })
          return
        }

        if (topLocation.startsWith('report-scheduled-')) {
          const schedulerID = location.substring(17)
          this.$router.push({ name: 'EditReportScheduledReporting', params: { schedulerId: schedulerID } })
          return
        }

        if (topLocation.startsWith('editDocumentTemplate-')) {
          const templateId = location.substring(21)
          this.$router.push({ name: 'EditDocumentTemplate', params: { templateId: templateId } })
          return
        }

        if (topLocation.startsWith('configure-email-template-')) {
          const templateId = location.substring(25)
          this.$router.push({ name: 'ConfigureEmailTemplate', params: { templateId: templateId } })
          return
        }

        if (topLocation.startsWith('configure-workflow-')) {
          const workflowId = location.substring(19)
          if (!this.isWorkflowDirty) {
            await this.$store.dispatch('workflow/selectWorkflow', Number(workflowId))
          }
          this.$router.push({ name: 'WorkflowAdmin' })
          return
        }

        if (topLocation.startsWith('configure-task')) {
          const taskId = location.substring(15)
          if (Number(taskId) === 0) {
            this.$router.push({ name: 'WorkflowTasks' })
          } else if (Number(taskId) === 1) {
            this.$router.push({ name: 'WorkflowTasklists' })
          }
        }

        if (topLocation.startsWith('configure-form-')) {
          const formID = topLocation.substring(15)
          this.$router.push({ name: 'EditForm', params: { formID: formID } })
          return
        }

        const subMenu = this.getCurrentSubMenu(location)
        if (location.startsWith('report-')) {
          this.$store.dispatch('reporting/setSelectedRunCustomReport', '')
          topLocation = 'reporting'
        }

        if (location.includes('recent-documents')) {
          const promise1 = this.$store.dispatch('workflow/getWorkflowRecentDocuments')
          const promise2 = this.$store.dispatch('workflow/setIsDataRecentDocuments', true)

          await Promise.all([promise1, promise2])

          this.$router.push({ name: 'TheWorkspaceHome' })
          return
        }

        if (location.startsWith('my-tasks-') || location.startsWith('my-requests-')) {
          let workspaceType = 0
          switch (location) {
            case 'my-tasks-completed':
              workspaceType = 1
              break
            case 'my-requests-pending':
              workspaceType = 2
              break
            case 'my-requests-completed':
              workspaceType = 3
              break
            default:
              workspaceType = 0
              break
          }
          this.$store.dispatch('workflow/setIsDataRecentDocuments', false)
          this.$store.dispatch('workflow/setWorkSpaceCurrentPage', 1)

          this.$router.push({ name: 'TheWorkspaceHome' })
          this.$store.dispatch('workflow/getWorkflowPendingTasks', workspaceType)

          return
        }

        switch (topLocation) {
          case 'barcodes':
          case AdminSideNavOptions.Processing:
          case AdminSideNavOptions.Capture:
          case AdminSideNavOptions.DigitalSignature:
          case AdminSideNavOptions.Integration:
          case 'reporting':
          case AdminSideNavOptions.Search:
          case AdminSideNavOptions.Security:
          case AdminSideNavOptions.Storage:
          case AdminSideNavOptions.FormBuilder:
          case 'vaults':
          case 'forms':
            this.$router.push({ path: `/${topLocation}${subMenu}` })
            break;
          case 'home':
            this.$router.push({ name: 'TheHome' })
            break;
          case 'templates':
            this.$router.push({ name: 'AllEmailTemplates' })
            break;
          case 'workflow':
            this.$router.push({ name: 'AllWorkflows' })
            break;
          default:
            break;
        }
      },
      switchActiveSubSideNav(name, locationName) {
        this.switchActiveSideNav(name)
        this.$router.push({ name: locationName })
      },
      toggleAllForms() {
        this.showAllForms = !this.showAllForms
        if (!this.areFormsDirty) {
          this.$router.push({ name: 'AllForms' })
        } else {
          this.$store.dispatch('forms/setDesiredPendingRoute', 'AllForms')
        }
      },
      toggleAllReports() {
        this.showAllReports = !this.showAllReports
      },
      toggleAllWorkflows() {
        this.showAllWorkflows = !this.showAllWorkflows
      },
      toggleAutomationEngine() {
        this.showAutomationEngine = !this.showAutomationEngine
      },
      toggleCaptureSetup() {
        this.showCaptureSetup = !this.showCaptureSetup
      },
      async toggleDocumentTemplates() {
        this.showDocumentTemplates = !this.showDocumentTemplates
      },
      toggleEmailTemplates() {
        this.showAllEmailTemplates = !this.showAllEmailTemplates
      },
      toggleFieldMapping() {
        this.showFieldMapping = !this.showFieldMapping
      },
      toggleFieldMappingApp() {
        this.fieldMappingList = !this.fieldMappingList
      },
      toggleIntegrations() {
        this.showIntegrations = !this.showIntegrations
      },
      toggleMyForms() {
        this.showMyForms = !this.showMyForms
        if (!this.areFormsDirty) {
          this.$router.push({ name: 'MyForms' })
        } else {
          this.$store.dispatch('forms/setDesiredPendingRoute', 'MyForms')
        }
      },
      toggleMyRequests() {
        this.showMyRequests = !this.showMyRequests
      },
      toggleMyTasks() {
        this.showMyTasks = !this.showMyTasks
      },
      toggleSavedAuditReports() {
        this.showSavedAuditReports = !this.showSavedAuditReports
      },
      toggleSavedWorkflowReports() {
        this.showSavedWorkflowReports = !this.showSavedWorkflowReports
      },
      toggleScheduledReports() {
        this.showScheduledReports = !this.showScheduledReports
      },
      toggleSearchSetup() {
        this.showSearchSetup = !this.showSearchSetup
      },
      toggleSecurityAndPermissions() {
        this.showSecurityAndPermissions = !this.showSecurityAndPermissions
      },
      toggleStorageSetup() {
        this.showStorageSetup = !this.showStorageSetup
      },
      toggleWorkflowTasks() {
        this.showWorkflowTasks = !this.showWorkflowTasks
      },
      workflowNotStarted() {
        this.showSnackbar(false)
      },
      workflowSideNavSelect(workflowType) {
        const selectWorkflowPayload = {
          id: 0,
          name: '',
        }

        this.$store.dispatch('workflow/selectWorkflow', selectWorkflowPayload.id)
        this.$store.dispatch('mainViews/changeMainNav', workflowType)
        this.$router.push({ name: 'AllWorkflows' })
      },
    },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .side-nav-content{
    height: 100vh;
    z-index: 5;
  }

  .dialog-window {
    max-height: 100%;
    z-index: 30;
  }

  @media (max-width: $phone){
    .dialog-window{
      min-width: 0px;
      padding: 10px;
    }
  }

  .calc-height{
    min-height: 100vh;
    height: 100%;
  }
  .chevronBox {
    width: 100%;
    padding: 15px;
    cursor: pointer;
  }
  .chevron {
    fill: $white;
  }
  #copyright {
    @include Text;
    position: absolute;
    bottom: 0;
    color: $white;
    & span {
      display: block;
      margin: 5px 10px;
    }
  }
  hr {
    border-width: 1px;
    border-color: $grey-400;
  }
  .side-nav{
    width: inherit;
    height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 56px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .link-box {
    height: 56px;
    display: flex;
    align-items: center;
    padding: 10px 0 0 37px;
    opacity: 1;
    position: sticky;
    border-bottom: 1px solid $plumb-500;

  }
  .link-box-mini {
    padding: 0px 0 6px 7px;
  }
  .logo {
    fill: $orange-300;
    width: 100%;
  }
  .height-fix {
    height: 32px;
  }
  .height-fix-mini {
    height: 18px;
  }
  .vasion-icon-bar {
    width: 38px;
    margin-right: 5px;
    padding-top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .plain-text-row {
    padding-left: 25px;
  }

  .vasion-side-nav-li-icon {
    fill: $white;
  }

  .vasion-side-nav-li-background {
    width: 278px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    color: $white;
    padding: 0 0 0 0px;
    margin: 0 6px 1px 6px;
    height: 40px;
    fill: $white;
    cursor: pointer;
    @include Subtitle;
  }

  .non-arrow-row {
    padding-left: 15px;
  }

  .vasion-side-nav-li-background-mini {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0px;
    .vasion-icon-bar {
      margin-right: 0;
    }
  }

  .vasion-ul-with-icons {
    padding: 7px 0 0 0;
    // white-space: nowrap;
  }

  @mixin vasion-active-list-item {
    opacity: 1;
    background-color: rgba(255, 255, 255, .12);
    border-radius: 4px;
  }

  .vasion-active-list-item {
    @include vasion-active-list-item()
  }

  .vasion-ul-with-icons li {
    margin-top: 8px;
    :hover {
      @include vasion-active-list-item()
    }
  }

  .vasion-ul-with-icons li:first-child {
    margin-top: 0px;
  }

  .vertical-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 56px);
  }

  .fade-enter /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .fade-enter-to {
    transition: opacity .5s ease;
  }
  .fade-leave {
    display: none;
  }
  .fade-leave-to {
    display: none;
  }

  .start-action-div {
    padding-top: 15px;
  }

  ::-webkit-scrollbar-track {
    background: $plumb-500;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 25px;
  }
</style>
