/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const addSignatureDocumentHistory = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    await axios.post('v1/Document/AddSignatureDocumentHistory', payload)
    return true
  } catch (error) {
    console.warn(error)
    return false
  }
}

const addTaskListsToDocument = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Tasks: { Values } } } = await axios.post('v1/Document/AddTaskListsToDocument', payload)
    return Values
  } catch (error) {
    console.warn(error)
  }
}

const addTaskToDoc = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/AddTaskToDoc', payload)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
      console.warn(error)
  }
}

const approveDocuments = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Workflow/MassApproval', payload)
    if (response) {
      return true
    }
  } catch (error) {
      console.warn(error)
  }
}

const approveDocumentsWithSteps = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Workflow/MassApprovalWithSteps', payload)
    return response.data ? response.data : null
  } catch (error) {
      console.warn(error)
  }
}

const approveSignatureDocument = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/ApproveSignatureDocument', payload)
    if (response && response.Value && response.Value === 'True') {
      return true
    } else {
      return false
    }
  } catch (error) {
      console.warn(error)
  }
}

const burnAnnotations = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/BurnAnnotations', payload)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const canRemoveAttributeField = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Document/CanRemoveIndexField?indexFormID=${payload.formID}&fieldName=${payload.fieldName}`)
    if (response?.data) {
      return response.data.Value === 'True'
    }
    return false
  } catch (error) {
    console.warn(error)
  }
}

const checkFileExtension = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/CheckFileExtension', payload)
    if (response?.data) {
      return response.data.Value
    }
    return false
  } catch (error) {
    console.warn(error)
  }
}

const completeTask = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/CompleteTask', payload)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const copyDocuments = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/CopyDocuments', payload)
    if (response && response.data) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const createVersionAttributeFormPDF = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/CreateVersionAttributeFormPDF', { Value: payload })
    if (response) {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteDocCacheFiles = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Imaging/DeleteDocCacheFiles', payload)
    if (response) {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteDocumentLink = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/DeleteLink', payload)
    if (response) {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteDocumentPages = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Document/DeleteDocumentPages', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const deleteMultipleDocuments = async (apiToken, documentIDString) => {
  const axios = createAxiosInstance(apiToken)
  const payload = {
    DocumentIdString: documentIDString,
  }

  try {
    const response = await axios.post('v1/Document/DeleteDocuments', payload)
    if (response?.data) {
      return response.data.sGenRes
    } else {
      return 'Error while deleting'
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteSignatureDocumentTemplate = async (apiToken, documentIDString) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Document/DeleteSignatureDocumentTemplate', {
      data: {
        Value: documentIDString,
      },
    })
    if (response) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const downloadAttributeFormDocument = async (apiToken, documentID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const getStatement = `v1/Download/WebDownloadAttributeForm?Value=${documentID}`

    const response = await axios.get(getStatement, { responseType: 'arraybuffer' })

    if (response?.data && response.headers['content-type'] !== 'text/html') {
      return response.data
    }
    return false
  } catch (error) {
    console.warn(error)
  }
}

const downloadVasionDocument = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const getStatement = `v1/Download/WebDownload?T=${payload.T}&BurnAnnotations=${payload.BurnAnnotations}&\
UserID=${payload.UserID}&IncludeWatermark=${payload.IncludeWatermark}&ExtractPages=${payload.ExtractPages}&\
ConvertToPDF=${payload.ConvertToPDF}&StreamPDF=${payload.StreamPDF}&IncludeHistory=${payload.IncludeHistory}`

    const response = await axios.get(getStatement, { responseType: 'arraybuffer' })

    if (response?.data && response.headers['content-type'] !== 'text/html') {
      return response.data
    }
    return false
  } catch (error) {
    console.warn(error)
  }
}

const emailAttributeForm = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/EmailAttributeForm', payload)
    if (response && response.data) {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const emailMultiDocument = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/EmailMulti', payload)
    if (response && response.data) {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const emailSingleDocument = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/Email', payload)
    if (response) {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const exportDocumentHistoryCSV = async (apiToken, documentID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Download/ExportDocumentHistory?DocumentId=${documentID}&IsPDF=false`, { responseType: 'arraybuffer' })
    if (response?.data) {
       return response
    }

    return 0
  } catch (error) {
    console.warn(error)
  }
}

const exportSignatureDocumentHistory = async (apiToken, documentID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Download/ExportSignatureDocumentHistory?Value=${documentID}`, { responseType: 'arraybuffer' })
    if (response?.data) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const exportDocumentHistoryPDF = async (apiToken, documentID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Download/ExportDocumentHistory?DocumentId=${documentID}&IsPDF=true`, { responseType: 'arraybuffer' })
    if (response?.data) {
      return response
    }

    return 0
  } catch (error) {
    console.warn(error)
  }
}

const exportWorkflowHistoryPDF = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Download/DownloadWorkflowHistory?DocumentId=${payload.documentID}&UserId=${payload.userID}`, { responseType: 'arraybuffer' })
    if (response?.data) {
      return response
    }

    return 0
  } catch (error) {
    console.warn(error)
  }
}

const getAnnotationImageList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Values } } = await axios.get('v1/Document/GetAnnotationImageList')
    return Values
  } catch (err) {
    console.warn(err)
  }
}

const getDefaultLookupId = async (apiToken, attributeFormId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: response } = await axios.get(`v1/Document/GetDefaultLookup?Value=${attributeFormId}`)
    return response
  } catch (err) {
    console.warn(err)
  }
}

const getDocumentDetails = async (apiToken, documentID, workflowStepID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const payload = {
      DocumentId: documentID,
      WorkflowStepID: workflowStepID,
      IncludeFileInfo: true,
      IncludeIndexForm: true,
      IncludeNotes: true,
      IncludeWorkflow: true,
      IncludeLinks: true,
      IncludeVersions: true,
      CopyPDF: true,
    }
    const response = await axios.post('v1/Document/GetDocument', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentDetailsByGuid = async (apiToken, documentID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const payload = {
      GUID: documentID,
      IncludeFileInfo: true,
      IncludeIndexForm: true,
      IncludeNotes: true,
      IncludeWorkflow: true,
      IncludeLinks: true,
      IncludeVersions: true,
      CopyPDF: true,
    }
    const response = await axios.post('v1/Document/GetDocumentByGUID', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentDetailsWithPayload = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/GetDocument', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentDetailsWithVersion = async (apiToken, documentID, version) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const payload = {
      DocumentId: documentID,
      IncludeFileInfo: true,
      IncludeIndexForm: true,
      IncludeNotes: true,
      IncludeWorkflow: true,
      IncludeLinks: true,
      IncludeVersions: true,
      VersionNumber: version,
      CopyPDF: true,
    }
    const response = await axios.post('v1/Document/GetDocument', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentDownloadCount = async (apiToken, documentGuid) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Document/GetGUIDDownloadCount?Value=${documentGuid}`)
    if (response?.data) {
       return response
    }

    return null
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentDueDate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/GetDocumentDueDate?documentID=${payload.DocumentID}&workflowID=${payload.WorkflowID}`)
    if (response?.data) {
       return response.data.Value
    }

    return null
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentHistory = async (apiToken, documentID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/LoadDocumentHistory', {
        Value: documentID.toString(),
    })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentImageURL = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Imaging/GetFullPage', payload)
    // TODO: response.data.Image.Height and response.data.Image.Width - that's the actual height and width of the image - we can use this further upstream
    if (response?.data) {
      const result = {
        url: `${axios.defaults.baseURL}v1/Imaging/GetFullPageImage?t=${response.data.Image.Token}`,
        width: response.data.Image.Width,
        height: response.data.Image.Height,
        annotations: response.data.Annotations.Values,
        PageDPI: response.data.Image.DPI,
        OriginalHeight: response.data.Image.OriginalHeight,
        OriginalWidth: response.data.Image.OriginalWidth,
        OriginalDPI: response.data.Image.OriginalDPI,
      }

      return result
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentLineItems = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Document/GetDocumentLineItems?Value=${payload.Value}`)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentPriority = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Document/GetDocumentPriority?Value=${payload}`)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentVersions = async (apiToken, documentId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/GetDocumentVersions', {
      DocumentId: documentId.toString(),
    })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getEmailBodyWithLinks = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/GetEmailBodyWithLinks', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFieldType = async (apiToken, fieldName) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Document/GetFieldControlType?Value=${fieldName}`)
    if (response?.data) {
      return response.data.Value
    }

    return 0
  } catch (error) {
    console.warn(error)
  }
}

const getFileImageURL = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Imaging/GetFileFullPage', payload)
    if (response?.data) {
      const result = {
        url: `${axios.defaults.baseURL}v1/Imaging/GetFullPageImage?t=${response.data.Image.Token}`,
        width: response.data.Image.Width,
        height: response.data.Image.Height,
        annotations: response.data.Annotations.Values,
        PageDPI: response.data.Image.DPI,
        OriginalHeight: response.data.Image.OriginalHeight,
        OriginalWidth: response.data.Image.OriginalWidth,
        OriginalDPI: response.data.Image.OriginalDPI,
      }

      return result
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFilePageCount = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Imaging/GetFilePageCount', payload)
    return Value
  } catch (error) {
    console.warn(error)
    return 0
  }
}

const getFilterFields = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/GetFilterFields', payload)
    if (response && response.data) return response.data
  } catch (error) {
    console.warn(error)
    return []
  }
}

const getSignatureDocumentHistory = async (apiToken, documentID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { History: { Values } } } = await axios.get(`v1/Document/GetSignatureDocumentHistory?Value=${documentID}`)
    return Values
  } catch (error) {
    console.warn(error)
    return []
  }
}

const getThumbnail = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Imaging/GetThumbnailImageByPage', payload)
    return Value
  } catch (error) {
    console.warn(error)
    return 0
  }
}

const incrementDownloadCounter = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/IncrementDownloadCounterForGUID', payload)
    if (response && response.status === 200) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.warn(error)
    return false
  }
}

const insertReplacePages = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/InsertReplacePages', payload)
    return response
  } catch (error) {
    console.warn(error)
    return false
  }
}

const legalHoldDocuments = async (apiToken, documentIDString) => {
  const axios = createAxiosInstance(apiToken)
  const payload = {
    DocumentIdString: documentIDString,
  }

  try {
    const response = await axios.post('v1/Document/LegalHold', payload)
    if (response?.data) {
      return response.data.sGenRes
    } else {
      return 'Error while deleting'
    }
  } catch (error) {
    console.warn(error)
  }
}

const linkDocuments = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    await axios.post('v1/Document/LinkDocuments', payload)
  } catch (error) {
    console.warn(error)
  }
}

const loadDocLinks = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const result = await axios.post('v1/Document/LoadDocLinks', payload)
    return result.data
  } catch (error) {
    console.warn(error)
  }
}

const logSignatureAdded = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    await axios.post('v1/Document/LogSignatureAdded', payload)
  } catch (error) {
    console.warn(error)
  }
}

const mergeDocuments = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/MergeDocs', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const mergeDocumentsFilter = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/ValidateMergeDocumentIDs', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const mergeLinkedDocuments = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/MergeLinkedDocsToBase', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const moveDocuments = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/MoveDocuments', payload)
    if (response && response.data) {
      return response.data.sGenRes
    }
  } catch (error) {
    console.warn(error)
  }
}

const printDocumentAttributeForm = async (apiToken, documentID) => {
  const axios = createAxiosInstance(apiToken)

  try {
    const getStatement = `v1/Download/WebDownloadForPrintAttributeForm?Value=${documentID}`

    const response = await axios.get(getStatement)
    if (response?.data) {
      return response.data
    }
    return false
  } catch (error) {
    console.warn(error)
  }
}

const recallSignatureDocument = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: response } = await axios.post('v1/Document/RecallSignatureDocument', payload)
    return response
  } catch (error) {
    console.warn(error)
  }
}

const rejectDocument = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: response } = await axios.post('v1/Workflow/MassRejection', payload)
    return response
  } catch (error) {
    console.warn(error)
  }
}

const rejectSignatureDocument = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/RejectSignatureDocument', payload)
    if (response && response.data.Value && response.data.Value === 'True') {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.warn(error)
  }
}

const rollbackVersion = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const {data: response } = await axios.post('v1/Document/RollbackVersion', payload)
    return response
  } catch (error) {
    console.warn(error)
  }
}

const rotateDocument = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: response } = await axios.post('v1/Document/RotateDocumentPage', payload)
    return response
  } catch (error) {
    console.warn(error)
  }
}

const runEFormLookup = async (apiToken, payload) => {
    const axios = createAxiosInstance(apiToken)
    try {
      const { data } = await axios.post('v1/Document/RunEFormLookup', payload)
      return data
    } catch (error) {
      console.warn(error)
    }
}

const runLinkedDocumentSearch = async (apiToken, documentID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Document/RunLinkedDocumentSearch?Value=${documentID}`)
    return response.data
  } catch (error) {
    console.warn(error)
  }
}

const saveBulkProcessActionAIP = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SaveBulkProcessActionAIP', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveBulkProcessActionBarcode = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SaveBulkProcessActionBarcode', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveBulkProcessActionOCR = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SaveBulkProcessActionOCR', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveDocumentAttributes = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/SaveForm', payload)
    if (response) {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveDocumentRedaction = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Imaging/SaveAnnotations', payload)
    if (response) {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveNote = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/NewNote', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const savePriority = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/SetDocumentPriority', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveThumbnailInfo = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Imaging/SaveThumbnailInfo', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const sendNewSignatureDocumentEmail = (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    axios.post('v1/Document/SendNewSignatureDocumentEmail', payload)
  } catch (error) {
    console.warn(error)
  }
}

const takeOwnershipDocuments = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: response } = await axios.post('v1/Workflow/TakeOwnership', payload)
    return response
  } catch (error) {
      console.warn(error)
  }
}

const terminateDocuments = (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return axios.post('v1/Workflow/TerminateWF', payload)
  } catch (error) {
    console.warn(error)
  }
}

const tryStreamPDF = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Document/StreamPDF?Value=${payload.documentID}`, {
      responseType: 'blob',
      signal: payload.requestController.signal
    })

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const updateDocumentGUIDs = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)

  try {
    const response = await axios.post('v1/Document/UpdateDocumentGUIDS', payload)
    if (response) {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const updateNote = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/UpdateNote', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const uploadTempDocForMerge = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Upload/UploadFileForMerge', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const useEFormDBLookupResult = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/UseEFormDBLookupResult', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

export {
  addSignatureDocumentHistory,
  addTaskToDoc,
  addTaskListsToDocument,
  approveDocuments,
  approveDocumentsWithSteps,
  approveSignatureDocument,
  burnAnnotations,
  canRemoveAttributeField,
  checkFileExtension,
  completeTask,
  copyDocuments,
  createVersionAttributeFormPDF,
  deleteDocumentLink,
  deleteDocCacheFiles,
  deleteDocumentPages,
  deleteMultipleDocuments,
  deleteSignatureDocumentTemplate,
  downloadAttributeFormDocument,
  downloadVasionDocument,
  emailAttributeForm,
  emailMultiDocument,
  emailSingleDocument,
  exportDocumentHistoryPDF,
  exportSignatureDocumentHistory,
  exportWorkflowHistoryPDF,
  exportDocumentHistoryCSV,
  getAnnotationImageList,
  getDefaultLookupId,
  getDocumentDetails,
  getDocumentDetailsByGuid,
  getDocumentDetailsWithPayload,
  getDocumentDetailsWithVersion,
  getDocumentDownloadCount,
  getDocumentDueDate,
  getDocumentHistory,
  getDocumentImageURL,
  getDocumentLineItems,
  getDocumentPriority,
  getDocumentVersions,
  getEmailBodyWithLinks,
  getFieldType,
  getFileImageURL,
  getFilePageCount,
  getFilterFields,
  getSignatureDocumentHistory,
  getThumbnail,
  incrementDownloadCounter,
  insertReplacePages,
  legalHoldDocuments,
  linkDocuments,
  loadDocLinks,
  logSignatureAdded,
  mergeDocuments,
  mergeDocumentsFilter,
  mergeLinkedDocuments,
  moveDocuments,
  printDocumentAttributeForm,
  recallSignatureDocument,
  rejectDocument,
  rejectSignatureDocument,
  rollbackVersion,
  rotateDocument,
  runLinkedDocumentSearch,
  runEFormLookup,
  saveBulkProcessActionAIP,
  saveBulkProcessActionBarcode,
  saveBulkProcessActionOCR,
  saveDocumentAttributes,
  saveDocumentRedaction,
  saveNote,
  savePriority,
  saveThumbnailInfo,
  sendNewSignatureDocumentEmail,
  takeOwnershipDocuments,
  terminateDocuments,
  tryStreamPDF,
  updateDocumentGUIDs,
  updateNote,
  uploadTempDocForMerge,
  useEFormDBLookupResult,
}
