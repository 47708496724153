<template>
  <div
    v-if="dataArray.length"
    :id="divIDComputed"
    v-click-outside="close"
    class="vasion-droplist"
    :style="{ width: inputGroupWidth}"
  >
    <VasionInput
      v-if="!type.includes('check')"
      v-bind="$attrs"
      :id="inputIDComputed"
      v-model="selectedDisplay"
      :inputType="inputType"
      :title="title"
      :arrowValue="open"
      :arrowClickCallback="toggleOpen"
      :placeholder="placeholder"
      :isDisabled="isDisabled"
      :required="required"
      readonly
      @mousedown="tabOpen"
      @keydown="inputKeyDown"
      @focusout="inputFocusOut"
      @focus="tabOpen"
    />
    <div v-else>
      <span class="multi-droplist-tab-label">{{ title }}</span>
      <span v-if="required" class="vasion-required-indicator">*</span>
      <div
        class="multi-droplist-container"
        tabindex="0"
        @mousedown="toggleOpen"
      >
        <div class="multi-droplist-tabs">
          <span v-if="!selectedArrayLocal || selectedArrayLocal.length < 1" class="multi-droplist-placeholder">{{ placeholder }}</span>
          <div
            v-for="(item, index) in selectedArrayLocal"
            :id="item.value"
            :key="index"
            class="droplist-tab"
          >
            <span style="width: 100%">{{ item.name }}</span><VasionExIcon class="droplist-tab-icon" @click="handleSelection(item, true)" />
          </div>
        </div>
        <i v-if="open" class="drop-arrow"><VasionArrowDropUpIcon /></i>
        <i v-if="!open" class="drop-arrow"><VasionArrowDropDownIcon /></i>
      </div>
    </div>
    <transition name="fade">
      <ul
        v-if="open && !isDisabled"
        :id="title && title.length > 0 && title !== undefined
          ? `${title.toLowerCase()}-ul`
          : `${uniqueId}-ul`"
        ref="dropdownList"
        role="list"
        class="list"
        :class="{ 'lowerHeight': decreaseListHeight }"
        :style="{ width: dropListWidth, bottom: offset }"
      >
        <li v-if="showSearchField" :class="{ 'search-item': showSearchField }" tabIndex="0">
          <VasionInput
            id="vasion-droplist-search"
            ref="searchInput"
            v-model="searchValue"
            v-debounce:100ms="searchList"
            name="vasion-droplist-search"
            class="search-field"
            :placeholder="searchPlaceholder"
            :required="required"
            :width="'95'"
            :widthUnit="'%'"
            inputType="search-white"
            @focusout="open = type.includes('check')"
            @listNavigation="navigateList"
          />
        </li>
        <li
          v-for="(item, index) in dataArrayLocal"
          :key="item[valueName] ? item[valueName] : index"
          :class="{
            'selected-item': selectedArrayLocal.findIndex((o) => { return (o[valueName] === item[valueName] || o === item); }) != -1,
            'multiselect-list-item': type.includes('check')
          }"
          role="listitem"
          class="list-item"
          tabIndex="1"
          @click="handleSelection(item, false)"
          @keyup.enter="handleSelection(item, false)"
          @keydown="navigateList"
        >
          <slot :item="item">
            {{ item }}
          </slot>
          <VasionCheckbox
            v-if="type.includes('check')"
            :id="item[valueName].toString()"
            :class="{ 'selected-item': selectedArrayLocal.findIndex((o) => { return o[valueName] === item[valueName]; }) != -1 }"
            :checked="selectedArrayLocal.findIndex((o) => { return o[valueName] === item[valueName]; }) != -1"
            :noLabel="true"
          />
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'VasionDropList',
  inheritAttrs: false,
  props: {
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
      required: false,
    },
    dataArray: {
      type: Array,
      required: true,
    },
    decreaseListHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayName: {
      type: String,
      required: false,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    openAbove: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select...',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    returnValueOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: 'Type to search ...',
    },
    showSearchField: {
      type: Boolean,
      default: true,
      required: false,
    },
    theID: {
      type: String,
      default: 'drop-list',
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    type: {
      type: String,
      default: 'plain-list',
      required: false,
      validator: function (value) {
        return ['plain-list', 'check-list', 'plain-list-search', 'check-list-search', 'number'].indexOf(value) !== -1
      },
    },
    // eslint-disable-next-line
    value: {
      required: true,
    },
    valueName: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      default: '326px',
      required: false,
    },
  },
  data: function () {
    return {
      dataArrayLocal: this.dataArray,
      forceClosed: false,
      inHandleSelection: false,
      open: false,
      searchValue: '',
      selectedArrayLocal: [],
      selectedIndex: -1,
      selection: null,
      uuid: this.$uuid.v1(),
    }
  },
  computed: {
    computedDataArray: {
      get: function () {
        return this.dataArray
      },
      set: function (newArray) {
        return newArray
      },
    },
    divIDComputed() {
      return this.title && this.title.length > 0 && this.title !== undefined ? this.title.toLowerCase() : this.uniqueId
    },
    dropListWidth() {
      const newWidth = document.getElementById(this.divIDComputed).offsetWidth

      if (newWidth <= 0) {
        return '100%'
      } else {
        return `${newWidth}px`
      }
    },
    inputGroupWidth() {
      return this.$options.propsData.width ? `${this.width}px` : this.width
    },
    inputIDComputed() {
      return `${this.divIDComputed}-input`
    },
    inputType() {
      if (this.type.includes('search')) {
        return 'search-white-top'
      }

      return 'top-white'
    },
    offset() {
      const result = `${26}px`
      if (this.openAbove === true) {
        return result
      }

      const id = this.divIDComputed
      const viewportOffset = document.getElementById(id).getBoundingClientRect()
      const { top } = viewportOffset;
      const viewport = document.documentElement.clientHeight

      if (viewport !== 0 && ((top / viewport) > 0.75)) {
        return result
      } else {
        return false
      }
    },
    selectedDisplay: {
      get: function () {
        let returnValue
        if (this.selection) {
          switch (typeof this.selection) {
            case 'string':
              returnValue = this.selection
              break;
            case 'object':
              returnValue = this.selection[this.displayName]
              break;
            case 'number':
              returnValue = this.selection
              break;
            default:
              returnValue = ''
              break;
          }
        }
        return returnValue
      },
      set: function (newArray) {
        return newArray
      },
    },
    uniqueId() {
      return this.theID === 'drop-list' ? this.$uuid.v1().toString() : this.theID
    },
  },
  watch: {
    dataArray: function (newVal) {
      this.dataArrayLocal = newVal
    },
    open: function (newVal) {
      this.$emit("openDropDown", newVal)
    },
    value: function (newValue, oldValue) {

      if (this.type && this.type.includes('check')) {
        if (this.value) {
          this.selection = this.value[this.displayName] ? this.value[this.displayName] : this.value
        } else {
          this.selection = ''
        }
      } else if (newValue !== oldValue && !this.inHandleSelection) {
        if (newValue) {
          this.handleSelection(this.value, false)
        } else {
          this.selection = ''
          this.selectedIndex = -1
        }
      }
    },
  },
  mounted: function() {
    this.selection = this.calculateSelection()
  },
  methods: {
    calculateSelection() {
      if (typeof this.dataArray[0] !== 'object') {
        return this.value
      } else {
        const localSelection = this.dataArray.filter((obj, index) => {
          const dn = this.displayName
          const vn = this.valueName
          const v = this.value
          // Select the matching selection value no matter which part matches
          const match = (obj[dn] === v || obj[vn] === v || obj[dn] === v[dn] || obj[vn] === v[vn])
          if (match) {
            this.selectedIndex = index
            return true
          } else {
            return false
          }
        })
        return localSelection[0] ? localSelection[0][this.displayName] : ''
      }
    },
    close: function () {
      if (this.closeOnOutsideClick) {
        this.open = false
      }
    },
    filteredValues(items, text) {
      if (!text) { return items }
      const input = text.toLowerCase()

      return items.filter(item => {
        const target = typeof item[this.displayName] === 'string' ? item[this.displayName].toLowerCase() : item.name.toLowerCase()
        return target.startsWith(input)
      })
    },
    // eslint-disable-next-line
    handleSelection(item, forceCloseDroplist) {
      if (item === {}) {
        return
      }
      this.inHandleSelection = true
      if (forceCloseDroplist) {
        this.open = false
        this.forceClosed = true
      }
      const targetValue = this.valueName ? item[this.valueName] : item
      const name = this.valueName

      const lastSelectedIndex = this.selectedIndex
      this.selectedIndex = this.dataArray.findIndex((o) => { return (name ? o[name] : o) === targetValue; })
      const existsIndex = this.selectedArrayLocal.findIndex((o) => { return (name ? o[name] : o) === targetValue; })
      if (this.type.includes('check')) {
        this.selection = ''
        if (existsIndex === -1) {
          // eslint-disable-next-line
          this.selectedArrayLocal.push(item)[this.valueName]
          // eslint-disable-next-line
        } else {
          this.selectedArrayLocal.splice(existsIndex, 1);
        }
        this.$emit('input', this.selectedArrayLocal)
        this.open = true
      } else {
        const selectionValue = this.dataArray[this.selectedIndex]
        const selectionDisplay = this.displayName ? this.dataArray[this.selectedIndex][this.displayName] : this.dataArray[this.selectedIndex]

        this.selection = selectionDisplay

        let emitValue = this.returnValueOnly ? selectionValue.name : selectionValue
        if (this.selectedIndex != lastSelectedIndex) {
          this.$emit('input', emitValue)
        }

        this.open = false
      }

      this.$nextTick(()=>{
        this.inHandleSelection = false
      })
    },
    inputFocusOut() {
      this.$emit('inputFocusOut')
    },
    inputKeyDown(key) {
      this.$emit('inputKeyDown', key)
    },
    navigateList(e) {
      const ul = this.$refs.dropdownList // This is the ul (dropdown list)
      const first = ul.firstChild // This is the search input, not the li the search input resides in

      let keyCode = ''
      keyCode = !e.code ? e : e.code

      switch (keyCode) {
        case 'ArrowUp':
          if (document.activeElement.parentNode.parentNode.parentNode === first) {
            break
          } else if (document.activeElement.previousSibling === first) {
            document.activeElement.previousSibling.firstChild.childNodes[3].childNodes[1].focus() // Select the search input, not the li that the search input resides in
          } else {
            document.activeElement.previousSibling.focus()
          }
          e.preventDefault()
          break
        case 'ArrowDown':
          if (document.activeElement.parentNode.parentNode.parentNode === first && this.dataArrayLocal.length > 0) {
            document.activeElement.parentNode.parentNode.parentNode.nextSibling.focus() // Select the search input, not the li that the search input resides in
          } else if (document.activeElement.nextSibling && this.dataArrayLocal.length > 0) {
            document.activeElement.nextSibling.focus()
          }
          this.open = true
          e.preventDefault()
          break
        default:
          break
      }
      this.open = true
    },
    resetLocalSelectedValues(selectedValues) {
      this.selectedArrayLocal = selectedValues ? selectedValues.slice() : []
    },
    searchList(input) {
      this.dataArrayLocal = input ? this.filteredValues(this.dataArray, input) : this.dataArray
    },
    tabOpen() {
      if (!this.open) {
        this.toggleOpen()
      }
    },
    toggleOpen() {
      if (this.forceClosed) {
        this.open = false
        this.forceClosed = false
      } else {
        this.open = !this.open
        if (this.showSearchField && this.open) {
          setTimeout(() => {
            if (this.$refs.searchInput && this.$refs.searchInput.$refs.mainInput) this.$refs.searchInput.$refs.mainInput.focus()
          }, 200)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .multi-droplist-tab-label {
    height: 12px;
    font-family: $font-family-medium, 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: $grey-400;
  }
  .multi-droplist-container {
    min-height: 40px;
    border-radius: 8px;
    border: solid 1px $grey-100;
    background-color: $white;
    padding: 4px;
    z-index: 1;
    display: flex;
    outline: none;

    .multi-droplist-tabs {
      display: flex;
      font-family: $font-family-book;
      font-size: 16px;
      color: $grey-300;
      width: 100%;
      flex-wrap: wrap;

      .multi-droplist-placeholder {
        display: inline-block;
        height: 25px;
        padding: 5px;
        margin: 2px;
        line-height: 18px;
        text-align: left;
        vertical-align: middle;
      }

      .droplist-tab {
        display: flex;
        align-items: center;
        min-height: 25px;
        background-color: $orange-75;
        color: $grey-700;
        border-radius: 4px;
        padding: 5px;
        margin: 2px;
        line-height: 18px;
        text-align: left;
        vertical-align: middle;

        .droplist-tab-icon {
          fill: $grey-700;
          cursor: pointer;
        }
      }
    }

    .drop-arrow {
      position: relative;
      left: 0;
      top: 3px;
      cursor: pointer;
    }
  }

  .list {
    list-style-type: none;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: $white;
    padding: 0;
    margin-top: 0px;
    padding: 10px 0px;
    position: absolute;
    z-index: 1000;
    max-height: 250px;
    overflow: auto;
    width: 100% !important;
  }
  .lowerHeight {
    max-height: 125px;
  }

  li {
    cursor: pointer;
  }

  .list-item {
    color: $grey-500;
    height: 40px;
    display: block;
    justify-content: space-between;
    align-items: center;
    font-family: $font-family-book;
    font-size: 14px;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .list-item:hover, .list-item:focus {
    background-color: $orange-50;
    color: $orange-300;
    outline: none;
  }
  .multiselect-list-item {
    display: flex;
  }

  .checkbox {
    fill: $grey-400;
  }

  .selected-item {
    color: $orange-300;
    background-color: $orange-50;
    fill: $orange-300
  }
  .selected-item:focus {
    outline: solid 1px $orange-300;
  }

  .search-item {
    background: none !important;
    :hover{
      background: none !important;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .vasion-droplist {
    position: relative;
  }

  .search-field {
    margin-left: 7px;
  }
</style>
