<!-- /* Available icons for icon buttons can be found at https://material.io/resources/icons/?style=baseline */ -->
<template>
  <div v-if="isValid()" class="vasion-button" :class="{ divFullWidth: isFullWidth, hasLabel: label.length > 0 }">
    <label v-if="label.length > 0" id="vasion-button-label" class="vasion-input-label-top">{{ updatedLabel.toUpperCase() }}</label>

    <!-- Icon Button -->
    <md-button
      v-if="isIcon()"
      :class="calculatedClasses()"
      :disabled="isDisabled"
      :md-ripple="ripple"
      @click.prevent="doClickAction"
    >
      <component
        :is="icon"
        v-if="isVasionIcon()"
        :style="{ fill: iconFill }"
        :class="{ 'vasion-icon-disabled': isDisabled }"
      />
      <md-icon v-else>
        {{ icon }}
      </md-icon>
    </md-button>

    <!-- Router Link Button -->
    <md-button
      v-else-if="isRouterLink()"
      :to="to"
      :class="calculatedClasses()"
      :disabled="isDisabled"
      :md-ripple="ripple"
      @click.prevent="doClickAction"
    >
      <slot>Route Link</slot>
    </md-button>

    <!-- Link Button -->
    <md-button
      v-else-if="isLink()"
      :href="href"
      :class="calculatedClasses()"
      :disabled="isDisabled"
      :md-ripple="ripple"
      @click="doClickAction"
    >
      <slot>Link</slot>
    </md-button>

    <!-- Standard Button -->
    <md-button
      v-else-if="isSubmit && !isIcon() && !isRouterLink() && !isLink()"
      type="submit"
      :class="calculatedClasses()"
      :disabled="isDisabled"
      :md-ripple="ripple"
      @click="doClickAction"
    >
      <slot>Submit</slot>
    </md-button>

    <md-button
      v-else
      :class="calculatedClasses()"
      :disabled="isDisabled"
      :md-ripple="ripple"
      @click.prevent="doClickAction"
    >
      <slot>Standard</slot>
    </md-button>
    <!-- TODO: Add support for Secondary Icon buttons -->
  </div>
</template>

<script>
export default {
  name: 'VasionButton',
  props: {
    classProp: {
      type: String,
      default: '',
      required: false,
      validator: function (value) {
        return ['', 'primary', 'primary-light', 'primary-warning', 'secondary', 'secondary-grey', 'secondary-transparent', 'secondary-light', 'secondary-toggled', 'secondary-warning', 'text', 'text-selected', 'text-light', 'tab', 'tab-selected'].indexOf(value) !== -1
      },
    },
    clickActionCallback: {
      type: Function,
      default: null,
      required: false,
    },
    href: {
      type: String,
      default: '',
      required: false,
    },
    icon: {
      type: String,
      default: '',
      required: false,
    },
    iconFill: {
      type: String,
      default: '',
      required: false,
    },
    isDense: {
      type: Boolean,
      default: false,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
      required: false,
    },
    isInErrorState: {
      type: Boolean,
      default: false,
      required: false,
    },
    isRaised: {
      type: Boolean,
      default: false,
      required: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    makeHoverEffectSquare: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    ripple: {
      type: Boolean,
      default: true,
      required: false,
    },
    to: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    updatedLabel() { return this.required ? `${this.label} *` : this.label },
  },
  watch: {
    classProp: function () {
      this.calculatedClasses()
    },
  },
  methods: {
    calculatedClasses: function () {
      const classes = []

      if (this.isIcon()) {
        classes.push('md-icon-button')
        if (this.makeHoverEffectSquare) {
          classes.push('md-icon-button-no-circle')
        }
      }

      switch (this.classProp) {
        case 'primary':
        case 'primary-light':
          classes.push('md-primary')
          break;
        case 'secondary':
        case 'secondary-grey':
        case 'secondary-transparent':
        case 'secondary-light':
        case 'secondary-toggled':
        case 'text':
        case 'text-light':
        case 'tab':
        case 'tab-selected':
          classes.push('md-default')
          break;
        case 'primary-warning':
        case 'secondary-warning':
          classes.push('md-accent')
          break;
        default:
          classes.push('md-default')
          break;
      }

      if (this.isDense) {
        classes.push('md-dense')
      }

      if (this.isRaised) {
        classes.push('md-raised')
      }
      if (this.isInErrorState) {
        classes.push('vasion-tab-error-state')
      }

      switch (this.classProp) {
        case 'primary':
          classes.push('vasion-primary')
          break;
        case 'primary-light':
          classes.push('vasion-primary-light')
          break;
        case 'primary-warning':
          classes.push('vasion-primary-warning')
          break;
        case 'secondary':
          classes.push('vasion-secondary')
          break;
        case 'secondary-grey':
          classes.push('vasion-secondary-grey')
          break;
        case 'secondary-transparent':
          classes.push('vasion-secondary-transparent')
          break;
        case 'secondary-light':
          classes.push('vasion-secondary-light')
          break;
        case 'secondary-toggled':
          classes.push('vasion-secondary-toggled')
          break;
        case 'secondary-warning':
          classes.push('vasion-secondary-warning')
          break;
        case 'text':
          classes.push('vasion-text')
          break;
        case 'text-selected':
          classes.push('vasion-text-selected')
          break;
        case 'text-light':
          classes.push('vasion-text-light')
          break;
        case 'tab':
            classes.push('vasion-tab')
            break;
        case 'tab-selected':
          classes.push('vasion-tab-selected')
          break;
        default:
          break;
      }

      if (this.isFullWidth) {
        classes.push('buttonFullWidth')
      }

      return classes
    },
    doClickAction: function (e) {
      if (typeof this.clickActionCallback === 'function') {
        this.clickActionCallback()
      }

      this.$emit('vasionButtonClicked', e)
    },
    isIcon: function () {
      return typeof this.icon !== 'undefined' && this.icon && this.icon !== ''
    },
    isLink: function () {
      return typeof this.href !== 'undefined' && this.href && this.href !== ''
    },
    isRouterLink: function () {
      return typeof this.to !== 'undefined' && this.to && this.to !== ''
    },
    isValid: function () {
      let error = ''

      const validClassValues = ['', 'primary', 'primary-light', 'primary-warning', 'secondary', 'secondary-grey', 'secondary-transparent', 'secondary-light', 'secondary-toggled', 'secondary-warning', 'text', 'text-selected', 'text-light', 'tab', 'tab-selected']
      if (validClassValues.indexOf(this.classProp) === -1 && typeof this.classProp !== 'undefined' && this.classProp !== '') {
        error = `${error}The value of the class property, '${this.classProp},' is invalid for a vasion-button. Acceptable values are 'primary', 'primary-light', 'primary-warning', 'secondary', 'secondary-transparent', 'secondary-light', 'secondary-toggled', 'secondary-warning', 'text', 'text-selected', 'text-light', 'tab', 'tab-selected', or an empty string. `
      }

      if (this.isRouterLink() && this.isLink()) {
        error = `${error}A vasion-button cannot be both a router-link and a link. Please provide either the 'to' property or the 'href' property, but not both. `
      }

      if (this.isIcon() && (this.isRouterLink() || this.isLink())) {
        error = `${error}A vasion-button cannot be an icon button as well as a router-link button or a link button. Please remove the 'to' and 'href' properties. `
      }

      if (this.isSubmit && (this.isIcon() || this.isRouterLink() || this.isLink())) {
        error = `${error}A vasion-button with isSubmit set to true cannot be an icon button or a router-link button or a link button. Please remove the 'icon', to', and 'href' properties. `
      }

      if (error && error !== '') {
        console.warn(`ERROR: ${error}`)
        return false
      }

      return true
    },
    isVasionIcon: function () {
      return this.isIcon() && this.icon.startsWith('Vasion')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.divFullWidth {
  width: 100%;
}

.md-icon-button-no-circle:before {
  border-radius: 0 !important;
}

.buttonFullWidth {
  width: calc(100% - 15px);
}

#vasion-button-label {
  display: flex;
  margin-bottom: 2px;
  margin-left: 8px;
}
.hasLabel {
  margin-top: 3px !important;
}

.vasion-button {
  display: inline-block;
  padding: 0;

  a, button {
      height: 36px;
      border-radius: 6px;
      font-family: $font-family-book !important;
      color: $white !important;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.71;
      letter-spacing: normal;
      text-align: center;
      z-index: 4;

      &.md-default {
          color: $grey-500 !important;
      }

      &.md-icon-button {
          margin: 0;
      }

      // If we ever need an icon that is any color other than black, we'll need to remove this and handle some other way
      i.md-icon-font {
          color: $grey-500;
      }

      &.vasion-primary {
          background-color: $orange-300 !important;

          &:hover, &:focus {
              background-color: $orange-400 !important;
          }

          &:active {
              background-color: $orange-500 !important;
          }

          &:disabled, &[disabled] {
              background-color: $grey-100 !important;
              color: $grey-400 !important;
          }
      }

      &.vasion-primary-light {
          background-color: $white !important;
          color: $orange-300 !important;

          &:hover, &:focus {
              background-color: $grey-50 !important;
          }

          &:active {
              background-color: $grey-100 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white-24 !important;
              color: $grey-400 !important;
          }
      }

      &.vasion-primary-warning {
          background-color: $red-300 !important;

          &:hover, &:focus {
              background-color: $red-400 !important;
          }

          &:active {
              background-color: $red-500 !important;
          }

          &:disabled, &[disabled] {
              background-color: $grey-75 !important;
              color: $grey-400 !important;
          }
      }

      &.vasion-secondary {
          background-color: $white !important;
          color: $orange-300 !important;
          border-radius: 4px;
          border: solid 1px $orange-300;

          &:hover, &:focus {
              background-color: $orange-50 !important;
          }

          &:active {
              background-color: $orange-75 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-75 !important;
              border-color: $grey-75 !important;
          }
      }

      &.vasion-secondary-grey {
          background-color: $grey-50 !important;
          color: $grey-700 !important;
          border-radius: 4px;

          &:hover, &:focus {
              background-color: $grey-75 !important;
          }

          &:active {
              background-color: $grey-75 !important;
          }

          &:disabled, &[disabled] {
              background-color: $grey-25 !important;
              color: $grey-75 !important;
          }
      }

      &.vasion-secondary-transparent {
          background-color: transparent !important;
          color: $tan !important;
          border-radius: 4px;
          border: solid 1px $tan;

          &:hover, &:focus {
              background-color: $plumb-300 !important;
          }

          &:disabled, &[disabled] {
              background-color: transparent !important;
              color: $grey-75 !important;
              border-color: $grey-75 !important;
          }
      }

      &.vasion-secondary-toggled {
          background-color: $orange-75 !important;
          color: $orange-300 !important;
          border-radius: 4px;
          border: solid 1px $orange-300;

          &:hover, &:focus {
              background-color: $orange-75 !important;
          }

          &:active {
              background-color: $orange-75 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-75 !important;
              border-color: $grey-75 !important;
          }
      }

      &.vasion-secondary-light {
          background-color: $white-24 !important;
          color: $white !important;
          border-radius: 4px;
          border: solid 1px $white;

          &:hover, &:focus {
              background-color: $white-12 !important;
          }

          &:active {
              background-color: $white-24 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white-24 !important;
              color: $white-48 !important;
              border-color: $white;
          }
      }

      &.vasion-secondary-warning {
          background-color: $white !important;
          color: $red-300 !important;
          border-radius: 4px;
          border: solid 1px $red-300;

          &:hover, &:focus {
              background-color: $red-50 !important;
          }

          &:active {
              background-color: $red-75 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-75 !important;
              border-color: $grey-75;
          }
      }

      &.vasion-text {
          background-color: $white !important;
          color: $orange-300 !important;

          &:hover, &:focus {
              background-color: $orange-50 !important;
          }

          &:active {
              background-color: $orange-75 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-400 !important;
          }
      }

      &.vasion-text-selected {
          background-color: $orange-75 !important;
          color: $orange-300 !important;

          &:hover, &:focus {
              background-color: $orange-50 !important;
          }

          &:active {
              background-color: $orange-75 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-400 !important;
          }
      }

      &.vasion-text-light {
          background-color: $white-24 !important;
          color: $white !important;

          &:hover, &:focus {
              background-color: $white-12 !important;
          }

          &:active {
              background-color: $white-24 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white-24 !important;
              color: $white-48 !important;
          }
      }

      &.vasion-tab {
          background-color: $white !important;
          color: $orange-300 !important;
          margin-bottom: 0;
          margin-left: 2px;
          margin-right: 2px;

          &:hover {
              background-color: $orange-50 !important;
              color: $orange-300 !important;
          }

          &:active, &:visited, &:focus {
              background-color: $orange-50 !important;
              color: $orange-300 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-400 !important;
          }
      }

      &.vasion-tab-selected {
          margin-bottom: 0;
          margin-left: 2px;
          margin-right: 2px;
          background-color: $orange-75 !important;
          color: $orange-300 !important;

          &:active, &:visited, &:focus {
              background-color: $orange-75 !important;
              color: $orange-300 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-400 !important;
          }
      }
      &.vasion-tab-error-state {
          margin-bottom: 0;
          margin-left: 2px;
          margin-right: 2px;
          background-color: $error-red !important;
          color: $white !important;

          &:active, &:visited, &:focus {
              background-color: $orange-75 !important;
              color: $orange-300 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-400 !important;
          }
      }
  }
}
</style>
