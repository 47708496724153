// ************** INTERNAL FUNCTIONS ************* //
// **************** Do not export **************** //
function booleanInEnglish(boolValue) {
  return boolValue ? 'Yes' : 'No'
}

const parentFolderVisibilityFunc = function (payload) {
  const { button, item } = payload
  if (button.id !== 'delete-folder-button') {
    return true
  }
  return !item.Values[1]
}

// *********** EXTERNAL FUNCTIONS **************** //
const buildGroupTableData = (dataValues) => {
  const data = !dataValues.dataList ? [] : dataValues.dataList.map((group) => {
    return {
      Values: [
        group.ID,
        group.Name,
        group.Description,
        booleanInEnglish(group.Dynamic),
        booleanInEnglish(group.Active),
        'VasionEllipsisIcon',
      ],
      ValueType: 'String',
    }
  })
  const tableData = {
    Columns: dataValues.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

const buildParentFolderGroupTableData = (dataValues) => {
  const data = !dataValues.dataList ? [] : dataValues.dataList.map((group) => {
    return {
      Values: [
        group.Name,
        group.checkboxes === undefined ? { show: true, value: false } : { show: true, value: group.checkboxes[1].value },
        group.checkboxes === undefined ? { show: true, value: false } : { show: true, value: group.checkboxes[2].value },
        group.checkboxes === undefined ? { show: true, value: false } : { show: true, value: group.checkboxes[3].value },
        group.checkboxes === undefined ? { show: true, value: false } : { show: true, value: group.checkboxes[4].value },
        group.checkboxes === undefined ? { show: true, value: false } : { show: true, value: group.checkboxes[5].value },
        group.checkboxes === undefined ? { show: true, value: false } : { show: true, value: group.checkboxes[6].value },
      ],
      ValueType: 'String',
    }
  })
  const tableData = {
    Columns: dataValues.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

const buildParentFolderUserTableData = (dataValues) => {
  const data = !dataValues.dataList ? [] : dataValues.dataList.map((user) => {
    return {
      Values: [
        user.Username,
        user.checkboxes === undefined ? { show: true, value: false } : { show: true, value: user.checkboxes[1].value },
        user.checkboxes === undefined ? { show: true, value: false } : { show: true, value: user.checkboxes[2].value },
        user.checkboxes === undefined ? { show: true, value: false } : { show: true, value: user.checkboxes[3].value },
        user.checkboxes === undefined ? { show: true, value: false } : { show: true, value: user.checkboxes[4].value },
        user.checkboxes === undefined ? { show: true, value: false } : { show: true, value: user.checkboxes[5].value },
        user.checkboxes === undefined ? { show: true, value: false } : { show: true, value: user.checkboxes[6].value },
      ],
      ValueType: 'String',
    }
  })
  const tableData = {
    Columns: dataValues.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

const buildUserTableData = (dataValues) => {
  const data = !dataValues.dataList ? [] : dataValues.dataList.map((user) => {
    return {
      Values: [
        user.UserId,
        user.Username,
        user.FullName,
        user.Email,
        booleanInEnglish(user.Active),
        'VasionEllipsisIcon',
      ],
      ValueType: 'String',
    }
  })
  const tableData = {
    Columns: dataValues.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

const getEllipsisButtonConfig = (configName) => {
  let buttonConfig = {}

  switch (configName) {
    case 'aip-buttons':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-aip-button',
            name: 'Edit AIP',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
          {
            id: 'delete-aip-button',
            name: 'Delete AIP',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
        ],
      }
      break;
    case 'all-forms':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-form-button',
            name: `Edit ${$formsLabel}`,
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
          {
            id: 'delete-form-button',
            name: `Delete ${$formsLabel}`,
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
        ],
      }
      break;
    case 'automation-engine':
      buttonConfig = {
        buttons: [
          {
            id: 'run-automation-engine-button',
            name: 'Run Manually',
            icon: 'VasionRunIcon',
            permissionGetter: 'common/canRunScheduler',
          },
          {
            id: 'edit-automation-engine-button',
            name: 'Edit',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canConfigureScheduler',
          },
          {
            id: 'delete-automation-engine-button',
            name: 'Delete',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canConfigureScheduler',
          },
        ],
      }
      break;
    case 'custom-folder-view':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-custom-folder-view-button',
            name: 'Edit View',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
          {
            id: 'copy-custom-folder-view-button',
            name: 'Copy View',
            icon: 'VasionFileCopyIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
          {
            id: 'delete-custom-folder-view-button',
            name: 'Delete View',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canConfigureUsers',
          },
        ],
      }
      break;
    case 'document-templates':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-document-templates-button',
            name: 'Edit Template',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
          {
            id: 'delete-document-templates-button',
            name: 'Delete Template',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
        ],
      }
      break;
    case 'email-templates':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-email-templates-button',
            name: 'Edit Template',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canConfigureEmailTemplates',
          },
          {
            id: 'delete-email-templates-button',
            name: 'Delete Template',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canConfigureEmailTemplates',
          },
        ],
      }
      break;
    case 'field-mapping':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-field-mapping-button',
            name: 'Edit Lookup',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canAccessFormsApp_RolesOnly',
          },
          {
            id: 'delete-field-mapping-button',
            name: 'Delete Lookup',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canAccessFormsApp_RolesOnly',
          },
        ],
      }
      break;
    case 'group-buttons':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-group-button',
            name: 'Edit Group',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canConfigurGroups',
          },
          {
            id: 'delete-group-button',
            name: 'Delete Group',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canConfigurGroups',
          },
          {
            id: 'assign-users-button',
            name: 'Assign Users',
            icon: 'VasionGroupIcon',
            permissionGetter: 'common/canConfigurGroups',
          },
          {
            id: 'assign-roles-button',
            name: 'Assign Roles',
            icon: 'VasionSettingsIcon',
            permissionGetter: 'common/canConfigurSecurityRoles',
          },
          {
            id: 'assign-app-security-button',
            name: 'Assign App Security',
            icon: 'VasionApps',
            permissionGetter: 'common/canConfigurSecurityRoles',
          },
          {
            id: 'token-security-button',
            name: 'Token Security',
            icon: 'VasionSecurityIcon',
            permissionGetter: 'common/canConfigurGroups',
          },
        ],
      }
      break;
    case 'parent-folder':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-folder-button',
            name: 'Edit Folder',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canConfigureUsers',
            customVisibilityFunc: parentFolderVisibilityFunc,
          },
          {
            id: 'refresh-for-search-button',
            name: 'Refresh For Search',
            icon: 'VasionRefreshIcon',
            permissionGetter: 'common/canAccessAdmin',
            customVisibilityFunc: parentFolderVisibilityFunc,
          },
          {
            id: 'delete-folder-button',
            name: 'Delete Folder',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canConfigurGroups',
            customVisibilityFunc: parentFolderVisibilityFunc,
          },
        ],
      }
      break;
    case 'search-setup':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-search-setup-button',
            name: 'Edit Search',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
          {
            id: 'delete-search-setup-button',
            name: 'Delete Search',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
          {
            id: 'secure-search-setup-button',
            name: 'Secure Search',
            icon: 'VasionSecurityIcon',
            permissionGetter: 'common/canConfigureUsers',
          },
          {
            id: 'refresh-search-setup-button',
            name: 'Refresh Search',
            icon: 'VasionRefreshIcon',
            permissionGetter: 'common/canRefreshFullTextSearch',
          },
        ],
      }
      break;
    case 'signature-templates':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-signature-template-button',
            name: 'Edit',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canCreateSignatureDocument',
          },
          {
            id: 'use-signature-template-button',
            name: 'Use Template',
            icon: 'VasionActionsLayout',
            permissionGetter: 'common/canCreateSignatureDocument',
          },
          {
            name: 'divider',
            isHorizontalRule: true,
          },
          {
            id: 'delete-signature-template-button',
            name: 'Delete',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canCreateSignatureDocument',
          },
        ],
      }
      break;
    case 'user-buttons':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-user-button',
            name: 'Edit User',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canConfigureUsers',
          },
          {
            id: 'assign-roles-button',
            name: 'Assign Roles',
            icon: 'VasionSettingsIcon',
            permissionGetter: 'common/canConfigurSecurityRoles',
          },
          {
            id: 'assign-app-security-button',
            name: 'Assign App Security',
            icon: 'VasionApps',
            permissionGetter: 'common/canConfigurSecurityRoles',
          },
          {
            id: 'token-security-button',
            name: 'Token Security',
            icon: 'VasionSecurityIcon',
            permissionGetter: 'common/canConfigureUsers',
          },
        ],
      }
      break;
    case 'workflow':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-workflow-button',
            name: 'Edit Workflow',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/isWorkflowAdminUser',
          },
          {
            id: 'delete-workflow-button',
            name: 'Delete Workflow',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/isWorkflowAdminUser',
          },
        ],
      }
      break;
    case 'tasks':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-task-button',
            name: 'Edit Task',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/isWorkflowAdminUser',
          },
          {
            id: 'delete-task-button',
            name: 'Delete Task',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/isWorkflowAdminUser',
          },
        ],
      }
      break;
    case 'tasklist':
      buttonConfig = {
        buttons: [
          {
            id: 'edit-tasklist-button',
            name: 'Edit Tasklist',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/isWorkflowAdminUser',
          },
          {
            id: 'delete-tasklist-button',
            name: 'Delete Tasklist',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/isWorkflowAdminUser',
          },
        ],
      }
      break;
    default:
      buttonConfig = {}
      break;
  }

  return buttonConfig
}

export {
  buildGroupTableData,
  buildParentFolderGroupTableData,
  buildParentFolderUserTableData,
  buildUserTableData,
  getEllipsisButtonConfig,
}
