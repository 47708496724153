<template>
  <div id="main-div">
    <div class="button-section">
      <div class="zoom-buttons" :class="{ 'no-radius-right' : combined }">
        <VasionButton
          name="btnZoomOut"
          title="Zoom Out"
          :icon="'VasionMinusIcon'"
          @vasionButtonClicked="zoom('Out')"
        />
        <p>{{ zoomValue }}%</p>
        <VasionButton
          name="btnZoomIn"
          title="Zoom In"
          :icon="'VasionAddIcon'"
          @vasionButtonClicked="zoom('In')"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VasionCompareZoomButtons',
  props: {
    zoomValue: {
      type: Number,
      required: true,
    },
    combined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    zoom(direction) {
      this.$emit(`zoom${direction}`)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .background {
    background: $grey-50;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    padding: 25px;
    overflow: auto;
  }

  .right-border {
    border-right: solid 1px $grey-200;
  }

  .button-section {
    z-index: 1;
    height: calc(100vh - 100px);
    position: relative;
    margin: 0 auto;
  }

  .zoom-buttons {
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    fill: white;
    width: 156px;
    border-radius: 8px;
    padding: 6px;
    bottom: 50px;
    position: fixed;
    margin-left: calc(-312px / 2);
  }
  .zoom-buttons svg {
    margin-top: 3px;
  }
  .zoom-buttons p {
    font-size: 18px;
    margin: 8px auto 0 auto;
  }
  .no-radius-right {
    border-radius: 8px 0px 0px 8px;
  }
</style>
