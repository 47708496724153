<template>
  <div class="vasion-checkbox">
    <VasionCheckBoxCheckedIcon
      v-if="checked"
      :fill="'#ff4702'"
      :class="{'disabled-state': isDisabled}"
      class="pointer background-fix checkbox"
      role="checkbox"
      aria-checked="true"
      @mousedown="mousedown($event)"
      @click="selectCheckbox($event)"
    />
    <VasionCheckBoxEmptyIcon
      v-else
      :fill="color"
      :class="{'error-state-label': isInErrorState, 'disabled-state': isDisabled}"
      class="pointer background-fix checkbox"
      role="checkbox"
      aria-checked="false"
      @mousedown="mousedown($event)"
      @click="selectCheckbox($event)"
    />
    <label v-if="!noLabel" :class="{'error-state-label': isInErrorState,'disabled-state': isDisabled}">
      <input
        :id="id"
        autocomplete="off"
        style="display:none"
        type="checkbox"
        :name="name"
        :checked="checked"
        @change="selectCheckbox"
      >
      <slot />
    </label>
    <span v-if="required" class="vasion-required-indicator">*</span>
  </div>
</template>

<script>
export default {
  name: 'VasionCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
      required: false,
    },
    color: {
      type: String,
      default: '#707273',
      required: false,
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    isInErrorState: {
      type: Boolean,
      default: false,
      required: false,
    },
    name: {
      type: String,
      default: '',
      required: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    isDisabled() {
      if (this.isDisabled) { this.$emit('disabled') }
    },
  },
  created: function () {
    if (this.checked) {
      this.$emit('checkbox-checked-oncreate', true)
    }
  },
  methods: {
    selectCheckbox(event) {

      this.$emit('change', event.shiftKey)
      this.$emit('value', !this.checked)
    },
    mousedown(event)
    {
      if (event.shiftKey) {
        event.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .error-state-label {
    color: $error-red;
    fill: $error-red !important;
  }
  .disabled-state{
    color: $grey-100;
    fill: $grey-100 !important;
    pointer-events: none;
  }
  .pointer:hover {
    cursor: pointer
  }

  svg {
    float: left;
    margin-right: 16px;
  }
  label {
    height: 24px;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: $font-family-book;
    color: $grey-400;
  }
  .background-fix {
    background-color:rgba(0, 0, 0, 0);
  }
</style>
