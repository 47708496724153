import {
  authenticateWebSearchLogin,
  deleteGroup,
  generateSigDocLinkGUIDAuthCode,
  getAllApps,
  getDepartmentList,
  getExternalUserList,
  getGroupApps,
  getGroupChildren,
  getGroupList,
  getGroupListEnabledOnly,
  getGroupRoles,
  getGroupTokens,
  getLoginTypes,
  getSignatureLinkGUIDDetails,
  getUserDetails,
  getUserList,
  getUserListEnabledOnly,
  getUserApps,
  getUserRoles,
  getUserTokens,
  saveGroupApps,
  saveGroupChildren,
  saveGroupDetails,
  saveGroupRoles,
  saveGroupTokens,
  saveUserDetails,
  saveUserApps,
  saveUserRoles,
  saveUserTokens,
 } from '@/store/apiModules/security.api'

 import {
  buildGroupTableData,
  buildParentFolderGroupTableData,
  buildParentFolderUserTableData,
  buildUserTableData,
  getEllipsisButtonConfig,
 } from '@/store/helperModules/security.module';

 function initialState() {
  return {
    departmentList: [],
    ellipsisButtonConfig: null,
    externalUserList: [],
    groupList: [],
    groupListEnabledOnly: [],
    loginTypeList: [],
    securitySideNav: '',
    userList: [],
  }
}

const security = {
  namespaced: true,
  state: initialState,
  mutations: {
    changeSecuritySideNav(state, value) { state.securitySideNav = value },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setDepartmentList(state, value) { state.departmentList = value },
    setEllipsisButtonConfig(state, value) { state.ellipsisButtonConfig = value },
    setExternalUserList(state, value) { state.externalUserList = value },
    setGroupList(state, value) { state.groupList = value },
    setGroupListEnabledOnly(state, value) { state.groupListEnabledOnly = value },
    setLoginTypeList(state, value) { state.loginTypeList = value },
    setUserList(state, value) { state.userList = value },
  },
  actions: {
    async authenticateWebSearchLogin({ rootState, dispatch }, payload) {
      const loginResult = await authenticateWebSearchLogin(rootState.common.apiToken, payload)
      if (loginResult && loginResult.Token) {
        await dispatch('common/setUserDataWithAuthInfo', loginResult, { root: true })
      }
      return loginResult
    },
    changeSecuritySideNav({ commit }, value) { commit('changeSecuritySideNav', value) },
    async deleteGroup({ rootState }, groupID) {
      const result = await deleteGroup(rootState.common.apiToken, groupID)
      return result
    },
    async generateSigDocLinkGUIDAuthCode({ rootState }, payload) {
      const result = await generateSigDocLinkGUIDAuthCode(rootState.common.apiToken, payload)
      return result
    },
    async getAllApps({ rootState }, value) {
      const appList = await getAllApps(rootState.common.apiToken, value)
      return appList
    },
    async getGroupApps({ rootState }, groupID) {
      const rolesResult = await getGroupApps(rootState.common.apiToken, groupID)
      return rolesResult
    },
    async getGroupChildren({ rootState }, groupID) {
      const childrenResult = await getGroupChildren(rootState.common.apiToken, groupID)
      return childrenResult
    },
    async getGroupRoles({ rootState }, groupID) {
      const rolesResult = await getGroupRoles(rootState.common.apiToken, groupID)
      return rolesResult
    },
    async getGroupTokens({ rootState }, groupID) {
      const tokensResult = await getGroupTokens(rootState.common.apiToken, groupID)
      return tokensResult
    },
    async getSignatureLinkGUIDDetails({ rootState, dispatch }, payload) {
      const signatureLinkGUIDResult = await getSignatureLinkGUIDDetails(rootState.common.apiToken, payload)
      if (signatureLinkGUIDResult && signatureLinkGUIDResult.Token) {
        await dispatch('common/signInSignatureDocLinkView', signatureLinkGUIDResult.Token, { root: true })
      }
      return signatureLinkGUIDResult
    },
    async getUserApps({ rootState }, userID) {
      const rolesResult = await getUserApps(rootState.common.apiToken, userID)
      return rolesResult
    },
    async getUserDetails({ rootState }, userID) {
      const userDetails = await getUserDetails(rootState.common.apiToken, userID)
      return userDetails
    },
    async getUserListEnabledOnly({ rootState }) {
      const userListResult = await getUserListEnabledOnly(rootState.common.apiToken)
      return userListResult
    },
    async getUserRoles({ rootState }, userID) {
      const rolesResult = await getUserRoles(rootState.common.apiToken, userID)
      return rolesResult
    },
    async getUserTokens({ rootState }, userID) {
      const tokensResult = await getUserTokens(rootState.common.apiToken, userID)
      return tokensResult
    },
    async loadDepartmentList({ rootState, commit }) {
      let departmentList = await getDepartmentList(rootState.common.apiToken)
      if (!departmentList) {
        departmentList = []
      }

      commit('setDepartmentList', departmentList)
    },
    async loadExternalUsersList({ rootState, commit }) {
      let externalUserList = await getExternalUserList(rootState.common.apiToken)
      if (!externalUserList) {
        externalUserList = []
      }

      commit('setExternalUserList', externalUserList)
    },
    async loadGroupList({ rootState, commit }) {
      let groupList = await getGroupList(rootState.common.apiToken)
      if (!groupList) {
        groupList = []
      }

      commit('setGroupList', groupList)
    },
    async loadGroupListEnabledOnly({ rootState, commit }) {
      let groupList = await getGroupListEnabledOnly(rootState.common.apiToken)
      if (!groupList) {
        groupList = []
      }

      commit('setGroupListEnabledOnly', groupList)
    },
    async loadLoginTypeList({ rootState, commit }) {
      let loginTypeList = await getLoginTypes(rootState.common.apiToken)
      if (!loginTypeList) {
        loginTypeList = []
      }

      commit('setLoginTypeList', loginTypeList)
    },
    async loadUserList({ rootState, commit }) {
      let userList = await getUserList(rootState.common.apiToken)
      if (!userList) {
        userList = []
      }

      commit('setUserList', userList)
    },
    async saveGroupApps({ rootState }, payload) {
      const result = await saveGroupApps(rootState.common.apiToken, payload)
      return result
    },
    async saveGroupChildren({ rootState }, payload) {
      const result = await saveGroupChildren(rootState.common.apiToken, payload)
      return result
    },
    async saveGroupDetails({ rootState }, payload) {
      const result = await saveGroupDetails(rootState.common.apiToken, payload)
      return result
    },
    async saveGroupRoles({ rootState }, payload) {
      const result = await saveGroupRoles(rootState.common.apiToken, payload)
      return result
    },
    async saveGroupTokens({ rootState }, payload) {
      const result = await saveGroupTokens(rootState.common.apiToken, payload)
      return result
    },
    async saveUserApps({ rootState }, payload) {
      const result = await saveUserApps(rootState.common.apiToken, payload)
      return result
    },
    async saveUserDetails({ rootState }, payload) {
      const result = await saveUserDetails(rootState.common.apiToken, payload)
      return result
    },
    async saveUserRoles({ rootState }, payload) {
      const result = await saveUserRoles(rootState.common.apiToken, payload)
      return result
    },
    async saveUserTokens({ rootState }, payload) {
      const result = await saveUserTokens(rootState.common.apiToken, payload)
      return result
    },
    async setEllipsisButtonConfig({ commit }, configName) { commit('setEllipsisButtonConfig', getEllipsisButtonConfig(configName)) },
    // eslint-disable-next-line no-empty-pattern
    async setGroupTableData({}, data) {
      const response = await buildGroupTableData(data)
      return response
    },
    // eslint-disable-next-line no-empty-pattern
    async setParentFolderGroupTableData({}, data) {
      const response = await buildParentFolderGroupTableData(data)
      return response
    },
    // eslint-disable-next-line no-empty-pattern
    async setParentFolderUserTableData({}, data) {
      const response = await buildParentFolderUserTableData(data)
      return response
    },
    // eslint-disable-next-line no-empty-pattern
    async setUserTableData({}, data) {
      const response = await buildUserTableData(data)
      return response
    },
  },
}

export default security;
