<template>
  <div v-if="selectedSubFolder" class="mainDiv">
    <Loading
      :active.sync="isLoading"
      :is-full-page="true"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <h3>New Folder</h3>
    <div class="md-layout">
      <div class="md-layout-item">
        <VasionInput
          v-model="folderName"
          class="input-style"
          inputType="top-white"
          title="Folder Name"
          width="100%"
          :isInErrorState="error"
        />
      </div>
    </div>
    <div class="rightAlign">
      <VasionButton
        id="btnSaveVaultConfig"
        :classProp="'primary'"
        :isDisabled="folderName === ''"
        @vasionButtonClicked="saveVault()"
      >
        SAVE
      </VasionButton>
      <VasionButton id="button-cancel" :classProp="'secondary'" @vasionButtonClicked="$emit('closeWindow')">
        CANCEL
      </VasionButton>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'VaultConfig',
  components: {
    Loading,
  },
  data: function () {
    return {
      error: false,
      folderName: '',
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
    }
  },
  computed: {
    selectedSubFolder() {
      return this.$store.state.common.selectedFolder
    },
    selectedSubFolderId() {
      return this.$store.state.common.selectedFolderId
    },
  },
  methods: {
    async saveVault() {
      this.isLoading = true

      let validData = true
      if (this.folderName.trim() === '') {
        validData = false
        this.error = true
      }

      if (validData) {
        this.error = false
        const folderIDs = await this.$store.dispatch('vault/saveSubFolder', {
          FolderId: this.selectedSubFolderId,
          SubFolderName: this.folderName
        })
        if (this.selectedSubFolder) {
          this.selectedSubFolder.loadChildren(true)
        }
        await this.$store.dispatch('vault/setRefreshGrid', true)
        this.$emit('saved', this.folderName, folderIDs[1])
      }

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
  .rightAlign {
    text-align: right;
  }

  .mainDiv {
    width: 550px;
    height: 180px;
    margin: 10px 10px 10px 10px;
  }

  #errorMessage {
    color: red;
    padding-top: 5px;
  }
</style>
