/* eslint-disable linebreak-style */
/* eslint-disable no-console */

import {
  attributeFieldLookUp,
  createNewForm,
  deleteAttributeForm,
  getFormDetails,
  getForms,
  getMyForms,
  getObjectIDsWhereFieldUsed,
  isDocumentAssociatedWithObject,
  renameForm,
  saveAttributeForm,
  saveAttributeFormFields,
  saveAttributeFormHeader,
 } from '@/store/apiModules/attributeForm.api'

 import {
  buildFormTableData,
  buildFormTableDataNoEllips,
 } from '@/store/helperModules/attributeForm.module';

function initialState() {
  return {
    activeIndexForm: {},
    forms: [],
    isForm: false,
    myForms: [],
  }
}

const attributeForm = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setActiveIndexForm(state, value) { state.activeIndexForm = value },
    setFormList(state, forms) { state.forms = forms },
    setIsForm(state, value) { state.isForm = value },
    setMyFormList(state, forms) { state.myForms = forms },
    setNewFormId(state, value) { state.newFormId = value },
  },
  actions: {
    attributeFieldLookUp({ rootState }, payload) {
      return attributeFieldLookUp(rootState.common.apiToken, payload)
    },
    async canEditFieldTypeOrLength({ rootState }, payload) {
      if (!payload.fieldName) {
        return false
      }

      const lowerFieldName = payload.fieldName.toLowerCase()
      if (lowerFieldName === 'workflow_status' ||
        lowerFieldName === 'workflow_approver' ||
        lowerFieldName === 'workflow_due_date' ||
        lowerFieldName === 'workflow_initiator') {
        return false
      }

      const docAssociatedObjectPayload = {
        Value: payload.objectID.toString(),
      }
      
      const docAssociatedResponse = await isDocumentAssociatedWithObject(rootState.common.apiToken, docAssociatedObjectPayload)
      if (!docAssociatedResponse ||
        !docAssociatedResponse.data ||
        !docAssociatedResponse.data.Value ||
        docAssociatedResponse.data.Value === 'True') {
        return false
      }
      const fieldUsedOnObjectsPayload = {
        Value: payload.fieldName,
      }

      const fieldUsedOnObjectsResponse = await getObjectIDsWhereFieldUsed(rootState.common.apiToken, fieldUsedOnObjectsPayload)
      if (!fieldUsedOnObjectsResponse ||
        !fieldUsedOnObjectsResponse.data ||
        !fieldUsedOnObjectsResponse.data.Values ||
        fieldUsedOnObjectsResponse.data.Values.length != 1 ||
        fieldUsedOnObjectsResponse.data.Values[0] != payload.objectID) {
        return false
      }

      return true
    },
    async createNewForm({ commit, rootState }, payload) {
      const response = await createNewForm(rootState.common.apiToken, payload)
      try {
        if (response?.data && response.data.Value) {
          commit('setNewFormId', response.data.Value)
        }
      } catch (error) {
      console.warn(error)
      }
    },
    async deleteAttributeForm({ dispatch, rootState }, formId) {
      const result = await deleteAttributeForm(rootState.common.apiToken, formId)
      try {
        dispatch('rebuildAdminFormList')
      } catch (error) {
        console.warn(error)
      }

      return result
    },
    async getFormDetails({ commit, rootState }, formId) {
      const payload = {
        Value: formId.toString(),
      }
      const response = await getFormDetails(rootState.common.apiToken, payload)
      commit('setActiveIndexForm', response?.data)
    },
    async getForms({ commit, rootState }) {
      const formsList = []
      try {
        const response = await getForms(rootState.common.apiToken)
        if (response?.data && response.data.IndexForms) {
          Object.keys(response.data.IndexForms).map((key) => {
            formsList.push({
              name: response.data.IndexForms[key],
              value: Number(key),
            })
            return true
          })
        }
      } catch (error) {
        console.warn(error)
      }

      formsList.sort((a, b) => {
        const x = a.name.toLowerCase();
        const y = b.name.toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
      });

      commit('setFormList', formsList.slice())
      return formsList
    },
    async getMyForms({ commit, rootState }, payload) {
      const formsList = []
      try {
        const response = await getMyForms(rootState.common.apiToken, payload)
        if (response?.data && response.data.EFormCollection) {
          response.data.EFormCollection.Values.map(v => {
            formsList.push({
              name: v.Name,
              value: v.EFormID,
              WorkflowID: v.WorkflowID,
            })
            return true
          })
        }
      } catch (error) {
        console.warn(error)
      }
      commit('setMyFormList', formsList.slice())
      return formsList
    },
    async populateIndexFormAdminData({ dispatch }) {
      const usersPromise = dispatch('common/getUsers', null, { root: true })
      const groupsPromise = dispatch('common/getGroups', null, { root: true })
      const formsPromise = dispatch('getForms')

      await Promise.all([usersPromise, groupsPromise, formsPromise])
    },
    async rebuildAdminFormList({ dispatch, rootState }) {
      await dispatch('getForms')
      if (rootState.attributeForm.forms && rootState.attributeForm.forms.length > 0) {
        await dispatch('getFormDetails', rootState.attributeForm.forms[0].value)
      }
    },
    async renameForm({ rootState }, payload) { await renameForm(rootState.common.apiToken, payload) },
    async resetActiveForm({ commit }) { commit('setActiveIndexForm', {}) },
    async saveAttributeForm({ commit, rootState }, form) {
      const response = await saveAttributeForm(rootState.common.apiToken, form)
      try {
        if (response?.data && response.data.Value && response.data.Value === 'True') {
          commit('setActiveIndexForm', form)
          return ''
        } else if (response?.data && response.data.Value) {
          return response.data.Value
        }
      } catch (error) {
        console.warn(error)
      }
      return 'An unexpected error occurred'
    },
    async saveAttributeFormFields({ rootState }, fields) { return saveAttributeFormFields(rootState.common.apiToken, fields) },
    async saveAttributeFormHeader({ rootState }, form) { return saveAttributeFormHeader(rootState.common.apiToken, form) },
    // eslint-disable-next-line no-empty-pattern
    async setFormTableData({}, data) {
      const response = await buildFormTableData(data)
      return response
    },
    // eslint-disable-next-line no-empty-pattern
    async setFormTableDataNoEllips({}, data) {
      const response = await buildFormTableDataNoEllips(data)
      return response
    },
    setIsForm({ commit }, value) { commit('setIsForm', value) },
    updateActiveFormField({ commit, rootState }, field) {
      const form = rootState.attributeForm.activeIndexForm
      let index = form.fields.findIndex(f => f.fieldID === field.fieldID)
      if (index >= 0) {
        form.fields[index] = field
      } else {
        form.fields = form.fields.concat(field)
      }
      commit('setActiveIndexForm', form)
    },
  },
}

export default attributeForm
