<template>
  <div id="main" class="vld-parent">
    <Loading
      id="throbber"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-show="showParentFolder">
      <!-- TOOLBAR -->
      <div id="toolbar">
        <h1 id="title" :class="{ 'new-parent-folder-name' : isNewParentFolder }">
          {{ folderTitle }}
        </h1>
        <VasionButton
          id="save-button"
          :classProp="'primary'"
          :isDisabled="!isDirty"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>

        <VasionButton
          id="cancel-button"
          :classProp="'secondary'"
          @vasionButtonClicked="cancelClick()"
        >
          Cancel
        </VasionButton>
      </div>

      <!-- TABS -->
      <div class="md-layout">
        <VasionButton
          id="tab-general"
          :classProp="calculatedClass('general')"
          @vasionButtonClicked="updateSelectedTab('general')"
        >
          General
        </VasionButton>

        <VasionButton
          id="tab-permissions"
          :classProp="calculatedClass('permissions')"
          @vasionButtonClicked="updateSelectedTab('permissions')"
        >
          Permissions
        </VasionButton>
      </div>

      <!-- GENERAL -->
      <div v-show="selectedTabName === 'general'" id="general" class="tab">
        <ParentFolderGeneral
          ref="general"
          :defaultDriveLocationId="defaultDriveLocationId"
          @markAsDirty="markAsDirty"
          @markRequiredInputsComplete="markRequiredInputsComplete"
          @markRequiredInputsNotComplete="markRequiredInputsNotComplete"
          @setParentFolderName="setParentFolderName"
        />
      </div>

      <!-- PERMISSIONS -->
      <div v-show="selectedTabName === 'permissions'" id="permissions" class="tab">
        <ParentFolderPermissions
          ref="permissions"
          @markAsDirty="markAsDirty"
        />
      </div>

      <VasionSnackbar
        id="results-snack"
        :showSnackbarBool.sync="showSnackbarBool"
        :snackbarImage="snackbarImage"
        :snackbarSubTitle="snackbarSubTitle"
        :snackbarTitle="snackbarTitle"
      />

      <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
        <VasionConfirmationDialog :message="leaveMessage" @noButtonClick="cancelLeave()" @yesButtonClick="doLeave()" />
      </md-dialog>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import ParentFolderGeneral from '@/components/storage/ParentFolderGeneral.vue';
import ParentFolderPermissions from '@/components/storage/ParentFolderPermissions.vue';

export default {
  name: 'TheParentFolder',
  components: {
    Loading,
    ParentFolderGeneral,
    ParentFolderPermissions,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  props: {
    defaultDriveLocationId: {
      type: Number,
      default: null,
      required: false,
    },
    isModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: function () {
    return {
      activeFolder: {},
      areRequiredInputsComplete: false,
      areRequiredInputsCompleteAndIsDirty: false,
      dataLoaded: false,
      encrypted: false,
      encryptionPassword: '',
      groupAccess: [],
      groupReadOnlyFolderSecurity: [
        {
          canDelete: false,
          canEmail: false,
          canExport: false,
          canModify: false,
          canPrint: false,
          canViewNative: false,
          entityID: 0,
          folderID: 0,
        },
      ],
      folderTitle: '',
      isDirty: false,
      isLoading: false,
      isNewParentFolder: true,
      loaderColor: '#ff4702',
      loaderBackgroundColor: '#ece7ec',
      parentFolderName: '',
      piiData: false,
      readOnly: false,
      selectedTabName: 'general',
      showLeaveDialog: false,
      showParentFolder: true,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      storageType: '',
      userAccess: [],
      userReadOnlyFolderSecurity: [
        {
          canDelete: false,
          canEmail: false,
          canExport: false,
          canModify: false,
          canPrint: false,
          canViewNative: false,
          entityID: 0,
          folderID: 0,
        },
      ],
      vaultID: 0,
      vaultName: '',
    }
  },
  computed: {
    leaveMessage() {
      return this.parentFolderName.length > 0
        ? `Are you sure you want to leave? Changes to "${this.parentFolderName}" have not been saved.`
        : 'Are you sure you want to leave? Changes to your Untitled Parent Folder have not been saved.'
    },
  },
  async created() {
    await this.loadActiveFolderData()

    if (this.activeFolder && this.activeFolder.vaultID && this.activeFolder.vaultID > 0) {
      await this.setComponentDetails(this.activeFolder.vaultID, this.activeFolder)
    }

    this.folderTitle = this.activeFolder && this.activeFolder.vaultName ? this.activeFolder.vaultName : 'Untitled Parent Folder'
  },
  async mounted() {
    await this.loadActiveFolderData()

    if (!this.activeFolder || this.activeFolder.vaultID === undefined || this.activeFolder.vaultID <= 0) {
      // special case where if there's no folder data, we actually want to load things in mounted to avoid a race condition
      await this.setComponentDetails(0, null)
    }
  },
  methods: {
    calculatedClass(selectedTab) { return this.selectedTabName === selectedTab ? 'text-selected' : 'text' },
    cancelClick() {
      this.$emit('close')
      if (!this.isModal) {
        this.$router.push({ name: 'ParentFolders' })
      }
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    doLeave() {
      this.toggleLeaveDialog()
      if (this.routeTo && this.routeTo.name !== '') {
        this.$router.push({ name: this.routeTo.name });
        this.routeTo = null
      }
    },
    async loadActiveFolderData() {
      if (this.dataLoaded || !this.$route.params.folderId || parseInt(this.$route.params.folderId, 10) <= 0) {
        return
      }

      this.isNewParentFolder = false
      this.activeFolder = await this.$store.dispatch('vault/getSingleVault', parseInt(this.$route.params.folderId, 10))
      this.dataLoaded = true
    },
    markAsClean() { this.isDirty = false },
    markAsDirty() {
      this.isDirty = true
      if (this.areRequiredInputsComplete) {
        this.areRequiredInputsCompleteAndIsDirty = true
      } else {
        this.areRequiredInputsCompleteAndIsDirty = false
      }
    },
    markRequiredInputsComplete() {
      this.areRequiredInputsComplete = true
      if (this.isDirty) {
        this.areRequiredInputsCompleteAndIsDirty = true
      }
    },
    markRequiredInputsNotComplete() {
      this.areRequiredInputsComplete = false
      this.areRequiredInputsCompleteAndIsDirty = false
    },
    async save() {
      this.isLoading = true
      let decryptResult = {}
      const generalData = await this.$refs.general.buildGeneralSaveDataJSON()
      let result = await this.$store.dispatch('search/saveFolderIndexSchedule', generalData.folderIndexSchedule)
      const permissionsData = this.$refs.permissions.buildPermissionsSaveDataJSON()

      // check for decrypt password to match
      if (generalData.decryptPassword) {
        const decryptPayload = {
          vaultID: this.activeFolder.vaultID,
          password: generalData.decryptPassword,
        }
        decryptResult = await this.$store.dispatch('vault/decryptVault', decryptPayload)
      }
      if (decryptResult.sGenRes === '2') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'ERROR: Password'
        this.snackbarSubTitle = 'Invalid Password.'
        this.snackbarImage = false
        this.isLoading = false
      } else if (generalData.parentFolderName.trim() === '') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'ERROR: Parent Folder Name'
        this.snackbarSubTitle = 'Parent Folder Name cannot only include spaces.'
        this.snackbarImage = false
        this.isLoading = false
      } else {
          const payload = {
            vaultID: this.activeFolder.vaultID,
            vaultName: generalData.parentFolderName,
            piiData: generalData.containsPIIData,
            encrypted: generalData.encryptFolder,
            encryptionPassword: generalData.password,
            readOnly: generalData.readOnly,
            userAccess: permissionsData.assignedUsers,
            groupAccess: permissionsData.assignedGroups,
            storageConfigId: generalData.driveStorageConfig.value,
            userReadOnlyFolderSecurity: generalData.userReadOnlyFolderSecurity,
            groupReadOnlyFolderSecurity: generalData.groupReadOnlyFolderSecurity,
            autoVersioning: generalData.autoVersioning,
          }
          result = await this.$store.dispatch('vault/saveVault', payload)
          const saveSuccess = result !== undefined && result !== null && result.errorCode < 1

          if (result.errorCode > 0) {
            switch (result.errorCode) {
              case 1:
                this.snackbarSubTitle = 'Folder with same name exists.'
                break
              case 2:
                this.snackbarSubTitle = 'Error creating record in database.'
                break
              case 3:
                this.snackbarSubTitle = 'The folder was created, but there was a problem during encryption.'
                break
              default:
                this.snackbarSubTitle = 'If problems continue to persist, please contact your Administrator.'
                break
            }
          }

        if (saveSuccess) {
          this.snackbarTitle = 'Parent Folder Saved Successfully'
          this.snackbarSubTitle = `Saved "${generalData.parentFolderName}" to ${generalData.driveStorageConfig?.name ?? generalData.driveStorageConfig}`
          this.markAsClean()
          this.$emit('saved', generalData.parentFolderName, generalData.driveStorageConfig, result.vaultID)
          if (!this.isModal) {
            this.$router.push({ name: 'ParentFolders', params: { saveSnackTitle: this.snackbarTitle, saveSnackSubtitle: this.snackbarSubTitle, saveSnackShowBool: true } })
          }
        } else {
          this.isLoading = false
          this.snackbarTitle = 'Unable to Save Parent Folder'
          this.snackbarImage = false
          this.showSnackbarBool = true
        }
      }
    },
    async setComponentDetails(vaultID, localActiveFolder) {
      // the order of these calls are important - don't use promise.all
      await this.$store.dispatch('admin/setParentFolderPermissions', {})
      await this.setPermissionsDetails(vaultID, localActiveFolder)
      await this.setGeneralDetails(vaultID, localActiveFolder)
    },
    async setGeneralDetails(id, details) {
      const { general } = this.$refs
      await general.loadDriveList()
      if (id > 0) {
        await general.setValues(details)
      } else {
        await general.setValues()
      }
    },
    setParentFolderName(newName) { this.parentFolderName = newName },
    async setPermissionsDetails(id, details) {
      const { permissions } = this.$refs
      if (id > 0) {
        await permissions.setValues(details)
      } else {
        permissions.setValues()
      }
    },
    updateSelectedTab(newSelectedTab) { this.selectedTabName = newSelectedTab },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #main {
    background-color: white;
    padding: 15px 20px;
    width: 100%;
    height: calc(100vh - 60px);
    overflow: auto;

    #toolbar {
      height: 40px;
      margin: 0;
      width: 100%;
      text-align: left;
      line-height: 40px;
      vertical-align: middle;
      min-width: 500px;

      #title {
        @include Headline;
        float: left;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        margin: 0;

        &.new-parent-folder-name {
          color: $grey-400;
        }
      }

      #save-button, #cancel-button {
        float: right;
      }

      #save-button {
        margin-right: 10px;
      }
    }

    .tab {
      overflow-y: auto;
      overflow-x: none;
    }

    #throbber {
      z-index: 100;
      height: 100vh;
    }
  }
</style>
