<template>
  <div id="user-settings-container">
    <div id="user-settings-header">
      <h3 id="user-settings-title">
        User Settings
      </h3>
      <div id="close-x-box" @click="$emit('close')">
        <VasionClear />
      </div>
    </div>
    <div id="user-settings-body">
      <div id="selection-nav">
        <ul>
          <li id="user-settings" :class="{ selected: currentApp === 'MiscUserSettings'}" @click.prevent="currentApp = 'MiscUserSettings'; $emit('openUserSettings')">
            <VasionPersonIcon /><span>User Settings</span>
          </li>
          <li id="default-app" :class="{ selected: currentApp === 'DefaultAppPicker'}" @click.prevent="currentApp = 'DefaultAppPicker'; $emit('openUserSettings')">
            <VasionApps /><span>Default App</span>
          </li>
        </ul>
      </div>
      <component :is="currentApp" />
    </div>
  </div>
</template>

<script>

import DefaultAppPicker from '@/components/userSettings/DefaultAppPicker.vue'
import MiscUserSettings from '@/components/userSettings/MiscUserSettings.vue'

export default {
  name: 'UserSettings',
  components: {
    DefaultAppPicker,
    MiscUserSettings,
  },
  data: function () {
    return {
      currentApp: 'MiscUserSettings',
      defaultApp: '',
    }
  },
  computed: {
    showResetPassword() { return this.userLoginType === '1' },
    userLoginType() { return this.$store.state.common.loginType },
  },
  methods: {
    setDefaultApp(appName) {
      this.defaultApp = appName
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  #close-x-box {
    height: 32px;
    width: 32px;
    border-radius: 8px;
    background-color: $grey-75;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 8px;
  }
  #user-settings-container {
    width: 964px;
    height: 432px;
    display: flex;
    flex-direction: column;
    box-shadow: none;
  }
  #user-settings-header {
    padding-top: 3px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  #user-settings-title {
    margin-top: 0;
  }
  #user-settings-body {
    display: flex;
  }
  #selection-nav {
    min-width: 280px;
    @include Text;
    & > ul {
      list-style: none;
      padding: 8px 0;
      margin: 0;

      & > li {
        padding: 8px 8px;
        cursor: pointer;
        border-radius: 8px;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 8px 0 0 0;

        & > span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 16px;
        }
      }

      & li:hover {
        background-color: $grey-75;
      }
    }
    .selected {
      background-color: $orange-50;
    }
  }
</style>
