/* eslint-disable no-multi-spaces */
import { format } from 'date-fns'

const buildWorkspaceTableData = function buildHistoryTableData(response, editableColumns) {
  let headers = []
  if (response.Rows.customFolderID && response.Rows.customFolderID !== 0) {
    headers = response.Columns.Values.filter((item) => {
      if (item.startsWith('CanMass')) {
        return false;
      } else {
        return true;
      }
    }).map((item, index) => {
      if (index < 3) {
        return `_${item[0].toLowerCase() + item.slice(1)}_`
      } else {
        return item
      }
    })
    headers.unshift('_VasionCheckBox_')
  } else {
    headers = editableColumns ? [
      '_VasionCheckBox_',
      '_documentID_',
      '_wFID_',
      '_stepID_',
      '_Priority_',
      'Workflow',
      'Type',
      'Primary ID',
      'Assigned To',
      'Status',
      'Step Name',
      'Due Date',
    ] : [
      '_VasionCheckBox_',
      '_documentID_',
      '_wFID_',
      '_stepID_',
      'Priority',
      'Workflow',
      'Type',
      'Primary ID',
      'Assigned To',
      'Status',
      'Step Name',
      'Due Date',
    ]
  }

  if (response === null || response.length === 0) {
    return { tableHeaders: headers, tableRows: [] }
  }

  const returnArr = response.Rows.Values.map((item) => {
      let dueDateString = item.Values[12]

      if (dueDateString && dueDateString !== '') {
        try {
          const tempDate = Date.parse(dueDateString)
          dueDateString = format(tempDate, 'MM/dd/yyyy')
        } catch (error) {
          dueDateString = item.dueDate
        }
      }
      const customArrayCopy = [...item.Values]
      if (response.Rows.customFolderID !== 0) {
        customArrayCopy.splice(2, 2)
        customArrayCopy.unshift({
          show: true,
          value: false,
        })
      }
      return {
        Values: response.Rows.customFolderID === 0 ? [
          {                        // _VasionCheckBox_
            show: true,
            value: false,
          },                       // _VasionCheckBox_
          item.Values[0],          // DocumentID
          item.Values[1],          // Workflow ID
          item.Values[14],         // Step ID
          item.Values[6],          // Priority
          item.Values[7],          // Workflow
          item.Values[8],          // Type
          item.Values[9],          // Primary ID
          item.Values[10],         // Assigned To
          item.Values[11],         // Status
          item.Values[13],         // Step Name
          dueDateString,           // Due Date
        ] : customArrayCopy,
          ValueType: 'String',
          priority: {
            value: item.Values[4] !== '' ? Number(item.Values[4]) : 2,
            name: item.Values[6] !== '' ? item.Values[6] : 'Normal',
          },
      }
  })
  return { tableHeaders: headers, tableRows: returnArr }
}

const buildRecentDocumentsTableData = (response) => {
  let headers = []
  headers = response.Columns.Values.map((item) => {
    if (item === 'DocumentID') {
      return `_${item[0].toLowerCase() + item.slice(1)}_`
    } else {
      return item
    }
  })
  headers.unshift('_VasionCheckBox_')

  if (response === null || response.length === 0) {
    return { tableHeaders: headers, tableRows: [] }
  }

  const returnArr = response.Rows.Values.map((item) => {
      return {
        Values: [
          {                        // _VasionCheckBox_
            show: true,
            value: false,
          },                       // _VasionCheckBox_
          item.Values[0],          // DocumentID
          item.Values[1],          // Document Name
          item.Values[2],          // Created Date
          item.Values[3],          // Modified Date
          item.Values[4],          // Date Accessed
        ],
          ValueType: 'String',
      }
  })
  return { tableHeaders: headers, tableRows: returnArr }
}

const formatDate = function (date) {
  const d = new Date(date)
  let month = `${(d.getMonth() + 1)}`
  let day = `${d.getDate()}`
  const year = `${d.getFullYear()}`
  if (month.length < 2) {
    month = `0${month}`
  }
  if (day.length < 2) {
    day = `0${day}`
  }
  return format(new Date([month, day, year].join('/').toString()), 'MM/dd/yyyy')
}

export {
  buildWorkspaceTableData,
  buildRecentDocumentsTableData,
  formatDate,
}
