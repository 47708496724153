<template>
  <div>
    <label v-if="title" class="vasion-input-label-top"> {{ title }} </label>
    <md-datepicker
      v-model="dateValue"
      :value="value"
      :md-model-type="String"
      @md-closed="close"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    value: {
      type: String,
      required: false,
      default: '',
    }
  },
  data () {
    return {
      dateValue: this.value,
    }
  },
  watch: {
    dateValue: function () {
      this.$emit('input', this.dateValue)
    },
    value: function () {
      this.dateValue = this.value
    },
  },
  methods: {
    close() {
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .md-field {
    width: 100%;
    margin: 21px 0;
    min-height: 0 !important;
    display: flex;
    position: relative;
    font-family: inherit;
    padding: 4px 12px;
    border: solid 1px $grey-200;
    border-radius: 6px;
    margin-top: 0;
    margin-bottom: 10px;

  }
  .md-field::v-deep .md-input-action {
    top: 4px;
  }
  .md-field:after {
    display: none;
  }
</style>
