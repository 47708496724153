<template>
  <div id="multipleLookupsModal" class="root-div">
    <span id="modalTitle" class="modalTitle">Lookup Results</span>
    <div class="vasion-html-table-default element-list">
      <table id="users-groups-list-table">
        <thead>
          <tr>
            <th v-for="column in getColumns" :key="column">
              <label id="users-groups-modal-header" class="vasion-html-table-header">{{ column }}</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in getRows" :key="index" :class="{selectedForm: index === selectedRowIndex}">
            <td v-for="item in row" :key="item" @click="selectRowHandler(row, index)">
              <label class="vasion-html-table-field">{{ item }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="vasion-flex-row buttons">
      <VasionButton id="buttonCancel" :class-prop="'secondary'" @vasionButtonClicked="noButtonClick()">
        Cancel
      </VasionButton>
      <VasionButton id="buttonConfirm" :class-prop="'primary'" @vasionButtonClicked="yesButtonClick()">
        Ok
      </VasionButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MultipleLookups',
  props: {
    notRemoveRow: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableValues: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      selectedRow: [],
      selectedRowIndex: '',
    }
  },
  computed: {
    getColumns() {
      return this.tableValues.Columns.filter(column => !column.includes('hidden_'))
    },
    getRows() {
      return this.filterRows()
    },
  },
  methods: {
    filterRows() {
      const filteredKeys = []
      const filteredRows = []
      Object.entries(this.tableValues.Columns).forEach(([key, value]) => {
        if (value.includes('hidden_')) {
          filteredKeys.push(key.substring('hidden_'))
        }
      })

      Object.values(this.tableValues.Rows).forEach((row) => {
        const newRowArray = []
        Object.entries(row).forEach(([key, item]) => {
          if (!filteredKeys.includes(key)) {
            newRowArray.push(item)
          }
        })
        filteredRows.push(newRowArray)
      })
      return filteredRows
    },
    noButtonClick() {
      this.$emit('noButtonClick')
    },
    selectRowHandler(row, index) {
      this.selectedRow = row
      this.selectedRowIndex = index
    },
    yesButtonClick() {
      const selectedRow = Object.values(this.tableValues.Rows).filter((rowValue, rowIndex) => {
        if (rowIndex !== this.selectedRowIndex) {
          return false
        }
        return rowValue
      })

      let localTableValues = JSON.parse(JSON.stringify(this.tableValues))
      localTableValues.Rows = selectedRow

      this.$emit('yesButtonClick', localTableValues)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .root-div {
    height: 100%;
    padding: 25px;
    .selectedForm {
      background-color: $orange-50;
    }
    th {
      cursor: auto;
    }
    tr {
      cursor: pointer;
      &:nth-child(even) {
        &:hover {
          background-color: $orange-50;
        }
      }
    }

    .modalTitle {
      text-align: left;
      font-size: 1.75em;
      font-family: Gotham Medium;
      font-style: normal;
      font-weight: 500;
      font-stretch: normal;
      color: #161718;
    }
    .buttons {
      justify-content: flex-end;
    }
    .element-list {
      margin: 10px 0;
      max-height: 57vh;
      min-height: 20vh;
      overflow: auto;
    }
  }
</style>
