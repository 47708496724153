<template>
  <div id="folder-list">
    <VasionBrowseFolders
      v-for="driveType in driveTypes.filter(driveType => onlyShowStorageConfigId === null || driveType.storageConfigId == onlyShowStorageConfigId)"
      :id="driveType.storageType + '-' + driveType.storageConfigId"
      :key="driveType.storageConfigId"
      :name="driveType.storageType + '-' + driveType.storageConfigId"
      class="side-nav-folders"
      :class="{ 'modal-design': !isInLeftNav }"
      :routeToFolderOnSelection="isInLeftNav"
      fillColor="fillColor"
      :sourceName="driveType.storageDisplayName"
      :toggleTopByDefault="toggleStorageConfigIdByDefault == driveType.storageConfigId"
      :enforceUserSecurity="true"
      :storageConfigId="driveType.storageConfigId"
      :storageType="driveType.storageType"
      :includeWFQueue="includeWFQueue"
      :showFiles="showFiles"
      :extraTextLength="extraTextLength"
      :disableLoader="disableLoader"
      :disableRootSelect="disableRootSelect"
      @itemSelected="handleFolderSelected"
    />
  </div>
</template>

<script>
import VasionBrowseFolders from '@/components/shared/VasionBrowseFolders.vue';

export default {
  name: 'VasionFolders',
  components: {
    VasionBrowseFolders,
  },
  props: {
    disableLoader: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableRootSelect: {
      type: Boolean,
      default: false,
      required: false,
    },
    extraTextLength: {
      type: Boolean,
      default: false,
      required: false,
    },
    fillColor: {
      type: String,
      default: '#ffffff',
      required: false,
    },
    includeWFQueue: {
      type: Boolean,
      default: false,
      required: false,
    },
    isInLeftNav: {
      type: Boolean,
      default: false,
      required: false,
    },
    routeToFolderOnSelection: {
      type: Boolean,
      default: false,
      required: false,
    },
    showFiles: {
      type: Boolean,
      default: false,
      required: false,
    },
    onlyShowStorageConfigId: {
      type: Number,
      default: null,
      required: false,
    },
    toggleStorageConfigIdByDefault: {
      type: Number,
      default: 0,
      required: false,
    },
    showOnlyDataAutomation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      driveTypes: [],
    }
  },
  created() {
    this.loadDriveTypes()
  },
  methods: {
    handleFolderSelected(selectedData) {
      this.$emit('itemSelected', selectedData)
    },
    async loadDriveTypes() {
      this.driveTypes = await this.$store.dispatch('storage/getConfiguredDriveTypes', this.showOnlyDataAutomation)
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';

  .modal-design {
    .tree-list-item {
      .folderText {
        color: $grey-700;
        width: 100%;
      }

      .arrowDiv {
        fill: $grey-400;
      }

      .defaultFillColor {
        fill: $grey-700;
        color: $grey-700;
      }

      .defaultFillColor:hover {
        background-color: $grey-75;
        fill: $grey-400;
        color: $grey-400;

        .arrowDiv {
          fill: $grey-400;
        }
      }

      .tree-list-item {
        .folderText {
          color: $grey-700;
        }

        .drop-down-arrow {
          fill: $grey-400;
        }

        .defaultFillColor {
          fill: $grey-700;
          color: $grey-700;
        }

        .defaultFillColor:hover {
          background-color: $grey-75;
          fill: $grey-400;
          color: $grey-400;
        }

        .selected {
          background-color: $orange-50;
          color: $orange-300;
          fill: $orange-300;

          .folderText {
            color: $orange-300;
          }

          .drop-down-arrow {
            fill: $orange-300;
          }
        }

        .selected:hover {
          background-color: $orange-50;
          color: $orange-300;
          fill: $orange-300;

          .folderText {
            color: $orange-300;
          }

          .drop-down-arrow {
            fill: $orange-300;
          }
        }
      }
    }
  }
</style>
