export const SecurityRoles = {
  AdminAudit: 'ADMIN_AUDIT',
  AdminBarcodeIndexing: 'ADMIN_BARCODE_INDEXING',
  AdminConfigureAIP: 'ADMIN_CONFIGURE_AIP',
  AdminConfigureGroups: 'ADMIN_CONFIGURE_GROUPS',
  AdminConfigureLookups: 'ADMIN_CONFIGURE_LOOKUPS',
  AdminConfigureRoles: 'ADMIN_CONFIGURE_ROLES',
  AdminConfigureUsers: 'ADMIN_CONFIGURE_USERS',
  AdminConfigureWorkflow: 'ADMIN_CONFIGURE_WORKFLOW',
  AdminConfigureCustomReports: 'ADMIN_CREATE_DOC_REPORTS',
  AdminConfigureCustomDataTables: 'ADMIN_CUST_DATA_TABLE',
  AdminConfigureDocumentAttributes: 'ADMIN_DOC_ATTRIBUTES',
  AdminDocumentControl: 'ADMIN_DOCUMENT_CONTROL',
  AdminConfigureEmailTemplates: 'ADMIN_EMAIL_CONFIG',
  AdminConfigureFolders: 'ADMIN_FOLDERS',
  AdminGlobalIndexEdit: 'ADMIN_GLOBAL_INDEX_EDIT',
  AdminConfigureIndexForm: 'ADMIN_INDEX_FORM',
  AdminConfigureSchedulers: 'ADMIN_SCHEDULERS',
  AdminConfigureSearch: 'ADMIN_SEARCH',
  AdminConfigureForm: 'ADMIN_CONFIGURE_FORM',
  AdminRunWorkflowReport: 'ADMIN_WORKFLOW_REPORT',
  AnnotationAdd: 'ANN_ADD',
  AnnotationBurn: 'ANN_BURN',
  AnnotationLock: 'ANN_LOCK',
  AnnotationSecure: 'ANN_SECURE',
  AnnotationView: 'ANN_VIEW',
  CanRunBulkUpdate: 'BULK_UPDATE',
  ConfigureCaptureProfile: 'CONFIG_CAPTURE_PROFILE',
  DelegateWorkflow: 'DELEGATE_WORKFLOW',
  DigitalSignature: 'DIGITAL_SIGNATURE',
  DocumentNoteAddEdit: 'DOC_ADD_EDIT',
  DocumentArchive: 'DOC_ARCHIVE',
  DocumentAssignTask: 'DOC_ASSIGN_TASK',
  DocumentBatesLabel: 'DOC_BATES',
  DocumentCopy: 'DOC_COPY',
  DocumentDelete: 'DOC_DELETE',
  DocumentDeleteNote: 'DOC_DELETE_NOTE',
  DocumentDeletePages: 'DOC_DELETE_PAGES',
  DocumentAssignIndexForm: 'DOC_EDIT_INDEXFORM',
  SecureSubfolder: 'DOC_EDIT_METADATA', // This is not a typo...  that system name maps to 'Sub Folder - Secure' in the DB...
  RecordManager: 'DOC_EDIT_RO_FIELDS',
  DocumentEmail: 'DOC_EMAIL',
  DocumentExport: 'DOC_EXPORT',
  DocumentImport: 'DOC_IMPORT',
  DocumentIndexBursting: 'DOC_INDEX_BURSTING',
  DocumentLegalHold: 'DOC_LEGAL_HOLD',
  DocumentLinkedDocAccess: 'DOC_LINKED_DOCUMENTS',
  DocumentMerge: 'DOC_MERGE',
  DocumentMove: 'DOC_MOVE',
  DocumentMoveToArchive: 'DOC_MOVE_TO_ARCHIVE',
  DocumentOCR: 'DOC_OCR',
  DocumentOpenInNativeFormat: 'DOC_OPEN_NATIVE',
  DocumentPrint: 'DOC_PRINT',
  DocumentRename: 'DOC_RENAME',
  DocumentRetrieveFromArchive: 'DOC_RETRIEVE',
  VersionRollBack: 'DOC_ROLLBACK',
  VersionSaveNewVersion: 'DOC_SAVEVERSION',
  DocumentScan: 'DOC_SCAN',
  DocumentSendToWorkflow: 'DOC_SEND_TO_WORKFLOW',
  DocumentSplit: 'DOC_SPLIT',
  DocumentAddStickyNote: 'DOC_STICKY_NOTE',
  DocumentTerminateWorkflow: 'DOC_TERMINATE_WORKFLOW',
  DocumentThumbnailManager: 'DOC_THUMBNAIL_MGR',
  DocumentToggleAnnotations: 'DOC_TOGGLE_ANN',
  DocumentVersionHistory: 'DOC_VERSIONHISTORY',
  DocumentViewNotes: 'DOC_VIEW_FILE_NOTE',
  DocumentViewHiddenFieldsWithValue: 'DOC_VIEWHIDDEN',
  DocumentViewHiddenFieldsWithoutValue: 'DOC_VIEWHIDDEN_WOVALUE',
  DocumentControlEdit5059: 'DOCUMENT_CONTROL_5059',
  DocumentControlEdit: 'DOCUMENT_CONTROL_EDIT',
  DocumentControlIssueDocument: 'DOCUMENT_CONTROL_ISSUE',
  DocumentControlView: 'DOCUMENT_CONTROL_VIEW',
  DocumentDownload: 'DOCUMENT_DOWNLOAD',
  DocumentHistoryExport: 'DOCUMENT_HISTORY_EXPORT',
  DocumentHistoryView: 'DOCUMENT_HISTORY_VIEW',
  DocumentIndexValidation: 'DOCUMENT_INDEX_VALIDATION',
  DocumentNoteAccess: 'DOCUMENT_NOTE_ACCESS',
  DocumentDeleteToRecycle: 'DOCUMENT_RECYCLE',
  DocumentShuffle: 'DOCUMENT_SHUFFLE',
  DocumentUpload: 'DOCUMENT_UPLOAD',
  DragAndDropAccess: 'DRAG_AND_DROP_ACCESS',
  DocumentTaskEdit: 'EDIT_DOCUMENT_TASK',
  GeneratePDF: 'GENERATE_NEW_PDF',
  SubFolderCopyStructure: 'FOLDER_COPYSTRUCTURE',
  SubfolderCreate: 'FOLDER_CREATE',
  SubfolderDelete: 'FOLDER_DELETE',
  SubFolderAssignIndexForm: 'FOLDER_EDIT_INDEXFORM',
  EmailImport: 'FOLDER_IMPORT_EMAIL',
  ImportFormProcessing: 'FOLDER_IMPORT_FORM',
  SubfolderRename: 'FOLDER_RENAME',
  MobileAccess: 'MOBILE_ACCESS',
  OfficeAddinsAccess: 'OFFICE_ADDINS_ACCESS',
  DocumentPrintWithAnnotations: 'PRINT_WITH_ANNOTATIONS',
  DocumentPrintWithWatermark: 'PRINT_WITH_WATERMARK',
  ReadOnly: 'READ_ONLY',
  RefreshFullTextSearch: 'REFRESH_FULL_TEXT_SEARCH',
  ReportingDashboard: 'REPORTING_DASHBOARD',
  ReportingDocuments: 'REPORTING_DOCUMENTS',
  ReportingUsers: 'REPORTING_USERS',
  ReportingWorkflow: 'REPORTING_WORKFLOW',
  ReportingRunCustom: 'REPORTING_RUN_CUSTOM',
  ShowMainMenu: 'SHOW_MAIN_MENU',
  SignatureAppConfigure: 'SIGNATURE_APP_CONFIGURE',
  SignatureAppUser: 'SIGNATURE_APP_USER',
  SystemAdmin: 'SYSTEM_ADMIN',
  UserEditEmailPreferences: 'USER_EDIT_EMAILPREFS',
  FlatFileImport: 'USER_FLATFILE_IMPORT',
  MaxxSafeAccess: 'USER_MAXXSAFE',
  EditDocumentProperties: 'VIEWER_SETTINGS_EDIT',
  VirtualPrinterAccess: 'VIRTUAL_PRINTER_ACCESS',
  WebAccess: 'WEB_ACCESS',
  WorkflowMassApproval: 'WF_MASS_APPROVAL',
}
