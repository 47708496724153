/* eslint-disable no-tabs */
/* eslint-disable arrow-parens */

import 'core-js';
import {
  MdCard, MdContent, MdTable, MdRipple, MdCheckbox, MdField, MdButton, MdIcon, MdRadio, MdMenu,
  MdDatepicker, MdDialog, MdAutocomplete, MdSnackbar, MdSwitch, MdList,
} from 'vue-material/dist/components'

import Vue from 'vue'
import VueSignaturePad from 'vue-signature-pad'
import filepondPolyfill from 'filepond-polyfill'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import vueDebounce from 'vue-debounce'
import vClickOutside from 'v-click-outside'
import UUID from 'vue-uuid';
import VueObserveVisibility from 'vue-observe-visibility'
import '@/registerServiceWorker'
import 'flatpickr/dist/flatpickr.css'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store/store'

// Import with paranthesis makes it an asynchronous function and returns a Promise
// This fix was suggested in https://github.com/vuejs/vue-loader/issues/808#issuecomment-463415613
// I have absolutely no idea why this works.
import('vue-material/dist/vue-material.min.css')
import('vue-material/dist/theme/default.css')

import VueGlobalVariable from 'vue-global-var';
import boolStringFilter from './filters/boolean-in-english-filter'
import emptyStringFilter from './filters/empty-string-dash-filter'
import dateFormatFilter from './filters/date-format'

Vue.use(require('vue-moment'))

Vue.use(VueObserveVisibility)
Vue.use(filepondPolyfill)
Vue.use(VueSignaturePad);
Vue.use(MdCard)
Vue.use(MdContent)
Vue.use(MdTable)
Vue.use(MdRipple)
Vue.use(MdCheckbox)
Vue.use(MdField)
Vue.use(MdButton)
Vue.use(MdIcon)
Vue.use(MdRadio)
Vue.use(MdMenu)
Vue.use(MdDatepicker)
Vue.use(MdDialog)
Vue.use(MdAutocomplete)
Vue.use(MdSnackbar)
Vue.use(MdSwitch)
Vue.use(MdList)
Vue.use(UUID);
Vue.use(vueDebounce, {
  defaultTime: '700ms',
})
Vue.use(vClickOutside)
Vue.use(VueGlobalVariable, {
  globals: { // Use the $ to show that it is a global variable
    // FORMS APP
    $formsLabel: 'Object',
    $formsLabelPlural: 'Objects',
    $formsLabelWithPrefix: 'an Object',
    $layoutsLabel: 'Form',
    $layoutsLabelPlural: 'Forms',
    $layoutsLabelWithPrefix: 'a Form',
    $fieldsLabelPlural: 'Fields',
  },
})

Vue.filter('boolString', boolStringFilter)
Vue.filter('emptyStringDash', emptyStringFilter)
Vue.filter('dateFormat', dateFormatFilter)

Vue.config.productionTip = false

const registerGlobalComponent = (context) => {
	context.keys().forEach((fileName) => {
		// Get the component config
		const componentConfig = context(fileName)

		// Get the PascalCase name of the component
		const componentName = upperFirst(
			camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')),
		)

		// Register component globally
		Vue.component(componentName, componentConfig.default || componentConfig)
	})
}

// Dynamically load Vasion components
registerGlobalComponent(require.context('./components/shared', false, /Vasion[\w]+\.vue$/))

// Dynamically load Vasion images
registerGlobalComponent(require.context('./assets/images', false, /Vasion[\w]+\.svg$/))
Vue.config.devtools = process.env.VUE_APP_ENABLE_DEVTOOLS ? process.env.VUE_APP_ENABLE_DEVTOOLS : process.env.VUE_APP_ENV === 'dev'
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
