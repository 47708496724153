<template>
  <div id="app">
    <TheMainView />
  </div>
</template>

<script>
import TheMainView from '@/views/TheMainView.vue';

export default {
  name: 'App',
  components: {
    TheMainView,
  },
  computed: {
  },
  async created() {
    await this.$store.dispatch('common/resetAxiosDefaultBaseURL')
  },
};
</script>

<style>
  @import 'https://unpkg.com/formiojs@latest/dist/formio.full.min.css';
  .modal-open {
    overflow: hidden;
  }
</style>

<style lang="scss">
  @import '@/assets/css/vasionStyles.scss';
</style>
