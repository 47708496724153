<template>
  <div id="compare-documents-display" class="background" :class="{ 'right-border': addRightBorder }">
    <div class="image-section">
      <DocumentImage
        class="image"
        :documentID="documentID"
        :height="documentHeight"
        :width="documentWidth"
        :pageNumber="currentPage"
        :zoomScale="zoomValue"
        :imageEndpoint="'document/getDocumentImageURL'"
        transformOrigin="top left"
        :fileDetail="fileDetails"
      />
    </div>
    <div class="navigation-section">
      <VasionCompareZoomButtons
        :zoomValue.sync="zoomDisplay"
        :combined="true"
        @zoomIn="zoomIn"
        @zoomOut="zoomOut"
      />
      <div class="page-navigation">
        <div class="divider" />
        <VasionButton
          name="btnPreviousPage"
          title="Previous Page"
          :icon="'VasionChevronDown'"
          :isDisabled="currentPage === 1"
          @vasionButtonClicked="goToPage(currentPage - 1)"
        />
        <p>{{ currentPage }}/{{ fileDetails.PageCount }}</p>
        <VasionButton
          name="btnNextPage"
          title="Next Page"
          :icon="'VasionChevronUp'"
          :isDisabled="currentPage === fileDetails.PageCount"
          @vasionButtonClicked="goToPage(currentPage + 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DocumentImage from '@/components/document/DocumentImage.vue';
import VasionCompareZoomButtons from '@/components/shared/VasionCompareZoomButtons.vue';

export default {
  name: 'VasionCompareDocument',
  components: {
    DocumentImage,
    VasionCompareZoomButtons,
  },
  props: {
    addRightBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    documentHeight: {
      type: Number,
      required: true,
      default: 0,
    },
    documentID: {
      type: Number,
      required: true,
      default: 0,
    },
    documentWidth: {
      type: Number,
      required: true,
      default: 0,
    },
    fileDetails: {
      type: Object,
      required: true,
      // eslint-disable-next-line
      default: {},
    },
    startingPageNumber: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: 0,
      zoomValue: 1,
      zoomDisplay: 100,
    }
  },
  computed: {
  },
  created() {
    this.currentPage = this.startingPageNumber
  },
  methods: {
    goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0) {
        this.currentPage = pageNumber
        this.$store.dispatch('document/uploadedPagesPageNumber', pageNumber)
      }
    },
    zoomIn() {
      if (this.zoomValue < 3) {
        this.zoomValue *= 1.25
        this.zoomDisplay += 25
      } 
    },
    zoomOut() {
     if (this.zoomValue >= 0.6) {
        this.zoomValue /= 1.25
        this.zoomDisplay -= 25
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .background {
    background: $grey-50;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    padding: 25px;
    overflow: auto;
  }

  .right-border {
    border-right: solid 1px $grey-200;
  }

  .navigation-section {
    z-index: 1;
    height: calc(100vh - 100px);
    position: relative;
    margin: 0 auto;
  }

  .page-navigation {
    display: flex;
    background: rgba(0, 0, 0, 0.9);
    align-items: center;
    color: white;
    fill: white;
    width: 156px;
    border-radius: 0px 8px 8px 0px;
    padding: 6px;
    bottom: 50px;
    position: fixed;
    margin-left: 0;
  }
  .page-navigation svg {
    margin-top: 3px;
  }
  .page-navigation p {
    font-size: 18px;
    margin: 8px auto 0 auto;
  }
  .image-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divider {
    background-color: rgba(255, 255, 255, 0.3);
    width: 1px;
    height: 20px;
  }
</style>
