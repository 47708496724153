<template>
  <div v-if="apiToken" id="actions-menu" @mouseleave="loseFocus">
    <ul v-if="active === &quot;my-tasks-pending&quot;" id="first-list">
      <li id="action-approve" @click="approve">
        <VasionDoneAllIcon class="icon" />Approve
      </li>
      <li :class="actionsConfig.rejectDisable === true ? 'disabled' : ''" @click="reject">
        <VasionCancelIcon class="icon" />Reject
      </li>
      <li @click="take">
        <VasionUserIcon class="icon" />Take Ownership
      </li>
      <li :class="actionsConfig.reassignDisable === true ? 'disabled' : ''" @click="reassign">
        <VasionGroupIcon class="icon" />Reassign
      </li>
      <li @click="terminate">
        <VasionDeleteIcon class="icon" />Terminate Workflow
      </li>
      <li v-if="areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments" @click="emitGoToCompareDocuments()">
        <VasionColumnViewIcon class="icon" />Compare Documents
      </li>
    </ul>
    <ul>
      <li v-if="canEmailDocument" @click="makeAction('email')">
        <VasionEmailIcon class="icon" />Email
      </li>
      <li @click="print">
        <VasionPrintIcon class="icon" />Print
      </li>
      <li v-if="!actionsConfig.thumbnailManagerDisabled" @click="$emit('thumbnailManager')">
        <VasionPhotoGallery class="icon" />Thumbnail Manager
      </li>
      <li @click="makeAction('download')">
        <VasionCloudDownloadIcon class="icon" />Download
      </li>
      <li v-if="isAdminUser || canRunBulkUpdate" @click="makeAction('updateFields')">
        <VasionUpdateFieldsIcon class="icon" />Update Fields
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'VasionActionDropDown',
  props: {
    actionsConfig: {
      type: Object,
      required: false,
      default: null,
    },
    areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      modalConfig: {
        buttonGroup: 'center',
        confirmButtonText: 'Yes',
        hideButtons: 'false',
        message: '',
        modalId: '',
        modalKey: '',
        modalTitle: '',
        modalType: 'message',
        rejectButtonText: 'No',
        slotComponentName: '',
      },
    }
  },
  computed: {
    active() { return this.$store.state.mainViews.mainNav },
    apiToken() { return this.$store.state.common.apiToken },
    canEmailDocument() { return this.$store.getters['common/canEmailDocument'] },
    canRunBulkUpdate() { return this.$store.getters['common/canRunBulkUpdate'] },
    isAdminUser() { return this.$store.getters['common/isAdminUser'] },
  },
  methods: {
    approve() {
      this.modalConfig.modalId = 'approve-modal'
      this.modalConfig.modalKey = 'approve-modal'
      this.modalConfig.message = 'Are you sure you want to Approve the selected Documents ?'
      this.$emit('approve', this.modalConfig)
    },
    emitGoToCompareDocuments() { this.$emit('goToCompareDocuments') },
    loseFocus() { this.$emit('loseFocus') },
    makeAction(action) {
      // eslint-disable-next-line
      switch (action) {
        case 'email':
          this.$emit('email')
          break;
        case 'download':
          this.$emit('download')
          break;
        case 'updateFields':
          this.$emit('updateFields')
          break;
      }
    },
    print() {
      this.modalConfig.modalTitle = 'Print Documents'
      this.modalConfig.modalKey = 'print-modal'
      this.modalConfig.buttonGroup = 'end'
      this.modalConfig.modalType = 'slot'
      this.modalConfig.modalId = 'reassign-modal'
      this.modalConfig.slotComponentName = 'VasionPrintOptionsForGenericModal'
      this.modalConfig.confirmButtonText = 'Print'
      this.modalConfig.rejectButtonText = 'Cancel'
      this.$emit('print', this.modalConfig)
    },
    reassign() {
      this.modalConfig.modalTitle = 'Reassign'
      this.modalConfig.modalKey = 'reassign-modal'
      this.modalConfig.buttonGroup = 'end'
      this.modalConfig.modalType = 'slot'
      this.modalConfig.modalId = 'reassign-modal'
      this.modalConfig.slotComponentName = 'VasionUserGroupSelect'
      this.modalConfig.confirmButtonText = 'Confirm'
      this.modalConfig.rejectButtonText = 'Cancel'
      this.modalConfig.hideButtons = 'true'
      this.$emit('reassign', this.modalConfig)
    },
    reject() {
      this.modalConfig.modalId = 'reject-modal'
      this.modalConfig.modalKey = 'reject-modal'
      this.modalConfig.message = 'Are you sure you want to reject the selected workflow(s)?'
      this.$emit('reject', this.modalConfig)
    },
    take() {
      this.modalConfig.modalId = 'take-ownership-modal'
      this.modalConfig.modalKey = 'take-ownership-modal'
      this.modalConfig.message = 'Are you sure you want to Take Ownership of the selected Documents?'
      this.$emit('takeOwnership', this.modalConfig)
    },
    terminate() {
      this.modalConfig.modalId = 'terminate-modal'
      this.modalConfig.modalKey = 'terminate-modal'
      this.modalConfig.message = 'Are you sure you want to TERMINATE the selected document(s)? Once you TERMINATE these document(s) they cannot be recovered!'
      this.$emit('terminate', this.modalConfig)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  #actions-menu {
    @include Text;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 $shadow;
    z-index: 1000;
    position: absolute;
    top: 106px;
    left: 20px;
    width: 343px;

    #first-list {
      border-bottom: 1px solid $grey-100;
    }

    .icon {
      fill: $grey-400;
      margin: -5px 15px 0 5px;
    }

    & > ul {
      list-style: none;
      padding: 8px 0;
      margin: 0;

      & > li {
        padding: 8px 8px;
        cursor: pointer;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0;

        & > span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 16px;
        }
      }

      & li:hover {
        background-color: rgba(44, 55, 66, 0.12);
      }
    }
  }
</style>
