<template>
  <div class="search-container">
    <div v-if="isInModalView" class="title-group">
      <h1 id="header">
        AP Invoice FT
      </h1>
      <VasionButton
        id="save-button"
        class="merge-buttons"
        name="save-button"
        icon="VasionClear"
        :classProp="'secondary-grey'"
        @vasionButtonClicked="close"
      />
    </div>
    <div class="search-builder">
      <textarea
        id="full-text-builder-text-area"
        ref="fullTextBody"
        v-model="fullText"
        class="search-builder-text-area"
      />
    </div>
    <div class="button-bar">
      <div class="left">
        <VasionButton
          id="and-button"
          class="search-builder-buttons"
          name="and-button"
          :classProp="'secondary-grey'"
          @vasionButtonClicked="addToFullText('AND')"
        >
          AND
        </VasionButton>
        <VasionButton
          id="or-button"
          class="search-builder-buttons"
          name="or-button"
          :classProp="'secondary-grey'"
          @vasionButtonClicked="addToFullText('OR')"
        >
          OR
        </VasionButton>
        <VasionButton
          id="and-not-button"
          class="search-builder-buttons"
          name="and-not-button"
          :classProp="'secondary-grey'"
          @vasionButtonClicked="addToFullText('AND NOT')"
        >
          AND NOT
        </VasionButton>
        <VasionButton
          id="clear-button"
          class="search-builder-buttons"
          name="clear-button"
          :classProp="'secondary-grey'"
          @vasionButtonClicked="clearText()"
        >
          CLEAR
        </VasionButton>
      </div>
      <div class="right">
        <VasionButton
          id="percent-button"
          class="search-builder-buttons"
          name="percent-button"
          :classProp="'secondary-grey'"
          @vasionButtonClicked="addToFullText('%')"
        >
          %
        </VasionButton>
        <VasionButton
          id="tilde-button"
          class="search-builder-buttons"
          name="tilde-button"
          :classProp="'secondary-grey'"
          @vasionButtonClicked="addToFullText('~')"
        >
          ~
        </VasionButton>
        <VasionButton
          id="andpercant-button"
          class="search-builder-buttons"
          name="andpercant-button"
          :classProp="'secondary-grey'"
          @vasionButtonClicked="addToFullText('&')"
        >
          &
        </VasionButton>
        <VasionButton
          id="hash-button"
          class="search-builder-buttons"
          name="hash-button"
          :classProp="'secondary-grey'"
          @vasionButtonClicked="addToFullText('#')"
        >
          #
        </VasionButton>
      </div>
    </div>
    <div class="position-relative">
      <VasionButton
        id="hash-button"
        class="search-builder-buttons add-unique"
        name="hash-button"
        icon="VasionAddIcon"
        :classProp="'secondary-light'"
        :isDisabled="canNotAddUniqueField"
        @vasionButtonClicked="addUniqueFieldAndValue"
      />
    </div>
    <div class="field-row">
      <VasionDropList
        v-slot="slotItem"
        v-model="currentFieldSelection"
        title="FIELD"
        :dataArray="uniqueFieldNames"
        :showSearchField="true"
        :returnValueOnly="true"
        width="432"
        type="plain-list"
        displayName="name"
        valueName="value"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
      <span class="span">=</span>
      <VasionInput
        v-model="textSearchValue"
        width="432"
        class="input-style"
        inputType="blank-white"
      />
    </div>
    <span class="date-header vasion-input-label-top">CREATED DATE</span>
    <div class="field-row">
      <div class="date-picker-wrap">
        <md-datepicker
          id="name"
          v-model="createdFrom"
          :format="'MM-DD-YYYY'"
          name="name"
        >
          <label class="select-date">Select date</label>
        </md-datepicker>
      </div>
      <span class="span">To</span>
      <div class="date-picker-wrap">
        <md-datepicker
          id="name"
          v-model="createdTo"
          :format="'MM-DD-YYYY'"
          name="name"
        >
          <label class="select-date">Select date</label>
        </md-datepicker>
      </div>
    </div>
    <span class="date-header vasion-input-label-top">MODIFIED DATE</span>
    <div class="field-row">
      <div class="date-picker-wrap">
        <md-datepicker
          id="name"
          v-model="modifiedFrom"
          :format="'MM-DD-YYYY'"
          name="name"
        >
          <label class="select-date">Select date</label>
        </md-datepicker>
      </div>
      <span class="span">To</span>
      <div class="date-picker-wrap">
        <md-datepicker
          id="name"
          v-model="modifiedTo"
          :format="'MM-DD-YYYY'"
          name="name"
        >
          <label class="select-date">Select date</label>
        </md-datepicker>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullTextSearchOptions',
  components: {
  },
  data: function () {
    return {
      createdFrom: null,
      createdTo: null,
      currentFieldSelection: '',
      fullText: '',
      isInModalView: false,
      modifiedFrom: null,
      modifiedTo: null,
      textSearchValue: '',
    }
  },
  computed: {
    activeSearch() { return this.$store.state.search.selectedSearch },
    activeSearchData() { return this.$store.state.search.searchCriteria },
    canNotAddUniqueField() { return this.currentFieldSelection === '' || this.textSearchValue === '' },
    uniqueFieldNames() { return this.$store.state.storage.uniqueFieldNames },
  },
  watch: {
  },
  created() {
    this.$store.dispatch('storage/loadUniqueFieldNames')

    if (this.$store.state.search.refineSearch && this.activeSearchData && this.activeSearchData.SearchType === 161) {
      this.createdFrom = this.activeSearchData.More.CreatedFrom
      this.createdTo = this.activeSearchData.More.CreatedTo
      this.currentFieldSelection = this.activeSearchData.More.CurrentFieldSelection
      this.fullText = this.activeSearchData.FullText
      this.modifiedFrom = this.activeSearchData.More.ModifiedFrom
      this.modifiedTo = this.activeSearchData.More.ModifiedTo
      this.textSearchValue = this.activeSearchData.More.TextSearchValue
    }
  },
  methods: {
    addToFullText(value) {
      const position = this.$refs.fullTextBody.selectionStart
      this.fullText = [this.fullText.slice(0, position), value, this.fullText.slice(position)].join('');
    },
    addUniqueFieldAndValue() {
      this.addToFullText(`${this.currentFieldSelection}:${this.textSearchValue}`)
      this.currentFieldSelection = ''
      this.textSearchValue = ''
    },
    clear() {
      this.createdFrom = null
      this.createdTo = null
      this.currentFieldSelection = ''
      this.fullText = ''
      this.modifiedFrom = null
      this.modifiedTo = null
      this.textSearchValue = ''
    },
    clearText() {
      this.fullText = ''
    },
    async search() {
      const payload = {
        SearchId: this.activeSearch.SearchId,
        SearchType: 161,
        FullText: this.fullText,
        More: {
          CreatedFrom: this.createdFrom,
          CreatedTo: this.createdTo,
          CurrentFieldSelection: this.currentFieldSelection,
          ModifiedFrom: this.modifiedFrom,
          ModifiedTo: this.modifiedTo,
          TextSearchValue: this.textSearchValue,
        },
      }
      await this.$store.dispatch('search/setSearchCriteria', payload)
      this.$store.dispatch('mainViews/toggleSavedSearchDropDown', false)
      // eslint-disable-next-line
      this.$router.push({ name: 'SearchResults' }).catch(err => {})
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
  .add-unique {
    position: absolute;
    top: 23px;
    right: -45px;
  }
  .button-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  .date-header {
    bottom: 3px;
  }
  .date-picker-wrap {
    width: 432px;
    height: 40px;
    border-radius: 8px;
    border: solid 1px $grey-100;
    padding: 4px 5px 5px 9px;
  }
  .field-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .field-row:not(:last-child) {
    margin-bottom: 16px;
  }
  #header {
    font-size: 20px;
    color: $grey-700;
  }
  .md-field {
    position: relative;
    padding-top: 18px !important;
    bottom: 17px;
  }
  .md-field.md-input-action {
    position: relative;
    top: 17px;
    // top: 0px !important;
  }
  .md-field.md-theme-default:after {
    position: relative;
    top: 17px;
    background-color: white;
  }
  .position-relative {
    position: relative;
  }
  .md-field {
    min-height: 0px;
    margin: 0px;
    padding: 0px;
  }
  .search-container {
    width: 928px;
    z-index: 2000;
  }
  .search-builder {
    margin: 28px 0 10px 0;
  }
  .search-builder-text-area {
    width: 100%;
    height: 120px;
    border-radius: 8px;
    border: solid 1px $grey-200;
  }
  .select-date {
    font-size: 14px;
    color: $grey-500;
    font-weight: normal;
  }
  .span {
    position: relative;
    bottom: 10px;
  }
  .title-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
