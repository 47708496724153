<template>
  <div id="automation-engine-shared-header">
    <!-- TABS -->
    <div v-if="usePermissionsComputed" class="md-layout">
      <VasionButton
        id="tab-general-automation"
        :classProp="calculatedClass('general-automation')"
        @vasionButtonClicked="updateSelectedTab('general-automation')"
      >
        General
      </VasionButton>

      <VasionButton
        id="tab-permissions"
        :classProp="calculatedClass('permissions')"
        @vasionButtonClicked="updateSelectedTab('permissions')"
      >
        Permissions
      </VasionButton>
    </div>

    <div v-show="selectedTabName === 'general-automation'" id="general-automation" class="tab">
      <div class="row">
        <VasionInput
          v-if="!hideNameLabelComputed"
          id="export-name"
          v-model="name"
          class="row-field"
          :title="nameLabel"
          inputType="top-white"
          name="export-name"
          placeholder="Enter Name..."
          :width="'396'"
          @input="$emit('dirty')"
        />

        <VasionInput
          v-if="!useForSchedulingOnly"
          id="run-on-machine"
          v-model="runOnMachine"
          class="row-field"
          title="RUN ON MACHINE"
          input-type="top-white"
          name="run-on-machine"
          placeholder="Enter Value..."
          :width="'396'"
          @input="$emit('dirty')"
        />
        <VasionDropList
          v-slot="slotItem"
          v-model="perform"
          class="row-field"
          :dataArray="performList"
          width="396"
          title="PERFORM"
          placeholder="Select Frequency..."
          type="plain-list"
          displayName="name"
          valueName="value"
          @input="$emit('dirty')"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div class="row">
        <VasionDropList
          v-show="perform.value === 42"
          ref="dayDropList"
          v-slot="slotItem"
          v-model="weekdays"
          class="row-field"
          :dataArray="dayList"
          width="396"
          title="WEEKDAYS"
          placeholder="Select Weekdays..."
          type="check-list"
          displayName="name"
          valueName="value"
          @input="$emit('dirty')"
        >
          {{ slotItem.item.name }}
        </VasionDropList>

        <VasionDropList
          v-show="perform.value === 43"
          ref="monthDropList"
          v-slot="slotItem"
          v-model="months"
          class="row-field"
          :dataArray="monthList"
          width="396"
          title="MONTHS"
          placeholder="Select Months..."
          type="check-list"
          displayName="name"
          valueName="value"
          @input="$emit('dirty')"
        >
          {{ slotItem.item.name }}
        </VasionDropList>

        <VasionInput
          v-if="perform.value === 45"
          id="frequency-minutes"
          v-model="frequencyMinutes"
          class="row-field"
          title="FREQUENCY (minutes)"
          inputType="top-white"
          name="frequency-minutes"
          placeholder="Enter Value..."
          :width="'396'"
          type="number"
          min="0"
          @input="$emit('dirty')"
        />
        <VasionInput
          v-if="perform.value === 44"
          id="start-date"
          v-model="startDate"
          class="row-field"
          title="START DATE"
          inputType="top-white"
          name="start-date"
          placeholder="Enter Start Date..."
          :width="'396'"
          type="date"
          @input="$emit('dirty')"
        />

        <VasionInput
          id="start-time"
          v-model="startTime"
          class="row-field"
          title="START TIME"
          inputType="top-white"
          name="start-time"
          placeholder="Enter Start Time..."
          :width="'396'"
          type="time"
          @input="$emit('dirty')"
        />

        <VasionInput
          v-if="perform.value === 43"
          id="day-of-month"
          v-model="dayOfMonth"
          class="row-field"
          title="DAY OF MONTH"
          inputType="top-white"
          name="day-of-month"
          placeholder="Enter Day..."
          :width="'396'"
          type="number"
          min="1"
          @input="$emit('dirty')"
        />
      </div>
    </div>

    <div
      v-show="selectedTabName === 'permissions'"
      id="permissions"
      class="tab"
      :class="{ 'permissions-height': restrictHeight }"
    >
      <div>
        <VasionListSelection
          :available-fields.sync="availableUsers"
          :selected-fields.sync="assignedUsers"
          :allow-field-ordering="false"
          :available-label-text="'Users'"
          :selected-label-text="'Permitted Users'"
          :unique-id="'permissions-users'"
          @update:availableFields="$emit('dirty')"
          @update:selectedFields="$emit('dirty')"
          @update:addAll="$emit('dirty')"
          @update:removeAll="$emit('dirty')"
        />
      </div>
      <div class="separator-div" />
      <div>
        <VasionListSelection
          :available-fields.sync="availableGroups"
          :selected-fields.sync="assignedGroups"
          :allow-field-ordering="false"
          :available-label-text="'Groups'"
          :selected-label-text="'Permitted Groups'"
          :unique-id="'permissions-groups'"
          @update:availableFields="$emit('dirty')"
          @update:selectedFields="$emit('dirty')"
          @update:addAll="$emit('dirty')"
          @update:removeAll="$emit('dirty')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutomationEngineSharedHeader',
  props: {
    hideNameLabel: {
      type: Boolean,
      default: false,
      required: false,
    },
    nameLabel: {
      type: String,
      required: true,
    },
    restrictHeight: {
      type: Boolean,
      default: true,
      required: false
    },
    useForSchedulingOnly: {
      type: Boolean,
      default: false,
      required: false
    },
    usePermissions: {
      type: Boolean,
      default: true,
      required: false,
    }
  },
  data: function () {
    return {
      assignedGroups: [],
      assignedUsers: [],
      availableGroups: [],
      availableUsers: [],
      dayOfMonth: 1,
      enableWatchers: false,
      errors: '',
      frequencyMinutes: 0,
      groupIDs: [],
      months: [],
      name: '',
      perform: '',
      runOnMachine: '',
      schedulerId: 0,
      selectedTabName: 'general-automation',
      startDate: '',
      startTime: '',
      userIDs: [],
      weekdays: [],
    }
  },
  computed: {
    dayList() {
      return [
        { name: 'Sunday', value: 31 },
        { name: 'Monday', value: 32 },
        { name: 'Tuesday', value: 33 },
        { name: 'Wednesday', value: 34 },
        { name: 'Thursday', value: 35 },
        { name: 'Friday', value: 36 },
        { name: 'Saturday', value: 37 }]
    },
    groups() { return this.$store.state.common.groups },
    hideNameLabelComputed() { return this.hideNameLabel || this.useForSchedulingOnly },
    monthList() {
      return [
        { name: 'January', value: 51 },
        { name: 'February', value: 52 },
        { name: 'March', value: 53 },
        { name: 'April', value: 54 },
        { name: 'May', value: 55 },
        { name: 'June', value: 56 },
        { name: 'July', value: 57 },
        { name: 'August', value: 58 },
        { name: 'September', value: 59 },
        { name: 'October', value: 510 },
        { name: 'November', value: 511 },
        { name: 'December', value: 512 }]
    },
    performList() {
      return [
        { name: 'Once', value: 44 },
        { name: 'Daily', value: 41 },
        { name: 'Weekly', value: 42 },
        { name: 'Monthly', value: 43 },
        { name: 'Frequency', value: 45 }]
    },
    usePermissionsComputed() { return this.usePermissions && !this.useForSchedulingOnly },
    users() { return this.$store.state.common.users },
  },
  watch: {
    dayOfMonth() {
      if (parseInt(this.dayOfMonth, 10) < 1) {
        this.dayOfMonth = 1
      }
    },
    frequencyMinutes() {
      if (parseInt(this.frequencyMinutes, 10) < 0) {
        this.frequencyMinutes = 0
      }
    },
    perform: function () {
      if (this.enableWatchers) {
        if (this.perform.value !== 42) {
          this.weekdays = []
          this.$refs.dayDropList.selectedArrayLocal = []
        }
        if (this.perform.value !== 43) {
          this.months = []
          this.$refs.monthDropList.selectedArrayLocal = []
          this.dayOfMonth = 1
        }
        if (this.perform.value !== 44) {
          this.startDate = ''
        }
        if (this.perform.value !== 45) {
          this.frequencyMinutes = 0
        }
      }
    },
  },
  async created() {
    this.enableWatchers = true
    this.setValues()
    await Promise.all([
      this.$store.dispatch('common/getUsers'),
      this.$store.dispatch('common/getGroups'),
    ])
    this.setPermissions()
  },
  methods: {
    async buildObjectArray(control, list, values) {
      const filteredList = list.filter(function (i) {
        return this.indexOf(i.value) >= 0
      },
      values)
      const filterObjectList = filteredList.map(i => {
          return { name: i.name, value: i.value }
        })

      control.selectedArrayLocal = filterObjectList // This is kind of a hack to get the selected values to load when editing an existing scheduler

      return filterObjectList
    },
    calculatedClass(selectedTab) { return this.selectedTabName === selectedTab ? 'text-selected' : 'text' },
    // Used externally
    // eslint-disable-next-line
    async getValues() {
      let runDateTime
      if (this.startTime && this.startTime !== '') {
        if (this.startDate && this.startDate !== '') {
          runDateTime = `${this.startDate} ${this.startTime}`
        } else {
          // Important to note: the backend ignores the date portion of the datetime
          // if Perform is not set to Once. So, the date we use here doesn't matter,
          // but it makes most sense to set it to tomorrow.
          let currentDate = new Date()
          let tomorrowDate = new Date(currentDate)
          tomorrowDate.setDate(tomorrowDate.getDate() + 1)
          runDateTime = `${tomorrowDate.toISOString().slice(0, 10)} ${this.startTime}`
        }
      }

      return {
        schedulerID: this.schedulerId,
        name: this.name,
        runFromMachine: this.runOnMachine,
        emailWhenFinished: this.emailWhenFinished,
        selectedMonths: this.months ? this.months.map(m => m.value) : null,
        selectedDays: this.weekdays ? this.weekdays.map(d => d.value) : null,
        startMonthDay: this.dayOfMonth,
        schedulerPerformType: this.perform.value,
        runDateTime: runDateTime,
        frequencyMinutes: this.frequencyMinutes,
        groupIDs: this.assignedGroups.map(group => { return group.value }),
        userIDs: this.assignedUsers.map(user => { return user.value }),
      }
    },
    async setPermissions() {
      this.assignedUsers = []
      this.assignedGroups = []
      this.availableUsers = []
      this.availableGroups = []

      this.groups.forEach((group) => {
        const newGroup = {
          name: group.name,
          value: group.value,
        }

      if (this.groupIDs.includes(group.value)) {
          this.assignedGroups.push(newGroup)
        } else {
          this.availableGroups.push(newGroup)
        }
      })

      this.users.forEach((user) => {
        const newUser = {
          name: user.name,
          value: user.value,
        }

        if (this.userIDs.includes(user.value)) {
          this.assignedUsers.push(newUser)
        } else {
          this.availableUsers.push(newUser)
        }
      })
    },
    async setValues(values) {
      if (values) {
        this.schedulerId = values.schedulerID
        this.name = values.name
        this.runOnMachine = values.runFromMachine
        this.emailWhenFinished = values.emailWhenFinished
        const promises = await Promise.all([
          this.buildObjectArray(this.$refs.monthDropList, this.monthList, values.selectedMonths),
          this.buildObjectArray(this.$refs.dayDropList, this.dayList, values.selectedDays),
        ])
        // eslint-disable-next-line prefer-destructuring
        this.months = promises[0]
        // eslint-disable-next-line prefer-destructuring
        this.weekdays = promises[1]
        this.perform = this.performList.find(p => p.value === values.schedulerPerformType) || ''
        // eslint-disable-next-line prefer-destructuring
        this.startDate = (values.runDateTime !== null) ? values.runDateTime.split('T')[0] : ''
        // eslint-disable-next-line prefer-destructuring
        this.startTime = (values.runDateTime !== null) ? values.runDateTime.split('T')[1] : ''
        this.frequencyMinutes = values.frequencyMinutes
        this.groupIDs = values.groupIDs ?? []
        this.userIDs = values.userIDs ?? []
        this.setPermissions()
      } else {
        this.schedulerId = 0
        this.name = ''
        this.runOnMachine = ''
        this.emailWhenFinished = ''
        this.months = ''
        this.weekdays = ''
        this.perform = ''
        this.startDate = ''
        this.startTime = ''
        this.frequencyMinutes = 0
        this.groupIDs = []
        this.userIDs = []
      }
    },
    updateSelectedTab(newSelectedTab) {
      this.selectedTabName = newSelectedTab
      this.$emit(newSelectedTab)
    },
    // Used externally
    // eslint-disable-next-line
    async validate() {
      let errorText = ''
      const today = new Date()
      const formattedStartDate = new Date(`${this.startDate} ${this.startTime}:00`)

      if (this.name === '') {
        errorText += `- Please provide a value for "${this.nameLabel}."\n`
      }
      else if (this.name.trim() === '') {
        errorText += `- "${this.nameLabel}" cannot only include spaces.\n`
      }

      if (this.perform === '') {
        errorText += '- Please provide a value for "Perform."\n'
      }

      if (!this.startTime || this.startTime === '') {
        errorText += '- Please provide a value for "Start Time".\n'
      }

      if (this.perform.value === 42 && (!this.weekdays || this.weekdays.length === 0)) {
        errorText += '- Please select at least one Weekday, since Perform is set to "Weekly."\n'
      }

      if (this.perform.value === 43 && (!this.months || this.months.length === 0)) {
        errorText += '- Please select at least one Month, since Perform is set to "Monthly."\n'
      }

      if (this.perform.value === 44 && (!this.startDate || this.startDate === '')) {
        errorText += '- Please provide a Start Date, since Perform is set to "Once."\n'
      }

      if (this.perform.value === 44 && (formattedStartDate < today)) {
        errorText += '- Start Date and Start Time needs to be for today or later.\n'
      }
      this.errors = errorText
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #automation-engine-shared-header {
    width: 100%;
    .row {
      width: 100%;
      margin: 13px 0;
      display: flex;

      .row-field {
        margin: 0 14px 5px 0;
      }
    }

    #permissions {
      overflow: auto;
    }
    .permissions-height {
      height: calc(100vh - 200px);
    }
  }
</style>
