<template>
  <div class="vasion-tree-item">
    <li :id="item.Name.toLowerCase()" :title="item.Name" class="tree-list-item">
      <div class="defaultFillColor vld-parent" :class="{ selected: isSelected || isSelectedProgramatically }" @click="selectFolder(false, null, true)">
        <Loading
          v-if="$route.name !== 'VaultFolders' && !disableLoader"
          id="folders-loader"
          :active.sync="isLoading"
          :is-full-page="false"
          :color="loaderColor"
          loader="dots"
          :background-color="loaderBackgroundColor"
        />
        <div class="arrowDiv" @click="toggle()">
          <VasionArrowDropDownRight16Icon v-if="showRightArrowExternal" class="drop-down-arrow" />
          <VasionArrowDropDown16Icon v-if="showDownArrowExternal" class="drop-down-arrow" />
        </div>
        <div class="dataDiv" @click="toggle()" @contextmenu.prevent="openContextMenu($event)">
          <div>
            <!-- eslint-disable vue/max-attributes-per-line -->
            <VasionGoogleIcon v-if="item.FolderId === 0 && item.ThirdPartyStorageType === 'GoogleDrive'" :class="{ folderSelected: isSelected }" class="folder-icon 1" :style="{ fill: fillColor }" />
            <VasionOneDriveIcon v-else-if="item.FolderId === 0 && item.ThirdPartyStorageType === 'OneDrive'" :class="{ folderSelected: isSelected }" class="folder-icon 2" :style="{ fill: fillColor }" />
            <VasionBoxIcon v-else-if="item.FolderId === 0 && item.ThirdPartyStorageType === 'Box'" :class="{ folderSelected: isSelected }" class="folder-icon 2" :style="{ fill: fillColor }" />
            <VasionWorkDocsIcon v-else-if="item.FolderId === 0 && item.ThirdPartyStorageType === 'WorkDocs'" :class="{ folderSelected: isSelected }" class="folder-icon 2" :style="{ fill: fillColor }" />
            <VasionS3Icon v-else-if="item.FolderId === 0 && item.ThirdPartyStorageType === 'S3'" :class="{ folderSelected: isSelected }" class="folder-icon 2" :style="{ fill: fillColor }" />
            <VasionWasabiIcon v-else-if="item.FolderId === 0 && item.ThirdPartyStorageType === 'Wasabi'" :class="{ folderSelected: isSelected }" class="folder-icon 2" :style="{ fill: fillColor }" />
            <VasionFolderSparkIcon v-else-if="item.FolderId === 0" :class="{ folderSelected: isSelected }" class="folder-icon 2" :style="{ fill: fillColor }" />
            <VasionInsertDriveFileIcon v-else-if="item.IsDocument" :class="{ folderSelected: isSelected }" class="folder-icon 3" :style="{ fill: fillColor }" />
            <VasionFolderIcon v-else :class="{ folderSelected: isSelected }" class="folder-icon 3" :style="{ fill: fillColor }" />
            <!-- eslint-enable vue/max-attributes-per-line -->
          </div>
          <div class="folderText" :class="{ extraTextLengthClass: extraTextLength }">
            <label v-if="!isLoading">{{ item.Name }}</label>
          </div>
        </div>
      </div>

      <VasionContextMenu
        ref="contextMenu"
        :close-on-click="true"
        :close-on-scroll="true"
        :options="options()"
        @option-clicked="optionClicked"
      />

      <VasionFileUploadDialog
        :allowMultiple="true"
        :serverOptions="serverOptions"
        :show.sync="showUploadDialog"
        @allFilesUploaded="allFilesUploaded"
      />

      <ul v-if="(isOpen && hasRenderedChildren) || (isOpenProgramatically && hasRenderedChildren)">
        <VasionTreeItem
          v-for="(child, index) in item.Children"
          :key="index"
          class="item"
          :treeData="child"
          :lazy-load-subfolders-callback="lazyLoadSubfoldersCallback"
          :extra-text-length="extraTextLength"
          :storageConfigId="storageConfigId"
          :storage-type="storageType"
          :show-files="showFiles"
          :enforce-user-security="enforceUserSecurity"
          :enable-context-menu="enableContextMenu"
          :fill-color="fillColor"
          @itemSelected="handleFolderSelected"
          @itemDeleted="handleChildDeleted"
          @itemRenamed="handleChildRename"
        />
        <li v-if="hasMoreFiles" class="tree-list-item">
          <div class="dataDiv md-layout folderText" style="margin-left: 57px;">
            <label>Show More Documents...</label>
          </div>
        </li>
      </ul>

      <md-dialog id="confirmFormDelete" :md-active.sync="showDeleteDialog">
        <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="toggleDeleteDialog()" @yesButtonClick="deleteFolderAndToggle()" />
      </md-dialog>

      <md-dialog id="rename-folder" class="dialog" :md-active.sync="showRenameDialog">
        <VasionRename
          :name="item.Name"
          @saveClick="renameFolder"
          @cancelClick="showRenameDialog = !showRenameDialog"
        />
      </md-dialog>

      <md-dialog id="copy-structure-modal" :md-active.sync="showBrowseFolderStructuresDialog" class="folder-select">
        <VasionFolderSelect
          title="Copy Structure To"
          okButtonText="Copy"
          @cancelButtonClick="toggleBrowseFolderStructureDialog"
          @okButtonClick="copyStructureOkClick"
        />
      </md-dialog>

      <VasionGeneralModal
        id="create-new-sub-storage"
        :modal-type="'slot'"
        :hide-buttons="true"
        :sync="showNewSubFolderDialog"
      >
        <div id="vault-folder-container">
          <VaultConfig @closeWindow="showNewSubFolderDialog = !showNewSubFolderDialog" @saved="handleCreateSubFolderSave" />
        </div>
      </VasionGeneralModal>

      <md-dialog id="create-new-top-storage" :md-active.sync="showNewTopFolderDialog" class="vasion-parent-folder-div">
        <TheParentFolder
          :isModal="true"
          :defaultDriveLocationId="storageConfigId"
          @close="showNewTopFolderDialog = !showNewTopFolderDialog"
          @saved="handleCreateTopFolderSave"
        />
      </md-dialog>

      <VasionSnackbar
        id="delete-folder-snack"
        :show-snackbar-bool.sync="showSnackbarBool"
        :snackbar-image="snackbarImage"
        :snackbar-sub-title="snackbarSubTitle"
        :snackbar-title="snackbarTitle"
      />
    </li>
  </div>
</template>

<script>
/* eslint-disable prefer-arrow-callback */

import Loading from 'vue-loading-overlay';
import VaultConfig from '@/components/vault/VaultConfig.vue';
import TheParentFolder from '@/views/storage/TheParentFolder.vue'
import VasionFolderSelect from '@/components/shared/VasionFolderSelect.vue';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { toBase64 } from '@/store/helperModules/storage.module'
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'VasionTreeItem',
  components: {
    VasionFolderSelect,
    Loading,
    TheParentFolder,
    VaultConfig,
  },
  props: {
    disableLoader: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableRootSelect: {
      type: Boolean,
      default: false,
      required: false,
    },
    enableContextMenu: {
      type: Boolean,
      default: true,
      required: false,
    },
    enforceUserSecurity: {
      type: Boolean,
      default: false,
      required: false,
    },
    extraTextLength: {
      type: Boolean,
      default: false,
      required: false,
    },
    fillColor: {
      type: String,
      default: '#000000',
      required: false,
    },
    storageConfigId: {
      type: Number,
      required: true,
    },
    includeWFQueue: {
      type: Boolean,
      default: false,
      required: false,
    },
    lazyLoadSubfoldersCallback: {
      type: Function,
      default: null,
      required: false,
    },
    maxDocumentCount: {
      type: Number,
      required: false,
      default: 10,
    },
    routeToFolderOnSelection: {
      type: Boolean,
      default: false,
      required: false,
    },
    showFiles: {
      type: Boolean,
      default: false,
      required: false,
    },
    storageType: {
      type: String,
      default: '', // Value of empty string means we are in the Vasion Drive
      required: false,
    },
    toggleTopByDefault: {
      type: Boolean,
      default: true,
      required: false,
    },
    topLevelFolder: {
      type: Boolean,
      default: false,
      required: false,
    },
    treeData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasRenderedChildren: false,
      hasMoreFiles: false,
      isLoading: false,
      isOpen: false,
      isOpenProgramatically: this.amIOpenedProgramatically(),
      isSelected: false,
      isSelectedProgramatically: false,
      item: this.treeData,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      serverOptions: {
        process: this.processHandler,
      },
      showBrowseFolderStructuresDialog: false,
      showDeleteDialog: false,
      showDownArrowExternal: false,
      showNewSubFolderDialog: false,
      showNewTopFolderDialog: false,
      showRenameDialog: false,
      showRightArrowExternal: false,
      showSnackbarBool: false,
      showUploadDialog: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      storageid: '',
    };
  },
  computed: {
    activeFolderId() { return this.$store.state.vault.activeFolderId },
    activeFolderName() { return this.$store.state.vault.folderDisplayName },
    canConfigureFolders() { return this.$store.getters['common/canConfigureFolders'] },
    canCopyFolderStructure() { return this.$store.getters['common/canCopyFolderStructure'] },
    canCreateSubFolder() { return this.$store.getters['common/canCreateSubfolder'] },
    canRenameSubFolders() { return this.$store.getters['common/canRenameSubFolders'] },
    canUploadDocuments() { return this.$store.getters['common/canUploadDocument'] },
    deletePromptMessage() { return `Are you sure you want to delete folder "${this.item.Name}"?` },
    folderAncestorsList() { return this.$store.state.vault.folderAncestorsList },
    syncParentFolder() { return this.$store.state.vault.syncParentFolder },
  },
  watch: {
    activeFolderId: function (folder) {
      if (folder === 0) {
        this.isSelected = (this.item?.Name === this.activeFolderName)
      } else {
        this.isSelected = (this.item?.FolderId === folder)
      }
    },
    folderAncestorsList() {
      if (!this.folderAncestorsList.length) {
        this.isSelectedProgramatically = false
      }
    },
    syncParentFolder: function (folder) {
      if ((folder === 0 && this.item?.Name === this.activeFolderName) || (folder !== 0 && this.item?.FolderId === folder)) {
        this.$store.dispatch('vault/syncParentFolder', -1)
        this.loadChildren(true)
        this.selectFolder(true, null, true)
      }
    },
  },
  created() {
    if (this.item && this.item.FolderId === 0 && this.toggleTopByDefault === true) {
      this.toggle();
    }
    this.showRightArrow()
  },
  mounted() {
    this.amISelectedProgramaticlly()
  },
  methods: {
    allFilesUploaded() {
      this.$store.dispatch('vault/setRefreshGrid', true)
      this.showUploadDialog = false
    },
    amIOpenedProgramatically() {
      if (this.$store.state.vault.folderAncestorsList.length) {
        const itemId = this.item.FolderId
        const index = this.$store.state.vault.folderAncestorsList.findIndex(item => item === itemId);
        if (index >= 0 || (itemId === 0 && this.item.Name === 'Vasion Drive')) {
          this.loadChildren(true)
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    amISelectedProgramaticlly() {
      const itemId = this.item.FolderId
      if (this.$store.state.vault.folderAncestorsList[this.$store.state.vault.folderAncestorsList.length - 1] === itemId) {
        const selectedData = {
          storageid: '',
          isSelected: true,
          value: this.item.FolderId,
          name: this.item.Name,
        }
        this.isSelectedProgramatically = true
        this.$emit('itemSelected', selectedData)
        return true
      } else {
        return false
      }
    },
    closeItem() {
      this.isOpen = false
    },
    async deleteFolderAndToggle() {
      const deleteFolderID = this.item.FolderId
      const deleteSubFolderAncestors = await this.$store.dispatch('vault/deleteSubFolder', deleteFolderID)

      if (deleteSubFolderAncestors.Values?.length >= 1) {
        this.toggleDeleteDialog()
        this.$emit('itemDeleted', deleteFolderID)
        await this.showSnackbar(true)
      } else {
        this.toggleDeleteDialog()
        this.showSnackbar(false)
      }
    },
    async findStructureTargetFolder(selectedTargetFolderId) {
      let currentObject = this.$parent
      while (currentObject && currentObject.item && currentObject.item.HasChildren) {
        currentObject = currentObject.$parent
      }

      const resultTree = []
      await this.traverseTreeForTargetItem(currentObject.$children, selectedTargetFolderId, resultTree, this.traverseTreeForTargetItem)

      return resultTree
    },
    async copyStructureOkClick(payload) {
      this.$store.dispatch('vault/setIsLoading', true)
      this.isLoading = true
      this.toggleBrowseFolderStructureDialog()
      const copyWorked = await this.saveCopiedStructureInTargetFolder(payload.SelectedFolderID)

      if (copyWorked === false) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Error copying folder structure'
        this.snackbarImage = false
        this.$store.dispatch('vault/setIsLoading', false)
        this.isLoading = false
        return
      }

      this.showSnackbarBool = true
      this.snackbarTitle = 'SUCCESS'
      this.snackbarSubTitle = 'Successfully copied folder structure'
      this.snackbarImage = true

      const targetFolderResults = await this.findStructureTargetFolder(payload.SelectedFolderID)
      this.selectFolder(false, targetFolderResults[0], false, payload.SelectedFolderID)
      this.$store.dispatch('vault/setIsLoading', false)
      this.isLoading = false
    },
    handleChildDeleted(deletedFolderID) {
      if (!deletedFolderID || deletedFolderID <= 0) {
        return
      }

      const deletedFolder = this.item.Children.filter(folder => {
        return folder.FolderId === deletedFolderID
      })

      if (!deletedFolder) {
        return
      }

      this.item.Children = this.item.Children.filter(folder => {
        return folder.FolderId !== deletedFolderID
      })

      this.selectFolder(true)
      this.showDownArrowExternal = this.showDownArrowExternal && (this.item.Children.length > 0 || (this.HasDocumentsBeneath && this.showFiles))
      this.showRightArrowExternal = this.showRightArrowExternal && (this.item.Children.length > 0 || (this.HasDocumentsBeneath && this.showFiles))
    },
    handleChildRename(renameFolderId, newFolderName) {
      if (!renameFolderId || renameFolderId <= 0) {
        return
      }
      const renamedFolder = this.item.Children.filter(folder => {
        return folder.FolderId === renameFolderId
      })

      if (!renamedFolder) {
        return
      }
      this.item.Children = this.item.Children.map(folder => {
        if (folder.FolderId === renameFolderId) {
          folder.Name = newFolderName
        }
        return folder
      })
    },
    async handleCreateSubFolderSave(parentFolderName, folderId) {
      this.snackbarTitle = 'Folder Created Successfully'
      this.snackbarSubTitle = `Created "${parentFolderName}"`
      this.snackbarImage = true
      this.showSnackbarBool = true
      this.showNewTopFolderDialog = false

      this.item.HasChildren = true
      const data = {
        isDocument: false,
        isSelected: true,
        name: parentFolderName,
        showFiles: false,
        storageid: '',
        value: folderId,
      }
      this.handleFolderSelected(data)
      this.isSelected = false
      this.showNewSubFolderDialog = !this.showNewSubFolderDialog
    },
    async handleCreateTopFolderSave(parentFolderName, driveStorageConfig, folderId) {
      this.snackbarTitle = 'Parent Folder Saved Successfully'
      this.snackbarSubTitle = `Saved "${parentFolderName}" to ${driveStorageConfig?.name ?? driveStorageConfig}`
      this.snackbarImage = true
      this.showSnackbarBool = true
      this.showNewTopFolderDialog = false

      await this.refreshTopParent()
      const data = {
        isDocument: false,
        isSelected: true,
        name: parentFolderName,
        showFiles: false,
        storageid: '',
        value: folderId,
      }
      this.handleFolderSelected(data)
      this.isSelected = false
    },
    handleFolderSelected(selectedData) {
      this.$store.dispatch('vault/setIsLoading', true)
      this.isLoading = true

      this.$store.dispatch('workflow/setSelectedDocumentId', selectedData && selectedData.isDocument === true ? selectedData.value : null)
      this.$emit('itemSelected', selectedData)

      if (this.routeToFolderOnSelection === true && this.$route.path !== '/storage/vaults') {
        this.$router.push({ name: 'VaultFolders' })
      }
      this.$store.dispatch('vault/setIsLoading', false)
      this.isLoading = false
    },
    async loadChildren(force) {
      if ((!this.item.HasChildren && !this.item.HasDocumentsBeneath) && !force) {
        return;
      }
      if ((!this.isOpen && (!this.item.Children || this.item.Children.length === 0)) || force) {
        let response
        this.item.Children = [];

        if (force && (this.isOpen || this.isOpenProgramatically)) {
          this.toggle()
        }

        if (this.lazyLoadSubfoldersCallback) {
          response = await this.lazyLoadSubfoldersCallback(this.item.FolderId)
        } else {
          const payload = {
            parentFolderId: this.item.FolderId,
            enforceUserSecurity: this.enforceUserSecurity,
            groupByStorageType: false,
            maxxDocumentCount: this.maxDocumentCount,
            includeWFQueue: this.includeWFQueue,
            storageConfigId: this.storageConfigId,
          }
          if (this.showFiles && !this.topLevelFolder) {
            response = await this.$store.dispatch('common/getFolderListWithDocuments', payload)
            if (response.data.HasMoreDocuments) { this.hasMoreFiles = true }
          } else {
            response = await this.$store.dispatch('common/getChildFolders', payload)
          }
        }

        if (response?.data && response.data.FolderList) {
          this.item.Children = response.data.FolderList.Values;
        }
        if (response && response.length) {
          this.item.Children = response
        }

        if (force) {
          // redraw the children
          this.toggle()
        }
      }
      if (this.item.Children) {
        this.hasRenderedChildren = true
      }
    },
    openContextMenu(event) {
      // First, hide all previously opened context menus
      const elements = document.getElementsByClassName('context-menu')
      // eslint-disable-next-line
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('aria-hidden', false)
        elements[i].style.cssText = 'top: -9999px; left: -9999px;'
      }

      const availableOptions = this.options()
      if (availableOptions && availableOptions.length > 0) {
        this.$refs.contextMenu.$refs.menu.open(event)
      }
    },
    async optionClicked(slug) {
      switch (slug) {
        case 'upload-file':
          this.showUploadDialog = true
          break
        case 'scan-to-vasion':
          await this.$store.dispatch('common/openScanToVasion', {
            folderId: this.item.FolderId,
            workflowId: 0,
          })
          break
        case 'delete-folder':
          this.toggleDeleteDialog()
          break
        case 'rename-folder':
          this.showRenameDialog = true
          break
        case 'copy-structure':
          this.toggleBrowseFolderStructureDialog()
          break;
        case 'new-sub-folder':
          this.selectFolder(true)
          this.showNewSubFolderDialog = true
          break;
        case 'new-top-level-folder':
          this.showNewTopFolderDialog = true
          break;
        default:
          break;
      }
    },
    options() {
      const options = []
      if (!this.item || !this.item.FolderId || this.item.FolderId === 0) {
        if (this.canConfigureFolders) {
          options.push({
            name: 'New Folder',
            slug: 'new-top-level-folder',
          })
        }
        return options
      }

      if (this.canUploadDocuments) {
        options.push({
          name: 'Upload File',
          slug: 'upload-file',
        })

        options.push({
          name: 'Scan to Vasion',
          slug: 'scan-to-vasion',
        })
      }

      if (this.item.CanRename && this.canRenameSubFolders) {
        options.push({
          name: 'Rename Folder',
          slug: 'rename-folder',
        })
      }

      if (this.item.HasDocumentsBeneath === false && this.item.CanRename) {
        options.push({
          name: 'Delete Folder',
          slug: 'delete-folder',
        })
      }

      if (this.canCopyFolderStructure) {
        options.push({
          name: 'Copy Structure',
          slug: 'copy-structure',
        })
      }

      if (this.canCreateSubFolder) {
        options.push({
          name: 'New Folder',
          slug: 'new-sub-folder',
        })
      }

      return options
    },
    // eslint-disable-next-line
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      try {
        let fileDataString = await toBase64(file)
        const indexOf = fileDataString.indexOf(';base64,')
        if (indexOf >= 0) {
          fileDataString = fileDataString.substring(indexOf + 8)
        }

          const vasionPayload = {
            folderId: this.item.FolderId,
            name: file.name,
            lastModified: file.lastModified,
            lastModifiedData: file.lastModifiedDate,
            size: file.size,
            type: file.type,
            fileData: fileDataString,
          }
        await this.$store.dispatch('storage/uploadFileToVasion', vasionPayload)
        progress(true, 0, 1024);
        load(file.name);
      } catch (e) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Something went wrong while uploading the file'
        this.snackbarImage = false
        console.warn(e)
      }
    },
    async refreshTopParent() {
      this.closeItem()
      this.item.HasChildren = true
      this.item.Children = []
      await this.toggle()
    },
    async renameFolder(newName) {
      const payload = {
        FolderId: this.item.FolderId,
        SubFolderName: newName,
      }
      const response = await this.$store.dispatch('storage/renameSubfolder', payload)

      if (response?.sGenRes) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = response.sGenRes
        return
      }

      this.showRenameDialog = false

      this.$store.dispatch('vault/setRefreshGrid', true)
      this.$store.dispatch('vault/syncParentFolder', this.item.ParentId)
      if (this.isSelected) {
        this.$store.dispatch('vault/setActiveFolderName', newName)
      }

      this.$emit('itemRenamed', this.item.FolderId, newName)
    },
    async saveCopiedStructureInTargetFolder(selectedTargetFolderId) {
      const payload = {
        parentFolderID: selectedTargetFolderId,
        copyFolderID: this.item.FolderId,
      }
      const response = await this.$store.dispatch('storage/copyFolderStructure', payload)
      return response && response === 'True'
    },
    async selectFolder(forceSelection, structureTargetFolder, userClick, selectedTargetFolderId) {
      let selectedData = {}
      let selectedFolderChildCount = 0

      if (this.disableRootSelect && this.topLevelFolder) {
        return
      }

      // If the user just copied a folder structure ...
      if (selectedTargetFolderId && structureTargetFolder) {
        if (structureTargetFolder.isOpen) {
          structureTargetFolder.toggle()
        }

        structureTargetFolder.item.Children = []
        await structureTargetFolder.loadChildren(false)

        const { selectedFolder } = this.$store.state.common
        if (selectedFolder) {
          selectedFolderChildCount = selectedFolder.item
          ? selectedFolder.item.ChildCount
          : 0

          this.$store.dispatch('common/setSelectedFolderSelectionValue', false)
        }
        this.$store.dispatch('common/setSelectedFolder', structureTargetFolder);
        this.$store.dispatch('common/setSelectedFolderId', structureTargetFolder.item.FolderId);

        // unselect the current TreeItem
        this.isSelected = false
        this.isOpen = false
        this.isOpenProgramatically = false
        this.isSelectedProgramatically = false

        // Select the new target item
        structureTargetFolder.isSelected = true
        structureTargetFolder.isSelectedProgramatically = true
        structureTargetFolder.item.HasChildren = true
        structureTargetFolder.item.ChildCount = (structureTargetFolder.item.ChildCount ? structureTargetFolder.item.ChildCount : 0)
          + selectedFolderChildCount
          + 1 // account for the top folder in the copied structure

        selectedData = {
          storageid: structureTargetFolder.storageid,
          isSelected: structureTargetFolder.isSelected,
          value: structureTargetFolder.item.FolderId,
          name: structureTargetFolder.item.Name,
          isDocument: structureTargetFolder.item.isDocument,
          showFiles: this.showFiles,
        }
        structureTargetFolder.toggle()
      } else {
        this.$store.dispatch('vault/clearAncestorsList')

        if (forceSelection || this.isSelected !== true) {
          if (this.$store.state.common.selectedFolder) {
            this.$store.dispatch('common/setSelectedFolderSelectionValue', false);
          }
          this.$store.dispatch('common/setSelectedFolder', this);
          this.$store.dispatch('common/setSelectedFolderId', this.item.FolderId);
        } else {
          this.$store.dispatch('common/setSelectedFolder', null);
        }

        this.isSelected = !this.isSelected
        if (!userClick) {
          this.isOpenProgramatically = !this.isOpenProgramatically
        }
        selectedData = {
          storageid: this.storageid,
          isSelected: this.isSelected,
          value: this.item.FolderId,
          name: this.item.Name,
          isDocument: this.item.IsDocument,
          showFiles: this.showFiles,
        }
      }
      selectedData.storageConfigId = this.storageConfigId
      this.$store.dispatch('common/setSelectedStorageConfigId', this.storageConfigId);

      this.$emit('itemSelected', selectedData)
    },
    showDownArrow(value) {
      if (typeof value === 'undefined' || value === null) {
        this.showDownArrowExternal = this.item && (this.item.HasChildren || (this.item.HasDocumentsBeneath && this.showFiles)) && this.isOpen
      } else {
        this.showDownArrowExternal = value
      }
    },
    showRightArrow(value) {
      if (typeof value === 'undefined' || value === null) {
        this.showRightArrowExternal = this.item && (this.item.HasChildren || (this.item.HasDocumentsBeneath && this.showFiles)) && !this.isOpen
      } else {
        this.showRightArrowExternal = value
      }
    },
    showSnackbar(worked) {
      this.snackbarTitle = worked ? 'Subfolder deleted' : 'Unable to delete folder'
      this.snackbarSubTitle = worked ? '' : 'Please try again or contact your administrator'
      this.snackbarImage = worked
      this.showSnackbarBool = true;
    },
    async toggle() {
      this.$store.dispatch('vault/setIsLoading', this.$route.name && this.$route.name !== 'VaultFolders')
      this.isLoading = this.$route.name && this.$route.name !== 'VaultFolders'
      await this.loadChildren()
      this.isOpen = !this.isOpen;
      this.isOpenProgramatically = false
      this.isSelectedProgramatically = false
      this.showDownArrow(this.isOpen && this.HasChildren)
      this.showRightArrow(!this.isOpen && this.HasChildren)
      this.$store.dispatch('vault/setIsLoading', false)
      this.isLoading = false
    },
    toggleBrowseFolderStructureDialog() {
      this.showBrowseFolderStructuresDialog = !this.showBrowseFolderStructuresDialog
    },
    toggleDeleteDialog() {
      this.showDeleteDialog = !this.showDeleteDialog
    },
    async traverseTreeForTargetItem(array, targetId, resultTree, traverseFunc) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < array.length; i++) {
        const child = array[i]
        if (!child.item) {
          // eslint-disable-next-line no-continue
          continue
        }

        if (child.item.FolderId === targetId) {
          resultTree.push(child)
          break
        }

        traverseFunc(child.$children, targetId, resultTree, traverseFunc)
      }
    },
  },
};
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';

  .arrowDiv {
    width: 20px;
    margin-top: 3px;
  }

  .button-alignment {
    align-self: flex-end;
    position: relative;
    left: 5px;
    top: 63px;
  }

  .defaultFillColor {
    fill: $white;
    color: $white;
  }

  .defaultFillColor:hover{
    background-color: $white-12;
    // font-weight: bold;
    border-width: 0px;
    border-radius: 5px;

    .arrowDiv {
      fill: $white;
    }
  }

  .dataDiv :hover {
    cursor: pointer;
  }

  .dialog {
    border-radius: 5px;
  }

  .folderText {
    margin-left: 12px;
    margin-top: 6px;
  }

  .folder-icon {
    position: relative;
    top: 2px;
    opacity: 0.48;
  }

  .folderSelected {
    opacity: 1;
  }

  .selected {
    background-color: $white-12;
    fill: $grey-500;
    color: $grey-500;
    // font-weight: bold;
    border: solid;
    border-width: 0px;
    border-radius: 5px;
  }

  .side-nav-folders .dataDiv {
    padding-top: 5px;
    display: flex;
  }
  .side-nav-folders .arrowDiv {
    margin-top: 10px;
  }
  .side-nav-folders .item .folderText {
    color: $white;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .extraTextLengthClass {
    width: 90% !important;
  }
  @media (max-width: $phone){
    .extraTextLengthClass{
      width: 70% !important;
    }
  }

  .side-nav-folders li .folderText {
    color: $white;
  }
  .side-nav-folders  .defaultFillColor{
    height: 40px;
    border-width: 0px;
    border-radius: 5px;
    font-size: 16px;
    font-family: $font-family-book;
    display: flex;
  }
  .side-nav-folders  .defaultFillColor:hover{
    background-color: $white-12;
    // font-weight:bold;
    fill: $white-24;
    color: $white-24;
    .folder-icon{
      opacity: 1;
    }
  }
  .side-nav-folders .item .drop-down-arrow {
    fill: $white;
  }
  .side-nav-folders .item .selected {
    background-color: $white-12;
    fill: $white-24;
    color: $white-24;
    // font-weight: bold;
    border: solid;
    border-width: 0px;
    border-radius: 5px;
  }

  .tree-list-item {
    width: 100%;
  }

  ul {
    padding-left: 8px;
    list-style-type: none;
    white-space: nowrap;
  }

  .label {
      @include Text
    }
  .rename-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    width: 530px;
    height: 200px;
  }
  .rename-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .rename-input, .rename-folder-title, .rename-buttons{
    width: 100%;
  }

  #copy-structure-modal {
    overflow: auto;
  }

  #parent-folder-container{
    min-width: 800px;
    width: calc(100vw - 400px);
  }

  #vault-config-container{
    width: 800px;
  }

  #folders-loader {
    width: 100%;
  }
</style>
