<template>
  <div class="card">
    <apexchart
      type="radialBar"
      :colors="colorProp"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'VasionChartsGauge',
  components: {
   apexchart: VueApexCharts,
  },
  props: {
    colorProp: {
      type: String,
      required: true,
    },
    heightProp: {
      type: Number,
      required: true,
    },
    labelProp: {
      type: String,
      required: true,
    },
    percentProp: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        colors: [this.colorProp],
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                fontSize: '14px',
                formatter: function (val) {
                  return `${val}%`
                },
                show: true,
              },
              total: {
                label: this.labelProp,
                show: true,
              },
            },
            endAngle: 135,
            hollow: {
              margin: 0,
              size: '75%',
            },
            startAngle: -135,
            track: {
              background: '#f1f2f3',
              endAngle: 135,
              startAngle: -135,
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
      series: [this.percentProp],
    }
  },
}
</script>

<style scoped>
</style>
