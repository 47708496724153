<template>
  <div id="direct-scan-upload">
    <div id="direct-scan-contents">
      <VasionButton
        id="start-scan"
        name="start-scan"
        :classProp="'secondary'"
        @vasionButtonClicked="openScanToVasion"
      >
        START SCAN
      </VasionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DirectScanUpload',
  computed: {
    selectedWorkflow() { return this.$store.state.workflow.selectedWorkflow },
  },
  created() {},
  methods: {
    async openScanToVasion() {
      this.$emit('scanToVasionStarted')
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';

  #direct-scan-upload {
    #direct-scan-contents {
      width: 116px;;
      margin: 0 auto;

      #start-scan {
        margin: 0 auto;

        button {
          border-color: $red-200 !important;

          .md-button-content {
            color: $red-200;
          }
        }
      }
    }
  }
</style>
