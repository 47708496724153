import { parseISO, format } from 'date-fns'

function parseISOString(isoDate) {
  const splitISODate = isoDate.split(/\D+/);
  return new Date(Date.UTC(splitISODate[0], splitISODate[1] - 1, splitISODate[2], splitISODate[3], splitISODate[4], splitISODate[5], splitISODate[6]));
}

function parseISOStringNoUTC(isoDate) {
  const splitISODate = isoDate.split(/\D+/);
  return new Date(splitISODate[0], splitISODate[1] - 1, splitISODate[2], splitISODate[3], splitISODate[4], splitISODate[5], splitISODate[6]);
}

// eslint-disable-next-line consistent-return
const buildHistoryTableData = function buildHistoryTableData(response) {
    const headers = ['Action Date', 'Action Description', 'Description', 'User Name']
    if (response == null) {
      return { tableHeaders: headers, tableRows: [] }
    }
    const returnArr = response.map((item) => {
        const date = parseISOStringNoUTC(item.dtActionDate)
        return {
          Values: [date.toLocaleString(), item.sActionDescription, item.sDescription, item.sUserName],
          ValueType: 'String',
        }
    })
    return { tableHeaders: headers, tableRows: returnArr }
}

const buildLinkedDocumentsTableData = function buildLinkedDocumentsTableData(response) {
  const headers = ['_DocID_', 'Name', 'Created', 'Modified', '_VasionIconButton_']
  if (response == null) {
    return { tableHeaders: headers, tableRows: [] }
  }
  const returnArr = response.map((item) => {
      const created = parseISOString(item.CreatedUTC)
      const modified = !isNaN(Date.parse(item.ModifiedUTC)) ? parseISOString(item.ModifiedUTC) : created
      return {
        Values: [item.DocumentId, item.DisplayName, created.toLocaleString(), modified.toLocaleString(), 'VasionDeleteIcon'],
        ValueType: 'String',
      }
  })
  return { tableHeaders: headers, tableRows: returnArr }
}

const buildNotesTableData = function buildNotesTableData(response) {
  const headers = ['_NoteID_', 'Date', 'Username', 'Note', 'Page']
  if (response == null) {
    return { tableHeaders: headers, tableRows: [] }
  }
  const returnArr = response.map((item) => {
      const date = parseISOString(item.CreatedDateUTC)
      return {
        Values: [item.NoteID, date.toLocaleString(), item.NameOfUser, item.NoteText, item.LinkPageNumber],
        ValueType: 'String',
      }
  })
  return { tableHeaders: headers, tableRows: returnArr }
}

const buildVersionTableData = function buildVersionTableData(response) {
  const headers = ['Name', 'Created', 'Modified', 'Version', '_VasionEllipsisButton_']
  if (response == null) {
    return { tableHeaders: headers, tableRows: [] }
  }
  var returnArr = response.map((item) => {
      return {
        Values: [item.DisplayName, format(parseISO(item.CreatedUTC), 'MMM dd, yyyy, hh:mm aa'), format(parseISO(item.ModifiedUTC), 'MMM dd, yyyy, hh:mm aa'), item.VersionNumber, 'VasionEllipsisIcon'],
        ValueType: 'String',
      }
  })
  return { tableHeaders: headers, tableRows: returnArr }
}

export {
  buildHistoryTableData,
  buildLinkedDocumentsTableData,
  buildNotesTableData,
  buildVersionTableData,
}
