<template>
  <div>
    <vue-context
      ref="menu"
      :close-on-click="closeOnClick"
      :close-on-scroll="closeOnScroll"
      :lazy="lazy"
      :role="role"
      :tag="tag"
      :item-selector="itemSelector"
      class="context-menu"
    >
      <li
        v-for="option in options"
        :id="option.name.toLowerCase()"
        :key="option.slug"
        class="item-class"
      >
        <a @click.prevent="onClick($event, option.slug)">
          {{ option.name }}
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
  import VueContext from 'vue-context';
  import 'vue-context/src/sass/vue-context.scss';

  export default {
    name: 'VasionContextMenu',
    components: { VueContext },
    props: {
      // when set to true, the context  menu will close when clicked on
      closeOnClick: {
        type: Boolean,
        default: false,
        required: false,
      },
      // when set to true, the context  menu will close when the window is scrolled
      closeOnScroll: {
        type: Boolean,
        default: false,
        required: false,
      },
      // This is how the component is able to find each menu item. Useful if you use non-recommended markup
      itemSelector: {
        type: Array,
        default: () => ['.item-class'],
        required: false,
      },
      // When false, the context menu is shown via v-show and will always be present in the DOM
      lazy: {
        type: Boolean,
        default: false,
        required: false,
      },
      // Array containing the options to be displayed in the context menu `{ name: 'name', slug: 'option-slug'}`
      options: {
        type: Array,
        default: () => [],
        required: false,
      },
      // The `role` attribute on the menu. Recommended to stay as `menu`
      role: {
        type: String,
        default: 'menu',
        required: false,
      },
      // The root html tag of the menu. Recommended to stay as `ul`
      tag: {
        type: String,
        default: 'ul',
        required: false,
      },
    },
    methods: {
      onClick(event, slug) {
        this.$emit('option-clicked', slug)
      },
    },
  }
</script>
