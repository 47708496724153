<template>
  <div id="profile-menu" v-click-outside="loseFocus" @mouseleave="loseFocus">
    <ul id="profile-ul">
      <!--<li id="profile-user">
        <VasionPersonIcon /><span>Profile</span>
      </li>-->
      <li id="user-settings" @click.prevent="$emit('openUserSettings')">
        <VasionPersonIcon /><span>User Settings</span>
      </li>
      <li id="profile-language">
        <VasionLanguageIcon /><span>Language - English</span>
      </li>
      <li v-if="canPerformDigitalSignature" id="profile-digital-signature" @click.prevent="routeToDigitalSignature">
        <VasionGestureIcon /><span>Digital Signature</span>
      </li>
      <li id="help" @click="openHelpPageInNewTab">
        <VasionHelp /><span>Help</span>
      </li>
      <li id="profile-logout" @click.prevent="signOut">
        <VasionPowerSettingsNewIcon /><span>Logout</span>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'TheProfileMenu',
  components: {
  },
  data: function () {
    return {
      showSaveSignatureDialog: true,
    }
  },
  computed: {
    canPerformDigitalSignature() { return this.$store.getters['common/canPerformDigitalSignature'] },
  },
  methods: {
    loseFocus() {
      this.$emit('loseFocus')
    },
    openHelpPageInNewTab() {
      window.open('https://docs.vasion.com/', '_blank', 'noopener noreferrer')
    },
    routeToDigitalSignature() {
      this.$emit('openSignature')
    },
    routeToUserSettings() {
      this.$store.dispatch('mainViews/changeActiveSubApp', 'profile')
    },
    signOut() {
      this.$store.dispatch('common/signOut').then(() => {
          this.$router.push({ name: 'TheLogin' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #profile-menu {
    @include Text;
    background-color: $white;
    border: thin solid $grey-300;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 $shadow;

    & > ul {
      list-style: none;
      padding: 8px 0;
      margin: 0;

      & > li {
        padding: 8px 8px;
        cursor: pointer;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0;

        & > span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 16px;
        }
      }

      & li:hover {
        background-color: rgba(44, 55, 66, 0.12);
      }
    }
  }

  svg {
    fill: black;
  }
</style>
