/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const deleteDatabaseConnection = async (apiToken, connectionId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Admin/DeleteDBConnection', {
      data: {
        Value: connectionId.toString(),
      },
    })
    if (response && response.data && response.data.Value === 'True') {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteDatabaseLookup = async (apiToken, lookupId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Admin/DeleteDBLookup', {
      data: {
        Value: lookupId.toString(),
      },
    })
    if (response) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDatabaseConnections = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetDBConnectionList')
    if (response && response.data) {
      return response.data.connectionList
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDatabaseLookupDetails = async (apiToken, lookupId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetExternalLookupDetails?Value=${lookupId}`)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDatabaseLookups = async (apiToken, includeSFLookups) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetExternalLookupList?IncludeSFLookups=${includeSFLookups}`)
    if (response && response.data) {
      return response.data.lookupList
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDefaultLookupId = async (apiToken, attributeFormId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetDefaultLookupID?Value=${attributeFormId}`)
    if (response && response.data) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
    return false
  }
}

const saveDatabaseConnection = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveDBConnection', payload)

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveDatabaseLookup = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveExternalLookup', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const testDatabaseConnection = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/TestDBConnection', payload)
    if (response?.data) {
      return response.data.Value === ''
    }
  } catch (error) {
    console.warn(error)
  }
}

export {
  deleteDatabaseConnection,
  deleteDatabaseLookup,
  getDatabaseConnections,
  getDatabaseLookupDetails,
  getDatabaseLookups,
  getDefaultLookupId,
  saveDatabaseConnection,
  saveDatabaseLookup,
  testDatabaseConnection,
}
