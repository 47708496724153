<template>
  <div>
    <div v-if="isLoading" class="relative">
      <div class="container" :style="{ minHeight: '455px', minWidth: parentWidth }" :class="{'temp-drop-parent-down-class': activeSearch.Type === 161 }">
        >
        <Loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="loaderColor"
          loader="dots"
          :background-color="loaderBackgroundColor"
        />
      </div>
    </div>
    <!-- <div class="relative" v-if="searches.Items && !isLoading"> -->
    <div class="relative">
      <!-- <div class="relative" v-if="searches.length"> -->
      <div v-if="!showActiveSearch" class="container" :style="{ minWidth: parentWidth}">
        <h4 class="sub-header">
          SAVED SEARCHES
        </h4>
        <!-- <VasionTable :headerColumns="columns" :tableRows="searches" :hideColumns='["Id"]' @vasion-selection="handleRowSelect" /> -->
        <md-table>
          <md-table-row class="table-row search-header">
            <md-table-head :class="'vasion-table-head-label'" class="type search-header cell-center" md-numeric>
              Type
            </md-table-head>
            <md-table-head :class="'vasion-table-head-label'" class="name search-header cell-center">
              Name
            </md-table-head>
          </md-table-row>
          <div v-if="searches.Items && searches.Items.Values && searches.Items.Values.length >= 1" class="search-form-container">
            <md-table-row
              v-for="(value, index) in searches.Items.Values"
              :key="value.SearchId"
              :class="{'vasion-lightest-gray-background': index % 2 === 0 }"
              class="table-row vasion-cursor"
              @click="setActiveSearch(value)"
            >
              <md-table-cell class="type cell-center" md-numeric>
                {{ value.Type === 162 ? $formsLabel : 'Full Text' }}
              </md-table-cell>
              <md-table-cell class="name cell-center">
                {{ value.Name }}
              </md-table-cell>
            </md-table-row>
          </div>
          <div v-else class="search-form-container">
            <md-table-row
              :key="0"
              :class="{'vasion-lightest-gray-background': true }"
              class="table-row vasion-cursor"
            >
              <md-table-cell class="type cell-center" md-numeric>
                *Empty*
              </md-table-cell>
              <md-table-cell class="name cell-center">
                *There are no saved searches*
              </md-table-cell>
            </md-table-row>
          </div>
        </md-table>
        <div v-if="subfolderSearchEnabled" class="button-container">
          <VasionButton classProp="text" :clickActionCallback="showSubfolderSearch">
            Subfolder Search
          </VasionButton>
        </div>
      </div>
      <div v-else class="container" :style="{ minWidth: parentWidth}">
        <VasionArrowLeftIcon class="left vasion-cursor" @click="toggleActiveSearchDisplay" />
        <div class="header-bar">
          <h4 class="search-header">
            {{ !subfolderSearch ? activeSearch.Name : 'Subfolder Search' }}
          </h4>
          <div />
        </div>
        <form class="search-form-container" :class="{'temp-drop-down-class': activeSearch.Type === 161 }">
          <FullTextSearchOptions
            v-if="activeSearch.Type === 161 && !subfolderSearch"
            ref="fullTextSearch"
            class="temp-drop-list-styles"
          />
          <SubfolderSearch v-else-if="subfolderSearch" ref="subfolderSearchComponent" />
          <IndexSearch
            v-else
            ref="indexSearch"
            :searchID="activeSearch.SearchId"
            :closeSearchDisplay="toggleActiveSearchDisplay"
          />
        </form>
        <div class="button-container">
          <VasionButton classProp="text" :clickActionCallback="closeSearchDropdown">
            CANCEL
          </VasionButton>
          <VasionButton classProp="primary" :clickActionCallback="saveSearchCriteria">
            SEARCH
          </VasionButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import FullTextSearchOptions from '@/components/search/FullTextSearchOptions.vue';
import IndexSearch from '@/components/search/IndexSearch.vue'
import SubfolderSearch from '@/components/search/SubfolderSearch.vue'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'SavedSearchDropDown',
  components: {
    FullTextSearchOptions,
    IndexSearch,
    Loading,
    SubfolderSearch,
  },
  props: {
    width: {
      type: String,
      default: '100%',
      required: false,
    },
  },
  data() {
    return {
      columns: ['Name', 'Type'],
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      showActiveSearch: false,
      subfolderSearch: false,
      subfolderSearchEnabled: false,
      textSearchValue: '',
    };
  },
  computed: {
    activeSearch() { return this.$store.state.search.selectedSearch },
    parentWidth() {
      if (window.innerWidth < 490) {
        return '360px'
      }
      return this.$options.propsData.width ? `${this.width}px` : this.width
    },
    searches() { return this.$store.state.search.searches },
  },
  async created() {
    this.isLoading = true
    this.subfolderSearchEnabled = await this.$store.dispatch('common/getConfig', 'EnableFolderSearch')
    this.subfolderSearchEnabled = this.subfolderSearchEnabled.toLowerCase() === 'true' // Convert to bool to avoid truthy strings
    await this.$store.dispatch('search/loadSearches')
    this.isLoading = false
  },
  methods: {
    closeSearchDropdown() {
      this.$store.dispatch('mainViews/toggleSavedSearchDropDown', false)
    },
    handleRowSelect(value) {
      this.$emit('search-selection', value)
    },
    async saveSearchCriteria() {
      if (this.activeSearch.Type === 161 && !this.subfolderSearch) {
        this.$refs.fullTextSearch.search()
      } else if (this.subfolderSearch) {
        this.$refs.subfolderSearchComponent.search()
      } else {
        this.$refs.indexSearch.saveSearchCriteria()
      }
    },
    async setActiveSearch(search) {
      await this.$store.dispatch('search/setSelectedSearch', search)
      this.showActiveSearch = !this.showActiveSearch
    },
    async showSubfolderSearch() {
      this.isLoading = true

      this.subfolderSearch = true
      this.showActiveSearch = true

      this.isLoading = false
    },
    toggleActiveSearchDisplay() {
      this.showActiveSearch = !this.showActiveSearch
      this.subfolderSearch = false
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .search-form-container {
    max-height: 500px;
    overflow-y: auto;
  }

  .md-table-row td {
      border-top: none;
      height: 40px;
  }
  .md-table-head-container .md-table-head-label {
    color: #373c41;
    font-family: $font-family-medium;
  }

  .header-bar {
    display: flex;
    justify-content: center;
  }

  .left {
    position: absolute;
    top: 24px;
  }

  .cell-center {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $grey-500 !important;
  }

  .table-row {
    display: flex;
    align-items: center;
    height: 40px;
  }
  .search-header {
    background-color: #e9ebed;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .temp-drop-down-class {
    min-height: 566px;
  }
  .temp-drop-parent-down-class {
    width: 1000px;
  }
  .vasion-table-head-label {
    font-family: $font-family-medium;
    color: #373c41 !important;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
  }
  .temp-drop-list-styles {
    margin: 0 auto;
  }
  .type {
    width: 35%;
  }
  .name {
    width: 65%;
    color: $red-200;
  }
  .row {
    display: flex;
    justify-content: space-between
  }

  .container{
    position: absolute;
    top: 20px;
    right: 0px;
    background-color: white;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    width: calc(100vw - 500px);
  }
  .relative{
    position: relative;
    height: 0px;
    width: 0px;
    z-index: 2;
  }
  .sub-header {
    color: $sub-nav-color;
    margin: 5px 0 15px 0;
    font-size: 12px;
  }

  .search-header{
    @include Subtitle;
    color: #373c41;
    margin: 0px;
    font-weight: bold;
    font-family: $font-family-medium;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
  .input-style {
    margin-top: 20px;
  }

  @media (max-width: $tablet) {
    .relative{
      position: absolute;
      left: 47%;
      -webkit-transform: translateX(-51%);
      transform: translateX(-51%);
      z-index: 9;
    }
    .container{
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-51%);
      transform: translateX(-51%)
    }
  }

  @media (max-width: $phone) {
    .relative{
      width: 100px;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-51%);
      transform: translateX(-51%);
      z-index: 9;
    }
    .container{
      position: absolute;
      left: 30%;
      -webkit-transform: translateX(-51%);
    }
  }

</style>
