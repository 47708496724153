const buildTableData = (dataValues) => {
  const data = !dataValues.folderList ? [] : dataValues.folderList.map((folder) => {
      let calculatedIcon = 'VasionFolderIcon'
      switch (folder.storageType) {
        case 'GoogleDrive':
          calculatedIcon = 'VasionGoogleIcon'
          break
        case 'OneDrive':
          calculatedIcon = 'VasionOneDriveIcon'
          break
        case 'Box':
          calculatedIcon = 'VasionBoxIcon'
          break
        case 'WorkDocs':
          calculatedIcon = 'VasionWorkDocsIcon'
          break
        case 'S3':
          calculatedIcon = 'VasionS3Icon'
          break
        case 'Wasabi':
          calculatedIcon = 'VasionWasabiIcon'
          break
        default:
          calculatedIcon = 'VasionFolderSparkIcon'
          break
      }

      return {
        Values: [
          folder.vaultID,
          // eslint-disable-next-line
          folder.hasChildren || folder.hasDocuments,
          calculatedIcon,
          folder.vaultName,
          folder.storageDisplayName,
          folder.piiData ? 'Yes' : 'No',
          folder.readOnly ? 'Yes' : 'No',
          folder.encrypted ? 'Yes' : 'No',
          'VasionEllipsisIcon',
        ],
        ValueType: 'String',
      }
  })
  const tableData = {
    Columns: dataValues.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

export {
    buildTableData,
}
