<template>
  <div
    id="app-picker"
    v-click-outside="loseFocus"
    :class="{
      'apps-per-row-1': appAccessCount <= 1,
      'apps-per-row-2': appAccessCount === 2 || appAccessCount === 4,
      'apps-per-row-3': appAccessCount === 3 || appAccessCount === 5 || appAccessCount === 6,
      'apps-per-row-4': appAccessCount === 7 || appAccessCount === 8,
      'apps-per-row-5': appAccessCount > 8
    }"
    @mouseleave="loseFocus"
  >
    <h2>Apps</h2>
    <div class="group-row">
      <div
        v-if="showWorkspaceApp"
        id="apps-workspace"
        class="app-group"
        @click="changeActiveSubApp('workspace')"
      >
        <VasionAppWorkspace />
        <label class="app-label">Workspace</label>
      </div>
      <div
        v-if="showReportsApp"
        id="apps-reports"
        class="app-group"
        @click="changeActiveSubApp('reports')"
      >
        <VasionAppReports />
        <label class="app-label">Reports</label>
      </div>
      <div
        v-if="showStorageApp"
        id="apps-storage"
        class="app-group"
        @click="changeActiveSubApp('storage')"
      >
        <VasionAppStorage />
        <label class="app-label">Storage</label>
      </div>
      <div
        v-if="showCaptureApp"
        id="apps-capture"
        class="app-group"
        @click="changeActiveSubApp('capture')"
      >
        <VasionAppCapture />
        <label class="app-label">Capture</label>
      </div>
      <div
        v-if="showSignatureApp"
        id="apps-signatures"
        class="app-group"
        @click="changeActiveSubApp('signature')"
      >
        <VasionAppSignature />
        <label class="app-label">Signature</label>
      </div>
      <div
        v-if="showFormsApp"
        id="apps-forms"
        class="app-group"
        @click="changeActiveSubApp('forms')"
      >
        <VasionAppForms />
        <label class="app-label">Forms</label>
      </div>
      <div
        v-if="showTemplatesApp"
        id="apps-templates"
        class="app-group"
        @click="changeActiveSubApp('templates')"
      >
        <VasionAppTemplates />
        <label class="app-label">Templates</label>
      </div>
      <div
        v-if="showAutomationApp"
        id="apps-automation"
        class="app-group"
        @click="changeActiveSubApp('automation')"
      >
        <VasionAppAutomation />
        <label class="app-label">Automation</label>
      </div>
      <div
        v-if="showWorkflowApp"
        id="apps-workflow"
        class="app-group"
        @click="changeActiveSubApp('workflow')"
      >
        <VasionAppWorkflow />
        <label class="app-label">Workflow</label>
      </div>
      <div
        v-if="showAdminApp"
        id="apps-admin"
        class="app-group"
        @click="changeActiveSubApp('admin')"
      >
        <VasionAppAdmin />
        <label class="app-label">Admin</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheAppPicker',
  data: function () {
    return {
      appAccessCount: 0,
    }
  },
  computed: {
    hasAccessToAllForms() { return this.$store.getters['common/canAccessFormsApp_RolesOnly'] },
    hasAccessToMyForms() { return this.$store.getters['common/APP_ACCESS_FORMS'] },
    showAdminApp() { return this.$store.getters['common/showAdminApp'] },
    showAutomationApp() { return this.$store.getters['common/APP_ACCESS_AUTOMATION'] && (this.$store.state.common.userHasSchedulers || this.$store.getters['common/canAccessAutomationApp_RolesOnly']) },
    showCaptureApp() { return this.$store.getters['common/showCaptureApp'] },
    showFormsApp() { return this.$store.getters['common/APP_ACCESS_FORMS'] && (this.userHasForms || this.$store.getters['common/canAccessFormsApp_RolesOnly']) },
    showReportsApp() { return this.$store.getters['common/showReportsApp'] },
    showSignatureApp() { return this.$store.getters['common/showSignatureApp'] },
    showStorageApp() { return this.$store.getters['common/showStorageApp'] },
    showTemplatesApp() { return this.$store.getters['common/showTemplatesApp'] },
    showWorkflowApp() { return this.$store.getters['common/showWorkflowApp'] },
    showWorkspaceApp() { return this.$store.getters['common/showWorkspaceApp'] },
    userHasForms() { return this.$store.state.attributeForm.myForms.length > 0 },
  },
  created() {
    const payload = {
      StartNumber: 1,
      MaxReturnCount: 10000,
      IncludeUserSecurity: false,
      IncludeFields: false,
      PopulateOnlyNameAndID: true,
    }
    this.$store.dispatch('attributeForm/getMyForms', payload)
    this.calculateDivWidth() // Used when refreshing the page
  },
  beforeUpdate() {
    this.calculateDivWidth() // Used when first logging in
  },
  methods: {
    calculateDivWidth() {
      this.appAccessCount = 0
      if (this.showWorkspaceApp) this.appAccessCount += 1
      if (this.showReportsApp) this.appAccessCount += 1
      if (this.showStorageApp) this.appAccessCount += 1
      if (this.showCaptureApp) this.appAccessCount += 1
      if (this.showSignatureApp) this.appAccessCount += 1
      if (this.showFormsApp) this.appAccessCount += 1
      if (this.showTemplatesApp) this.appAccessCount += 1
      if (this.showAutomationApp) this.appAccessCount += 1
      if (this.showWorkflowApp) this.appAccessCount += 1
      if (this.showAdminApp) this.appAccessCount += 1
    },
    changeActiveSubApp(appName) {
      this.toggleAppPicker()

      let formsRouteName = ''
      if (this.hasAccessToAllForms) {
        formsRouteName = 'AllForms'
      } else if (this.hasAccessToMyForms && !this.hasAccessToAllForms) {
        formsRouteName = 'MyForms'
      }

      switch (appName) {
        case 'admin':
          this.$router.push({ name: 'TheEmptyAdmin' })
          break
        case 'capture':
          this.$router.push({ name: 'StartScan' })
          break
        case 'signature':
          this.$router.push({ name: 'TheSignatureInbox' })
          this.$store.dispatch('mainViews/changeMainNav', 'signatureInbox')
          break
        case 'reports':
          this.$router.push({ name: 'TheEmptyReport' })
          break
        case 'storage':
          this.$router.push({ name: 'VaultFolders' })
          break
        case 'workspace':
          this.$router.push({ name: 'TheWorkspaceHome' })
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
          break;
        case 'forms':
          this.$router.push({ name: formsRouteName })
          break;
        case 'templates':
          this.$store.dispatch('common/getEmailTemplates')
          this.$router.push({ name: 'AllEmailTemplates' })
          this.$store.dispatch('mainViews/changeMainNav', 'all-emails')
          break;
        case 'automation':
          this.$router.push({ name: 'TheAutomationHome' })
          break;
        case 'workflow':
          this.$store.dispatch('workflow/selectWorkflow', 0)
          this.$router.push({ name: 'AllWorkflows' })
          this.$store.dispatch('mainViews/changeMainNav', 'workflow-designer')
          break;
        default:
          this.$router.push({ name: 'TheWorkspaceHome' })
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
          break
      }
    },
    loseFocus() { this.$emit('loseFocus') },
    toggleAppPicker(e) { this.$emit('toggleAppPicker', e) },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  h2 {
    font-family: $font-family-black;
    font-size: 20px;
  }

  #app-picker {
    @include Text;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 $shadow;
    padding: 12px 17px;
    z-index: 1000;
  }
  .apps-per-row-1 {
    width: 200px !important;
  }
  .apps-per-row-2 {
    width: 295px;
  }
  .apps-per-row-3 {
    width: 435px;
  }
  .apps-per-row-4 {
    width: 580px;
  }
  .apps-per-row-5 {
    width: 725px;
  }
  .group-row {
    display: flex;
    margin: 10px 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  .app-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    height: 115px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    z-index: 20;
  }
  .icon-box {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: $grey-300;
    z-index: 20;
  }
  .app-label {
    font-size: 14px;
    margin-top: 5px;
  }

  .group-row .app-group:hover {
    background-color: $grey-100;
  }

</style>
