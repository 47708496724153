/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const deleteDocumentTemplate = async (apiToken, documentTemplateId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Admin/DeleteDocumentTemplate', {
      data: {
        Value: documentTemplateId,
      },
    })
    return response
  } catch (error) {
    console.warn(error)
  }
}

const findAndReplaceIndexData = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return axios.post('v1/Admin/FindAndReplaceIndexData', payload)
  } catch (error) {
    console.warn(error)
  }
}

const generateSamlSSOLogin = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Auth/GenerateSamlSSOLogin')
    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const getCaptureProfile = async (apiToken, profileID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetCaptureProfile?Value=${profileID}`)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserSetting = async (apiToken, name) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Auth/GetUserSetting?Value=${name}`)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentTemplateTypeList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetDocumentTemplateTypeList')
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getEmailTemplateDetails = async (apiToken, templateID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetEmailTemplateDetails?Value=${templateID}`)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getLookupBindings = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Values } } = await axios.get('v1/Admin/GetLookupBindings?Value=15')
    return Values
  } catch (error) {
    console.warn(error)
  }
}

const getSamlConfig = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetSamlConfig')
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSamlSSOUrl = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Auth/GetSamlSSOUrl')
    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const getTransactionLogAggregateData = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Admin/GetTransactionLogAggregateData', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const loadDashboardStatistics = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Report/LoadDashboardStatistics')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveBarcodeIndex = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveBarcodeIndex', payload)
      return response
  } catch (error) {
    console.warn(error)
  }
}

const saveCaptureProfile = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveCaptureProfile', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const setUserSetting = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SetUserSetting', payload)
      return response
  } catch (error) {
    console.warn(error)
  }
}

const saveEmailTemplate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Admin/SaveEmailTemplate', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveOcrTemplate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Admin/SaveOCRScheduler', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const savePasswordSettings = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Auth/SavePasswordSettings', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveSamlConfig = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Admin/SaveSamlConfig', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const setThirdPartyCode = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('/v1/Admin/SetThirdPartyCode', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

export {
  deleteDocumentTemplate,
  findAndReplaceIndexData,
  generateSamlSSOLogin,
  getCaptureProfile,
  getUserSetting,
  getDocumentTemplateTypeList,
  getEmailTemplateDetails,
  getLookupBindings,
  getSamlConfig,
  getSamlSSOUrl,
  getTransactionLogAggregateData,
  loadDashboardStatistics,
  saveBarcodeIndex,
  saveCaptureProfile,
  setUserSetting,
  saveEmailTemplate,
  saveOcrTemplate,
  savePasswordSettings,
  saveSamlConfig,
  setThirdPartyCode,
}
