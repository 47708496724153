<template>
  <div class="container">
    <div class="underline">
      <input
        id="typed-signature"
        v-model="signature"
        autocomplete="off"
        placeholder="Type your signature"
        type="text"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Type',
  data: function () {
    return {
      base64DataPrefix: 'data:image/png;base64,',
      signature: '',
    }
  },
  watch: {
    signature() {
      if (this.signature.length > 0) {
        this.$emit('hasSignature')
      } else {
        this.$emit('noSignature')
      }
    },
  },
  methods: {
    async getSignatureImage() {
      const response = await this.$store.dispatch('digitalSignature/convertTextToSignatureImage', { Value: this.signature })
      const responseWithPrefix = `${this.base64DataPrefix}${response}`
      return responseWithPrefix
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import url('https://fonts.googleapis.com/css?family=Parisienne&display=swap');
  .container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    border: 1px solid;
  }

  #typed-signature {
    font-size: 48px;
    font-family: "Segoe Script", cursive;
    font-style: italic;
    text-align: center;
    border: none;
    width: 100%;
  }

  #typed-signature::placeholder,
  #typed-signature::-webkit-input-placeholder,
  #typed-signature::-moz-placeholder,
  #typed-signature::-ms-input-placeholder,
  #typed-signature::-moz-placeholder {
    color: $grey-300;
    font-family: sans-serif;
    font-style: normal;
  }

  #typed-signature:focus {
    outline: none;
  }

  @media(max-width: $phone) {
    #typed-signature {
      font-size: 30px;
    }
  }

  .underline {
    width: 90%;
    border-bottom: 1px solid $grey-300;
    padding: 10px;
  }
</style>
