import axios from 'axios'

export const createAxiosInstance = token => axios.create({
  baseURL: axios.defaults.baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `X-MVApiAuth ${token}`,
    timeout: 1,
  },
})

export default { createAxiosInstance }
