<template>
  <div class="start-workflow-manager-container">
    <VasionSelectWorkflow
      v-show="uiType === 'wfSelection'"
      :workflowList="workflowList"
      @cancelButtonClick="closeStartWorkflow"
      @continueButtonClick="workflowSelected"
    />

    <StartWorkflowForm
      v-if="uiType === 'formLayout'"
      :formLayout="selectedFormLayout"
      :workflowID="selectedWorkflowID"
      @backButtonClick="backToWorkflowSelect"
      @cancelButtonClick="closeStartWorkflow"
      @workflowStarted="showWorkflowStarted"
      @workflowUnsuccessful="showWorkflowNotStarted"
    />

    <StartWorkflowForm
      v-if="uiType === 'eForm' && eForm"
      :formLayout="eForm"
      :workflowID="selectedWorkflowID"
      @backButtonClick="backToWorkflowSelect"
      @cancelButtonClick="closeStartWorkflow"
      @workflowStarted="showWorkflowStarted"
      @workflowUnsuccessful="showWorkflowNotStarted"
    />

    <StartWorkflowFileUpload
      v-if="uiType === 'standardWF'"
      @backButtonClick="backToWorkflowSelect"
      @cancelButtonClick="closeStartWorkflow"
      @continueToAttributeForm="continueToAttributeForm"
      @scanToVasionStarted="scanToVasionStarted"
      @workflowUnsuccessful="showWorkflowNotStarted"
      @kickOffWorkflow="kickOffWorkflow"
    />

    <StartWorkflowAttributeForm
      v-if="uiType === 'attributeForm'"
      :workflowID="selectedWorkflowID"
      @backButtonClick="backToWorkflowFileUpload"
      @cancelButtonClick="closeStartWorkflow"
      @kickOffWorkflow="kickOffWorkflow"
      @workflowUnsuccessful="showWorkflowNotStarted"
    />

    <VasionUserGroupSelect
      v-if="uiType === 'selectUser'"
      modalTitle="Select User"
      :userOnly="true"
      :groupFilterId="assignUserGroupFilterId"
      @noButtonClick="closeStartWorkflow"
      @yesButtonClick="assignWorkflowWithSelectUser"
    />
  </div>
</template>
<script>

import StartWorkflowAttributeForm from '@/components/workflow/StartWorkflowAttributeForm.vue';
import StartWorkflowFileUpload from '@/components/workflow/StartWorkflowFileUpload.vue'
import StartWorkflowForm from '@/components/workflow/StartWorkflowForm.vue'

export default {
  name: 'StartWorkflowManager',
  components: {
    StartWorkflowAttributeForm,
    StartWorkflowFileUpload,
    StartWorkflowForm,
  },
  props: {
    viewMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      assignUserGroupFilterId: 0,
      selectedWorkflowID: 0,
      uiType: this.viewMode ? 'formLayout' : 'wfSelection',
      userAssignPayload: {},
      workflowList: [],
    }
  },
  computed: {
    eForm() { return this.$store.state.forms.eForm },
    selectedDocumentId() { return this.$store.state.workflow.selectedDocumentId },
    selectedFormLayout() { return this.startWorkflowPathData && this.startWorkflowPathData?.maxxForm ? this.startWorkflowPathData.maxxForm : {} },
    startWorkflowPathData() { return this.$store.state.workflow.startWorkflowPathData },
    userId() { return this.$store.state.common.userID },
    workflowDueDate() { return this.$store.state.workflow.workflowDueDate },
  },
  async created() {
    if (this.viewMode && this.$route.params.workflowID) {
      this.workflowSelected(parseInt(this.$route.params.workflowID, 10))
      this.selectedWorkflowID = parseInt(this.$route.params.workflowID, 10)
    } else {
      this.workflowSelected(0)
    }
    this.resetStoreValues()
    this.$store.dispatch('workflow/resetStartWorkflowSuccess')
    this.workflowList = await this.$store.dispatch('workflow/getWorkflowMenuItems')
    this.$store.dispatch('forms/getEForm', this.$route.params.formID)
  },
  methods: {
    async assignWorkflowWithSelectUser(payload) {
      if (!payload || !payload.username || !payload.value) {
        this.closeStartWorkflow()
      }

      this.userAssignPayload.promptData.prmt_ChooseFromGroup.cfg_UserID = payload.value
      const { Result: result } = await this.$store.dispatch('workflow/assignToWorkflowWithData', this.userAssignPayload)
      if (!result) {
        this.showWorkflowNotStarted()
      } else {
        this.setDueDate()
        this.showWorkflowStarted()
      }
    },
    backToWorkflowFileUpload() { this.uiType = 'standardWF' },
    backToWorkflowSelect() { this.workflowSelected(0) },
    closeStartWorkflow() {
      this.resetStoreValues()
      this.$emit('closeStartWorkflow')
    },
    continueToAttributeForm() { this.uiType = 'attributeForm' },
    async kickOffWorkflow() {
      const payload = {
        documentId: this.selectedDocumentId,
        workflowId: this.selectedWorkflowID,
        userId: parseInt(this.userId, 10),
      }

      const responsePayload = await this.$store.dispatch('workflow/attemptAssignToWorkflowWithData', payload)

      if (!responsePayload) {
        this.setDueDate()
        this.showWorkflowStarted()
      } else {
        this.assignUserGroupFilterId = responsePayload.assignUserGroupFilterId
        this.userAssignPayload = responsePayload.userAssignPayload
        this.uiType = 'selectUser'
      }
    },
    resetStoreValues() {
      this.$store.dispatch('workflow/setWorkflowDueDate', '')
      this.$store.dispatch('workflow/setSelectedDocumentId', null)
    },
    async scanToVasionStarted() {
      const payload = {
        folderId: 0,
        workflowId: this.selectedWorkflowID,
      }
      await this.$store.dispatch('common/openScanToVasion', payload)
      this.closeStartWorkflow()
    },
    setDueDate() {
      if (!this.workflowDueDate) {
        return
      }

      const payload = {
          documentID: this.selectedDocumentId,
          workflowID: this.selectedWorkflowID,
          dueDate: this.workflowDueDate,
      }
      this.$store.dispatch('workflow/saveWorkflowDueDate', payload)
    },
    showWorkflowNotStarted() {
      this.$emit('workflowFailed')
      this.$store.dispatch('workflow/setStartWorkflowSuccess', 0)
      this.closeStartWorkflow()
    },
    showWorkflowStarted() {
      this.$emit('workflowStarted')
      this.$store.dispatch('workflow/setStartWorkflowSuccess', 1)
      if (this.$route.fullPath === '/') {
        this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
        this.$store.dispatch('workflow/getWorkflowPendingTasks', 0)
      }
      this.closeStartWorkflow()
    },
    async workflowSelected(workflowID) {
      this.selectedWorkflowID = workflowID
      const payload = {
        Value: workflowID,
      }
      await this.$store.dispatch('workflow/loadStartWorkflowPathData', payload)
      if (this.viewMode) {
        this.uiType = 'eForm'
      } else if (workflowID <= 0) {
        this.uiType = 'wfSelection'
      } else if (!this.startWorkflowPathData?.maxxForm && !this.viewMode) {
        this.uiType = 'standardWF'
      } else {
        this.uiType = 'formLayout'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .start-workflow-manager-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    z-index: 1;
  }

  @media (max-width: $phone){
    .start-workflow-manager-container{
      max-height: 100%;
      width: 100%;
    }
  }
</style>
