/* eslint-disable */

const enrichCloudData = function enrichCloudData(data) {
  if(data){
    let folders
    if(data.folders){
      folders = data.folders.map((vault) => {
        // eslint-disable-next-line
        vault['piiData'] = false
        vault['encrypted'] = false
        vault['errorCode'] = false
        vault['userAccess'] = []
        vault['groupAccess'] = []
        vault['allAccess'] = false
        vault['vaultName'] = vault.name
        vault['Name'] = vault.name
        vault['storageid'] = vault.storageid
        vault['FolderId'] = vault.id
        vault['vaultID'] = vault.id
        vault['HasChildren'] = vault.hasChildren
        vault['CanModify'] = true
        vault['Type'] = 0
        return vault
      })
    }
    let files
    if(data.files){
      files = data.files.map((vault) => {
        // eslint-disable-next-line
        vault['piiData'] = false
        vault['encrypted'] = false
        vault['errorCode'] = false
        vault['userAccess'] = []
        vault['groupAccess'] = []
        vault['allAccess'] = false
        vault['vaultName'] = vault.name
        vault['Name'] = vault.name
        vault['storageid'] = vault.storageid
        vault['FolderId'] = vault.id
        vault['vaultID'] = vault.id
        vault['HasChildren'] = true
        vault['CanModify'] = true
        vault['Type'] = 1
        return vault
      })
    }
    if(folders){
      folders.sort( function( a, b ) {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();

        return a < b ? -1 : a > b ? 1 : 0;
      });
    }
    if(files){
      files.sort( function( a, b ) {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();

        return a < b ? -1 : a > b ? 1 : 0;
      });
    }
    // const foldersAndFiles = [...folders, ...files]
    return {folders: folders, files: files}

  }else {return {folders: [], files: []}}
}

const enrichCloudDataFoldersOnly = function enrichCloudData(data) {
  if(data && data.folders){
    const folders = data.folders.map((vault) => {
      // eslint-disable-next-line
      vault['piiData'] = false
      vault['encrypted'] = false
      vault['errorCode'] = false
      vault['userAccess'] = []
      vault['groupAccess'] = []
      vault['allAccess'] = false
      vault['vaultName'] = vault.name
      vault['Name'] = vault.name
      vault['storageid'] = vault.storageid
      vault['FolderId'] = vault.id
      vault['vaultID'] = vault.id
      vault['HasChildren'] = true
      vault['CanModify'] = true
      vault['Type'] = 0
      return vault
    })

    return folders.sort( function( a, b ) {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();

      return a < b ? -1 : a > b ? 1 : 0;
  });
  }
}

const saveFileToVasionDrive = async function (uploadedFile) {
  let fileDataString = await toBase64(uploadedFile.fileData)
  const indexOf = fileDataString.indexOf(';base64,')
  if (indexOf >= 0) {
    fileDataString = fileDataString.substring(indexOf + 8)
  }

  const payload = {
    folderId: uploadedFile.folderId,
    name: uploadedFile.name,
    lastModified: uploadedFile.fileData.lastModified,
    lastModifiedData: uploadedFile.fileData.lastModifiedDate,
    size: uploadedFile.fileData.size,
    type: uploadedFile.fileData.type,
    fileData: fileDataString,
  }

  return payload
}

const toBase64 = function (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  })
}

export {
  enrichCloudData,
  enrichCloudDataFoldersOnly,
  saveFileToVasionDrive,
  toBase64,
}
