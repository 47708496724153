<template>
  <div class="vasion-white-background vasion-calc-height vld-parent">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="inner-div">
      <div class="md-layout">
        <div id="vasion-schedulers-page-title" class="md-layout-item vasion-page-title">
          {{ title }}
        </div>
        <div class="md-layout-item vasion-flex-end">
          <div v-for="(button, index) in buttons" :key="index">
            <VasionButton v-if="jobsAvailable && canConfigureScheduler" :classProp="'primary'" @vasionButtonClicked="handleNewClick(button.destination)">
              New {{ button.name }}
            </VasionButton>
          </div>
        </div>
      </div>
      <div v-if="jobs === true">
        <!-- Show white space while waiting for data to load -->
      </div>
      <div v-else-if="jobsAvailable === true && tableData.Columns" class="grid-div vasion-html-table-default">
        <VasionTable
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          :supportSorting="true"
          @vasion-column-sort="sortColumns"
          @vasion-selection="rowClick"
          @ellipsis-button-clicked="ellipsisButtonClicked"
        />
      </div>
      <div v-else class="no-items-div">
        <div class="vasion-page-subheader instructional-message-div">
          No data to show.
        </div>
        <div v-for="(button, index) in buttons" :key="index" class="no-data-buttons">
          <VasionButton v-if="canConfigureScheduler" :classProp="'primary'" @vasionButtonClicked="handleNewClick(button.destination)">
            <span class="vasion-button-plus"> + </span> New {{ button.name }}
          </VasionButton>
        </div>
      </div>
    </div>

    <md-dialog id="vasion-confirmation-div" :md-active.sync="showDeleteDialog">
      <VasionConfirmationDialog :message="deleteMessage" @noButtonClick="toggleDeleteDialog()" @yesButtonClick="deleteJob()" />
    </md-dialog>

    <md-dialog id="vasion-confirmation-div" :md-active.sync="showRunDialog">
      <VasionConfirmationDialog :message="runJobConfirmMessage" @noButtonClick="toggleRunDialog()" @yesButtonClick="runJob()" />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'VasionSchedulersPage',
  components: {
    Loading,
  },
  props: {
    title: {
      type: String,
      default: 'Landing Page',
      required: false,
    },
  },
  data: function () {
    return {
      ascendingOrder: true,
      buttons: [],
      columns: {
        Values: [
          '_WorkflowID_',
          'Name',
          'Import / Export',
          'Perform',
          'Created Date',
          'Start Time',
          '_VasionEllipsisButton_',
        ],
        ValueType: 'String',
      },
      deleteJobId: 0,
      deleteJobName: '',
      deleteMessage: '',
      headerCheckboxToggled: false,
      hiddenColumns: ['_WorkflowID_'],
      isLoading: false,
      jobs: true,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      runJobConfirmMessage: '',
      scheduledType: '',
      schedulerSubtypes: '',
      showDeleteDialog: false,
      showRunDialog: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      sortTableBy: 0,
      tableData: {},
    }
  },
  computed: {
    canConfigureScheduler() { return this.$store.getters['common/canConfigureScheduler'] },
    dataList() { return this.$store.state.automationEngine.schedulerProps },
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    jobList() { return this.$store.state.automationEngine.schedulerList },
    jobsAvailable() { return this.jobList && this.jobList.length > 0 },
    tableDataFromStore() { return this.$store.state.automationEngine.tableData },
  },
  async created() {
    this.isLoading = true
    if (this.title === 'Scheduled Reports') {
      this.columns.Values[1] = 'Scheduled Report Name'
    }

    if (this.title === 'Barcode Recognition') {
      this.schedulerSubtypes = 'Barcode'
    } else if (this.title === 'Scheduled OCR') {
      this.schedulerSubtypes = 'OCR'
    }
    const payload = {
      schedulerTypes: this.dataList.scheduledType,
      orderBy: 0,
      ascendingOrder: true,
      schedulerSubtypes: this.schedulerSubtypes,
    }
    if (this.canConfigureScheduler) {
      await this.$store.dispatch('automationEngine/getSchedulerList', payload)
    } else {
      await this.$store.dispatch('automationEngine/getSchedulerListForUser', payload)
    }
    await this.$store.dispatch('security/setEllipsisButtonConfig', 'automation-engine')
    this.hiddenColumns = this.dataList.hiddenColumns
    this.buttons = this.dataList.buttons
    this.jobs = false
    const data = {
      jobList: this.jobList,
      columns: this.columns,
    }
    this.tableData = await this.$store.dispatch('automationEngine/setTableData', data)
    this.isLoading = false
  },
  methods: {
    async deleteJob() {
      const response = await this.$store.dispatch('automationEngine/deleteScheduler', this.deleteJobId)
      this.showDeleteDialog = false

      this.snackbarTitle = response ? 'Error Deleting Scheduled Job' : `Scheduled Job: "${this.deleteJobName}" Deleted Successfully`
      this.snackbarSubTitle = response ? `Error: ${response}` : 'The Scheduled Job has been removed'
      this.snackbarImage = !response
      this.showSnackbarBool = true;
      this.refreshTable()
    },
    editJob(type, id) {
      this.buttons.forEach(button => {
        if (button.name && button.name === 'Export' && type === 'Import') {
          button = {
            name: 'Import',
            destination: 'EditImport',
          }
        }
        this.$router.push({ name: button.destination, params: { schedulerId: id } })
      });
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id, name, type] = payload.item.Values
        switch (payload.buttonId) {
          case 'run-automation-engine-button':
            this.runJobConfirm(name, id)
            break;
          case 'edit-automation-engine-button':
            this.editJob(type, id)
            break;
          case 'delete-automation-engine-button':
            this.deleteMessage = `Are you sure you want to delete "${name}"?`
            this.deleteJobId = id
            this.deleteJobName = name
            this.showDeleteDialog = true
            break;
          default:
            break;
        }
      }
    },
    handleNewClick(destination) {
      this.$router.push({ name: destination, params: { schedulerId: 0 } })
    },
    async refreshTable() {
      this.isLoading = true

      if (this.title === 'Barcode Recognition') {
        this.schedulerSubtypes = 'Barcode'
      } else if (this.title === 'Scheduled OCR') {
        this.schedulerSubtypes = 'OCR'
      }
      const payload = {
        schedulerTypes: this.dataList.scheduledType,
        orderBy: this.sortTableBy,
        ascendingOrder: this.ascendingOrder,
        schedulerSubtypes: this.schedulerSubtypes,
      }
      if (this.canConfigureScheduler) {
        await this.$store.dispatch('automationEngine/getSchedulerList', payload)
      } else {
        await this.$store.dispatch('automationEngine/getSchedulerListForUser', payload)
      }

      const data = {
        jobList: this.jobList,
        columns: this.columns,
      }
      this.tableData = await this.$store.dispatch('automationEngine/setTableData', data)

      this.isLoading = false
    },
    rowClick(selection) {
      if (this.canConfigureScheduler) {
        // eslint-disable-next-line
        const [id, name, type] = selection.Values
        this.editJob(type, id)
      }
    },
    async runJob() {
      const response = await this.$store.dispatch('automationEngine/runScheduler', this.runJobID)
      this.toggleRunDialog()

      this.snackbarTitle = response !== '' ? 'Error Starting Scheduled Job' : `Scheduled Job: "${this.runJobName}" Started Successfully`
      this.snackbarSubTitle = response !== '' ? `Error: ${response}` : 'The Scheduled Job has been started'
      this.snackbarImage = response === ''
      this.showSnackbarBool = true;
    },
    async runJobConfirm(name, id) {
      this.runJobConfirmMessage = `Are you sure you want to run "${name}"?`
      this.runJobName = name
      this.runJobID = id
      this.toggleRunDialog()
    },
    async sortColumns(options) {
      this.isLoading = true
      this.ascendingOrder = options.ascending
      switch (options.columnName) {
        case 'Name':
          this.sortTableBy = 0
          break
        case 'Import / Export':
          this.sortTableBy = 1
          break
        case 'Perform':
          this.sortTableBy = 2
          break
        case 'Created Date':
          this.sortTableBy = 3
          break
        case 'Start Time':
          this.sortTableBy = 4
          break
        default:
          break
        }
      this.refreshTable()
    },
    toggleDeleteDialog() {
        this.showDeleteDialog = !this.showDeleteDialog
    },
    toggleRunDialog() {
        this.showRunDialog = !this.showRunDialog
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .vasion-page-title {
    height: 35px;
  }

  .button-div {
    margin: auto;
    margin-top: 30px;
    width: 288px;
  }

  .inner-div {
    padding-top: 8px;
    margin-right: 8px;
    margin-left: 16px;
    text-align: center;
  }

  .instructional-message-div {
    margin-top: 24px;
  }

  .main-div {
    background-color: white;
    width: 100%;
  }

  .no-items-div {
    margin-top: 60px;
  }

  .grid-div {
    height: calc(100vh - 125px);
    overflow: auto;
    text-align: left;
  }

  .image-column {
    max-width: 40px;
    width: 40px;
    fill: $grey-400;
    padding-left: 15px;
  }

  .image-column-end {
    max-width: 100px;
    width: 100px;
    fill: $grey-400;
    padding-right: 15px;
  }

  .name-column {
    width: 30%;
  }

</style>
