<template>
  <div class="vasion-white-background">
    <Loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <MaxxFormFieldsDisplay ref="fieldComponent" :propMaxxFormFields="maxxFormFields" />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import MaxxFormFieldsDisplay from '@/components/forms/MaxxFormFieldsDisplay.vue';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'MaxxFormDisplay',
  components: {
    Loading,
    MaxxFormFieldsDisplay,
  },
  props: {
    maxxFormID: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data: function () {
    return {
      fullPage: true,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      maxxFormFields: [],
    }
  },
  created: function () {
    this.resetFormFields()
  },
  methods: {
    async resetFormFields() {
      const formDetails = await this.$store.dispatch('maxxForms/getMaxxFormDetails', this.maxxFormID)
      this.maxxFormFields = formDetails && formDetails.FormFields ? formDetails.FormFields.slice(0) : []
      if (!this.maxxFormFields || this.maxxFormFields.length === 0) {
        this.$emit('invalidForm')
      }
    },
    async submitForm(uploadedFileStringList, uploadedFileNameList, uploadedFieldValue) {
      const fieldData = this.$refs.fieldComponent.validateAndGetFieldData()
      if (!fieldData) {
        return
      }
      const submitFormPayload = {
        EFormID: this.maxxFormID,
        UploadFileList: uploadedFileStringList === undefined ? '' : uploadedFileStringList,
        UploadFileNameList: uploadedFileNameList === undefined ? '' : uploadedFileNameList,
        UploadFieldValue: uploadedFieldValue === undefined ? '' : uploadedFieldValue,
        SubmittedFieldValues: fieldData,
        ReturnDocumentID: true,
        workflowDueDate: this.dueDate,
      }
      this.isLoading = true
      const success = await this.$store.dispatch('maxxForms/submitMaxxForm', submitFormPayload)
      if (success) {
        this.resetFormFields()
      }
      this.isLoading = false
      // eslint-disable-next-line
      return success
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
