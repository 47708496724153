const buildTableDataForAIP = (aipData) => {
  const data = !aipData.aipList ? [] : aipData.aipList.map((aip) => {
      return {
        Values: [
          aip.aiAIPID,
          aip.procName,
          aip.ObjectName,
          'VasionEllipsisIcon',
        ],
        ValueType: 'String',
      }
  })
  const tableData = {
    Columns: aipData.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

const buildTableDataForBarcodeConfig = (bcData) => {
  const data = !bcData.list ? [] : bcData.list.map((b) => {
      return {
        Values: [
          b.ID,
          b.ConfigName,
          b.IndexFormName,
          'VasionEllipsisIcon',
        ],
        ValueType: 'String',
      }
  })
  const tableData = {
    Columns: bcData.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

const buildTableDataForProfiles = (cpData) => {
  const data = !cpData.list ? [] : cpData.list.map((c) => {
      return {
        Values: [
          c.iID,
          c.sName,
          'VasionEllipsisIcon',
        ],
        ValueType: 'String',
      }
  })
  const tableData = {
    Columns: cpData.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

export {
  buildTableDataForAIP,
  buildTableDataForBarcodeConfig,
  buildTableDataForProfiles,
}
