<template>
  <div class="vasion-white-background vld-parent">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="main-div" :style="{width: isInMyForms ? 'inherit': ''}">
      <div v-show="!showCompleteSignatureModal">
        <div class="vasion-page-title top-div">
          Add Your Signature
        </div>
        <div class="md-layout w100 tabs">
          <VasionButton id="button-draw" :classProp="drawTabClass" @vasionButtonClicked="updateSelectedTab('draw')">
            Draw
          </VasionButton>
          <VasionButton id="button-type" :classProp="typeTabClass" @vasionButtonClicked="updateSelectedTab('type')">
            Type
          </VasionButton>
          <VasionButton
            v-if="hasSavedSignature"
            id="button-add-saved"
            :classProp="addSavedTabClass"
            @vasionButtonClicked="updateSelectedTab('addSaved')"
          >
            Add Saved
          </VasionButton>
        </div>
        <div v-if="selectedTabName === 'draw'" id="tab-draw">
          <Draw ref="draw" @hasSignature="enableSignButton" />
        </div>
        <div v-if="selectedTabName === 'type'" id="tab-draw">
          <Type ref="typeSignature" @hasSignature="enableSignButton" @noSignature="disableSignButton" />
        </div>
        <div v-if="selectedTabName === 'addSaved'" id="tab-draw">
          <AddSaved />
        </div>
        <div class="bottom-div">
          {{ errorText }}
          <div>
            <VasionCheckbox
              id="apply-timestamp-to-signatures"
              name="apply-timestamp-to-signatures"
              :checked="shouldApplyTimestampForUser"
              @change="handleApplyTimestampChange()"
            >
              Apply Timestamp to Your Signatures
            </VasionCheckbox>
          </div>
          <div class="auto-left">
            <VasionButton id="button-cancel" :classProp="'secondary'" @vasionButtonClicked="cancelClick()">
              CANCEL
            </VasionButton>
            <VasionButton
              id="button-sign"
              :classProp="'primary'"
              :isDisabled="signButtonDisabled"
              @vasionButtonClicked="toggleShowCompleteSignatureModal"
            >
              SIGN
            </VasionButton>
          </div>
        </div>
      </div>
    </div>

    <VasionGeneralModal
      id="consent-modal"
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'I Agree'"
      modalTitle="Almost Done"
      :modalType="'slot'"
      :sync="showCompleteSignatureModal"
      @confirmButtonClick="agreeClick()"
      @noButtonClick="toggleShowCompleteSignatureModal"
    >
      <div id="confirmCompleteSignature">
        {{ confirmCompleteSignatureMessage }}
      </div>
    </VasionGeneralModal>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

import AddSaved from '@/components/digitalSignature/AddSaved.vue'
import Draw from '@/components/digitalSignature/Draw.vue'
import Type from '@/components/digitalSignature/Type.vue'

export default {
  name: 'VasionApplySignature',
  components: {
    AddSaved,
    Draw,
    Loading,
    Type,
  },
  props: {
    hasSavedSignature: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      applyTimestamp: false,
      base64DataPrefix: 'data:image/png;base64,',
      confirmCompleteSignatureMessage: 'I agree to be legally bound by this document and the Vasion Terms of Service. Click on "I Agree" to finish signing this document.',
      errorText: '',
      isInMyForms: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      signButtonDisabled: true,
      selectedTabName: 'draw',
      showCompleteSignatureModal: false,
    }
  },
  computed: {
    addSavedTabClass() { return this.selectedTabName === 'addSaved' ? 'tab-selected' : 'tab' },
    apiToken() { return this.$store.state.common.apiToken },
    drawTabClass() { return this.selectedTabName === 'draw' ? 'tab-selected' : 'tab' },
    shouldApplyTimestampForUser() { return this.$store.state.digitalSignature.shouldApplySignatureTimestampForUser || !this.apiToken },
    typeTabClass() { return this.selectedTabName === 'type' ? 'tab-selected' : 'tab' },
    userSignature() { return `data:image/png;base64,${this.$store.state.digitalSignature.userSignature}` },
  },
  async created() {
    this.isInMyForms = this.$route.name === 'MyForm' || this.$route.name === 'plainForm'
    await this.$store.dispatch('digitalSignature/shouldApplySignatureTimestamp')
    this.applyTimestamp = this.shouldApplyTimestampForUser
  },
  methods: {
    async agreeClick() {
      let signature;
      switch (this.selectedTabName) {
        case 'draw':
          signature = this.$refs.draw.saveSignature()
          break;
        case 'type':
          signature = await this.$refs.typeSignature.getSignatureImage()
          break;
        case 'addSaved':
          signature = this.userSignature
          break;
        default:
          signature = ''
          break;
      }

      if (this.applyTimestamp) {
        this.isLoading = true
        signature = await this.$store.dispatch('digitalSignature/burnTimestampToImage', { Value: signature.substr(22) })
        signature = `${this.base64DataPrefix}${signature}`
        this.isLoading = false
      }

      this.toggleShowCompleteSignatureModal()
      this.$emit('ok-click', signature)
    },
    cancelClick() { this.$emit('cancel-click') },
    disableSignButton() { this.signButtonDisabled = true },
    enableSignButton() { this.signButtonDisabled = false },
    async handleApplyTimestampChange() {
      this.applyTimestamp = !this.applyTimestamp
      await this.$store.dispatch('digitalSignature/saveShouldApplySignatureTimestamp', { Value: this.applyTimestamp ? 'True' : 'False' })
    },
    toggleShowCompleteSignatureModal() { this.showCompleteSignatureModal = !this.showCompleteSignatureModal },
    updateSelectedTab(tabName) {
      this.disableSignButton()
      this.selectedTabName = tabName
      if (tabName === 'addSaved') {
        this.enableSignButton()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

 .main-div {
    height: 463px;
    margin: 32px 24px 32px 24px;
    width: 65vw;
    overflow: hidden;
  }

  .vasion-loading-indicator {
    height: 113%;
    margin-top: -30px;
  }

  @media(max-width: 600px){
    .main-div{
      width: 90vw;
    }
  }

  @media(max-width: $phone){
    .main-div{
      width: auto;
    }
  }

  .top-div {
    height: 50px;
  }

  .bottom-div {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
    display: flex
  }

  @media(max-width: $tablet){
    .bottom-div{
      flex-wrap: wrap;
    }
  }

  .auto-left {
    margin-left: auto;
  }

  .tabs {
    margin-bottom: 20px;
  }

  #consent-modal {
    padding: 5px;
  }

</style>
