<template>
  <div v-if="apiToken" id="rotate-menu" @mouseleave="loseFocus">
    <ul id="first-list">
      <li @click="makeAction('rotateLeft')">
        <VasionRotateLeftIcon class="icon" />Rotate Left
      </li>
      <li @click="makeAction('rotateRight')">
        <VasionRotateRightIcon class="icon right" />Rotate Right
      </li>
    </ul>
    <ul v-if="isReadOnly === false">
      <li @click="makeAction('permanentlyRotateLeft')">
        <VasionRotateLeftIcon class="icon" />Permanently Rotate Left
      </li>
      <li @click="makeAction('permanentlyRotateRight')">
        <VasionRotateRightIcon class="icon right" />Permanently Rotate Right
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'VasionRotateDropDown',
  props: {
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
    }
  },
  computed: {
    apiToken() { return this.$store.state.common.apiToken },
  },
  methods: {
    loseFocus() {
      this.$emit('loseFocus')
    },
    makeAction(action) {
      // eslint-disable-next-line
      switch (action) {
        case 'rotateLeft':
          this.$emit('rotateLeft')
          break;
        case 'rotateRight':
          this.$emit('rotateRight')
          break;
        case 'permanentlyRotateLeft':
          this.$emit('permanentlyRotateLeft')
          break;
        case 'permanentlyRotateRight':
          this.$emit('permanentlyRotateRight')
          break;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #rotate-menu {
    @include Text;
    background-color: $white;
    border: thin solid $grey-100;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 $shadow;
    z-index: 1000;
    position: absolute;
    top: 105px;
    left: 690px;
    width: 343px;

    #first-list {
      border-bottom: 1px solid $grey-100;
    }

    .icon {
      fill: $grey-400;
      margin: -5px 15px 0 5px;
    }

    & > ul {
      list-style: none;
      padding: 8px 0;
      margin: 0;

      & > li {
        padding: 8px 8px;
        cursor: pointer;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0;

        & > span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 16px;
        }
      }

      & li:hover {
        background-color: rgba(44, 55, 66, 0.12);
      }
    }
  }
</style>
