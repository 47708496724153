/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable arrow-parens */

import {
  canAccessFolder,
  decryptVault,
  deleteSubFolder,
  deleteVault,
  getFolderName,
  getFolderContents,
  getParentFolderID,
  getReadOnlyFolderEntities,
  getReadOnlyFolderInfo,
  getSingleVault,
  getVaultList,
  saveSubFolder,
  saveVault,
  setClientDefaultEncryptionEnabled,
  vasionFolderSearch,
} from '@/store/apiModules/vault.api'

import {
  buildFolderGridData,
  buildFolderGridDataForRoot,
 } from '@/store/helperModules/vault.module'

function initialState() {
  return {
    activeFolderId: '',
    activeStorageId: '',
    cloudHold: false,
    cloudSelected: false,
    fileGridData: {},
    folderAncestorsList: [],
    folderContentsCount: 0,
    folderCurrentPage: 1,
    folderDisplayName: '',
    folderGridData: {},
    folderTotalPages: 1,
    isLoading: false,
    refreshGrid: false,
    searchingSubfolders: false,
    showSnackbar: false,
    syncParentFolder: -1,
    vaultFoldersPayload: {},
    vaultList: [],
    vaultSideNav: 'vaults',
  }
}

const vault = {
  namespaced: true,
  state: initialState,
  mutations: {
    activateCloudHold(state) { state.cloudHold = true },
    clearCloudHold(state) { state.cloudHold = false },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setActiveFolderId(state, value) { state.activeFolderId = value },
    setActiveFolderName(state, value) { state.folderDisplayName = value },
    setActiveStorageId(state, value) { state.activeStorageId = value },
    setAncestorsList(state, value) { state.folderAncestorsList = value },
    setFolderGridData(state, value) {
      if (value) {
        if (state.activeFolderId && value.FolderId !== state.activeFolderId) {
          return
        }

        state.folderSelected = false
        state.cloudSelected = false
        state.folderGridData = buildFolderGridData(value)
        state.folderContentsCount = value.Count
        state.folderTotalPages = value.totalPages
        state.folderCurrentPage = value.currentPage
      } else {
        state.folderGridData = {}
        state.folderContentsCount = 0
        state.folderDisplayName = ''
        state.folderTotalPages = 1
        state.folderCurrentPage = 1
      }
    },
    setRootFolderGridData(state, value) {
      if (value && value.data?.FolderList?.Values) {
        state.folderSelected = false
        state.cloudSelected = false
        state.folderGridData = buildFolderGridDataForRoot(value.data.FolderList.Values)
        state.folderContentsCount = value.data.FolderList.Values.length
        state.folderTotalPages = 1
        state.folderCurrentPage = 1
      } else {
        state.folderGridData = {}
        state.folderContentsCount = 0
        state.folderDisplayName = ''
        state.folderTotalPages = 1
        state.folderCurrentPage = 1
      }
    },
    setIsLoading(state, value) { state.isLoading = value },
    setRefreshGrid(state, value) { state.refreshGrid = value },
    setSyncParentFolder(state, value) { state.syncParentFolder = value },
    setVaultFoldersPayload(state, value) { state.vaultFoldersPayload = value },
    setVaultGridData(state, value) {
      if (!value || !value.vaults) {
        return
      }

      state.vaultList = value.vaults

      state.vaultList.forEach((element) => {
        element.allAccess = (!element.userAccess || element.userAccess.length === 0)
          && (!element.groupAccess || element.groupAccess.length === 0)
      })
    },
    setVaultSideNav(state, value) { state.vaultSideNav = value },
    showHideSnackbar(state, value) { state.showSnackbar = value },
  },
  actions: {
    activateCloudHold({ commit }) { commit('activateCloudHold') },
    async canAccessFolder({ rootState }, folderId) {
      const result = await canAccessFolder(rootState.common.apiToken, folderId)
      return result
    },
    changeVaultSideNav({ commit }, value) { commit('setVaultSideNav', value) },
    clearAncestorsList({ commit }) { commit('setAncestorsList', []) },
    clearCloudHold({ commit }) { commit('clearCloudHold') },
    async decryptVault({ rootState }, payload) {
      const result = await decryptVault(rootState.common.apiToken, payload)
      return result
    },
    async deleteSubFolder({ commit, rootState }, vaultID) {
      const result = await deleteSubFolder(rootState.common.apiToken, vaultID)
      if (result?.Values?.length >= 1) {
        commit('setAncestorsList', result?.Values)
      }
      return result
    },
    async deleteVault({ rootState }, vaultID) {
      const result = await deleteVault(rootState.common.apiToken, vaultID)
      return result
    },
    async getFolderContents({ commit, rootState }, payload) {
      commit('setVaultFoldersPayload', payload)
      const data = await getFolderContents(rootState.common.apiToken, payload)
      commit('setFolderGridData', data)
    },
    async getFolderName({ rootState }, payload) {
      const folderName = await getFolderName(rootState.common.apiToken, payload)
      return folderName
    },
    async getParentFolderID({ rootState }, folderID) {
      const parentFolderID = await getParentFolderID(rootState.common.apiToken, folderID)
      return parentFolderID
    },
    async getReadOnlyFolderEntities({ rootState }, folderID) {
      const result = await getReadOnlyFolderEntities(rootState.common.apiToken, folderID)
      return result
    },
    async getReadOnlyFolderInfo({ rootState }, folderID) {
      const result = await getReadOnlyFolderInfo(rootState.common.apiToken, folderID)
      return result
    },
    async getRootFolderContents({ commit, dispatch }, storageConfigId) {
      commit('setVaultFoldersPayload', {FolderId: 0})
      const rootFoldersPayload = {
        parentFolderId: 0,
        enforceUserSecurity: true,
        storageConfigId: storageConfigId,
      }
      const rootFoldersResponse = await dispatch('common/getChildFolders', rootFoldersPayload, { root: true })
      commit('setRootFolderGridData', rootFoldersResponse)
    },
    async getSingleVault({ rootState }, folderID) {
      const result = await getSingleVault(rootState.common.apiToken, folderID)
      return result
    },
    async getVaultList({ commit, rootState }) {
      const response = await getVaultList(rootState.common.apiToken)
      commit('setVaultGridData', response)
    },
    async saveCloudVault({ dispatch, rootState }, payload) {
      if (payload.allAccess) {
        payload.userAccess = []
        payload.groupAccess = []
      }

      await saveVault(rootState.common.apiToken, payload)
      dispatch('showSnackbar', true)
      return dispatch('getVaultList')
    },
    async saveSubFolder({ dispatch, rootState }, payload) {
      const newFolderID = await saveSubFolder(rootState.common.apiToken, payload)
      dispatch('showSnackbar', true)
      return newFolderID
    },
    async saveVault({ rootState }, payload) {
      if (payload.allAccess) {
        payload.userAccess = []
        payload.groupAccess = []
      }

      return saveVault(rootState.common.apiToken, payload)
    },
    setActiveFolderId({ commit }, value) { commit('setActiveFolderId', value) },
    setActiveFolderName({ commit }, value) { commit('setActiveFolderName', value) },
    setActiveStorageId({ commit }, value) { commit('setActiveStorageId', value) },
    async setClientDefaultEncryptionEnabled({ rootState }, payload) {
      const result = await setClientDefaultEncryptionEnabled(rootState.common.apiToken, payload)
      return result
    },
    setIsLoading({ commit }, value) { commit('setIsLoading', value) },
    setRefreshGrid({ commit }, value) { commit('setRefreshGrid', value) },
    setVaultFoldersPayload({ commit }, payload) { commit('setVaultFoldersPayload', payload) },
    showSnackbar({ commit }, value) { commit('showHideSnackbar', value) },
    syncParentFolder({ commit }, value) { commit('setSyncParentFolder', value) },
    async vasionFolderSearch({ rootState }, payload) {
      const result = await vasionFolderSearch(rootState.common.apiToken, payload)
      return result
    },
  },
  getters: {
    getVaultById: (state) => (id) => { return state.vaultList.find(item => item.vaultID === id) },
  },
}

export default vault
