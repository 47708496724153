<template>
  <div class="card">
    <div class="header">
      <div class="title">
        {{ titleProp }}
      </div>
    </div>
    <apexchart
      type="bar"
      :height="heightProp"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'VasionColumnChart',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    colorProp: {
      type: String,
      required: true,
    },
    dataProp: {
      type: Array,
      required: true,
    },
    heightProp: {
      type: String,
      required: true,
    },
    labelsProp: {
      type: Array,
      required: true,
    },
    titleProp: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        colors: this.colorProp,
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 1,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '15%',
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: this.labelsProp,
        },
      },
      series: [{
        data: this.dataProp,
      }],
    }
  },
  computed: {
    columnData() { return this.dataProp && this.labelsProp },
  },
  watch: {
    columnData() {
      this.series = [{
        data: this.dataProp,
      }]
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.labelsProp,
          },
        },
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  font-weight: bold;
  padding: 10px;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
