<template>
  <div class="misc-main-div">
    <div v-if="userLoginType === '1'">
      <label class="vasion-page-subheader">Reset Password</label>
      <div class="password-input-container">
        <div class="password-input">
          <VasionInput
            id="password"
            v-model="password"
            title="NEW PASSWORD"
            name="password"
            type="password"
            placeholder="Enter Password..."
            inputType="top-white"
          />
        </div>
        <div class="password-input">
          <VasionInput
            id="confirm-password"
            v-model="confirmPassword"
            title="CONFIRM PASSWORD"
            name="confirm-password"
            type="password"
            placeholder="Confirm Password..."
            inputType="top-white"
          />
        </div>
      </div>
      <div class="change-password-button-container">
        <label :class="{ labelSuccess: passwordResetSuccess}">{{ passwordResetMessage }}</label>
        <VasionButton
          id="change-password-button"
          name="change-password-button"
          :classProp="'primary'"
          :isDisabled="!enablePasswordButton"
          @vasionButtonClicked="changePassword"
        >
          <span v-show="!isLoading">Change Password</span>
          <div v-show="isLoading" id="loader-box">
            <Loading
              :active.sync="isLoading"
              :is-full-page="false"
              loader="dots"
              :color="'white'"
              :background-color="'#ff4702'"
            />
          </div>
        </VasionButton>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'MiscUserSettings',
  components: {
    Loading,
  },
  data: function () {
    return {
      confirmPassword: '',
      fullPage: true,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      password: '',
      passwordResetMessage: '',
      passwordResetSuccess: true,
    }
  },
  computed: {
    enablePasswordButton() { return this.password && this.password === this.confirmPassword },
    userLoginType() { return this.$store.state.common.loginType },
  },
  methods: {
    async changePassword() {
      this.passwordResetMessage = ''
      this.passwordResetSuccess = false
      if (!this.enablePasswordButton) {
        return
      }

      this.isLoading = true
      const resetResult = await this.$store.dispatch('common/setNewPassword', this.password)
      if (!resetResult) {
        this.passwordResetSuccess = true
        this.passwordResetMessage = 'Password Changed!'

        this.password = ''
      } else {
        this.passwordResetSuccess = false
        this.passwordResetMessage = resetResult
      }

      this.confirmPassword = ''
      this.isLoading = false

      setTimeout(() => { this.passwordResetMessage = '' }, 3500);
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .misc-main-div {
    background-color: $white;
    border-radius: 4px;
    padding: 12px 17px;
    z-index: 1000;
    width: 100%;
  }

  .password-input-container {
    display: inline-flex;
    width: 100%;
    padding-top: 8px;
  }

  .password-input {
    width: 50%;
    margin: 0 5px 0 5px;
  }

  .change-password-button-container {
    width: 100%;
    text-align: right;
    line-height: 64px;
  }

  .labelSuccess {
    color: $green-500;
  }

  #loader-box {
    z-index: 30;
    min-width: 150px;
    min-height: 36px;
  }
</style>
