/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const deleteScheduler = async (apiToken, schedulerId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    await axios.delete('v1/Admin/DeleteScheduler', {
      data: {
        Value: schedulerId.toString(),
      },
    })
  } catch (error) {
    console.warn(error)
  }
}
const getAddToWorkflowScheduler = async (apiToken, schedulerId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Admin/GetAddToWorkflowScheduler?Value=${schedulerId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getAipScheduler = async (apiToken, schedulerId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Admin/GetAIPScheduler?Value=${schedulerId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getBarcodeRecognitionScheduler = async (apiToken, schedulerId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Admin/GetBarcodeRecognitionScheduler?Value=${schedulerId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getDataImportDetails = async (apiToken, schedulerId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Admin/GetDataImportScheduler?Value=${schedulerId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentNameIndexingList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetDocumentNameIndexingList')
    if (response && response.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getExportConfigList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetExportConfigList')
    if (response && response.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getExportDetails = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/LoadExportScheduler', payload)
    if (response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFullTextUpdateScheduler = async (apiToken, schedulerId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Admin/GetFullTextUpdateScheduler?Value=${schedulerId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getImportDetails = async (apiToken, importId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetImportScheduler?Value=${importId}`)
    if (response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getIsValidUncPath = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/IsValidUNCPath', payload)
    if (response.data) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const getLookupDetails = async (apiToken, lookupId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: response } = await axios.get(`v1/Admin/GetLookupScheduler?Value=${lookupId}`)
    return response
  } catch (error) {
    console.warn(error)
  }
}

const getMoveFieldComparisons = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Values } } = await axios.get('v1/Admin/GetMoveFieldComparisons')
    return Values
  } catch (error) {
    console.warn(error)
  }
}

const getMoveScheduler = async (apiToken, schedulerId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Admin/GetMoveScheduler?Value=${schedulerId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getOcrScheduler = async (apiToken, schedulerId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Admin/GetOCRSchedule?Value=${schedulerId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getRetentionScheduler = async (apiToken, schedulerId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Admin/GetRetentionScheduler?Value=${schedulerId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getSchedulerFieldComparisonTypes = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Admin/GetSchedulerFieldComparisonTypes')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getSchedulerList = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetSchedulerList?schedulerTypes=${payload.schedulerTypes}&orderBy=${payload.orderBy}&ascendingOrder=${payload.ascendingOrder}&schedulerSubtypes=${payload.schedulerSubtypes}`)
    if (response.data) {
      return response.data.Values
    }
  } catch (error) {
      console.warn(error)
  }
}

const getSchedulerListForUser = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetSchedulerListForUser?schedulerTypes=${payload.schedulerTypes}&orderBy=${payload.orderBy}&ascendingOrder=${payload.ascendingOrder}&schedulerSubtypes=${payload.schedulerSubtypes}`)
    if (response.data) {
      return response.data.Values
    }
  } catch (error) {
      console.warn(error)
  }
}

const getScheduledReportDetails = async (apiToken, scheduledReportId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetReportingScheduler?Value=${scheduledReportId}`)
    if (response.data) {
      return response.data
    }
  } catch (error) {
      console.warn(error)
  }
}

const runScheduler = async (apiToken, schedulerId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Admin/RunScheduler', { Value: schedulerId.toString() })
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const saveAddToWorkflowScheduler = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Admin/SaveAddToWorkflowScheduler', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const saveAIPScheduler = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Admin/SaveAIPScheduler', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const saveBarcodeRecognitionScheduler = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveBarcodeRecognitionScheduler', payload)
    if (response.data) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveDataImportScheduler = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/saveDataImportScheduler', payload)

    if (response.data) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveFullTextUpdateScheduler = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Admin/SaveFullTextUpdateScheduler', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const saveImportScheduler = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveImportScheduler', payload)

    if (response.data) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveLookupScheduler = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Admin/SaveLookupScheduler', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const saveMoveScheduler = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveMoveScheduler', payload)
    return response
  } catch (error) {
    console.warn(error)
  }
}

const saveRetentionScheduler = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Admin/SaveRetentionScheduler', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const saveScheduledReport = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveReportingScheduler', payload)
    if (response.data) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveTheExportScheduler = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveExportScheduler', payload)
    if (response.data) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

export {
  deleteScheduler,
  getAddToWorkflowScheduler,
  getBarcodeRecognitionScheduler,
  getAipScheduler,
  getDataImportDetails,
  getDocumentNameIndexingList,
  getExportConfigList,
  getExportDetails,
  getFullTextUpdateScheduler,
  getImportDetails,
  getIsValidUncPath,
  getLookupDetails,
  getMoveFieldComparisons,
  getMoveScheduler,
  getOcrScheduler,
  getRetentionScheduler,
  getSchedulerFieldComparisonTypes,
  getSchedulerList,
  getSchedulerListForUser,
  getScheduledReportDetails,
  runScheduler,
  saveAddToWorkflowScheduler,
  saveAIPScheduler,
  saveBarcodeRecognitionScheduler,
  saveDataImportScheduler,
  saveFullTextUpdateScheduler,
  saveTheExportScheduler,
  saveImportScheduler,
  saveMoveScheduler,
  saveLookupScheduler,
  saveRetentionScheduler,
  saveScheduledReport,
}
