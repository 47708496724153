<template>
  <div id="attribute-form">
    <Loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-if="showMultiLookupResults">
      <MultipleLookups
        :tableValues="lookupResults"
        @yesButtonClick="updateLookupValues"
        @noButtonClick="showMultiLookupResults = false"
      />
    </div>
    <div v-show="!showMultiLookupResults">
      <div id="header">
        <span id="header-text">Fields</span>
        <div id="due-date-wrapper">
          <md-datepicker
            v-if="showDatePicker"
            id="due-date"
            v-model="dueDate"
            name="due-date"
          >
            <label>{{ dueDateLabel }}</label>
          </md-datepicker>
        </div>
      </div>

      <div v-show="!showUploadDialog" id="start-workflow-main-section">
        <IndexFieldList
          id="index-field-list"
          ref="indexFieldListComponent"
          :fields="indexFields"
          :selectorOnly="false"
          :showSubmit="false"
          :useMultiSelectLists="true"
          :enforceFieldSecurity="true"
          @fieldValueUpdated="updateLocalFieldValues"
        />
        <div v-if="uploadedFilesList.length > 0" class="uploaded-files-list">
          <div v-for="(file, index) in uploadedFilesList" :key="index" class="uploaded-file-item">
            <div>
              <span class="file-name"> {{ file.name }} </span><span class="bytes">({{ parseInt(file.size / 1000) }}K)</span>
            </div>
            <VasionButton
              :icon="'VasionExIcon'"
              title="Remove Uploaded File"
              class="configure-template-icon-button align-with-inputs"
              @vasionButtonClicked="removeUploadedFile(index)"
            />
          </div>
        </div>
      </div>

      <div v-if="showUploadDialog" id="upload-new-file-dialog" class="dialog">
        <div id="drag-file-contents" class="upload-container">
          <div class="file-pond-block-wrapper">
            <file-pond
              ref="pond"
              allowMultiple="true"
              :server="serverOptions"
              :label-idle="filePondLabel"
              @processfile="processedFile"
              @addfilestart="fileAdded"
            />
          </div>
          <div class="md-layout field-div">
            <div class="md-layout-item" />
            <div class="md-layout-item align-right push-down">
              <VasionButton
                id="back-to-workflow"
                name="back"
                :classProp="'secondary'"
                @vasionButtonClicked="showUploadDialog = false"
              >
                Back
              </VasionButton>
            </div>
          </div>
        </div>
      </div>

      <div id="footer">
        <label>
          {{ errorText }}
        </label>

        <VasionButton
          v-if="allowUploads"
          id="upload-file-button"
          name="upload-file-button"
          class="button-right"
          :classProp="'secondary'"
          @vasionButtonClicked="showUploadDialog = true"
        >
          Attach Files
        </VasionButton>

        <VasionButton
          id="back"
          name="back"
          :classProp="'secondary'"
          @vasionButtonClicked="backToFileUpload"
        >
          Back
        </VasionButton>

        <VasionButton
          id="cancel"
          name="cancel"
          :classProp="'secondary'"
          class="button-right"
          @vasionButtonClicked="cancelForm"
        >
          Cancel
        </VasionButton>

        <VasionButton
          v-show="showLookupButton"
          id="dbLookup"
          name="dbLookup"
          :classProp="'secondary'"
          class="button-right"
          @vasionButtonClicked="runDBLookup"
        >
          Database Lookup
        </VasionButton>

        <VasionButton
          id="start-workflow"
          name="start-workflow"
          :classProp="'primary'"
          :isDisabled="!formIsValid"
          class="button-right"
          @vasionButtonClicked="prepForStartWorkflow"
        >
          Start Workflow
        </VasionButton>
      </div>
    </div>
    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import IndexFieldList from '@/components/IndexFieldList.vue'
import MultipleLookups from '@/components/vault/MultipleLookups.vue'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { toBase64 } from '@/store/helperModules/storage.module'

export default {
  name: 'StartWorkflowAttributeForm',
  components: {
    IndexFieldList,
    Loading,
    MultipleLookups,
  },
  props: {
    workflowID: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data: function () {
    return {
      allowUploads: false,
      dueDate: null,
      dueDateLabel: '',
      errorText: '',
      formIsValid: true,
      fullPage: true,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      localFieldValues: [],
      lookupResults: {},
      numFilesUploaded: 0,
      serverOptions: {
        process: this.processHandler,
      },
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      showDatePicker: false,
      showLookupButton: false,
      showMultiLookupResults: false,
      showSnackbar: false,
      showUploadDialog: false,
      uploadedFileIDs: [],
      uploadedFileName: '',
      uploadedFileNameList: [],
      uploadedFilesList: [],
      uploadedFileString: '',
      uploadedFileStringList: [],
      uploadedFilesPayloads: [],
      wfFolderId: null,
    }
  },
  computed: {
    filePondLabel() {
      return `<span class="filepond-drag">Drag Files Here</span>
              <br/>
              <span class="filepond-drag-or">or</span>
              <br/><br />
              <span class="filepond-label-action">Click to Upload New File</span>`
    },
    indexFields() { return this.$store.state.common.indexFields },
    selectedDocumentId() { return this.$store.state.workflow.selectedDocumentId },
    startWorkflowPathData() { return this.$store.state.workflow.startWorkflowPathData },
    uploadedFile() { return this.$store.state.storage.uploadedFile },
  },
  async created() {
    this.$material.locale.dateFormat = 'MM/dd/yyyy'
    this.$store.dispatch('common/getIndexFieldsForForm', this.startWorkflowPathData.defaultAttributeFormID)

    this.$store.dispatch('document/getDefaultLookupId', this.startWorkflowPathData.defaultAttributeFormID).then((result) => {
      if (result && result.Value) {
        this.showLookupButton = Number(result.Value) > 0
      }
    })

    const workflowSettings = await this.$store.dispatch('workflow/getWorkflowSettingsNoCommit', this.workflowID)
    this.allowUploads = workflowSettings.supportAttachments

    const dueDateCalculation = await this.$store.dispatch('workflow/calculateDueDateAndSettings', this.workflowID)

    await this.$store.dispatch('storage/getVasionDriveFolderId')
    this.wfFolderId = this.$store.state.storage.vasionDriveFolderId

    this.dueDate = dueDateCalculation.dueDate
    this.dueDateLabel = dueDateCalculation.dueDateLabel
    if (this.dueDate !== null) this.showDatePicker = true
  },
  methods: {
    backToFileUpload() {
      this.$emit('backButtonClick')
    },
    cancelForm() {
      this.$emit('cancelButtonClick')
    },
    fileAdded() {
      this.numFilesUploaded += 1
    },
    getLocalIndexFieldValuePairs() {
      if (!this.localFieldValues) {
        return []
      }

      return this.localFieldValues.map(e => {
        if (Object.prototype.toString.call(e.value) === '[object Array]') {
          const dropdownValues = []
          e.value.forEach(v => {
            dropdownValues.push(v.name)
          })
          const dropDownSingleString = dropdownValues.join(';')
          return {
            Key: e.name,
            Value: dropDownSingleString,
          }
        } else {
          return {
            Key: e.name,
            Value: e.value,
          }
        }
      })
    },
    getStringFromIndexValue(indexValue) {
      let text = ''

      if (!indexValue) {
        return text
      }

      if (Array.isArray(indexValue)) {
        // .value is actually a string array, so we got that going for us...
        indexValue.map((value) => {
          text += `${value};`
          return true
        })

        if (text !== '') {
          // remove the trailing semi-colon
          text = text.substring(0, text.length - 1)
        }
      } else {
        text = indexValue
      }

      return text
    },
    async linkDocuments(documentIDs) {
      if (!documentIDs || documentIDs.length <= 0) {
        return
      }

      const linkDocumentPayload = {
        BaseDocumentID: parseInt(this.selectedDocumentId, 10),
        LinkDocumentIDs: {
            Values: documentIDs,
        },
      }

      await this.$store.dispatch('document/linkDocuments', linkDocumentPayload)
    },
    async prepForStartWorkflow() {
      const requiredFieldMissing = this.indexFields.some(field => field.required && !this.localFieldValues.some(localField => localField.name === field.value))
      if (requiredFieldMissing) {
        this.snackbarTitle = 'Required fields missing'
        this.snackbarSubTitle = 'Please fill in all required fields to start the workflow.'
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }

      this.isLoading = true

      // upload the file and save if necessary
      if (this.selectedDocumentId === null || this.selectedDocumentId <= 0) {
        const tempDocID = await this.$store.dispatch('storage/saveUploadedFileToVasionDrive', this.uploadedFile)
        await this.$store.dispatch('workflow/setSelectedDocumentId', tempDocID)
      }

      if (this.selectedDocumentId === null || this.selectedDocumentId <= 0) {
        // we still don't have a document id, which is bad news :(
        this.$emit('workflowUnsuccessful')
        return
      }

      let uploadIDs = []
      if (this.uploadedFilesPayloads.length > 0) {
        const promises = []
        this.uploadedFilesPayloads.forEach(payload => {
          promises.push(this.$store.dispatch('storage/uploadFileToVasion', payload))
        })
        uploadIDs = await Promise.all(promises)
      }

      uploadIDs.forEach(file => {
        this.uploadedFileIDs.push(parseInt(file, 10))
      })

      if (this.uploadedFileIDs.length > 0) {
        this.linkDocuments(this.uploadedFileIDs)
      }

      // save the field data
      const success = await this.saveAttributeData()
      if (!success) {
        this.$emit('workflowUnsuccessful')
        return
      }

      // put the due date in the store so it can be used upstream
      await this.$store.dispatch('workflow/setWorkflowDueDate', this.dueDate)
      this.$emit('kickOffWorkflow')
    },
    processedFile() {
      this.numFilesUploaded -= 1
      if (this.numFilesUploaded <= 0) {
        this.showUploadDialog = false
      }
    },
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      this.uploadedFileString = await toBase64(file)
      this.uploadedFileName = file.name
      this.uploadedFileStringList.push(this.uploadedFileString)
      this.uploadedFileNameList.push(this.uploadedFileName)
      this.uploadedFilesList.push(file)

      let fileDataString = await toBase64(file)
      const indexOf = fileDataString.indexOf(';base64,')
      if (indexOf >= 0) {
        fileDataString = fileDataString.substring(indexOf + 8)
      }
      const payload = {
        folderId: parseInt(this.wfFolderId, 10),
        name: file.name,
        lastModified: file.lastModified,
        lastModifiedData: file.lastModifiedDate,
        size: file.size,
        type: file.type,
        fileData: fileDataString,
      }
      this.uploadedFilesPayloads.push(payload)

      progress(true, 0, 1024)
      load(file.name)

      return {
        abort: () => {
          abort();
        },
      };
    },
    removeUploadedFile(index) {
      this.uploadedFileStringList.splice(index, 1)
      this.uploadedFileNameList.splice(index, 1)
      this.uploadedFilesList.splice(index, 1)
    },
    async runDBLookup() {
      this.isLoading = true

      const FieldValues = {
        Values: this.getLocalIndexFieldValuePairs(),
      }
      this.initialAttributeFieldListSize = 0

      this.lookupResults = await this.$store.dispatch('attributeForm/attributeFieldLookUp', {
        IndexFormId: this.startWorkflowPathData.defaultAttributeFormID,
        FieldValues,
      })

      switch (this.lookupResults.Rows?.length) {
        case 0:
          this.errorText = 'No Results Found'
          // eslint-disable-next-line no-return-assign
          setTimeout(() => this.errorText = '', 3000);
          break;
        case 1:
          this.$refs.indexFieldListComponent.updateFieldsFromLookup(this.lookupResults)
          break
        default:
          this.showMultiLookupResults = true
          break
      }

      this.isLoading = false
    },
    async saveAttributeData() {
      if (!this.startWorkflowPathData.defaultAttributeFormID || this.startWorkflowPathData.defaultAttributeFormID <= 0) {
        return false
      }

      const payload = {
        DocumentId: this.selectedDocumentId,
        FormId: this.startWorkflowPathData.defaultAttributeFormID,
        FieldValues: {
          Values: this.getLocalIndexFieldValuePairs(),
        },
      }

      return this.$store.dispatch('document/saveDocumentAttributes', payload)
    },
    toggleStartWorkflowButtonDisabled(value) {
      if (value !== undefined && value !== null && typeof value === 'boolean') {
        this.formIsValid = value
      } else {
        this.formIsValid = !this.formIsValid
      }
    },
    updateLocalFieldValues(childComponentValues) {
      this.localFieldValues = childComponentValues
    },
    updateLookupValues(item) {
      this.$refs.indexFieldListComponent.updateFieldsFromLookup(item)
      this.showMultiLookupResults = false
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';

  .field-div {
    width: 100%;
  }

  #attribute-form {
    width: 80vw;

    #header {
      width: 100%;
      border-bottom: 1px solid $grey-300;
      display: flex;
      justify-content: space-between;

      #header-text {
        @include Headline;
        display: block;
        margin: 20px;
        float: left;
      }

      #due-date-wrapper {
        overflow:hidden;
        width: 200px;
        float: left;
        margin-right: 20px;
      }
    }
  }

  @media(max-width: $phone){
    #attribute-form{
      width: 90vw;
    }
  }

  #upload-new-file-dialog {
    margin: 16px auto;
    width: 51%;
  }
  .uploaded-files-list {
    margin-top: -50px;
  }

  #start-workflow-main-section {
    max-height: 400px;
    overflow-y: auto;
    #index-field-list {
      width: 95%;
      margin: 15px;
    }
  }
  #footer {
    margin: 15px;
    display: flex;

    .buttons-right {
      float: right;
    }

    #upload-file-button {
      margin-right: 7px;
    }

    #back {
      margin-left: 0;
      padding-left: 0;

      button {
        margin-left: 0;
        padding-left: 0;
      }
    }

    #start-workflow {
      margin-right: 0;
      padding-right: 0;

      button {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  #upload-new-file-dialog {
    width: 540px;

    #drag-file-contents {
      width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .push-down {
        top: 0;
      }

      .filepond-drag {
        @include SubHeadline;
        display: block;
        width: 480px;
        text-align: center;
        margin: 0 auto;
        margin-top: 225px;
        color: $grey-400;
      }
      label {
        font-size: 14px;
      }
    }
    .filepond--root:not(text) {
      height: 275px;
      margin-top: -50px
    }
  }

  @media (max-width: $phone){
    #upload-new-file-dialog {
      #drag-file-contents{
        width: 300px;
      }
      .filepond-drag {
        width: 300px !important;
      }
    }
  }
</style>
