<template>
  <div class="container">
    <img
      v-if="hasUserSignature"
      class="signature-image-bounds"
      :src="userSignature"
      alt="Signature"
    >
  </div>
</template>

<script>
export default {
  name: 'AddSaved',
  data: function () {
    return {
      signaturePrefix: 'data:image/png;base64,',
    }
  },
  computed: {
    activeSignature() { return this.$store.state.document.activeSignature },
    hasUserSignature() {
      const signature = this.$store.state.digitalSignature.userSignature
      return signature !== '' && signature.replace(this.signaturePrefix, '') !== ''
    },
    userSignature() { return `${this.signaturePrefix}${this.$store.state.digitalSignature.userSignature}` },
  },
  created() {
    this.$store.dispatch('digitalSignature/getUserSignatureForStore')
  },
  methods: { },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .container {
    width: 100%;
    height: 231px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-100;
  }

  .signature-image-bounds {
    max-height: 231px;
  }

</style>
