<template>
  <div>
    <table id="vasion-table">
      <tr class="headerRow">
        <th
          v-for="(header, index) in headerColumns"
          :key="index"
          :header="header"
          :md-label="header"
          class="table-header"
          :class="{ hideTableCell: !shouldShowColumn(header), setColumnWidth: shouldSetColumnWidth(header), workSpaceColumn: shouldSetWorkSpaceColumn(header), widerCheckboxColumn: isHeaderTextLong, widerColumns: hasManyColumns, singleWideColumn: shouldIncreaseColumnWidth(header) && hasManyColumns }"
        >
          <div v-if="header === checkboxColumnHeader && !keepCheckedState" id="check-all">
            <VasionCheckbox :id="'chkHeader'" :checked="displayChecked" @change="headerCheckChange()" />
          </div>
          <div v-else-if="header === checkboxColumnHeader" id="check-all" />
          <div v-else-if="displayHeaderText(header)" @click="sort(index)">
            <label :class="{'sort-by-label': orderByParams.indexValue === header}">{{ displayHeaderText(header) }}</label>
            <span v-if="supportSorting && orderByParams.indexValue === header">
              <VasionArrowDropDownIcon v-show="orderByParams.order === 'desc'" />
              <VasionArrowDropUpIcon v-show="orderByParams.order === 'asc'" />
            </span>
          </div>
        </th>
      </tr>
      <tr
        v-for="(item, rowIndex) in filteredRows"
        :id="`row_${rowIndex}`"
        :key="rowIndex"
        class="iconFillColor table-rows"
        :class="{ 'vasion-lightest-gray-background': rowIndex % 2 === 0 }"
        @click="onSelect(item.Values, rowIndex)"
      >
        <td
          v-for="(value, columnIndex) in item.Values"
          :id="`column${columnIndex}`"
          :key="`emptyUser${columnIndex}`"
          class="table-data"
          :style="displayEmptyUser(rowIndex)"
          :md-sort-by="item.name"
          :md-label="item.name"
          :class="{ hideTableCell: !shouldShowColumn(headerColumns[columnIndex]), setColumnWidth: shouldSetColumnWidth(headerColumns[columnIndex]), workSpaceColumn: shouldSetWorkSpaceColumn(headerColumns[columnIndex]) }"
        >
          <div
            v-if="dropDownIndeces.includes(columnIndex)"
            :key="`${value}`"
          >
            <VasionDropList
              :id="`name-droplist-${rowIndex}`"
              v-slot="slotItem"
              v-model="filteredRows[rowIndex].Values[columnIndex]"
              class="td-set-width"
              :dataArray="dropDownValues"
              type="plain-list"
              :displayName="'name'"
              :valueName="'name'"
              :value="value"
              @input="$emit('cell-change', {row: rowIndex, column: columnIndex, value: item.Values[columnIndex].name})"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div
            v-else-if="headerColumns[columnIndex] === iconColumnHeader"
            class="vasion-icon grid-container"
            v-on:mouseover="mouseOnFile($event, rowIndex)"
            v-on:mouseleave="mouseOffFile()"
          >
            <component :is="value"/>
            <ThumbnailImage
              v-if="displayFile(rowIndex, item)"
              :width="150"
              :pageNumber="1"
              :documentID="item.Values[1]"
              :index="0"
              :style="thumbnailStyle"
            />
          </div>
          <div v-else-if="headerColumns[columnIndex] === iconButtonColumnHeader" class="vasion-icon-button" @click.stop="iconClick(item, columnIndex, rowIndex)">
            <component :is="value" class="icon" />
          </div>
          <div
            v-else
          >
            <VasionInput
              v-model="item.Values[columnIndex]"
              inputType="blank-white"
              class="vasion-table-text"
              @change="$emit('cell-change', {row: rowIndex, column: columnIndex, value: item.Values[columnIndex]})"
            />
          </div>
        </td>
        <td
          v-for="(value, index) in item.Values"
          :id="`column${index}`"
          :key="index"
          class="table-data"
          :style="displayNonEmptyUser(rowIndex)"
          :md-sort-by="item.name"
          :md-label="item.name"
          :class="{ hideTableCell: !shouldShowColumn(headerColumns[index]), setColumnWidth: shouldSetColumnWidth(headerColumns[index]), workSpaceColumn: shouldSetWorkSpaceColumn(headerColumns[index]) }"
        >
          <div
            v-if="headerColumns[index] === iconColumnHeader"
            class="vasion-icon grid-container"
            v-on:mouseover="mouseOnFile($event, rowIndex)"
            v-on:mouseleave="mouseOffFile()"
          >
            <component :is="value"/>
            <ThumbnailImage
              v-if="displayFile(rowIndex, item)"
              :width="150"
              :pageNumber="1"
              :documentID="item.Values[1]"
              :index="0"
              :style="thumbnailStyle"
            />
          </div>
          <div v-else-if="headerColumns[index] === iconButtonColumnHeader" class="vasion-icon-button" @click.stop="iconClick(item, index, rowIndex)">
            <component :is="value" class="icon" />
          </div>
          <div v-else-if="headerColumns[index] === ellipsisButtonColumnHeader && ellipsisButtonConfig" class="vasion-icon-button vasion-ellipsis-button pl-flex-vertical-center" @click.stop="ellipsisOpen($event, item)">
            <component :is="value" />
          </div>
          <div v-else-if="headerColumns[index].startsWith(checkboxColumnHeader)" class="vasion-icon">
            <VasionCheckbox
              v-if="value.show && (!keepCheckedState || !value.value)"
              :id="`chkRow_${index}`"
              :checked="value.value"
              @change="lineItemCheckChange($event, value, item, index, rowIndex)"
              @checkbox-checked-oncreate="setCheckedOnCreate(value, item)"
            />
            <img
              v-else-if="value.show && keepCheckedState && value.value"
              class="checkbox-checked"
              src="@/assets/images/Checkbox-checked.png"
              alt="checked"
            >
          </div>
          <div v-else-if="headerColumns[index].startsWith(priorityColumnHeader)" class="vasion-icon">
            <VasionDropList
              v-slot="slotItem"
              v-model="item.priority"
              :class=" { isHigh : item.priority.value === 1, isNormal : item.priority.value === 2, isLow : item.priority.value === 3 }"
              width="100"
              :dataArray="priorityListOptions"
              :showSearchField="false"
              type="plain-list"
              valueName="value"
              displayName="name"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div v-else-if="headerColumns[index] === dueDateHeader" class="due-date-column" @click="dueDateClick($event, item.Values, value)">
            <div class="due-date-text-div">
              {{ value | formatDateToDisplay }}
            </div>
            <div class="due-date-icon-div">
              <VasionCalendarTodayIcon />
            </div>
          </div>
          <div
            v-else
            :id="item.Values[4]"
            class="vasion-table-text"
            :style="{ 'max-width': maxTextLength }"
            :class="{ 'no-wrap' : !wrapCells }"
            :title="value"
          >
            {{ value }}
          </div>
        </td>
      </tr>
    </table>
    <div v-if="showDueDateComponent" class="set-due-date-component-div" :style="dueDateDisplayTopStyle">
      <SetDocumentDueDate
        :propDocumentID="dueDateDocumentID"
        :propWorkflowID="dueDateWorkflowID"
        :propDueDate="dueDate"
        @okButtonClick="dueDateOKClick"
        @cancelButtonClick="dueDateCancelClick"
      />
    </div>

    <VasionEllipsisMenu
      v-if="showEllipsisMenu"
      v-click-outside="ellipsisClose"
      :buttonConfig="ellipsisButtonConfig"
      :rowItem="ellipsisRowItem"
      :style="ellipsisMenuTopStyle"
      @ellipsis-button-clicked="ellipsisButtonClicked"
      @close="ellipsisClose"
    />
  </div>
</template>

<script>
// eslint-disable-next-line no-restricted-globals
import { format } from 'date-fns'
import SetDocumentDueDate from '@/components/workflow/SetDocumentDueDate.vue'
import ThumbnailImage from '@/components/document/ThumbnailImage.vue';

export default {
  name: 'VasionTable',
  components: {
    SetDocumentDueDate,
    ThumbnailImage,
  },
  filters: {
    formatDateToDisplay(val) {
      if (!val || val === '') {
        return val
      }

      let ret = ''
      try {
        const tempDate = Date.parse(val)
        ret = format(tempDate, 'MM/dd/yyyy')
      } catch (error) {
        ret = val
      }

      return ret
    },
  },
  props: {
    blankUsers: {
      type: Array,
      default: () => [],
      required: false,
    },
    containsPriorityDropLists: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableShiftSelect: {
      type: Boolean,
      default: false,
      required: false,
    },
    dropDownIndeces: {
      type: Array,
      default: () => [],
      required: false,
    },
    dropDownValues: {
      type: Array,
      default: () => [],
      required: false,
    },
    ellipsisButtonConfig: {
      type: Object,
      default: null,
      required: false,
    },
    ellipsisOffset: {
      type: Number,
      default: 0,
      required: false,
    },
    filterByColumnDefault: {
      type: Number,
      default: 0,
      required: false,
    },
    headerColumns: {
      type: Array,
      default: () => [],
      required: false,
    },
    hideColumns: {
      type: Array,
      default: () => [],
      required: false,
    },
    iconIndeces: {
      type: Array,
      default: () => [],
      required: false,
    },
    keepCheckedState: {
      type: Boolean,
      default: false,
      required: false,
    },
    maxTextLength: {
      type: String,
      default: '100%',
      required: false,
    },
    supportSorting: {
      type: Boolean,
      default: false,
      required: false,
    },
    tableRows: {
      type: Array,
      default: () => [],
      required: false,
    },
    wrapCells: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: function () {
    return {
      checkboxColumnHeader: '_VasionCheckBox_',
      checkedBoxes: [],
      dueDate: null,
      dueDateDisplayTopStyle: '',
      dueDateDocumentID: 0,
      dueDateHeader: '_DueDate_',
      dueDateRowIndex: -1,
      dueDateWorkflowID: 0,
      ellipsisButtonColumnHeader: '_VasionEllipsisButton_',
      ellipsisMenuTopStyle: '',
      ellipsisRowItem: 0,
      fileDisplay: -1,
      hasManyColumns: false,
      headerChecked: false,
      iconButtonColumnHeader: '_VasionIconButton_',
      iconColumnHeader: '_VasionIcon_',
      isClicked: false,
      isHeaderTextLong: false,
      lastCheckedRow: 0,
      lastCheckedBoolean: false,
      localFilteredRows: this.tableRows,
      mouseX: 0,
      mouseY: 0,
      orderByParams: {
        indexValue: this.supportSorting ? this.headerColumns[this.filterByColumnDefault] : '',
        order: 'asc',
      },
      priorityColumnHeader: '_Priority_',
      priorityListOptions: [{ name: 'High', value: 1 }, { name: 'Normal', value: 2 }, { name: 'Low', value: 3 }],
      selected: [],
      selectedDueDateIndex: -1,
      showDueDateComponent: false,
      showEllipsisMenu: false,
    }
  },
  computed: {
    displayChecked() { return this.headerChecked },
    filteredRows() {
      // eslint-disable-next-line no-undef
      return _.orderBy(this.localFilteredRows, [this.orderByParams.indexValue.replace(/\s/g, '')], [this.orderByParams.order])
    },
    thumbnailStyle() { return 'position: absolute; top: ' + this.mouseY.toString() + 'px; left: ' + this.mouseX.toString() + 'px; filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2)) drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12));' }
  },
  watch: {
    tableRows: function () {
      this.localFilteredRows = this.tableRows
      this.headerChecked = false
      this.checkedBoxes = []
    },
  },
  created() {
    this.localFilteredRows = this.tableRows
    this.checkedBoxes = []
    this.selected = []
    this.headerChecked = false
    this.hasManyColumns = this.headerColumns.length > 20
  },
  methods: {
    displayEmptyUser(rowIndex){
      if (this.blankUsers.includes(rowIndex)){
        return ""
      }
      return "display: none; "
    },
    displayNonEmptyUser(rowIndex){
      if (this.blankUsers.includes(rowIndex)){
        return "display: none; "
      }
      return ""
    },
    displayFile(rowIndex, item) { return this.fileDisplay == rowIndex && item.Values[3] == 'VasionInsertDriveFileIcon'},
    displayHeaderText(headerText) {
      if (!headerText
        || headerText === this.iconColumnHeader
        || headerText === this.iconButtonColumnHeader
        || headerText === this.checkboxColumnHeader
        || headerText === this.ellipsisButtonColumnHeader) {
        return ''
      }
      // If we have a header like ex _VasionCheckBox_{{Header Text Name}}, we need to apply header text, even though it is a checkbox
      if (headerText.startsWith('_VasionCheckBox_')) {
        headerText = headerText.replace('_VasionCheckBox_{{', '').replace('}}', '')
        this.isHeaderTextLong = headerText.length > 15
        return headerText
      } else if (headerText === this.dueDateHeader) {
        return 'Due Date'
      } else if (headerText === this.priorityColumnHeader) {
        return 'Priority'
      }
      return headerText
    },
    dueDateCancelClick() {
      this.showDueDateComponent = false
    },
    dueDateClick(event, items, selectedDueDate) {
      this.showDueDateComponent = false
      this.dueDateDocumentID = Number(items[1])
      this.dueDateWorkflowID = Number(items[2])

      if (!this.dueDateDocumentID || !this.dueDateWorkflowID) {
        return
      }

      this.dueDate = null
      if (selectedDueDate && selectedDueDate !== '') {
        this.dueDate = new Date(selectedDueDate)
      }

      let displayY = event.pageY - 40
      if (displayY + 350 > document.documentElement.scrollHeight) {
        displayY -= 350
      }

      this.dueDateDisplayTopStyle = `top:${displayY}px;`
      this.showDueDateComponent = true
    },
    dueDateOKClick(newDueDate) {
      const rowElement = this.localFilteredRows.find((element) => {
        // disabling lint because I want the "==" comparison - one is a Number, the other is a string
        // eslint-disable-next-line
        return element.Values[1] == this.dueDateDocumentID
      })

      if (rowElement) {
        rowElement.Values[8] = newDueDate
      }

      this.showDueDateComponent = false
    },
    ellipsisButtonClicked(payload) {
      this.$emit('ellipsis-button-clicked', payload)
      this.ellipsisClose()
    },
    ellipsisClose() {
      this.showEllipsisMenu = false
    },
    ellipsisOpen(event, item) {
      this.ellipsisClose()
      this.ellipsisRowItem = item
      let displayY = event.pageY
      let baseSize = 0

      if (this.ellipsisButtonConfig && this.ellipsisButtonConfig.buttons) {
        baseSize = this.ellipsisButtonConfig.buttons.length * 40
      }

      if (displayY + baseSize > document.documentElement.scrollHeight) {
        displayY -= baseSize
      }

      displayY += this.ellipsisOffset

      this.ellipsisMenuTopStyle = `top:${displayY}px;`
      this.showEllipsisMenu = true
    },
    headerCheckChange() {
      this.headerChecked = !this.headerChecked
      this.checkedBoxes = []

      this.filteredRows.forEach((item) => {
        item.Values.forEach((element) => {
          if (element && typeof element.show !== 'undefined' && element.show) {
            // kind of a lot going on here...
            // so, the header check was changed, meaning that we want to apply that value to all checkboxes below
            // so, loop through the items, and if it's a check box that's shown, set the value
            // then, add it to the list of checked rows if needed
            element.value = this.headerChecked
            if (this.headerChecked) {
              this.checkedBoxes.push(item)
            }
          }
        })
      })

      this.$emit('newList', this.checkedBoxes)
      this.$emit('vasion-row-checked', this.checkedBoxes)
    },
    iconClick(lineItem, clickedColumnIndex, clickedRowIndex) {
      this.$emit('vasion-icon-click', {
        item: lineItem,
        index: clickedColumnIndex,
        rowIndex: clickedRowIndex,
      })
    },
    lineItemCheckChange(shiftKey, checkValue, lineItem, clickedColumnIndex, clickedRowIndex) {
      if (shiftKey && !this.disableShiftSelect) {
        const startIndex = Math.min(clickedRowIndex, this.lastCheckedRow)
        const endIndex = Math.max(clickedRowIndex, this.lastCheckedRow)

        this.filteredRows.forEach((item, index) => {
          if (index >= startIndex && index <= endIndex) {
            item.Values.forEach((element) => {
              if (element && typeof element.show !== 'undefined' && element.show) {
                element.value = this.lastCheckedBoolean
                if (this.lastCheckedBoolean) {
                  this.checkedBoxes.push(item)
                } else {
                  const index = this.checkedBoxes.indexOf(lineItem)
                  if (index > -1) {
                     this.checkedBoxes.splice(index, 1);
                  }
                }
              }
            })
          }
        })

        // For shift-click, we want to make sure that the browser does not do a text selection
        document.getSelection().removeAllRanges()

        this.$emit('newList', this.checkedBoxes)
        this.$emit('vasion-row-checked', this.checkedBoxes)
      } else {
        checkValue.value = !checkValue.value
        if (checkValue.value) {
          this.checkedBoxes.push(lineItem)
          this.$emit('newList', this.checkedBoxes, lineItem)
        } else {
          // Find and remove just the first instance
          const index = this.checkedBoxes.indexOf(lineItem)
         if (index > -1) {
            this.checkedBoxes.splice(index, 1);
            this.$emit('newList', this.checkedBoxes, lineItem)
          }
        }

        const payload = this.checkedBoxes.map((row) => {
          return {
            ValueType: row.ValueType,
            Values: row.Values,
            clickedColumnIndex: clickedColumnIndex,
            clickedRowIndex: clickedRowIndex,
          }
        })

        this.$emit('vasion-row-checked', payload, clickedRowIndex, clickedColumnIndex, checkValue.value)
      }
      this.lastCheckedRow = clickedRowIndex
      this.lastCheckedBoolean = checkValue.value
    },
    mouseOffFile: function () {
      this.mouseX = 0
      this.mouseY = 0
      this.fileDisplay = -1
    },
    mouseOnFile: function (event, rowIndex) {
      this.mouseX = event.pageX - 220 //offset
      this.mouseY = event.pageY - 35  //offset
      this.fileDisplay = rowIndex

      // Image doesn't fall off bottom of screen:
      let maxY = document.body.clientHeight - 380
      if (this.mouseY >= maxY){
        this.mouseY = maxY
      }
    },
    onSelect(items, index) {
      this.selected = items
      // eslint-disable-next-line no-restricted-globals
      if (!event.target.parentNode.classList.contains('input-group') && !event.target.parentNode.parentNode.classList.contains('input-group') && !event.target.parentNode.parentNode.classList.contains('vasion-droplist') && !event.target.parentNode.parentNode.classList.contains('drop-arrow') /* DROPLIST */
      // eslint-disable-next-line no-restricted-globals
      && !event.target.classList.contains('checkbox') && !event.target.parentNode.classList.contains('checkbox') && event.srcElement.className !== 'vasion-checkbox' && !event.target?.childNodes[0]?.childNodes[0]?.classList.contains('vasion-checkbox') /* CHECKBOX */
      // eslint-disable-next-line no-restricted-globals
      && !event.target.parentNode.classList.contains('vasion-ellipsis-button') /* ELLIPSIS */) {
        this.$emit('vasion-selection', {
          Values: items,
          Index: index,
        })
      }

      if (event.srcElement.className === 'vasion-checkbox' || event.target?.childNodes[0]?.childNodes[0]?.classList.contains('vasion-checkbox')) {
        this.lineItemCheckChange(false, items[2], { ValueType: 'String', Values: items }, null, index)
      }
    },
    setCheckedOnCreate(checkValue, lineItem) {
      if (checkValue.value) {
        this.checkedBoxes.push(lineItem)
      }
    },
    shouldIncreaseColumnWidth(columnName) {
      return columnName.length >= 10
    },
    shouldSetColumnWidth(columnName) {
      return columnName.indexOf(this.iconColumnHeader) > -1 || columnName.indexOf(this.checkboxColumnHeader) > -1 || columnName.indexOf(this.iconButtonColumnHeader) > -1 || columnName.indexOf(this.ellipsisButtonColumnHeader) > -1
    },
    shouldSetWorkSpaceColumn(columnName) {
      return columnName === this.priorityColumnHeader || columnName === this.dueDateHeader
    },
    shouldShowColumn(columnName) {
      if (columnName && (!this.hideColumns || this.hideColumns.length === 0 || this.hideColumns.indexOf(columnName) < 0)) {
        return true
      }

      return false
    },
    sort(indexValue) {
      if (!this.supportSorting) {
        return
      }

      if (this.orderByParams.indexValue === this.headerColumns[indexValue]) {
       this.orderByParams.order = this.orderByParams.order === 'asc' ? 'desc' : 'asc'
      }
      this.orderByParams.indexValue = this.headerColumns[indexValue]

      this.$emit('vasion-column-sort', {
        columnName: this.orderByParams.indexValue,
        ascending: this.orderByParams.order === 'asc',
      })
    },
  },
};
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';

  .no-wrap{
    white-space: pre;
  }

  .vasion-loading-indicator {
    height: 100%;
  }

  .vasion-table-row {
    overflow: unset;
  }

  .set-due-date-component-div {
    position: absolute;
    right: 30px;
    background: $white;
    border: 1px solid $grey-300;
    margin: 0 auto;
    text-align: left;
    z-index: 1000;
    width: 430px;
  }

  .due-date-text-div {
    width: 70px;
    padding-top: 5px;
    margin-right: 15px;
  }

  .due-date-column {
    display: flex;
    flex-wrap: wrap;
    width: 110px;
  }

  .due-date-column:hover {
    color: $orange-300;
    fill: $orange-300;
    background: $orange-50;
    cursor: pointer;
  }

  .hideTableCell {
    display: none;
  }

  .setColumnWidth {
    max-width: 60px;
    width: 60px;
  }

  .workSpaceColumn {
    max-width: 160px;
    width: 160px;
  }
  .widerCheckboxColumn {
    max-width: 90px;
    width: 90px;
  }
  .widerColumns {
    min-width: 100px;
  }
  .singleWideColumn {
    min-width: 210px;
  }
  .headerRow {
    background-color: $grey-100;
    font-weight: bold;
    color: $grey-500;
  }

  .iconFillColor{
    fill: $grey-400;
  }

  .sort-by-label {
    position: relative;
    bottom: 6px;
  }

  .vasion-table-text {
    cursor: pointer;
    width: 100%;
    min-height: 20px;
    margin-left: -2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .vasion-icon-button:hover {
    color: $orange-300;
    fill: $orange-300;
    cursor: pointer;
  }

  .vasion-ellipsis-button {
    width: 32px;
    height: 32px;

    svg {
      margin: 4px 0 0 4px;
    }

    &:hover {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $orange-100;
    }
  }

  .checkbox-checked {
    padding: 3px;
  }

  @media (max-width: $phone){
    .set-due-date-component-div {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 97%;
    }
  }

  #vasion-table {
    width: 100%;
  }
  .table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $grey-100;
    font-weight: bold;
    color: $grey-500;
    padding: 15px 0 15px 11px;
    white-space: nowrap;
  }
  .table-data {
    padding: 10px 0 10px 15px;
  }
  .table-rows:hover {
    background-color: $grey-100;
    color: $grey-500;
    cursor: pointer;
  }
</style>
