<template>
  <div class="user-group-div">
    <div v-if="modalTitle && modalTitle !== ''" class="title">
      {{ modalTitle }}
    </div>
    <div class="vasion-flex-row">
      <div v-if="!userOnly && !groupsOnly" class="vasion-flex-row radio">
        <md-radio id="users-radio" v-model="radio" value="users">
          User
        </md-radio>
        <md-radio id="groups-radio" v-model="radio" value="groups">
          Group
        </md-radio>
      </div>
      <div v-else class="vasion-flex-row radio" />
      <div id="users-groups-search" class="input">
        <VasionInput
          id="details-step-name"
          v-model.trim="searchValue"
          name="details-step-name"
          placeholder="Search..."
          inputType="search-white"
        />
      </div>
    </div>
    <div class="vasion-html-table-default element-list">
      <table>
        <thead>
          <tr>
            <th>
              <label id="users-groups-modal-header" class="vasion-html-table-header">Name</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in filteredValues" :key="index" :class="{selectedUser: selectedRow.includes(item)}">
            <td @click="selectUserOrGroup(item)">
              <label class="vasion-html-table-field">{{ item }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!hideButtons" class="vasion-flex-end buttonsRow">
      <VasionButton id="button-cancel" :classProp="'secondary'" @vasionButtonClicked="noClick()">
        Cancel
      </VasionButton>
      <VasionButton
        id="button-save"
        :classProp="'primary'"
        :isDisabled="!selectedItem"
        @vasionButtonClicked="yesClick()"
      >
        OK
      </VasionButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VasionUserGroupSelect',
  props: {
    defaultSelected: {
      type: Number,
      required: false,
      default: null,
    },
    groupFilterId: {
      type: Number,
      required: false,
      default: 0,
    },
    groupsOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    includeNoneValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    modalTitle: {
      type: String,
      required: false,
      default: '',
    },
    userOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      filteredUsers: [],
      radio: this.groupsOnly ? 'groups' : 'users',
      searchValue: '',
      selectedItem: '',
      selectedRow: '',
      slotToParentValue: '',
    }
  },
  computed: {
    filteredValues() {
      const input = this.searchValue.toLowerCase()
      if (!input) { return this.getUserGroupsArray() }

      return this.getUserGroupsArray().filter(item => {
        const target = item.toLowerCase()
        return target.indexOf(input) > -1
      })
    },
    groups() {
      let groupList = this.$store.state.common.groups
      if (this.includeNoneValue) {
        groupList.unshift({ name: '[None]', value: 0 })
      }
      if (this.groupFilterId > 0) {
        groupList = this.filteredUsers.map(g => {
          return {
            name: this.removeUnnecessaryText(g.sName),
            value: g.iID,
          }
        })
      }

      return groupList
    },
    users() {
      let userList = this.$store.state.common.users
      if (this.groupFilterId > 0) {
        userList = this.filteredUsers.map(u => {
          return {
            name: this.removeUnnecessaryText(u.sName),
            username: this.removeUnnecessaryText(u.sName),
            value: u.iID,
          }
        })
      }

      return userList
    },
  },
  async created() {
    await this.$store.dispatch('common/getGroups')
    if (this.groupFilterId > 0) {
      this.filteredUsers = await this.$store.dispatch('security/getGroupChildren', this.groupFilterId)
    }
    if (this.defaultSelected) {
      const group = this.groups.find(g => g.value === this.defaultSelected)
      this.selectedRow = group.name
    }
  },
  methods: {
    getUserGroupsArray() {
      return this.radio === 'users' ? this.users.map(user => user.name) : this.groups.map(group => group.name)
    },
    noClick() {
      this.$emit('noButtonClick')
    },
    removeUnnecessaryText(value) {
      const delimiter = ' - '
      const index = value.indexOf(delimiter)

      if (index > -1) {
        value = value.substring(index + delimiter.length, value.length)
      }

      return value
    },
    selectUserOrGroup(select) {
      const userOrGroup = this.radio === 'users' ? this.users : this.groups
      this.selectedItem = userOrGroup.find(item => item.name === select)
      this.slotToParentValue = this.selectedItem
      this.selectedRow = select
      this.selectedItem.type = this.radio
      this.$emit('slotComponentEmit', this.selectedItem)
    },
    yesClick() {
      this.$emit('yesButtonClick', this.selectedItem)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .user-group-div {
    width: 100%;
    padding: 10px;
    .vasion-flex-row {
      justify-content: space-between;
    }
    .selectedUser {
      background-color: $orange-50;
    }
    th {
      cursor: auto;
    }
    tr {
      cursor: pointer;
      &:nth-child(even) {
        &:hover {
          background-color: $orange-50;
        }
      }
    }
    .radio {
      width: 25%;
    }
    .element-list {
      max-height: 475px;
      max-width: 40vw;
      overflow: auto;
      overflow-x: hidden;
      th {
        width: 100%;
      }
    }
  }
  .title {
    @include Headline;
  }

  @media(max-width: 820px){
    .user-group-div {
      .element-list {
        max-height: calc(100vw - 50px);
        max-width: 100vw;
      }
      .radio{
        width: auto;
      }
    }
  }
</style>
