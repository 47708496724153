/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const buildSendToVasionActionString = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/BuildSendToVasionActionString', payload)
    if (response && response.data) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteBarcodeConfig = async (apiToken, id) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Admin/DeleteBarcodeIndex', { data: { Value: id } })
    if (response) {
      return response.data
    }
    return false
  } catch (error) {
    console.warn(error)
  }
}

const deleteEForm = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/DeleteEForm', { Value: payload })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteEmailTemplate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Admin/DeleteEmailTemplate', { data: { Value: payload } })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGetEmailTemplates = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetEmailTemplateList')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGetGroups = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Auth/GetGroupList')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGetUsers = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Auth/GetUserList')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getAllDocumentTemplates = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetAllDocumentTemplates')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getConfig = async (apiToken, configName) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Document/GetConfig', { sConfigName: configName })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentTemplate = async (apiToken, templateID) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    const response = await axiosC.get(`/v1/Admin/GetDocumentTemplate?Value=${templateID}`)
    return response
  } catch (error) {
    console.warn(error)
    return {}
  }
}

const getIndexFields = async (apiToken, formId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Document/GetForm', { DocumentId: 0, FormId: formId })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getPasswordSchema = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return axios.get('v1/Auth/GetPasswordSettings')
  } catch (error) {
    console.warn(error)
  }
}

const getUserHasSchedulers = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetUserHasSchedulers')
    return (response && response.data && response.data.Value === 'true')
  } catch (error) {
    console.warn(error)
  }
}

const loginUser = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/auth/Authenticate', payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response && response.data) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const needPasswordReset = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return axios.post('v1/Auth/ShouldChangePassword', payload)
  } catch (error) {
    console.warn(error)
  }
}

const requestPasswordReset = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/RequestPasswordReset', payload)
    if (response && response.data && response.data.Value === '') {
      return true
    }
    return false
  } catch (error) {
    console.warn(error)
  }
}

const resetPassword = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return axios.post('v1/Auth/ResetPassword', payload)
  } catch (error) {
    console.warn(error)
  }
}

const resetPasswordCheckGuid = async (apiToken, guid) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/ResetPasswordCheckGUID', guid)
    if (response && response.data && response.data.Value === '') {
      return true
    }
    return false
  } catch (error) {
    console.warn(error)
  }
}

const saveDocumentTemplate = async (apiToken, payload) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    const response = await axiosC.post('v1/Admin/saveDocumentTemplate', payload)
    return response
  } catch (error) {
    console.warn(error)
  }
}

const setConfig = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return axios.post('v1/Document/SetConfig', payload)
  } catch (error) {
    console.warn(error)
  }
}

const updatePassword = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return axios.post('v1/Auth/ChangePassword', payload)
  } catch (error) {
    console.warn(error)
  }
}

const uploadTempFile = async (apiToken, payload) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axiosC.post('v1/Upload/UploadTempFile', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const validateSendToVasionActionString = async (apiToken, actionString) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/ParseSendToVasionActionString', actionString)
    return response && response.data && response.data.userName && response.data.userName !== ''
  } catch (error) {
    console.warn(error)
  }
}

export {
  buildSendToVasionActionString,
  doGetEmailTemplates,
  doGetGroups,
  doGetUsers,
  deleteBarcodeConfig,
  deleteEForm,
  deleteEmailTemplate,
  getAllDocumentTemplates,
  getDocumentTemplate,
  getConfig,
  getIndexFields,
  getPasswordSchema,
  getUserHasSchedulers,
  loginUser,
  requestPasswordReset,
  resetPassword,
  resetPasswordCheckGuid,
  saveDocumentTemplate,
  setConfig,
  needPasswordReset,
  updatePassword,
  uploadTempFile,
  validateSendToVasionActionString,
}
