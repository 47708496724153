<template>
  <div>
    <div class="vasion-container-flex vasion-white-background">
      <div :class="{'z-index-fix': !isDisplayingNavBarDialog}" v-show="isSignedIn && !isVasionHome && activeSubAppSet && isLoginRouteNotActive && !isSignatureDocLinkView && !doHide">
        <TheMainSideNav v-if="apiToken" class="side-bar" :class="{'hidden': !isMobileSideBarActive}" />
      </div>

      <!-- SIGNATURE DOCUMENT LINK PAGES -->
      <div v-if="isSignatureDocLinkView">
        <NavBar @showDialog="navBarDialog" />
        <transition name="component-fade" mode="out-in">
          <router-view v-if="activeSubAppSet" :key="$route.fullPath" to="/" />
        </transition>
      </div>

      <!-- VALID PAGES -->
      <div v-else-if="isSignedIn && isLoginRouteNotActive" class="main-class-view" :class="{'vasion-main-nav-mini-mode': !mainNavExpanded, 'vasion-main-nav-full-mode': mainNavExpanded, 'vasion-home-mode': isVasionHome, 'z-index-fix': isDisplayingNavBarDialog}"> 
        <div
          v-if="!isVasionHome"
          id="overlay"
          :class="{'overlay-effect': isMobileSideBarActive}"
          @click="isMobileSideBarActive = false"
        />
        <div v-if="!doHide" class="top-bar">
          <div v-if="isSignedIn && !isVasionHome" class="mobile-side-bar-toggle" @click="isMobileSideBarActive = true">
            <VasionMenu v-if="!isMobileSideBarActive" class="hamburger-icon" />
          </div>
          <div v-if="isMobileSideBarActive && !isVasionHome" class="side-bar-close-button" @click="isMobileSideBarActive = !isMobileSideBarActive">
            <VasionCloseIcon class="side-bar-close-icon" />
          </div>
          <NavBar @showDialog="navBarDialog" class="nav" />
        </div>
        <transition name="component-fade" mode="out-in">
          <router-view v-if="activeSubAppSet" :key="$route.fullPath" to="/" />
        </transition>
      </div>

      <!-- INVALID PAGE -->
      <div v-else>
        <NavBar />
        <transition name="component-fade" mode="out-in">
          <router-view to="/" />
        </transition>
      </div>
    </div>
    <md-dialog id="browseFoldersModal" :md-active.sync="showNetworkTimeoutModal">
      <div class="network-failed-container">
        <h1>Network Failed</h1>
        <h3>We don't know what you just did, but don't do it again</h3>
        <VasionButton :classProp="'primary'" @vasionButtonClicked="toggleNetworkTimeoutModal">
          CANCEL
        </VasionButton>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import NavBar from '@/components/mainViews/NavBar.vue';
import TheMainSideNav from '@/components/mainViews/TheMainSideNav.vue';

export default {
  name: 'TheMainView',
  components: {
    NavBar,
    TheMainSideNav,
  },
  data: function () {
    return {
      doHide: false,
      isDisplayingNavBarDialog: false,
      isMobileSideBarActive: false,
      menuVisible: false,
    }
  },
  computed: {
    activeSubAppSet() { return this.$store.state.mainViews.activeSubApp !== null },
    apiToken() { return this.$store.state.common.apiToken },
    hideNavbarAndHeader() { return this.$store.state.common.hideNavbarAndHeader },
    isLoginRouteNotActive() { return this.$route.name !== 'TheLogin' },
    isSignatureDocLinkView() { return this.$store.state.common.isSignatureDocLinkView },
    isSignedIn() { return this.$store.state.common.isSignedIn },
    isVasionHome() { return this.$store.state.mainViews.vasionHomeToggle },
    mainNavExpanded() { return this.$store.state.mainViews.mainNavExpanded },
    needsPasswordReset() { return this.$store.state.common.needsPasswordReset },
    showNetworkTimeoutModal() { return this.$store.state.mainViews.showNetworkTimeoutModal },
  },
  watch: {
    hideNavbarAndHeader() { this.doHide = this.hideNavbarAndHeader },
    isSignedIn: function (value) { return value ? this.$store.dispatch('admin/getUserSetting', 'defaultApp') : null },
  },
  created() {
    if (this.isSignedIn) {
      this.$store.dispatch('admin/getUserSetting', 'defaultApp')
    }
  },
  methods: {
    navBarDialog(showing) { this.isDisplayingNavBarDialog = showing },
    toggleMenu() { this.menuVisible = !this.menuVisible },
    toggleNetworkTimeoutModal() { this.$store.dispatch('mainViews/setNetworkTimeoutModal', !this.showNetworkTimeoutModal) },
  },
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/variables.scss';

    .top-bar{
      display: flex;
      width:100%;
    }

    .nav{
      width: 100%;
    }

    .main-class-view {
      width: 100%;
    }

    .mobile-side-bar-toggle{
      display: none;
    }

    hamburger-icon{
      display: none;
    }

    side-bar-close-button{
      display: none;
    }

    @media (max-width: 950px) {

      .side-bar{
        visibility: visible;
        transition: .275s;
        width: 256px;
        position: absolute;
        left: 0;
        z-index: 5;
      }

      .hidden{
        width: 0px;
        position: absolute;
        visibility: hidden;
        transition: .275s;
        left: 0;
        z-index: 5;
      }

      .mobile-side-bar-toggle{
        width: 56px;
        height: 56px;
        background-color: $plumb-500;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hamburger-icon{
        padding: 0px;
        margin: 0px;
        fill: $orange-300;
      }

      .side-bar-close-button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        top: 0;
        left: 268px;
        margin-top: 12px;
        z-index: 6;

      }

      .side-bar-close-icon path{
        fill: white !important;
      }

      .overlay-effect{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 1;
        transition: .5s ease;
        background-color: rgba(0,0,0, 0.4);
        overflow:hidden;
        z-index: 6;
      }
    }

    .vasion-container-flex{
        display: flex;
    }

    .network-failed-container {
      width: 80vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .z-index-fix {
      position: relative;
      z-index: 7;
    }
</style>
