<template>
  <div v-if="isTitleInput" :class="{'vasion-title-input': inputType === 'title-input'}">
    <!-- Title Input -->
    <input
      v-bind="$attrs"
      ref="mainInput"
      autocomplete="off"
      class="text-input"
      :class="calculatedClasses().input"
      :maxlength="maxLengthProp"
      :disabled="isDisabled"
      :readonly="readonly"
      @focus="$emit('focus', true)"
      v-on="listeners"
    >
  </div>
  <div v-else class="vasion-input possition-relative no-padding" :class="{'vasion-flex-vertical-center': inputType === 'left-white'}">
    <div>
      <label
        v-if="title && (inputType === 'top-white' || inputType === 'search-white-top')"
        class="vasion-input-label-top"
        :class="{'error-state-label':isInErrorState}"
      >
        {{ title }}
      </label>
      <span v-if="title && (inputType === 'top-white' || inputType === 'search-white-top') && required" class="vasion-required-indicator">*</span>
    </div>
    <div>
      <label
        v-if="title && inputType === 'left-white'"
        class="input-label-left"
        :class="{'error-state-label':isInErrorState}"
      >
        {{ title }}
      </label>
      <span v-if="title && inputType === 'left-white' && required" class="vasion-required-indicator">*</span>
    </div>
    <div v-if="!title && showTitlePlaceholder" class="title-placeholder-div" />
    <div class="input-group" :style="{ width: inputGroupWidth}" :class="calculatedClasses().container">
      <i v-if="inputType === 'search' || inputType === 'search-on-color' || inputType === 'search-white' || inputType === 'search-gray' || inputType === 'search-white-top'" class="search-icon"><VasionSearchIcon /></i>
      <!-- Number Input -->
      <input
        v-if="isNumber"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="readonly"
        type="number"
        step="any"
        @focus="$emit('focus', true)"
        v-on="listeners"
      >
      <!-- URL Input -->
      <input
        v-else-if="isURL && !showURLLink"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        type="url"
        name="url"
        placeholder="https://example.com"
        pattern="https://.*"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="readonly"
        @focus="$emit('focus', true)"
        @input="isValid"
        v-on="listeners"
      >
      <!-- URL Link -->
      <input
        v-else-if="isURL && showURLLink"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        type="urlLink"
        name="urlLink"
        placeholder="https://example.com"
        pattern="https://.*"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :readonly="true"
        @click="openUrlInNewTab"
        v-on="listeners"
      >
      <!-- Email Input -->
      <input
        v-else-if="isEmail"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        type="email"
        name="email"
        placeholder="jon_doe@example.com"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="readonly"
        @focus="$emit('focus', true)"
        @input="isValid"
        v-on="listeners"
      >
      <!-- Currency Input -->
      <input
        v-else-if="isCurrency"
        :id="currencyID"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input currency-vasion-input"
        lang="en-150"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="readonly"
        @focus="$emit('focus', true)"
        v-on="listeners"
      >
      <!-- Text Area Input -->
      <textarea
        v-else-if="isTextArea"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-area"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="readonly"
        @focus="$emit('focus', true)"
        v-on="listeners"
      >
      </textarea>
      <!-- Default Input -->
      <input
        v-else
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="readonly"
        @focus="$emit('focus', true)"
        @blur="$emit('blur', true)"
        @keydown="checkForListNavigation"
        v-on="listeners"
      >
      <i v-if="!isDisabled && arrowValue" class="drop-arrow" @click="arrowClickCallback"><VasionArrowDropUpIcon /></i>
      <i v-if="!isDisabled && arrowValue === false" class="drop-arrow" @click="arrowClickCallback"><VasionArrowDropDownIcon /></i>
    </div>
    <i v-if="isURL && showURLLink" class="edit-icons pencil-icon" @click="showURLLink = !showURLLink"><VasionEditIcon /></i>
    <i v-if="isURL && !showURLLink" class="edit-icons" @click="showURLLink = !showURLLink"><VasionTextFieldIcon /></i>
  </div>
</template>
<script>
import AutoNumeric from 'autonumeric';
import { uuid } from 'vue-uuid';

export default {
  name: 'VasionInput',
  components: {
  },
  inheritAttrs: false,
  props: {
    arrowClickCallback: {
      type: Function,
      required: false,
      default: null,
    },
    arrowValue: {
      type: Boolean,
      default: null,
      required: false,
    },
    inputType: {
      type: String,
      default: 'blank-white',
      required: true,
      validator: function (value) {
        return ['top-white', 'blank-white', 'search-gray', 'search-white', 'search-white-top', 'search-on-color', 'left-white', 'title-input'].indexOf(value) !== -1
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    isInErrorState: {
      type: Boolean,
      default: false,
      required: false,
    },
    isTitleInput: {
      type: Boolean,
      default: false,
      required: false,
    },
    maxLengthProp: {
      type: Number,
      required: false,
      default: -1,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTitlePlaceholder: {
      type: Boolean,
      required: false,
      default: false,
    },
    specialType: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      default: '100%',
      required: false,
    },
    widthUnit: {
      type: String,
      default: 'px',
      required: false,
    },
  },
  data() {
    return {
      currencyID: '',
      isInputValid: true,
      showURLLink: false,
    }
  },
  computed: {
    inputGroupWidth() {
      if (this.$options.propsData.width) {
        return this.width.endsWith('%') ? this.width : `${this.width}${this.widthUnit}`
      } else {
        return this.width
      }
    },
    isCheckbox() { return this.specialType && this.specialType.toLowerCase() === 'checkbox' },
    isCurrency() { return this.specialType && this.specialType.toLowerCase() === 'currency' },
    isEmail() { return this.specialType && this.specialType.toLowerCase() === 'email' },
    isNumber() { return this.specialType && this.specialType.toLowerCase() === 'number' },
    isRadioButton() { return this.specialType && this.specialType.toLowerCase() === 'radiobutton' },
    isURL() { return this.specialType && this.specialType.toLowerCase() === 'url' },
    isTextArea() { return this.specialType && this.specialType.toLowerCase() === 'textarea' },
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit('input', event.target.value),
      }
    },
  },
  watch: {
    value: function (val) {
      this.isInputValid = this.isValid(val)
    },
  },
  mounted() {
    if (this.isCurrency) {
      // eslint-disable-next-line
      new AutoNumeric(`#${this.currencyID}`, 'NorthAmerican')
    }
    if (this.$refs.mainInput.value && this.isURL) { this.showURLLink = true }
  },
  created() {
    // this is used for an DOM ID and a querySelector.  The querySelector requires that the ID does NOT start with a number
    this.currencyID = `v${uuid.v1().toString()}`
  },
  methods: {
    calculatedClasses: function () {
      const classes = {
        input: [],
        container: [],
      }
      if (this.isDisabled && this.isInErrorState) {
        classes.input.push('expand-height-for-error')
      } else if (this.isInErrorState || !this.isInputValid) {
        classes.container.push('error-state-container')
      }
      if (this.inputType.includes('search')) {
        classes.container.push('search')
      }
      if (this.inputType === 'search-on-color') {
        classes.container.push('search-on-color-container')
        classes.input.push('search-on-color-input')
      }
      if (this.inputType.includes('white')) {
        classes.container.push('white-theme')
      }
      if (this.inputType.includes('gray')) {
        classes.container.push('gray-theme')
      }
      if (this.isDisabled) {
        classes.input.push('disabled')
      }
      if (this.isURL && this.showURLLink) {
        classes.input.push('clickable-link')
      }
      return classes
    },
    checkForListNavigation(e) {
      switch (e.code) {
        case 'ArrowUp':
          this.$emit('listNavigation', 'ArrowUp')
          break
        case 'ArrowDown':
          this.$emit('listNavigation', 'ArrowDown')
          break
        default:
          break
      }
    },
    isValid(event) {
      if (this.isURL) {
        this.isInputValid = event.target.value === '' ? true : /^(ftp|http|https):\/\/[^ "]+$/.test(event.target.value)
      } else if (this.isEmail) {
        this.isInputValid = /\S+@\S+\.\S+/.test(event.target.value)
      }
      if (!this.isInputValid) {
        this.$emit('notValid')
      } else {
        this.$emit('isValid')
      }
    },
    openUrlInNewTab(event) {
      window.open(event.target.value)
    },
    setFocus() {
        this.$refs.mainInput.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

@mixin placeholder($selector, $color) {
  #{$selector}::-webkit-input-placeholder {color: $color}
  #{$selector}::-moz-placeholder          {color: $color}
  #{$selector}:-ms-input-placeholder      {color: $color}
}

@include placeholder('.search-on-color-input', $white);

// ----- Base styles -----
  #currency-dollar-sign {
    position: relative;
    top: 10px;
    left: 6px;
  }
  .full-width {
    width: 100%
  }
  .input-group {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    border-radius: 8px;
    border: solid 1px $grey-100;
    background-color: $grey-50;
    z-index: 1;
  }

  .input-group:hover {
    border: solid 1px $grey-300;
  }

  .input-group:focus-within {
    border: solid 1px $orange-300;
  }

  .text-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    border-style: solid;
    border: none;
    font-family: $font-family-book;
    font-size: 16px;
    z-index: 12;
    padding-left: 8px;
    color: $input-text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-area {
    resize: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    border-style: solid;
    border: none;
    outline: none;
    font-family: $font-family-book;
    font-size: 16px;
    z-index: 12;
    padding-left: 8px;
    color: $input-text;
  }

  input:focus{
    outline: none;
  }
  .possition-relative {
    position: relative;
  }
  .no-padding {
    padding: 0px;
  }

  .vasion-title-input input {
    font-family: $font-family-bold;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey-500;
    margin: auto;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
// ---- Label ----
  .edit-icons {
    position: absolute;
    right: 0px;
    cursor: pointer;
  }
  .pencil-icon {
    z-index: 1;
    bottom: 35px;
  }
  .input-label-left{
    height: 12px;
    font-family: $font-family-medium 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: $grey-400;
    margin-right: 22px;
    float: left;
    padding-top: 12px;
  }

// ---- Arrow ----

  .drop-arrow {
    position: relative;
    right: 8px;
    top: 1px;
    cursor: pointer;
  }

  @media screen and (min--moz-device-pixel-ratio:0) {
  .drop-arrow {
    z-index: 12;
  }
 }

// ---- Search ----

  .search {
    & input {
      padding-left: 45px;
    }
    & > .search-icon {
      position: absolute;
      padding: 6px 0px 0px 5px;
      fill: $grey-300;
    }
  }

// Input/Search/OnColor
  .clickable-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer !important;
  }
  .error-state-label {
    color: $error-red !important;
  }
  .error-state-container {
    border: 1px solid $error-red !important;
  }

  .input-group {
    &.search-on-color-container {
      border: solid 1px rgba(255, 255, 255, 0.24);
      background-color: rgba(255, 255, 255, 0.12);
      & > .search-icon {
        fill: $white;
      }
      & > .drop-arrow {
        fill: $white;
      }
    }
  }
  .search-on-color-input{
    color: $white;
  }

  .white-theme {
    border: solid 1px $grey-100;
    background-color: $white;

    ::placeholder {
      color: $grey-300;
    }
  }

  .gray-theme {
    border: solid 1px $grey-100;
    background-color: $white !important;
  }

  .title-placeholder-div {
    height: 20px;
  }

  .isLow {
    .white-theme {
      background-color: $grey-100;
    }
    .drop-arrow {
      fill: $grey-500;
    }
    .text-input {
      color: $grey-500;
      font-size: 14px;
    }
  }

  .isNormal {
    .white-theme {
      background-color: $blue-50;
    }
    .drop-arrow {
      fill: $blue-300;
    }
    .text-input {
      color: $blue-300;
      font-size: 14px;

    }
  }

  .isHigh {
    .white-theme {
       background-color: $red-50;
    }
    .drop-arrow {
      fill: $red-300;
    }
    .text-input {
       color: $red-300;
       font-size: 14px;
    }
  }

  .disabled {
    color: $grey-400;
    background-color: $grey-75;
  }
  .expand-height-for-error {
    border: 1px solid $error-red !important;
    height: 41px;
  }

</style>
