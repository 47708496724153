<template id="calendar">
  <div class="md-layout">
    <div class="selected-date-info">
      <div class="selected-date-spacing selected-year-color">
        {{ selectedDate | formatDateToYear }}
      </div>
      <div class="selected-date-spacing">
        {{ selectedDate | formatDateToShortDayOfWeek }}
      </div>
      <div class="selected-date-spacing">
        {{ selectedDate | formatDateToShortMonthAndDay }}
      </div>
    </div>
    <div class="calendar">
      <header class="header">
        {{ currentMonthLabel }} {{ currentYear }}
        <span>
          <button @click="previousMonth"><VasionChevronLeft /></button>
          <button @click="nextMonth"><VasionChevronRight /></button>
        </span>
      </header>
      <div v-for="(dayLabel, index) in dayLabels" :key="`${dayLabel}_${index}`" class="headings">
        {{ dayLabel }}
      </div>
      <div
        v-for="(day, index) in dates"
        :key="index"
        class="day"
        :class="dayClassObj(day)"
        @click="setSelectedDate(day)"
      >
        {{ day.date | formatDateToDay }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  addDays,
  addMonths,
  getDay,
  getMonth,
  isSameDay,
  isSameMonth,
  isToday,
  format,
  lastDayOfMonth,
  setMonth,
  startOfMonth,
} from 'date-fns'

export default {
  name: 'VasionCalendar',
  filters: {
    formatDateToDay(val) {
      return val ? format(val, 'd') : ''
    },
    formatDateToShortDayOfWeek(val) {
      if (!val) {
        return ''
      }

      let shortDay = 'Sun';
      switch (val.getDay()) {
        case 1:
          shortDay = 'Mon'
          break;
        case 2:
          shortDay = 'Tue'
          break;
        case 3:
          shortDay = 'Wed'
          break;
        case 4:
          shortDay = 'Thu'
          break;
        case 5:
          shortDay = 'Fri'
          break;
        case 6:
          shortDay = 'Sat'
          break;
        default:
          shortDay = 'Sun'
          break;
      }

      return `${shortDay},`
    },
    formatDateToShortMonthAndDay(val) {
      return val ? `${format(val, 'MMM')}, ${format(val, 'dd')}` : ''
    },
    formatDateToYear(val) {
      return val ? format(val, 'yyyy') : ''
    },
  },
  props: {
    propStartDate: {
      required: false,
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      currDateCursor: null,
      dayLabels: null,
      monthLabels: null,
      selectedDate: null,
      today: null,
    };
  },
  computed: {
    currentMonth() {
      return this.currDateCursor.getMonth()
    },
    currentMonthLabel() {
      return this.monthLabels[this.currentMonth]
    },
    currentYear() {
      return this.currDateCursor.getFullYear()
    },
    dates() {
      const cursorDate = this.currDateCursor
      let startDate = startOfMonth(cursorDate)
      let endDate = lastDayOfMonth(cursorDate)

      const daysNeededForLastMonth = getDay(startDate)
      const daysNeededForNextMonth = (7 - (getDay(endDate) + 1)) > 6 ? 0 : (7 - getDay(endDate)) - 1

      startDate = addDays(startDate, -daysNeededForLastMonth)
      endDate = addDays(endDate, daysNeededForNextMonth + 1)

      let tempDate = new Date(startDate)
      const ret = []

      while (isSameDay(tempDate, endDate) === false) {
        const retDate = {
          date: new Date(tempDate),
          isCurrentMonth: isSameMonth(cursorDate, tempDate),
          isToday: isToday(tempDate),
          isSelected: this.selectedDate ? isSameDay(this.selectedDate, tempDate) : false,
        }

        ret.push(retDate)
        tempDate = addDays(tempDate, 1)
      }

      return ret
    },
  },
  watch: {
    propStartDate: function () {
      if (this.propStartDate) {
        this.currDateCursor = this.propStartDate
        this.selectedDate = this.propStartDate
      } else {
        this.selectedDate = null
        this.currDateCursor = this.today
      }
    },
  },
  created() {
    this.dayLabels = ['S', 'M', 'T', 'W', 'Th', 'F', 'S']
    this.monthLabels = [
        'January', 'February', 'March',
        'April', 'May', 'June',
        'July', 'August', 'September',
        'October', 'November', 'December']
    this.today = new Date()
    // this.selectedDate = this.today
    this.currDateCursor = this.today
  },
  mounted() {
    if (this.propStartDate) {
      this.currDateCursor = this.propStartDate
      this.selectedDate = this.propStartDate
    }
  },
  methods: {
    dayClassObj(day) {
      const payload = {
        today: day.isToday,
        selected: day.isSelected,
        outsideCurrentMonth: day.isCurrentMonth === false,
      }

      return payload
    },
    nextMonth() {
      this.currDateCursor = addMonths(this.currDateCursor, 1)
    },
    previousMonth() {
      this.currDateCursor = addMonths(this.currDateCursor, -1)
    },
    setSelectedDate(day) {
      this.selectedDate = day.date
      this.$emit('input', this.selectedDate)
      // change calendar to correct month if they select previous or next month's days
      if (!day.isCurrentMonth) {
        const selectedMonth = getMonth(this.selectedDate)
        this.currDateCursor = setMonth(this.currDateCursor, selectedMonth)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .selected-date-info {
    width: 132px;
    background-color: $plumb-300;
    color: $white;
    padding: 15px 5px 5px 10px;
    font-family: $font-family-medium;
    font-size: 24px;
    font-weight: 500;
  }

  .headings {
    color: $grey-400;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font-family: inherit;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .calendar {
    background-color: $white;
    border-radius: 3px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 275px;

    > .top {
      grid-column: 1 / span 7;
    }

    > .header {
      padding: .75rem;
      font-size: 1.25rem;
      grid-column: 1 / span 7;

      >span {
        flex: 1;
        text-align: right;
      }

      button {
        border: none;
        background: $white;
      }
    }

    > * {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    > .day {
      font-size: 1rem;
      border-radius: 50%;

      &.selected {
        background-color: $plumb-300;
        color: $orange-300;
      }

      &.outsideCurrentMonth {
        color: $grey-300 !important;
      }

      &::before {
        content: "";
        display: inline-block;
        height: 0;
        padding-bottom: 100%;
        width: 1px;
      }
    }

    > .day:hover {
      background: $orange-100;
      color: $orange-300;
    }

    > .today {
      background: $plumb-100;
      color: $orange-500;
    }
  }

  .text-center {
    text-align: center;
  }

  .selected-year-color {
    color: $plumb-200;
  }

  .selected-date-spacing {
    height: 30px;
  }

  @media ( max-width: $phone){
    .selected-date-info {
      width: 100%;
      background-color: $plumb-300;
      color: $white;
      padding: 15px 5px 5px 10px;
      font-family: $font-family-medium;
      font-size: 24px;
      font-weight: 500;
    }

    .calendar {
      width: 100%;
    }
  }
</style>
