<template>
  <div :key="refreshKey" v-observe-visibility="visibilityChanged" class="fillWidth">
    <div class="documentWrapper">
      <div
        id="canvasWrapper24"
        :style="{ width: containerWidth, height: containerHeight, transform: `scale(${zoomScale})`, 'transform-origin': transformOrigin}"
        :class="{ rotateMeImage: largeDefaultLoadSize, documentWrapperInnerNewSignature: isInNewSignatureMode , documentWrapperInner: isNotInNewSignatureMode }"
      >
        <span v-show="!dataLoaded">Loading Image...</span>
        <!-- <img class="documentImage" :style="{ width: width+'px', height: height+'px' }" v-show="dataLoaded" :src="imageSource"> -->

        <!--
          // TODO: Create a new 'DocumentImage' component called 'ThumbnailImage' and move the below code into it
        <ThumbnailCanvas
          v-if="isThumbnail && dataLoaded"
          class="documentAnnotations"
          :key="refreshKey"
          :canvasReference="'thumbnails-canvas-context'"
          :id="annotationsID"
          :canvasWidth="width"
          :canvasHeight="height">
            // TODO: Create a new 'AnnotationsCanvasContext' component called 'DocumentCanvasContext' and move the below code into it
            <AnnotationsCanvasContext
              :key="refreshKey"
              :largeDefaultLoadSize="largeDefaultLoadSize"
              :annotations="annotations"
              :image="image"
              :pageNumber="pageNumber">
            </AnnotationsCanvasContext>
        </ThumbnailCanvas>
        -->
        <AnnotationsCanvas
          v-if="!isThumbnail && dataLoaded"
          :id="annotationsID"
          ref="annoCanvas"
          :key="refreshKey"
          class="documentAnnotations"
          :class="{'smallImage': smallImage}"
          :canvasReference="'annotations-canvas-context'"
          :canvasWidth="image.Width"
          :canvasHeight="image.Height"
        >
          <AnnotationsCanvasContext
            v-if="context === 'Annotations' && annotations !== null"
            :key="refreshKey"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :annotations="annotations"
            :image="image"
            :canDelete="canDeleteAnnotations"
            :pageNumber="pageNumber"
            @backgroundImageLoaded="$emit('backgroundImageLoaded')"
          />
          <AutoSignConfigContext
            v-if="context === 'AutoSignConfig'"
            :key="refreshKey"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :image="image"
            :pageNumber="pageNumber"
          />
          <SignatureAppContext
            v-if="context === 'SignatureApp'"
            :key="pageNumber"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :image="image"
            :pageNumber="pageNumber"
            @annotationDeleted="$emit('annotationDeleted', $event)"
          />
          <AIPZonesContext
            v-if="context === 'AIPZones'"
            :key="refreshKey"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :image="image"
            :pageNumber="pageNumber"
          />
          <DocTemplateZonesContext
            v-if="context === 'DocTemplateZones'"
            :key="refreshKey"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :image="image"
            :pageNumber="pageNumber"
          />
        </AnnotationsCanvas>
      </div>
    </div>
  </div>
</template>

<script>
import AIPZonesContext from '@/components/annotations/AIPZonesContext.vue';
import AnnotationsCanvas from '@/components/annotations/AnnotationsCanvas.vue';
import AnnotationsCanvasContext from '@/components/annotations/AnnotationsCanvasContext.vue';
import AutoSignConfigContext from '@/components/annotations/AutoSignConfigContext.vue';
import DocTemplateZonesContext from '@/components/annotations/DocTemplateZonesContext.vue';
import SignatureAppContext from '@/components/annotations/SignatureAppContext.vue';
// import ThumbnailCanvas from '@/components/annotations/ThumbnailCanvas.vue';

export default {
  name: 'DocumentImage',
  components: {
    AIPZonesContext,
    AnnotationsCanvas,
    AnnotationsCanvasContext,
    AutoSignConfigContext,
    DocTemplateZonesContext,
    SignatureAppContext,
    // ThumbnailCanvas,
  },
  props: {
    burnAnnotations: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDeleteAnnotations: {
      type: Boolean,
      required: false,
      default: true,
    },
    context: {
      type: String,
      required: false,
      default: 'Annotations',
    },
    documentID: {
      required: true,
      type: Number,
    },
    fileDetail: {
      required: false,
      type: Object,
      default: null,
    },
    filePath: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      required: true,
      type: Number,
    },
    imageEndpoint: {
      type: String,
      required: false,
      default: 'document/getDocumentImageURL',
    },
    isThumbnail: {
      type: Boolean,
      required: false,
      default: false,
    },
    largeDefaultLoadSize: {
      required: false,
      type: Boolean,
      default: false,
    },
    loadOnVisible: {
      required: false,
      type: Boolean,
      default: false,
    },
    pageNumber: {
      required: true,
      type: Number,
    },
    smallImage: {
      required: false,
      type: Boolean,
      default: false,
    },
    transformOrigin: {
      required: false,
      type: String,
      default: 'center'
    },
    width: {
      required: true,
      type: Number,
    },
    zoomScale: {
      required: false,
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      annotations: null,
      archiveData: [],
      dataLoaded: false,
      image: {},
      imageSource: '',
      isVisible: false,
      leftPercent: '50%',
      refreshOnVisible: false,
    };
  },
  computed: {
    annotationsID() {
      if (this.largeDefaultLoadSize) {
        return 'annotationsCanvas_main'
      } else {
        return `annotationsCanvas_${this.pageNumber}`
      }
    },
    containerHeight() {
      // to make sure the sure can scroll far enough to see the whole document without a lot of extra space we
      // multipy the zoomValue by the images natural height and add 20 as a buffer
      return (this.zoomValue * this.image.Height + 20) + 'px'
    },
    containerWidth() {
      // to make sure the sure can scroll far enough to see the whole document without a lot of extra space we
      // multipy the zoomValue by the images natural width and add 20 as a buffer
      return (this.zoomValue * this.image.Width + 20)+'px'
    },
    imageId() { return `doc:${this.documentID}page:${this.pageNumber}` },
    isInNewSignatureMode() { return this.$route.name === 'NewDocument' },
    isInSignatureMode() { return this.$route.name !== 'SignatureEditDocument' && this.$store.state.mainViews.activeSubApp === 'signatures' },
    isNotInNewSignatureMode() { return this.$route.name !== 'NewDocument' },
    refreshKey() { return this.$store.state.document.refreshKey },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  watch: {
    pageNumber: function () {
      this.updateImageSource()
    },
  },
  async created() {
    await this.updateImageSource()
  },
  methods: {
    async updateImageSource() {
      if (this.pageNumber <= 0) {
        return
      }

      if (this.isVisible === false && this.loadOnVisible === true) {
        this.refreshOnVisible = true
        return
      }

      this.dataLoaded = false

      const existingPage = this.archiveData.find((page) => {
        return page.documentID === this.documentID && page.pageNumber === this.pageNumber
      })

      if (existingPage && existingPage.imageSource) {
        if (this.imageSource !== existingPage.imageSource) {
          this.imageSource = existingPage.imageSource
          this.$emit('imageLoaded', existingPage)
        }

        this.dataLoaded = true
        this.annotations = this.burnAnnotations ? [] : existingPage.annotations
        this.image = existingPage
        return
      }

      let payload = {}
      if (this.imageEndpoint === 'document/getDocumentImageURL') {
        payload = {
          DocumentId: this.documentID,
          VersionNumber: this.$route.params.version ? this.$route.params.version : '',
          ImageWidth: this.largeDefaultLoadSize === true ? 1210 : this.width,
          ImageHeight: this.largeDefaultLoadSize === true ? 1500 : this.height,
          IncludeAnno: this.burnAnnotations ? this.burnAnnotations : false,
          PageNum: this.pageNumber,
          RenderMode: 0,
          ThumbWidth: 100,
          ThumbHeight: 150,
          ZoomMode: 1,
          RotationDegrees: -2, // This makes it refresh the image instead of loading from cache
          FileDetail: this.fileDetail,
        }
      } else if (this.imageEndpoint === 'document/getFileImageURL') {
        if (!this.filePath || this.filePath === '') {
          console.warn('The filePath Prop is required for the "document/getFileImageURL" endpoint')
        }

        payload = {
          FilePath: this.filePath,
          ImageWidth: this.largeDefaultLoadSize === true ? 1210 : this.width,
          ImageHeight: this.largeDefaultLoadSize === true ? 1500 : this.height,
          IncludeAnno: this.burnAnnotations ? this.burnAnnotations : false,
          PageNum: this.pageNumber,
          RenderMode: 0,
          ThumbWidth: 100,
          ThumbHeight: 150,
          ZoomMode: 1,
          RotateDegrees: -2, // This makes it refresh the image instead of loading from cache
        }
      }

      this.$store.dispatch(this.imageEndpoint, payload).then(async (data) => {
        this.imageSource = data.url
        this.dataLoaded = true
        const newImage = {
          DocumentId: this.documentID,
          PageNumber: this.pageNumber,
          ImageSource: this.imageSource,
          Width: data.width,
          Height: data.height,
          Annotations: { Values: data.annotations },
          PageDPI: data.PageDPI,
          OriginalDPI: data.OriginalDPI,
          OriginalHeight: data.OriginalHeight,
          OriginalWidth: data.OriginalWidth,
          FilePath: this.filePath,
        }

        this.archiveData.push(newImage)
        const annArr = []

        this.annotations = { Values: data.annotations }
        data.annotations.forEach(e => {
          const obj = {
              AnnType: 'Rectangle',
              height: e.Height,
              width: e.Width,
              top: e.PosY,
              left: e.PosX,
              UniqueID: e.UniqueID,
              subType: e.Properties.subType,
              includeTime: e.Properties.includeTime === undefined ? false : e.Properties.includeTime,
            }
          annArr.push(obj)
        })
        this.image = newImage
        await this.$store.dispatch('workflow/storeSignatureZoneAnnotations', annArr)
      })
    },
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
      if (this.isVisible === true && this.refreshOnVisible === true) {
        const me = this
        setTimeout(() => { me.updateImageSource() }, 250)
      }
    },
  },

}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .fillWidth {
    width: 100%;
    justify-content: center;
  }

  .documentWrapper {
    width: 100%;
    height: 100%;
  }

  .documentWrapperInner {
    margin: 0 auto;
  }

  .documentAnnotations {
    background-color: rgba(255, 255, 255, .1);
    margin-bottom: 20px;
  }
</style>
