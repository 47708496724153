const allEntriesExist = function (searchInArray, searchForArray) {
  if (!searchForArray || searchForArray.length === 0) {
    return true
  }

  if (!searchInArray || searchInArray.length === 0) {
    return false
  }

  let retValue = true
  searchForArray.forEach((role) => {
    if (!searchInArray.includes(role)) {
      retValue = false
    }
  })

  return retValue
}

const atLeastOneExists = function (searchInArray, searchForArray) {
  if (!searchForArray || searchForArray.length === 0) {
    return true
  }

  if (!searchInArray || searchInArray === 0) {
    return false
  }

  let retValue = false
  searchForArray.forEach((role) => {
    if (searchInArray.includes(role)) {
      retValue = true
    }
  })

  return retValue
}

const buildAndNavigateToScanToVasionUrl = async function (actionString) {
  const scanToVasionBaseUrl = process.env.VUE_APP_SCANTOVASION_BASE
  const apiUrl = `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_API}`
  window.open(
    `${scanToVasionBaseUrl}${encodeURIComponent(apiUrl)}~${actionString}`,
    '_blank',
  )
}

const getToken = function (token) {
  return token || localStorage.getItem('UserToken')
}

const removeIllegalChars = async function (value) {
  return value.replace(/[<>:"/\\|?*]/g, '')
}

/**
 * Sorts table data and allows you to transform elements to sort on,
 * so you can sort using keys as deep as you want in each iterated object.
 */
const tableSorterWithTransform = function (elementTransform, sortTableAscending) {
  return function (a, b) {
    const item1 = elementTransform(a)
    const item2 = elementTransform(b)

    if (sortTableAscending) {
      return typeof item1 === 'string'
        ? `${(item1).localeCompare(item2)}`
        : item1 - item2
    } else {
      return typeof item1 === 'string'
        ? `${(item2).localeCompare(item1)}`
        : item2 - item1
    }
  }
}

/**
 * Sorts table data.
 *
 * @deprecated by tableSorterWithTransform
 */
const tableSorter = function (sortTableBy, sortTableAscending) {
  return function (a, b) {
    const item1 = a[sortTableBy]
    const item2 = b[sortTableBy]

    if (sortTableAscending) {
      return typeof item1 === 'string'
        ? `${(item1).localeCompare(item2)}`
        : item1 - item2
    } else {
      return typeof item1 === 'string'
        ? `${(item2).localeCompare(item1)}`
        : item2 - item1
    }
  }
}

const testDownloadHasFileExtension = async function (downloadResult, fileName, testFileExtension) {
  // eslint-disable-next-line
  const fileDownload = require('js-file-download')

  if (fileName.endsWith(testFileExtension)) {
    fileDownload(downloadResult, `${fileName}`)
  } else {
    fileDownload(downloadResult, `${fileName}${testFileExtension}`)
  }
}

const testIsGuid = async function (value) {
  if (value === null) {
    return false
  }

  const test = value.toString().match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)
  return test !== null
}

export {
  allEntriesExist,
  atLeastOneExists,
  buildAndNavigateToScanToVasionUrl,
  getToken,
  removeIllegalChars,
  tableSorter,
  tableSorterWithTransform,
  testDownloadHasFileExtension,
  testIsGuid,
}
