<template>
  <form v-if="searchFields" action="">
    <Loading
      id="loader"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-for="(field, index) in searchFields" :key="index" class="space">
      <div v-if="field.Control === 1" class="text">
        <VasionInput
          v-model="field.TextValue"
          class="input-style"
          inputType="top-white"
          :title="field.DisplayName"
        />
      </div>
      <div v-if="field.Control === 8 || field.Control === 9 || field.Control === 10" class="text">
        <VasionInput
          v-model="field.TextValue"
          class="input-style"
          inputType="top-white"
          :title="field.DisplayName"
        />
      </div>
      <div v-if="field.Control === 11" class="text">
        <VasionInput
          v-model="field.TextValue"
          class="input-style"
          inputType="top-white"
          specialType="currency"
          :title="field.DisplayName"
        />
      </div>
      <div v-if="field.Control === 13" class="text-area">
        <VasionInput
          v-model="field.TextValue"
          class="text-area"
          inputType="top-white"
          specialType="textarea"
          :title="field.DisplayName"
        />
      </div>
      <div v-if="field.DropDownValueObjects" class="dropdown">
        <VasionDropList
          v-slot="slotItem"
          ref="multiDropList"
          v-model="field.SelectedDropDownObject"
          :dataArray="field.DropDownValueObjects"
          :title="field.DisplayName"
          type="check-list"
          valueName="name"
          displayName="name"
          width="100%"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div v-if="field.Control === 3" class="date">
        <label>{{ field.DisplayName }}</label>
        <md-datepicker :id="field.value" v-model="field.DateValueUTC" :name="field.value" />
      </div>
    </div>
  </form>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'IndexSearch',
  components: {
    Loading,
  },
  props: {
    closeSearchDisplay: {
      type: Function,
      required: true,
    },
    searchID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      multiDropListSelectedItems: [],
      searchFields: [],
      searchIDLocal: 0,
    };
  },
  async created() {
    this.isLoading = true

    if (!this.$store.state.search.refineSearch) {
      await this.$store.dispatch('search/loadIndexSearchFields', this.searchID)
    }
    this.searchIDLocal = this.$store.state.search.indexSearchFields.SearchId
    // If there are no search fields, this is an automatic search using only static fields, so call the search
    if (this.$store.state.search.indexSearchFields.Fields === null)
    {
      await this.$store.dispatch('search/setSearchCriteria', { SearchId: this.searchIDLocal, Fields: [] })
      this.$store.dispatch('mainViews/toggleSavedSearchDropDown', false)
      // eslint-disable-next-line
      this.$router.push({ name: 'SearchResults' }).catch(err => {})

      return
    }
    this.searchFields = this.$store.state.search.indexSearchFields.Fields.Values.map((element) => {
      if (element.DropDownValues && element.DropDownValues.length > 0) {
        element.DropDownValueObjects = element.DropDownValues.map((value) => {
          const retObject = {
            name: value,
          }

          return retObject
        })
      }

      if (element.DropDownSelectedValues && element.DropDownSelectedValues.length > 0) {
        element.SelectedDropDownObject = element.DropDownSelectedValues.map((value) => {
          const retObject = {
            name: value,
            value: element.DropDownValues.indexOf(value),
          }

          return retObject
        })
      } else {
        element.SelectedDropDownObject = []
      }
      if (element.SelectedDropDownObject?.length > 0) {
        const items = [
          element.DisplayName,
          element.SelectedDropDownObject,
        ]
        this.multiDropListSelectedItems.push(items)
      }

      return element
    })

    this.isLoading = false
  },
  mounted() {
    if (!this.$refs.multiDropList || !this.multiDropListSelectedItems) {
      return
    }

    this.$refs.multiDropList.forEach(ref => {
      this.multiDropListSelectedItems.forEach(selectedItem => {
        // eslint-disable-next-line
        if (selectedItem[0].includes(ref.title)) {
          // eslint-disable-next-line
          ref.selectedArrayLocal = selectedItem[1]
        }
      })
    })
  },
  methods: {
    buildNewPayload() {
      this.searchFields = this.searchFields.map((element) => {
        if (element.DropDownValueObjects) {
          element.DropDownSelectedValues = []
          if (element.SelectedDropDownObject && element.SelectedDropDownObject.length > 0) {
            element.SelectedDropDownObject.forEach(obj => {
              if (obj.name !== '') {
                element.DropDownSelectedValues.push(obj.name)
              }
            })
          }
        }

        return element
      })

      const returnPayload = {
        SearchId: this.searchIDLocal,
        Fields: this.searchFields,
      }

      return returnPayload
    },
    async clear() {
      this.isLoading = true

      if (!this.searchFields) {
        this.isLoading = false
        return
      }

      await this.$store.dispatch('search/loadIndexSearchFields', this.searchID)
      this.searchFields = this.$store.state.search.indexSearchFields.Fields.Values.map(element => {
        return element
      })

      this.isLoading = false
    },
    async saveSearchCriteria() {
      await this.$store.dispatch('search/setSearchCriteria', this.buildNewPayload())
      this.$store.dispatch('mainViews/toggleSavedSearchDropDown', false)
      // eslint-disable-next-line
      this.$router.push({ name: 'SearchResults' }).catch(err => {})
    },
  },
}
</script>

<style lang="scss">
  .space {
    margin-top: 15px;
  }

  #loader {
    z-index: 1001;
  }
  .text-area {
    height: 100px;
  }
</style>
