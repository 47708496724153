<template>
  <div id="filterToggle" @mouseleave="loseFocus">
    <Loading
      id="loader"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <VasionDropList
      id="viewCustomFolder"
      v-slot="slotItem"
      v-model="viewCustomFolder"
      :dataArray="customFolderList"
      displayName="sName"
      title="View"
      @input="updateFolder"
    >
      {{ slotItem.item.sName }}
    </VasionDropList>
    <VasionCheckbox
      id="default-view"
      name="default-view"
      :checked="defaultView"
      @change="defaultView=!defaultView"
    >
      Default View
    </VasionCheckbox>
    <VasionDropList
      id="workflowDropList"
      v-slot="slotItem"
      v-model="currentlyFilteredWorkflow"
      :dataArray="workflowList"
      displayName="sName"
      title="Workflow"
      @input="updateWorkflowDropDown($event)"
    >
      {{ slotItem.item.sName }}
    </VasionDropList>

    <div v-if="isAdminUser && headerText === 'Pending' && workSpaceType === 0">
      <VasionDropList
        v-slot="slotItem"
        v-model="toggleFilterItems"
        :dataArray="filterDisplayOptions"
        type="plain-list"
        displayName="name"
        title="Filter"
        placeholder=""
        :showSearchField="false"
        class="table-header-dropdown"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>

    <div v-if="!isLoading" id="theButtons">
      <VasionButton
        id="cancel-button"
        name="cancel-button"
        :classProp="'text'"
        @vasionButtonClicked="loseFocus"
      >
        Cancel
      </VasionButton>

      <VasionButton
        id="apply-filter-button"
        name="apply-filter-button"
        :classProp="'primary'"
        @vasionButtonClicked="emitApplyFilter"
      >
        Apply Filter
      </VasionButton>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'VasionFilterToggle',
  components: {
    Loading,
  },
  props: {
    headerText: {
      type: String,
      required: false,
      default: '',
    },
    workSpaceType: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      currentlyFilteredWorkflow: '',
      customFolderList: [],
      defaultView: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      selectedWorkflowItem: '',
      toggleFilterItems: '',
      viewCustomFolder: {},
      workflowList: [],
    }
  },
  computed: {
    assignedToUserOption() { return this.$store.state.workflow.assignedToUserOption },
    customFolderViewFromStore() { return this.$store.state.workflow.customFolderView },
    customFolderViewSavedFromStore() { return this.$store.state.workflow.customFolderViewSaved },
    filterDisplayOptions() {
      return [
        { name: 'Show Items that I can see', value: 0 },
        { name: 'Show Items Assigned to Me', value: 1 },
      ]
    },
    isAdminUser() { return this.$store.getters['common/isAdminUser'] },
    viewCustomFolderList() {
      return this.viewList.map(e => {
      return {
        sName: e.folderName,
        iID: e.id,
      }
      })
    },
    viewList() { return this.$store.state.search.fullFolderSearchConfigurations },
  },
  async created() {
    this.isLoading = true

    const promises = await Promise.all([
      this.$store.dispatch('search/getFullFolderSearchConfigurations'),
      this.$store.dispatch('workflow/getWorkflowMenuItems'),
    ])
    // eslint-disable-next-line
    this.workflowList = promises[1]
    this.workflowList.unshift(
      {
        ResultMessage: null,
        ResultStatus: 1,
        iID: null,
        sName: '[All Workflows]',
      },
    )
    this.customFolderList = this.viewCustomFolderList
    this.customFolderList.unshift(
      {
        iID: 0,
        sName: '[Default]',
      },
    )

    this.viewCustomFolder = this.customFolderList.find(e => e.iID === Number(this.customFolderViewFromStore))

    this.currentlyFilteredWorkflow = await this.$store.state.workflow.workflowCurrentlyFiltered
    if (this.assignedToUserOption && this.assignedToUserOption.value !== null) {
      this.toggleFilterItems = this.filterDisplayOptions.find(e => e.value === this.assignedToUserOption.value)
    }

    this.isLoading = false
  },
  methods: {
    async emitApplyFilter() {
      this.$store.dispatch('workflow/setAssignedToUserOption', this.toggleFilterItems)
      this.$store.dispatch('workflow/setCustomFolderView', this.viewCustomFolder.iID)

      if (this.selectedWorkflowItem && this.selectedWorkflowItem !== '') {
        this.$store.dispatch('workflow/setWorkflowCurrentlyFiltered', this.selectedWorkflowItem)
      }

      if (this.defaultView) {
        this.$store.dispatch('admin/setUserSetting', {settingName: 'defaultView', settingValue: this.viewCustomFolder.iID })
      }

      this.$emit('applyFilter')
    },
    loseFocus() { this.$emit('loseFocus') },
    updateFolder() {
      this.defaultView = this.viewCustomFolder.iID == this.customFolderViewSavedFromStore
    },
    async updateWorkflowDropDown(payload) {
      this.selectedWorkflowItem = payload.sName
      await this.$store.dispatch('workflow/setWorkflowID', payload.iID)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #filterToggle {
    position: absolute;
    margin-top: 450px;
    margin-left: -353px;
    width: 392px;
    height: 400px;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 $shadow;
    background-color: #ffffff;
    padding: 15px;
    overflow: auto;
  }
  #loader {
    z-index: 5;
  }

  #theButtons {
    position: relative;
    text-align: right;
  }

  .vasion-droplist, .vasion-checkbox {
    padding-bottom: 15px;
    width: 100% !important;
  }

  .vasion-button {
    margin-left: 15px;
  }

  @media(max-width: $tablet){
    #filterToggle {
      width: 300px !important;
      margin-left: -261px !important;
    }
  }

</style>
