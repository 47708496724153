import {
  addAllTemplateTasksToDoc,
  addWorkflowComment,
  assignToWorkflow,
  assignToWorkflowWithData,
  canPerformWFAction,
  calculateDueDateAndSettings,
  deleteAutoStepSetting,
  deletePrintedFile,
  deleteTemplateTask,
  deleteTaskList,
  deleteWorkflow,
  deleteWorkflowStep,
  downloadVasionDocumentsForPrint,
  doWorkflowAction,
  getAllTaskLists,
  getAllWorkflowsWithoutEForm,
  getAllTemplateTasks,
  getCurrentApproverName,
  getMaxxFormListForWorkflow,
  getTaskListWithData,
  getTemplateTaskText,
  getThirdPartyFolderList,
  getWorkflowAdminList,
  getWorkflowAutoStepDisplayData,
  getWorkflowBusinessHours,
  getWorkflowEmailSettings,
  getWorkflowInactivitySettings,
  getWorkflowMenuItems,
  getWorkflowMenuItemsFiltered,
  getWorkflowPriorities,
  getWorkflowRecentDocuments,
  getWorkflowSecurity,
  getWorkflowSettings,
  getWorkflowUserAssignedSummary,
  getWorkStepHeaders,
  getWorkflowStepsTasksOptions,
  loadStartWorkflowPathData,
  loadWorkspaceDataTable,
  postWorkflowBusinessHours,
  postWorkflowInactivitySettings,
  postWorkflowSecurity,
  postWorkflowUserAssignedSummary,
  reassignWorkflow,
  runWorkflowBulkUpdate,
  saveAllWorkflowAutoStepSettings,
  saveTaskList,
  saveTemplateTask,
  saveWorkflowAutoStepSetting,
  saveWorkflowDueDate,
  saveWorkflowEmailSettings,
  saveWorkflowSettings,
  saveWorkflowSteps,
  saveAllWorkflowSteps,
  shouldOpenInDataView,
  uploadWorkflowAutoSignatureFile,
} from '@/store/apiModules/workflow.api'

import {
  buildWorkspaceTableData,
  buildRecentDocumentsTableData,
  formatDate,
 } from '@/store/helperModules/workflow.module'

function initialState() {
  return {
    assignedToUserOption: {
      name: '',
      value: null,
    },
    businessHours: [],
    customFolderView: 0,
    customFolderViewSaved: 0,
    designerTabErrorConfig: {
      initialLogic: false,
      initialReject: false,
      permissions: false,
      settings: false,
      statusNotification: false,
      form: false,
    },
    holidays: [],
    inactivitySettings: [],
    inboxTableData: {},
    isDataRecentDocuments: false,
    isLoading: false,
    isWorkflowDirty: false,
    maxxFormList: [],
    newWorkflowClicked: false,
    pastDaysCount: 30,
    priorities: [],
    selectedDocumentId: null,
    selectedFormId: 0,
    selectedWorkflow: {
      id: 0,
      name: '',
    },
    signatureZoneAnnotations: [],
    signatureZoneSaveObject: {},
    startWorkflowPathData: {},
    startWorkflowSuccess: -1,
    stepHeaderData: [],
    userAssignedSummary: [],
    userAssignedSummarySendTimes: [],
    workflowAdminList: [],
    workflowCurrentlyFiltered: '[All Workflows]',
    workflowDocsToPrint: [],
    workflowDueDate: '',
    workflowEmailSettings: {},
    workflowID: null,
    workflowSettings: {},
    workflowTask: '',
    workSpaceCurrentPage: 1,
    workSpaceData: {},
    workSpaceDataIsReadOnly: false,
    workSpaceDataTotalCount: 0,
    workSpacePageSize: 25,
    workSpaceSortAscending: true,
    workSpaceSortBy: '',
    workSpaceTotalPages: 1,
    workSpaceType: 0,
  }
}

const workflow = {
  namespaced: true,
  state: initialState,
  mutations: {
    clearSignatureZoneAnnotations(state) { state.signatureZoneAnnotations = {} },
    clearTabError(state, value) {
      if (value === 'settings') {
        state.designerTabErrorConfig.settings = false
      } else if (value === 'initialLogic') {
        state.designerTabErrorConfig.initialLogic = false
      } else if (value === 'statusNotification') {
        state.designerTabErrorConfig.statusNotification = false
      } else if (value === 'permissions') {
        state.designerTabErrorConfig.permissions = false
      } else if (value === 'initialReject') {
        state.designerTabErrorConfig.initialReject = false
      } else if (value == 'form') {
        state.designerTabErrorConfig.form = false
      }
    },
    clearTabErrors(state) {
      // eslint-disable-next-line
      Object.keys(state.designerTabErrorConfig).map(key => state.designerTabErrorConfig[key] = false)
    },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setAssignedToUserOption(state, value) { state.assignedToUserOption = value },
    setCustomFolderView(state, value) { state.customFolderView = (value !== undefined && value !== '') ? value : 0 },
    setCustomFolderViewSaved(state, value) { state.customFolderViewSaved = (value !== undefined && value !== '') ? value : 0 },
    setIsLoading(state, value) { state.isLoading = value },
    setIsDataRecentDocuments(state, value) { state.isDataRecentDocuments = value },
    setIsWorkflowDirty(state, value) { state.isWorkflowDirty = value },
    setMaxxFormList(state, value) { state.maxxFormList = value },
    setNewWorkflowClicked(state, value) { state.newWorkflowClicked = value },
    setSelectedDocumentId(state, value) { state.selectedDocumentId = value },
    setSelectedFormId(state, value) { state.selectedFormId = value },
    setSelectedWorkflow(state, value) { state.selectedWorkflow = value },
    setStartWorkflowPathData(state, value) { state.startWorkflowPathData = value },
    setStartWorkflowSuccess(state, value) { state.startWorkflowSuccess = value },
    setStepHeaderData(state, value) { state.stepHeaderData = value },
    setWorkflowAdminList(state, value) { state.workflowAdminList = value },
    setWorkflowBusinessHours(state, value) {
      if (value) {
        if (value.businessHours) {
          state.businessHours = value.businessHours
        }
        if (value.holidays) {
          state.holidays = value.holidays
        }
      }
    },
    setWorkflowCurrentlyFiltered(state, value) { state.workflowCurrentlyFiltered = value },
    setWorkflowDueDate(state, value) { state.workflowDueDate = value ? formatDate(value) : '' },
    setWorkflowEmailSettingsData(state, value) { state.workflowEmailSettings = value },
    setWorkflowID(state, value) { state.workflowID = value },
    setWorkflowInactivitySettings(state, value) { state.inactivitySettings = value },
    setWorkflowPriorities(state, value) { state.priorities = value },
    setWorkflowSettingsData(state, value) { state.workflowSettings = value },
    setWorkflowUserAssignedSummary(state, value) {
      state.userAssignedSummary = value
      if (value?.sendTimes) { state.userAssignedSummarySendTimes = value.sendTimes }
    },
    setWorkflowTabError(state, value) {
      if (value === 'settings') {
        state.designerTabErrorConfig.settings = true
      } else if (value === 'initialLogic') {
        state.designerTabErrorConfig.initialLogic = true
      } else if (value === 'statusNotification') {
        state.designerTabErrorConfig.statusNotification = true
      } else if (value === 'permissions') {
        state.designerTabErrorConfig.permissions = true
      } else if (value === 'initialReject') {
        state.designerTabErrorConfig.initialReject = true
      } else if (value === 'form') {
        state.designerTabErrorConfig.form = true
      }
    },
    setWorkflowTask(state, value) { state.workflowTask = value },
    setWorkSpaceCurrentPage(state, value) { state.workSpaceCurrentPage = value },
    setWorkSpaceData(state, value) { state.workSpaceData = buildWorkspaceTableData(value, state.workSpaceDataIsReadOnly === false) },
    setWorkSpaceRecentDocumentsData(state, value) { state.workSpaceData = buildRecentDocumentsTableData(value) },
    setWorkSpaceDataIsReadOnly(state, value) { state.workSpaceDataIsReadOnly = value },
    setWorkSpaceDataTotalCount(state, value) {
      state.workSpaceDataTotalCount = value
      const quotient = Math.floor(value / state.workSpacePageSize)

      state.workSpaceTotalPages = value % state.workSpacePageSize === 0 ? quotient : (quotient + 1)
    },
    setWorkSpacePageSize(state, value) { state.workSpacePageSize = value },
    setWorkSpaceSortAscending(state, value) { state.workSpaceSortAscending = value },
    setWorkSpaceSortBy(state, value) { state.workSpaceSortBy = value },
    setWorkSpaceType(state, value) { state.workSpaceType = value },
    storeSignatureZoneAnnotations(state, value) { state.signatureZoneAnnotations = value },
    storeSignatureZoneSaveObject(state, value) { state.signatureZoneSaveObject = value },
    setInactivitySetting(state, payload) { state.inactivitySettings[payload.name] = payload.value },
  },
  actions: {
    async addAllTemplateTasksToDoc({ rootState }, payload) {
      const response = await addAllTemplateTasksToDoc(rootState.common.apiToken, payload)

      if (!response || !response.data || !response.data.Tasks) {
        console.warn('Unable to add tasks')
        return null
      } else {
        return response.data
      }
    },
    async addWorkflowComment({ rootState }, payload) {
      return addWorkflowComment(rootState.common.apiToken, payload)
    },
    async assignToWorkflow({ rootState }, payload) {
      const response = await assignToWorkflow(rootState.common.apiToken, payload)

      if (!response || !response.data || !response.data.Result) {
        console.warn('Unable to assign workflow')
      }
    },
    async assignToWorkflowWithData({ rootState }, payload) {
      const response = await assignToWorkflowWithData(rootState.common.apiToken, payload)

      if (!response || !response.data) {
        console.warn('Unable to assign workflow')
        return null
      } else {
        return response.data
      }
    },
    // eslint-disable-next-line no-unused-vars
    async attemptAssignToWorkflowWithData({ dispatch }, payload) {
      const result = await dispatch('assignToWorkflowWithData', payload)

      const returnPayload = {
        assignUserGroupFilterId: 0,
        userAssignPayload: {},
      }

      if (!result) {
        return returnPayload
      }

      if (result.Result || result.retPromptKey === '') {
        return null
      }

      if (result.retPromptKey && result.retPromptData && result.retPromptData.cfg_GroupID !== undefined) {
        // A user needs to be selected from with a specific group, to which the workflow can be assigned
        const groupId = parseInt(result.retPromptData.cfg_GroupID, 10)
        returnPayload.assignUserGroupFilterId = groupId

        returnPayload.userAssignPayload = {
          documentId: payload.documentId,
          workflowId: payload.workflowId,
          userId: payload.userId,
          workflowDueDate: null,
          retPromptKey: result.retPromptKey,
          retPromptData: {
            cfg_GroupID: groupId,
          },
          promptData: {
            prmt_ChooseFromGroup: {
              cfg_UserID: 0,
            },
          },
        }
      }

      return returnPayload
    },
    async calculateDueDateAndSettings({ rootState }, workflowId) {
      let results = await calculateDueDateAndSettings(rootState.common.apiToken, workflowId)

      if (!results) {
        results = { dueDate: new Date().toLocaleString(), dueDateLabel: 'Workflow Due Date' }
      }

      if (results.dueDate) {
        results.dueDate = formatDate(results.dueDate)
      }
      return results
    },
    async canPerformWFAction({ rootState }, documentGUID) { return canPerformWFAction(rootState.common.apiToken, documentGUID) },
    clearSignatureZoneAnnotations({ commit }) { commit('clearSignatureZoneAnnotations') },
    clearTabError({ commit }, value) { commit('clearTabError', value) },
    clearTabErrors({ commit }) { commit('clearTabErrors') },
    async deleteAutoStepSetting({ rootState }, payload) { return deleteAutoStepSetting(rootState.common.apiToken, payload) },
    async deletePrintedFile({ rootState }, payload) { return deletePrintedFile(rootState.common.apiToken, payload) },
    async deleteTaskList({ rootState }, taskId) { return deleteTaskList(rootState.common.apiToken, taskId) },
    async deleteTemplateTask({ rootState }, taskId) { return deleteTemplateTask(rootState.common.apiToken, taskId) },
    async deleteWorkflow({ rootState }, workflowId) { return deleteWorkflow(rootState.common.apiToken, workflowId) },
    async deleteWorkflowStep({ rootState }, stepId) {
      const success = await deleteWorkflowStep(rootState.common.apiToken, stepId)
      if (!success) {
        console.warn(`Unable to delete workflow step ID ${stepId}`)
      }
    },
    async downloadVasionDocumentsForPrint({ rootState }, payload) {
      const response = await downloadVasionDocumentsForPrint(rootState.common.apiToken, payload)

      if (!response) {
        console.warn(`Unable to perform workflow action "${payload.UniqueName}"`)
      }

      return response
    },
    async doWorkflowAction({ rootState }, payload) {
      const response = await doWorkflowAction(rootState.common.apiToken, payload)

      if (!response) {
        console.warn(`Unable to perform workflow action "${payload.UniqueName}"`)
      }

      return response
    },
    async getAllTaskLists({ rootState }) {
      const response = await getAllTaskLists(rootState.common.apiToken)
      if (!response) {
        return []
      }

      return response
    },
    async getAllTemplateTasks({ rootState }) { return getAllTemplateTasks(rootState.common.apiToken) },
    async getAllWorkflowsWithoutEForm({ rootState }, documentId) {
      const response = await getAllWorkflowsWithoutEForm(rootState.common.apiToken, documentId)

      if (!response || !response.Values) {
        console.warn('Couldnt get workflows')
        return ''
      }

      return response.Values
    },
    async getCurrentApproverName({ rootState }, documentId) {
      const response = await getCurrentApproverName(rootState.common.apiToken, documentId)

      if (!response || !response.data || !response.data.Value || response.data.Value === null) {
        console.warn('Couldnt find workflow approver name')
        return ''
      }

      return response.data.Value
    },
    async getMaxxFormListForWorkflow({ rootState, commit }, payload) {
      const response = await getMaxxFormListForWorkflow(rootState.common.apiToken, payload)
      if (!response) {
        console.warn('Unable to get the maxx form list')
      } else {
        commit('setMaxxFormList', response)
      }

      return response
    },
    async getTaskListWithData({ rootState }, taskId) {
      const response = await getTaskListWithData(rootState.common.apiToken, taskId)

      if (!response) {
        console.warn('Couldn\'t get tasklist')
        return ''
      }

      return response
    },
    async getTemplateTaskText({ rootState }, taskId) {
      const response = await getTemplateTaskText(rootState.common.apiToken, taskId)

      if (!response) {
        console.warn('Couldn\'t get task')
        return ''
      }

      return response
    },
    async getThirdPartyFolderList({ rootState }, payload) {
      const response = await getThirdPartyFolderList(rootState.common.apiToken, payload)
      if (!response) {
        console.warn('Couldn\'t get Folder List')
        return ''
      }
      return response
    },
    async getWorkflowAdminList({ rootState, commit }) {
      const response = await getWorkflowAdminList(rootState.common.apiToken)
      commit('setWorkflowAdminList', response)
      return response
    },
    async getWorkflowAutoStepDisplayData({ rootState }, payload) { return getWorkflowAutoStepDisplayData(rootState.common.apiToken, payload) },
    async getWorkflowBusinessHours({ rootState, commit }, id) {
      const data = id > 0 ? await getWorkflowBusinessHours(rootState.common.apiToken, id) : null
      commit('setWorkflowBusinessHours', data)
    },
    async getWorkflowEmailSettings({ rootState, commit }, workflowId) {
      const workflowEmailSettingsData = await getWorkflowEmailSettings(rootState.common.apiToken, workflowId)
      commit('setWorkflowEmailSettingsData', workflowEmailSettingsData)
    },
    async getWorkflowInactivitySettings({ rootState, commit }, id) {
      const data = id > 0 ? await getWorkflowInactivitySettings(rootState.common.apiToken, id) : null
      commit('setWorkflowInactivitySettings', data)
    },
    async getWorkflowMenuItems({ rootState }) {
      const menuItems = await getWorkflowMenuItems(rootState.common.apiToken)
      return menuItems === undefined || menuItems === null ? [] : menuItems
    },
    async getWorkflowMenuItemsFiltered({ rootState }, payload) {
      const menuItems = await getWorkflowMenuItemsFiltered(rootState.common.apiToken, payload)
      return menuItems
    },
    async getWorkflowPendingTasks({ rootState, commit, dispatch }, workspaceType) {
      commit('setIsLoading', true)
      if (workspaceType !== undefined) {
        await Promise.all([
          commit('setWorkSpaceType', workspaceType),
          commit('setWorkSpaceDataIsReadOnly', workspaceType > 0),
        ])
      }
      const payload = {
        workflowID: rootState.workflow.workflowID,
        skip: (rootState.workflow.workSpaceCurrentPage - 1) * rootState.workflow.workSpacePageSize,
        returnCount: rootState.workflow.workSpacePageSize,
        sortByColumn: rootState.workflow.workSpaceSortBy,
        sortAscending: rootState.workflow.workSpaceSortAscending,
        workspaceType: rootState.workflow.workSpaceType,
        assignedToUserOption: rootState.workflow.assignedToUserOption.value,
        customFolderID: rootState.workflow.customFolderView,
      }
      const pendingTasks = await loadWorkspaceDataTable(rootState.common.apiToken, payload)
      if (pendingTasks) {
        pendingTasks.items.Rows.customFolderID = rootState.workflow.customFolderView
        await commit('setWorkSpaceDataTotalCount', pendingTasks.totalRecords)
        if (rootState.workflow.workSpaceCurrentPage > rootState.workflow.workSpaceTotalPages) {
          // we've gone past the total number of pages somehow (something got removed?) - reload to the last page
          await commit('setWorkSpaceCurrentPage', rootState.workflow.workSpaceTotalPages)
          dispatch('getWorkflowPendingTasks')
        } else {
          commit('setWorkSpaceData', pendingTasks.items)
          commit('setIsLoading', false)
        }
      } else {
        commit('setWorkSpaceData', [])
        commit('setWorkSpaceDataTotalCount', 0)
        commit('setIsLoading', false)
      }
      return pendingTasks
    },
    async getWorkflowPriorities({ rootState, commit }) {
      const priorityData = await getWorkflowPriorities(rootState.common.apiToken)
      commit('setWorkflowPriorities', priorityData)
    },
    async getWorkflowRecentDocuments({ rootState, commit }) {
      commit('setIsLoading', true)
      const payload = {
        skip: (rootState.workflow.workSpaceCurrentPage - 1) * rootState.workflow.workSpacePageSize,
        returnCount: rootState.workflow.workSpacePageSize,
        pastDaysCount: rootState.workflow.pastDaysCount,
        sortBy: rootState.workflow.workSpaceSortBy,
        descSortOrder: rootState.workflow.workSpaceSortAscending,
      }
      const recentDocuments = await getWorkflowRecentDocuments(rootState.common.apiToken, payload)
      if (recentDocuments) {
        await commit('setWorkSpaceDataTotalCount', recentDocuments.totalRecords)
        commit('setWorkSpaceRecentDocumentsData', recentDocuments.items)
        commit('setIsLoading', false)
      } else {
        commit('setWorkSpaceData', [])
        commit('setWorkSpaceDataTotalCount', 0)
        commit('setIsLoading', false)
      }
      return recentDocuments
    },
    async getWorkflowSecurity({ rootState }, workflowID) { return getWorkflowSecurity(rootState.common.apiToken, workflowID) },
    async getWorkflowSettings({ rootState, commit }, workflowId) {
      const workflowSettingsData = await getWorkflowSettings(rootState.common.apiToken, workflowId)
      commit('setWorkflowSettingsData', workflowSettingsData)
      commit('setSelectedWorkflow', { id: workflowId, name: workflowSettingsData.name })
    },
    async getWorkflowSettingsNoCommit({ rootState }, workflowId) {
      const workflowSettingsData = await getWorkflowSettings(rootState.common.apiToken, workflowId)
      return workflowSettingsData
    },
    async getWorkflowStepsTasksOptions({ rootState }) { return getWorkflowStepsTasksOptions(rootState.common.apiToken) },
    async getWorkflowUserAssignedSummary({ rootState, commit }, id) {
      const data = id > 0 ? await getWorkflowUserAssignedSummary(rootState.common.apiToken, id) : null
      commit('setWorkflowUserAssignedSummary', data)
    },
    async getWorkStepHeaders({ rootState, commit }, workflowId) {
      const stepData = await getWorkStepHeaders(rootState.common.apiToken, workflowId)
      commit('setStepHeaderData', stepData)
    },
    async loadStartWorkflowPathData({ rootState, commit }, payload) {
      const data = await loadStartWorkflowPathData(rootState.common.apiToken, payload)
      commit('setStartWorkflowPathData', data)
    },
    reassignWorkflow({ rootState }, payload) { return reassignWorkflow(rootState.common.apiToken, payload) },
    async runWorkflowBulkUpdate({ rootState }, payload) {
      return runWorkflowBulkUpdate(rootState.common.apiToken, payload)
    },
    resetStartWorkflowSuccess({ commit }) { commit('setStartWorkflowSuccess', -1) },
    async saveAllWorkflowAutoStepSettings({ rootState }, payload) {
      const response = await saveAllWorkflowAutoStepSettings(rootState.common.apiToken, payload)
      return response
    },
    async saveAllWorkflowSteps({ rootState }, payload) {
      const response = await saveAllWorkflowSteps(rootState.common.apiToken, payload)

      if (!response || !response.data || !response.data.Values || response.data.Values === null) {
        console.warn('Unable to save workflow steps')
        return false
      }

      return true
    },
    async saveWorkflowAutoStepSetting({ rootState }, payload) { return saveWorkflowAutoStepSetting(rootState.common.apiToken, payload) },
    async saveWorkflowDueDate({ rootState }, payload) {
      const response = await saveWorkflowDueDate(rootState.common.apiToken, payload)
      if (!response || !response.Value || response.Value !== 'True') {
        console.warn('Unable to save workflow due date')
      }
      return response
    },
    async saveTaskList({ rootState }, payload) { return saveTaskList(rootState.common.apiToken, payload) },
    async saveTemplateTask({ rootState }, payload) { return saveTemplateTask(rootState.common.apiToken, payload) },
    async saveWorkflowEmailSettings({ rootState }, payload) {
      const response = await saveWorkflowEmailSettings(rootState.common.apiToken, payload)

      if (!response || !response.data || !response.data.Value || (response.data.Value !== payload.workflowID.toString() && response.data.Value !== 'True')) {
        console.warn('Unable to save workflow email settings')
        return false
      }

      return true
    },
    async saveWorkflowSecurity({ rootState }, payload) { return postWorkflowSecurity(rootState.common.apiToken, payload) },
    async saveWorkflowSettings({ rootState }, payload) {
      const response = await saveWorkflowSettings(rootState.common.apiToken, payload)

      if (!response || !response.data || !response.data.Value
        || (payload.wfID !== undefined && response.data.Value !== payload.wfID.toString() && response.data.Value !== 'True')) {
        console.warn('Unable to save workflow settings')
        return 0
      }

      return response.data.Value
    },
    async saveWorkflowSteps({ rootState }, payload) {
      const response = await saveWorkflowSteps(rootState.common.apiToken, payload)

      if (!response || !response.data || !response.data.Value) {
        if ((payload.stepId > 0 && ((response.data.Value !== payload.stepID.toString() && response.data.Value !== 'True'))) // Updating existing step
            || (payload.stepID === 0 && (response.data.Value <= 0 && response.data.Value !== 'True'))) { // Inserting new step
          console.warn('Unable to save workflow step')
        }
      }

      return response.data.Value
    },
    async selectWorkflow({ commit, dispatch }, workflowId) {
      if (workflowId !== 0) {
        dispatch('getWorkflowSettings', workflowId)
        dispatch('getWorkflowEmailSettings', workflowId)
        dispatch('getWorkStepHeaders', workflowId)
        dispatch('getWorkflowBusinessHours', workflowId)
        dispatch('getWorkflowInactivitySettings', workflowId)
        dispatch('getWorkflowUserAssignedSummary', workflowId)
      } else {
        commit('setSelectedWorkflow', { id: 0, name: 'Untitled Workflow' })
        commit('setWorkflowSettingsData', {})
        commit('setStepHeaderData', [])
        commit('setWorkflowEmailSettingsData', {})
      }
    },
    setAssignedToUserOption({ commit }, value) { commit('setAssignedToUserOption', value) },
    setCustomFolderView({ commit }, value) { commit('setCustomFolderView', value) },
    setCustomFolderViewSaved({ commit }, value) { commit('setCustomFolderViewSaved', value) },
    setIsLoading({ commit }, value) { commit('setIsLoading', value) },
    setIsDataRecentDocuments({ commit }, value) { commit('setIsDataRecentDocuments', value) },
    setIsWorkflowDirty({ commit }, isDirty) { commit('setIsWorkflowDirty', isDirty) },
    setNewWorkflowClicked({ commit }, value) { commit('setNewWorkflowClicked', value) },
    setSelectedDocumentId({ commit }, value) { commit('setSelectedDocumentId', value) },
    setSelectedFormId({ commit }, value) { commit('setSelectedFormId', value) },
    setStartWorkflowSuccess({ commit }, value) { commit('setStartWorkflowSuccess', value) },
    setWorkflowCurrentlyFiltered({ commit }, workflowCurrentlyFiltered) { commit('setWorkflowCurrentlyFiltered', workflowCurrentlyFiltered) },
    async setWorkflowDueDate({ commit }, dueDate) { commit('setWorkflowDueDate', dueDate) },
    setWorkflowID({ commit }, workflowID) { commit('setWorkflowID', workflowID) },
    setWorkflowTabError({ commit }, dueDate) { commit('setWorkflowTabError', dueDate) },
    setWorkSpaceCurrentPage({ commit }, pageNumber) { commit('setWorkSpaceCurrentPage', pageNumber) },
    setWorkSpacePageSize({ commit }, pageSize) { commit('setWorkSpacePageSize', pageSize) },
    setWorkSpaceSortAscending({ commit }, ascending) { commit('setWorkSpaceSortAscending', ascending) },
    setWorkSpaceSortBy({ commit }, sortBy) { commit('setWorkSpaceSortBy', sortBy) },
    setWorkSpaceType({ commit }, workSpaceType) { commit('setWorkSpaceType', workSpaceType) },
    setWorkflowTask({ commit }, taskType) { commit('setWorkflowTask', taskType) },
    async shouldOpenInDataView({ rootState }, docIDorGUID) { return shouldOpenInDataView(rootState.common.apiToken, docIDorGUID) },
    storeSignatureZoneAnnotations({ commit }, annotations) { commit('storeSignatureZoneAnnotations', annotations) },
    async storeSignatureZoneSaveObject({ commit }, zones) { commit('storeSignatureZoneSaveObject', zones) },
    async updateInactivitySettings({ rootState }, payload) { return postWorkflowInactivitySettings(rootState.common.apiToken, payload) },
    async updateSingleInactivitySetting({ commit }, payload) { commit('setInactivitySetting', payload) },
    async updateUserAssignedSummary({ rootState }, payload) { return postWorkflowUserAssignedSummary(rootState.common.apiToken, payload) },
    async updateWorkflowBusinessHours({ rootState }, payload) { return postWorkflowBusinessHours(rootState.common.apiToken, payload) },
    async uploadWorkflowAutoSignatureFile({ rootState }, payload) { return uploadWorkflowAutoSignatureFile(rootState.common.apiToken, payload) },
  },
}

export default workflow;
