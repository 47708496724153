/* eslint-disable consistent-return */
import { createAxiosInstance } from '@/services/axiosService'

const copyFolderStructure = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Folder/CopyFolderStructure', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const deleteCustomFolderView = async (apiToken, searchID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/admin/DeleteCustomFolderView', {
      data: {
        Value: searchID.toString(),
      },
    })

    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteThirdPartyStorageConfiguration = async (apiToken, searchID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete(`v1/admin/DeleteThirdPartyStorageConfiguration?Value=${searchID}`)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getConfiguredDriveTypes = async (apiToken, showOnlyDataAutomation) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`/v1/Folder/GetConfiguredDriveTypes?ShowOnlyDataAutomation=${showOnlyDataAutomation}`)
    if (data?.driveTypes) {
      return data.driveTypes
    }
  } catch (error) {
    console.warn(error)
  }
}

const getAvailableThirdPartyStorageConfigurationTypes = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetAvailableThirdPartyStorageConfigurationTypes')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getThirdPartyStorageConfigurations = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetThirdPartyStorageConfigurations')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUniqueFieldNames = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetUniqueFieldNames')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getVasionDriveFolderId = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Folder/GetWFFolderID')
  } catch (error) {
    console.warn(error)
  }
}

const renameSubfolder = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Folder/RenameSubfolder', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const setThirdPartyStorageConfiguration = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Admin/SetThirdPartyStorageConfiguration', payload)
    return data
  } catch (error) {
    console.warn(error)
    return error
  }
}

const testAmazonStorageConnection = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Admin/TestAmazonStorageConnection', payload)
    return data
  } catch (error) {
    console.warn(error)
    return error
  }
}

const uploadFileToVasion = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Upload/UploadDocument', payload)
  } catch (error) {
    console.warn(error)
  }
}

const uploadNewVersion = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Upload/OverwriteVersion', payload)
  } catch (error) {
    console.warn(error)
  }
}

export {
  copyFolderStructure,
  deleteCustomFolderView,
  deleteThirdPartyStorageConfiguration,
  getConfiguredDriveTypes,
  getUniqueFieldNames,
  getAvailableThirdPartyStorageConfigurationTypes,
  getThirdPartyStorageConfigurations,
  getVasionDriveFolderId,
  renameSubfolder,
  setThirdPartyStorageConfiguration,
  testAmazonStorageConnection,
  uploadFileToVasion,
  uploadNewVersion,
}
