<template>
  <div id="app-picker">
    <div class="group-row">
      <div
        id="apps-workspace"
        class="app-group"
        :class="{ selected: defaultApp === 'workspace'}"
        @click="changeDefaultApp('workspace')"
      >
        <VasionAppWorkspace />
        <label class="app-label">Workspace</label>
      </div>
      <div
        v-if="canAccessReportsApp"
        id="apps-reports"
        class="app-group"
        :class="{ selected: defaultApp === 'reports'}"
        @click="changeDefaultApp('reports')"
      >
        <VasionAppReports />
        <label class="app-label">Reports</label>
      </div>
      <div
        id="apps-storage"
        class="app-group"
        :class="{ selected: defaultApp === 'storage'}"
        @click="changeDefaultApp('storage')"
      >
        <VasionAppStorage />
        <label class="app-label">Storage</label>
      </div>
      <div
        v-if="canAccessCaptureApp"
        id="apps-capture"
        class="app-group"
        :class="{ selected: defaultApp === 'capture'}"
        @click="changeDefaultApp('capture')"
      >
        <VasionAppCapture />
        <label class="app-label">Capture</label>
      </div>
      <div
        v-if="canAccessDigitalSignature"
        id="apps-signatures"
        class="app-group"
        :class="{ selected: defaultApp === 'signature'}"
        @click="changeDefaultApp('signature')"
      >
        <VasionAppSignature />
        <label class="app-label">Signature</label>
      </div>
      <div
        v-if="canAccessFormsApp"
        id="apps-forms"
        class="app-group"
        :class="{ selected: defaultApp === 'forms'}"
        @click="changeDefaultApp('forms')"
      >
        <VasionAppForms />
        <label class="app-label">Forms</label>
      </div>
      <div
        v-if="canAccessTemplatesApp"
        id="apps-templates"
        class="app-group"
        :class="{ selected: defaultApp === 'templates'}"
        @click="changeDefaultApp('templates')"
      >
        <VasionAppTemplates />
        <label class="app-label">Templates</label>
      </div>
      <div
        v-if="canAccessAutomationApp"
        id="apps-automation"
        class="app-group"
        :class="{ selected: defaultApp === 'automation'}"
        @click="changeDefaultApp('automation')"
      >
        <VasionAppAutomation />
        <label class="app-label">Automation</label>
      </div>
      <div
        v-if="canAccessWorkflowApp"
        id="apps-workflow"
        class="app-group"
        :class="{ selected: defaultApp === 'workflow'}"
        @click="changeDefaultApp('workflow')"
      >
        <VasionAppWorkflow />
        <label class="app-label">Workflow</label>
      </div>
      <div
        v-if="canAccessAdminApp"
        id="apps-admin"
        class="app-group"
        :class="{ selected: defaultApp === 'admin'}"
        @click="changeDefaultApp('admin')"
      >
        <VasionAppAdmin />
        <label class="app-label">Admin</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultAppPicker',
  data: function () {
    return {
      defaultApp: '',
    }
  },
  computed: {
    canAccessAdminApp() { return this.$store.getters['common/canAccessAdminApp_RolesOnly'] },
    canAccessAutomationApp() { return this.$store.getters['common/canAccessAutomationApp_RolesOnly'] },
    canAccessCaptureApp() { return this.$store.getters['common/canAccessCaptureApp_RolesOnly'] },
    canAccessCustomReports() { return this.$store.getters['common/canAccessCustomReports'] },
    canAccessDigitalSignature() { return this.$store.getters['common/canAccessDigitalSignature_RolesOnly'] },
    canAccessFormsApp() { return this.$store.getters['common/canAccessFormsApp_RolesOnly'] },
    canAccessReportsApp() { return this.$store.getters['common/canAccessReportsApp_RolesOnly'] },
    canAccessTemplatesApp() { return this.$store.getters['common/canAccessTemplatesApp_RolesOnly'] },
    canAccessWorkflowApp() { return this.$store.getters['common/canAccessWorkflowApp_RolesOnly'] },
    customReports() { return this.$store.state.reporting.customReports },
    originalDefaultApp() { return this.$store.state.admin.defaultApp },
  },
  watch: {
    originalDefaultApp: function (newVal) {
      this.defaultApp = newVal
    },
  },
  created: function () {
    this.$store.dispatch('admin/getUserSetting', 'defaultApp')
    this.setOriginalDefaultAppToLocal()
  },
  methods: {
    async changeDefaultApp(appName) {
      if (this.defaultApp === appName) {
        this.defaultApp = ''
      } else {
        this.defaultApp = appName
      }
      this.$store.dispatch('admin/defaultAppSelection', this.defaultApp)
    },
    setOriginalDefaultAppToLocal() {
      this.defaultApp = this.originalDefaultApp
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  h2 {
    font-family: $font-family-black;
    font-size: 20px;
  }

  #app-picker {
    @include Text;
    background-color: $white;
    border-radius: 4px;
    padding: 12px 17px;
    z-index: 1000;
  }
  .group-row {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
    flex-wrap: wrap;
  }
  .app-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    height: 115px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    z-index: 20;
  }
  .icon-box {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: $grey-300;
    z-index: 20;
  }
  .app-label {
    font-size: 14px;
    margin-top: 5px;
  }

  .group-row .app-group:hover {
    background-color: $grey-100;
  }
  .selected {
    background-color: $orange-50;
  }

</style>
