<template>
  <div id="permissions-body">
    <div>
      <VasionListSelection
        :available-fields.sync="availableUsers"
        :selected-fields.sync="assignedUsers"
        :allow-field-ordering="false"
        :available-label-text="'All Users'"
        :selected-label-text="'Permitted Users'"
        :unique-id="'permissions-users'"
        @update:availableFields="emitParentFolderIsDirty"
        @update:selectedFields="emitParentFolderIsDirty"
        @update:addAll="emitParentFolderIsDirty"
        @update:removeAll="emitParentFolderIsDirty"
      />
    </div>
    <div class="permissions-separator-div" />    
    <div>
      <VasionListSelection
        :available-fields.sync="availableGroups"
        :selected-fields.sync="assignedGroups"
        :allow-field-ordering="false"
        :available-label-text="'All Groups'"
        :selected-label-text="'Permitted Groups'"
        :unique-id="'permissions-groups'"
        @update:availableFields="emitParentFolderIsDirty"
        @update:selectedFields="emitParentFolderIsDirty"
        @update:addAll="emitParentFolderIsDirty"
        @update:removeAll="emitParentFolderIsDirty"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParentFolderPermissions',
  data: function () {
    return {
      assignedGroups: [],
      assignedUsers: [],
      availableGroups: [],
      availableUsers: [],
      values: {},
    }
  },
  computed: {
    groupList() { return this.$store.state.security.groupList },
    userList() { return this.$store.state.security.userList },
  },
  mounted: function () {
    this.reloadParentFolderData()
  },
  methods: {
    buildPermissionsSaveDataJSON() {
      const payload = {
        assignedUsers: this.assignedUsers.map((user) => user.value),
        assignedGroups: this.assignedGroups.map((group) => group.value),
      }

      return payload
    },
    buildUsersAndGroups(values) {
      if (values && values.userAccess && values.userAccess.length > 0) {
        values.userAccess.forEach((element) => {
          const aUser = this.availableUsers.find(user => user.value === element)
          this.assignedUsers.push(aUser)
          this.availableUsers = this.availableUsers.filter(user => user.value !== element)
        })
      }
      if (values && values.groupAccess && values.groupAccess.length > 0) {
        values.groupAccess.forEach(element => {
          const aGroup = this.availableGroups.find(group => group.value === element)
          this.assignedGroups.push(aGroup)
          this.availableGroups = this.availableGroups.filter(group => group.value !== element)
        })
      }
    },
    emitParentFolderIsDirty() {
      this.updateParentFolderPermissionsPayload()
      this.$emit('markAsDirty', true)
    },
    async reloadParentFolderData() {
      await Promise.all([
        this.$store.dispatch('security/loadUserList'),
        this.$store.dispatch('security/loadGroupList')
      ])

      this.assignedGroups = []
      this.assignedUsers = []
      this.availableGroups = []
      this.availableUsers = []

      this.groupList.forEach((element) => {
        this.availableGroups.push({
          value: element.ID,
          name: element.Name,
        })
      })

      this.userList.forEach((element) => {
        this.availableUsers.push({
          value: element.UserId,
          name: element.Username,
        })
      })
    },
    setValues(values) {
      if (this.availableUsers.length > 0) {
        this.buildUsersAndGroups(values)
      } else {
        this.values = values
      }

      this.updateParentFolderPermissionsPayload()
    },
    updateParentFolderPermissionsPayload() {
      const payload = this.buildPermissionsSaveDataJSON()
      this.$store.dispatch('admin/setParentFolderPermissions', payload)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .permissions-separator-div {
    height: 15px;
  }
</style>
