<template>
  <div class="rename-container">
    <div class="rename-folder-title">
      <h2>{{ name }}</h2>
    </div>
    <div id="users-groups-search" class="rename-input">
      <VasionInput
        id="run-on-machine"
        v-model="newName"
        class="row-field"
        title="Folder Name"
        inputType="top-white"
        name="run-on-machine"
        placeholder="Enter new name..."
      />
    </div>
    <div class="rename-buttons">
      <VasionButton
        id="save-button"
        class="add-cancel"
        name="save-button"
        classProp="primary"
        :isDisabled="canSave"
        @vasionButtonClicked="save"
      >
        Save
      </VasionButton>

      <VasionButton
        id="cancel-button"
        class="add-cancel"
        name="cancel-button"
        classProp="secondary"
        @vasionButtonClicked="cancel"
      >
        Cancel
      </VasionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VasionDownloadOptions',
  props: {
    name: {
      type: String,
      default: 'Default Name',
      required: false,
    },
  },
  data: function () {
    return {
        newName: '',
    }
  },
  computed: {
      canSave() { return this.newName && this.newName > 0 },
  },
  methods: {
    cancel() {
      this.$emit('cancelClick')
    },
    save() {
      this.$emit('saveClick', this.newName)
    },
  },
}
</script>
<style lang="scss" scoped>
.rename-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    width: 530px;
    height: 200px;
  }
  .rename-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .rename-input, .rename-folder-title, .rename-buttons{
    width: 100%;
  }
</style>
