<template>
  <div id="local-drive-upload">
    <div id="drag-file-contents">
      <div class="local-file-pond-block-wrapper">
        <file-pond
          ref="pond"
          class="new-document"
          :server="serverOptions"
          :label-idle="filePondLabel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  name: 'LocalDriveUpload',
  components: {
    FilePond,
  },
  data: function () {
    return {
      myFiles: [],
      serverOptions: {
        process: this.processHandler,
      },
    }
  },
  computed: {
    filePondLabel() {
      return `<span class="filepond-drag">Drag File Here</span>
              <br /><br/>
              <span class="filepond-drag-or">or</span>
              <br /><br />
              <span class="filepond-label-action">Click to Upload New File</span>`
    },
    vasionDriveFolderId: function () { return this.$store.state.storage.vasionDriveFolderId },
  },
  created() {
    this.$store.dispatch('storage/getVasionDriveFolderId')
  },
  methods: {
    // eslint-disable-next-line
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      const payload = {
        folderId: this.vasionDriveFolderId,
        name: file.name,
        fileData: file,
      }
      try {
        await this.$store.dispatch('storage/saveUploadedFilePayloadInMemory', payload)

        progress(true, 0, 1024);
        load(file.name);

        this.$emit('fileUploaded')
      } catch (e) {
        console.warn(e)
      }

      return {
        abort: () => {
          abort();
        },
      };
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .local-file-pond-block-wrapper {
    width: 100%;
  }

  .row {
    width: 100%;
  }

  .select-location {
    display: flex;
  }

  #local-drive-upload {
    #drag-file-contents {
      width: 480px;
      display: flex;
      flex-direction: column;
      align-items: center;

      #drag-file-text {
        @include SubHeadline;
        display: block;
        width: 480px;
        text-align: center;
        margin: 0 auto;
        color: $grey-400;
      }
    }
  }

  @media (max-width: $phone){
    #local-drive-upload{
      #drag-file-contents{
        width: 300px;
      }
      #drag-file-text {
        width: 300px !important;
      }
    }
  }
</style>
