/* eslint-disable linebreak-style */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
// import { defaultComponent } from '@/defaults/default.JSON';
import {
  checkIfFieldIsUsedInAForm,
  checkIfUserCanAccessEForm,
  getAllFieldHeaders,
  getCreateTempDocsByEformID,
  getCreateTempDocsByFormID,
  getEform,
  getEFormDocTemplateFields,
  getEFormFieldsFromFormIOJson,
  getEFormsByFormID,
  getEFormExternalSettings,
  getFormBuilderConfigByFormID,
  saveEForm,
  saveEFormDocTemplateDefaultMapping,
  saveEFormExternalSettings,
  saveEFormDocTemplateMapping,
  saveNewField,
  updateFieldInEForms,
 } from '@/store/apiModules/forms.api'

function initialState() {
  return {
    activeFormIoJSON: { display: 'form', components: [] },
    activeId: 0,
    allFieldHeaders: null,
    areFormsDirty: false,
    builderConfigFromFormID: {},
    createTempDocsByFormID: [],
    defaultFormTab: '',
    desiredPendingRoute: '',
    docExternalSettings: {
      headerImageBase64: '',
      headerImageFileName: '',
      hexColor: '#ffffff',
    },
    eForm: null,
    eFormDocTemplateFields: null,
    eFormDocTemplateMapping: null,
    filePath: '',
    filterValue: '',
    newSelectedTemplates: [],
    originalbuilderConfigFromFormID: {},
    tempDocsForEform: [],
  }
}

const forms = {
  namespaced: true,
  state: initialState,
  mutations: {
    clearEform(state) {
      state.activeFormIoJSON = { display: `${$formsLabel}`, components: [] }
      state.eForm = null
      state.eFormDocTemplateFields = null
      state.eFormDocTemplateMapping = null
      state.createTempDocsByFormID = []
      state.tempDocsForEform = []
    },
    filterFormBuilderconfigByFormID(state, value) { state.filterValue = value },
    saveEFormDocTemplateMapping(state, value) { state.eFormDocTemplateMapping = value },
    saveEFormExternalSettings(state, value) { state.eFormDocTemplateMapping = value },
    setActiveFormIoJSON(state, value) {
      const newVal = value
      newVal.display = 'form'
      state.activeFormIoJSON = newVal
    },
    setAllFieldHeaders(state, value) { state.allFieldHeaders = value },
    setAreFormsDirty(state, value) { state.areFormsDirty = value },
    setCreateTempDocsByEFormID(state, value) { state.tempDocsForEform = value },
    setCreateTempDocsByFormID(state, value) {
      const returnArray = []
      Object.keys(value).map(key => {
        returnArray.push({ id: key, name: value[key], value: key })
      })
      state.createTempDocsByFormID = returnArray
    },
    setDefaultFormTab(state, value) { state.defaultFormTab = value },
    setDesiredPendingRoute(state, value) { state.desiredPendingRoute = value },
    setEform(state, value) { state.eForm = value },
    setEformDocTemplateFields(state, value) { state.eForm = value },
    setEformDocument(state, value) { state.eForm = value },
    setEFormExternalSettings(state, value) { state.docExternalSettings = value },
    setFormBuilderconfigByFormID(state, value) {
      if (value.Value !== '') {
        let cleanedValue = value.Value
        try { JSON.parse(cleanedValue) } catch { cleanedValue = value.Value.replaceAll("\\", "\\\\") }
        state.builderConfigFromFormID = JSON.parse(cleanedValue)
        state.originalbuilderConfigFromFormID = JSON.parse(cleanedValue)
      }
    },
    updateActiveForm(state, value) {
      value.key = `${value.key}${state.activeFormIoJSON.components.length}`
      state.activeFormIoJSON.components.push(value)
    },
  },
  actions: {
    async checkIfFieldIsUsedInAForm({ rootState }, payload) { return checkIfFieldIsUsedInAForm(rootState.common.apiToken, payload) },
    // eslint-disable-next-line
    async checkIfUserCanAccessEForm({ rootState }, value) {
      const data = await checkIfUserCanAccessEForm(rootState.common.apiToken, value)
      return data?.Value === 'True'
    },
    clearEform({ commit }) { commit('clearEform') },
    filterFormBuilderconfigByFormID({ commit }, value) { commit('filterFormBuilderconfigByFormID', value) },
    async getAllFieldHeaders({ commit, rootState }) {
      const data = await getAllFieldHeaders(rootState.common.apiToken)
      if (data) {
        commit('setAllFieldHeaders', data.fields)
      }
      return data
    },
    async getCreateTempDocsByEFormID({ commit, rootState }, formId) {
      const data = await getCreateTempDocsByEformID(rootState.common.apiToken, formId)
      if (data) {
        commit('setCreateTempDocsByEFormID', data.DocTemplates)
      }
      return data
    },
    async getCreateTempDocsByFormID({ commit, rootState }, formId) {
      const data = await getCreateTempDocsByFormID(rootState.common.apiToken, formId)
      if (data) {
        commit('setCreateTempDocsByFormID', data.Values)
      }
    return data
    },
    async getEForm({ commit, rootState }, formId) {
      try {
        const response = await getEform(rootState.common.apiToken, formId)
        commit('setEform', response)
        if (response.FormIOJSON) {
          await commit('setActiveFormIoJSON', JSON.parse(response.FormIOJSON))
        }
      } catch (error) {
        console.warn(error)
      }
    },
    async getEFormAccessibility({ commit, dispatch, rootState }, formId) {
      await dispatch('common/resetAxiosDefaultBaseURL', { root: true })
      try {
        const response = await getEform(rootState.common.apiToken, formId)
        commit('setEform', response)
        if (response.PublicAccessible) {
          return response.PublicAccessible
        } else {
          return false
        }
      } catch (error) {
        console.warn(error)
        return false
      }
    },
    async getEFormDocTemplateFields({ rootState }, payload) { return getEFormDocTemplateFields(rootState.common.apiToken, payload) },
    async getEFormExternalSettings({ commit, rootState }, formID) {
      const response = await getEFormExternalSettings(rootState.common.apiToken, formID)
      commit('setEFormExternalSettings', response)
    },
    async getEFormFieldsFromFormIOJson({ state, rootState }, id) {
      const payload = {
        eformID: id,
        formIOJson: JSON.stringify(state.activeFormIoJSON),
        why: '',
      }
      return getEFormFieldsFromFormIOJson(rootState.common.apiToken, payload)
    },
    async getEFormsByFormID({ rootState }, formID) {
      const payload = {
       Value: formID,
      }
      return getEFormsByFormID(rootState.common.apiToken, payload)
    },
    async getFormBuilderConfigByFormID({ commit, rootState }, formID) {
      const response = await getFormBuilderConfigByFormID(rootState.common.apiToken, formID)
      commit('setFormBuilderconfigByFormID', response)
    },
    async saveEForm({ commit, rootState }, payload) {
      const response = await saveEForm(rootState.common.apiToken, payload)
      commit('setEform', response)
      return response
    },
    async saveEFormDocTemplateDefaultMapping({ commit, rootState }, payload) {
      const response = await saveEFormDocTemplateDefaultMapping(rootState.common.apiToken, payload)
      commit('saveEFormDocTemplateMapping', response)
      return response
    },
    async saveEFormDocTemplateMapping({ commit, rootState }, payload) {
      const response = await saveEFormDocTemplateMapping(rootState.common.apiToken, payload)
      commit('saveEFormDocTemplateMapping', response)
      return response
    },
    async saveEFormExternalSettings({ dispatch, rootState }, payload) {
      const response = await saveEFormExternalSettings(rootState.common.apiToken, payload)
      dispatch('getEFormExternalSettings', response)
      return response
    },
    async saveNewField({ dispatch, rootState }, payload) {
      const response = await saveNewField(rootState.common.apiToken, payload)
      dispatch('getFormBuilderConfigByFormID', payload.formID)
      return response
    },
    async setActiveFormIoJSON({ commit }, config) { await commit('setActiveFormIoJSON', config) },
    setDefaultFormTab({ commit }, defaultTabName) { commit('setDefaultFormTab', defaultTabName) },
    setDesiredPendingRoute({ commit }, value) { commit('setDesiredPendingRoute', value) },
    updateActiveForm({ commit }, value) { return commit('updateActiveForm', value) },
    updateAreFormsDirty({ commit }, value) { return commit('setAreFormsDirty', value) },
    async updateFieldInEForms({ rootState }, payload) { return updateFieldInEForms(rootState.common.apiToken, payload) },
  },
  getters: {
    availableTemplates(state, getters) { return [...state.createTempDocsByFormID].filter(t => !getters.selectedTemplates.find(({ id }) => id === t.id)) },
    filterFormBuilderconfigByFormIDGetter(state, getters) {
      if (state.filterValue === '') { return getters.setAvailableBuilderComponentsGetter } else {
        return getters.setAvailableBuilderComponentsGetter.filter(obj => obj.title.toLocaleLowerCase().includes(state.filterValue.toLocaleLowerCase())) // Do the fliter on the title property
      }
    },
    setAvailableBuilderComponentsGetter(state) {
      const originalBuilderConfigArray = []
      // eslint-disable-next-line
      for (const [key, objValue] of Object.entries(state.originalbuilderConfigFromFormID)) { // Create an array of objects from the original config and add the original key to that new object
        originalBuilderConfigArray.push({ originalKey: key, ...objValue })
      }
      return originalBuilderConfigArray.filter(obj => {
        // eslint-disable-next-line
        return state.activeFormIoJSON.components.filter(item => {
          // Looping through Datagrid or Columns advanced components to locate the custom components that match the objects unique field name
          if (item.type === 'datagrid' && item.components.length > 0) {
            // Datagrid components have the custom components nested one layer deep in them ex: datagrid.components[]
            return item.components.filter(e => {
              return e.vasionUniqueFieldName === obj.vasionUniqueFieldName
            }).length > 0
          } else if (item.type === 'columns' && (item.columns[0].components.length > 0 || item.columns[1].components.length > 0)) {
            // Column components have the custom components nested two layers deep ex: Columns.column[0].components or Columns.column[1].components
            return item.columns.filter(c => {
              return c.components.filter(a => {
                return a.vasionUniqueFieldName === obj.vasionUniqueFieldName
              }).length > 0
            }).length > 0
          } else {
            return item.vasionUniqueFieldName === obj.vasionUniqueFieldName
          }
        }).length === 0
      })
    },
    selectedTemplates(state) {
      return state.createTempDocsByFormID.filter(item => {
        let returnBool
        if (state.tempDocsForEform.some(d => parseInt(d.CreateDocTemplateID, 10) === parseInt(item.id, 10))) {
          returnBool = true
        } else {
          returnBool = false
        }
        return returnBool
      })
    },
  },
}

export default forms
