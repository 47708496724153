<template>
  <div v-if="apiToken" class="container">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-show="!showConsentModal">
      <div v-if="isInChooseMode" class="choose">
        <h3 class="choose-header">
          Digital Signature
        </h3>
        <div class="sig-row">
          <div class="image-group" @click="toggleChooseMode('signature')">
            <div v-if="hasUserSignature" class="signature-image-bounds">
              <img class="signature-image" :src="userSignature" alt="Signature">
            </div>
            <div v-else>
              <div class="signature-image-bounds center">
                <VasionAddCircleIcon class="up-20" />
              </div>
            </div>
            <span v-if="hasUserSignature" class="sub-title">EDIT SIGNATURE</span>
            <span v-else class="sub-title center-sub">ADD SIGNATURE</span>
          </div>
          <div class="image-group" @click="toggleChooseMode('initials')">
            <div v-if="hasUserInitials" class="initials-image-bounds">
              <img class="initials-image" :src="userInitials" alt="User Signature">
            </div>
            <div v-else>
              <div class="initials-image-bounds center">
                <VasionAddCircleIcon class="up-20" />
              </div>
            </div>
            <span v-if="hasUserInitials" class="sub-title">EDIT INITIALS</span>
            <span v-else class="sub-title center-sub">ADD INITIALS</span>
          </div>
        </div>
        <div class="bottom-div">
          <div>
            <VasionCheckbox
              id="apply-timestamp-to-signatures"
              name="apply-timestamp-to-signatures"
              :checked="shouldApplyTimestampForUser"
              @change="handleApplyTimestampChange()"
            >
              Apply Timestamp to Your Signatures
            </VasionCheckbox>
          </div>
          <div class="signature-password">
            <VasionInput
              v-model="userPassword"
              v-debounce:300ms="savePassword"
              class="input-style"
              inputType="top-white"
              placeholder="Enter Password..."
              type="password"
            />
          </div>
          <div class="auto-left">
            <VasionButton id="closeAddOrEdit" :classProp="'primary'" @vasionButtonClicked="closeClick()">
              CLOSE
            </VasionButton>
          </div>
        </div>
      </div>
      <div v-else>
        <SaveSignatureOrInitials
          ref="saveSignatureOrInitials"
          :isInitials="version"
          @back-click="toggleChooseMode"
          @cancel-click="closeClick"
          @finish-consent="finishConsent"
          @show-consent="toggleConsentModal"
        />
      </div>
    </div>

    <VasionGeneralModal
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'I Agree'"
      modalTitle="Almost Done"
      :modalType="'slot'"
      :sync="showConsentModal"
      @confirmButtonClick="completeSignature"
      @noButtonClick="cancelClick"
    >
      <div v-if="isInitials" id="confirmCompleteInitials">
        {{ consentInitialsMessage }}
      </div>
      <div v-if="!isInitials" id="confirmCompleteSignature">
        {{ consentSignatureMessage }}
      </div>
    </VasionGeneralModal>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

import SaveSignatureOrInitials from '@/components/digitalSignature/SaveSignatureOrInitials.vue'

export default {
  name: 'AddOrEditSignatureOrInitials',
  components: {
    Loading,
    SaveSignatureOrInitials,
  },
  data: function () {
    return {
      applyTimestamp: false,
      consentInitialsMessage: 'I agree to be legally bound by these initials and the Vasion Terms of Service. Click on "I Agree" to finish updating your initials.',
      consentSignatureMessage: 'I agree to be legally bound by this signature and the Vasion Terms of Service. Click on "I Agree" to finish updating your signature.',
      isInChooseMode: true,
      isInConsent: false,
      isInitials: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      userPassword: '',
      showConsentModal: false,
      signaturePrefix: 'data:image/png;base64,',
      version: 'signature',
    }
  },
  computed: {
    apiToken() { return this.$store.state.common.apiToken },
    hasUserInitials() {
      const initials = this.$store.state.digitalSignature.userInitials
      return initials !== '' && initials.replace(this.signaturePrefix, '') !== ''
    },
    hasUserSignature() {
      const signature = this.$store.state.digitalSignature.userSignature
      return signature !== '' && signature.replace(this.signaturePrefix, '') !== ''
    },
    shouldApplyTimestampForUser() { return this.$store.state.digitalSignature.shouldApplySignatureTimestampForUser },
    userInitials() { return `${this.signaturePrefix}${this.$store.state.digitalSignature.userInitials}` },
    userSignature() { return `${this.signaturePrefix}${this.$store.state.digitalSignature.userSignature}` },
  },
  async created() {
    this.$store.dispatch('digitalSignature/getUserSignatureForStore')
    this.$store.dispatch('digitalSignature/getUserInitialsForStore')

    const response = await this.$store.dispatch('digitalSignature/getUserInfo')
    if (response?.HasSignature) {
      this.userPassword = response.SignaturePassword
    }
    await this.$store.dispatch('digitalSignature/shouldApplySignatureTimestamp')
    this.applyTimestamp = this.shouldApplyTimestampForUser
  },
  methods: {
    cancelClick() {
      this.toggleConsentModal()
      this.toggleChooseMode()
    },
    closeClick() {
      this.$emit('closeAddOrEdit')
    },
    completeSignature() {
      this.$refs.saveSignatureOrInitials.okClick()
    },
    finishConsent() {
      this.showConsentModal = false
      this.isInChooseMode = true
    },
    async handleApplyTimestampChange() {
      this.applyTimestamp = !this.applyTimestamp
      await this.$store.dispatch('digitalSignature/saveShouldApplySignatureTimestamp', { Value: this.applyTimestamp ? 'True' : 'False' })
    },
    async savePassword() {
      this.isLoading = true
      const result = await this.$store.dispatch('digitalSignature/saveUserPassword', { signature: this.userSignature.substr(22), password: this.userPassword })
      if (!result || result.Value === 'False') {
        console.warn('Error saving password')
      }
      this.isLoading = false
    },
    toggleChooseMode(value) {
      this.version = value === 'initials'
      this.isInitials = value === 'initials'
      this.isInChooseMode = !this.isInChooseMode
    },
    toggleConsentModal() {
      this.showConsentModal = !this.showConsentModal
    },
   },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .container {
    height: 463px;
    width: 928px;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid $grey-100;
  }

  .signature-image-bounds {
    width: 464px;
    height: 200px;
    border: 1px solid $grey-100;
    border-radius: 8px;
  }
  .signature-image {
    max-height: 170px;
    border-radius: 8px;
  }
  .initials-image-bounds {
    width: 200px;
    height: 200px;
    border: 1px solid $grey-100;
    border-radius: 8px;
    overflow: hidden;
  }
  .initials-image {
    border-radius: 8px;
    height: 154px;
    width: 154px;
    position: relative;
    left: 15px;
    bottom: -6px;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    fill: $orange-300;
  }

  .center-sub {
    top: 237px !important;
  }

  .choose {
    height: 100%;
    width:  100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sig-row {
    width: 880px;
    height: 283px;
    border: 1px solid $grey-100;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .image-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    :hover {
      background-color: $grey-100;
      cursor: pointer;
    }
  }
  .choose-header {
    width: 100%;
    font-family: $font-family-bold;
    font-size: 24px;
    color: $grey-500;
    margin: 5px 0 25px 0;
  }

  .sub-title {
    position: absolute;
    top: 279px;
    font-family: $font-family-medium;
    font-size: 14px;
    color: $orange-300;
  }

  .up-20 {
    position: relative;
    bottom: 20px;
  }

  .bottom-div {
    position: absolute;
    bottom: 29px;
    width: calc(100% - 37px);
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
    display: flex;
    align-items: center;
  }

  .auto-left {
    margin-left: auto;
  }
  .signature-password {
    margin-left: 10px;
  }
</style>
