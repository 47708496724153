<template>
  <div class="calc-height">
    <Loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />

    <div v-show="showApplySignatureDialog" id="apply-signature-dialog">
      <VasionApplySignature
        v-if="showApplySignatureDialog"
        @ok-click="okApplySignatureClick"
        @cancel-click="cancelApplySignatureClick"
      />
    </div>

    <div v-show="!showApplySignatureDialog" id="user-form" :class="{'autonomous-view': $route.name === 'plainForm'}">
      <div v-show="!showMultipleLookups" id="header">
        <div v-show="showLogo" id="header-logo-area">
          <img id="header-logo" :src="checkLogoImgNeedsData">
        </div>
        <div id="text-and-date">
          <span id="header-text">{{ formName }}</span>
          <div id="due-date-wrapper">
            <md-datepicker
              v-if="showDatePicker"
              id="due-date"
              v-model="dueDate"
              name="due-date"
            >
              <label>{{ dueDateLabel }}</label>
            </md-datepicker>
          </div>
          <div v-show="showWarnLabel" class="warnLabel">
            <h5>No lookups were found</h5>
          </div>
        </div>
      </div>
      <div id="main-section">
        <div v-if="showMultipleLookups">
          <MultipleLookups
            :tableValues="localFieldValues"
            :notRemoveRow="true"
            @yesButtonClick="updateLookupValues"
            @noButtonClick="showMultipleLookups = !showMultipleLookups"
          />
        </div>
        <div v-else>
          <div v-if="containsFormioJson" class="formio-container">
            <formio
              v-if="formioFields.components && formioFields.components.length"
              :key="refreshKey"
              :form="formioFields"
              @change="updateFormioJSON"
              @submit="submitEForm"
            />
          </div>
          <MaxxFormDisplay
            v-else
            ref="maxxFormComponent"
            :key="maxxFormComponent"
            :maxxFormID="formLayout.EFormID"
            @invalidForm="setStartWorkflowButtonToDisabled"
          />
        </div>
        <div v-if="uploadedFilesList.length > 0" class="uploaded-files-list">
          <div v-for="(file, index) in uploadedFilesList" :key="index" class="uploaded-file-item">
            <div>
              <span class="file-name"> {{ file.name }} </span><span class="bytes">({{ parseInt(file.size / 1000) }}K)</span>
            </div>
            <VasionButton
              :icon="'VasionExIcon'"
              title="Remove Uploaded File"
              class="configure-template-icon-button align-with-inputs"
              @vasionButtonClicked="removeUploadedFile(index)"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="doneCreating" v-show="!showApplySignatureDialog && !showMultipleLookups && isSignedIn" id="footer">
      <VasionButton
        v-show="showLookupBtn"
        :classProp="'primary'"
        class="button-left"
        @vasionButtonClicked="runDBLookup"
      >
        Run Lookup
      </VasionButton>
      <VasionButton
        v-if="!$route.params.formID"
        id="back"
        name="back"
        :classProp="'secondary'"
        @vasionButtonClicked="backToSelectWorkflow"
      >
        Back
      </VasionButton>

      <VasionButton
        v-if="formLayout.RequiresSignature"
        id="sign-file-button"
        name="sign-file-button"
        :classProp="'secondary'"
        class="button-right"
        @vasionButtonClicked="showApplySignatureDialog = !showApplySignatureDialog"
      >
        {{ SignEditSignature }}
      </VasionButton>

      <VasionButton
        v-if="supportsFileUpload"
        id="upload-file-button"
        name="upload-file-button"
        class="button-right"
        :classProp="'secondary'"
        @vasionButtonClicked="showUploadDialog = true"
      >
        Attach Files
      </VasionButton>

      <VasionButton
        v-if="!$route.params.formID"
        id="cancel"
        name="cancel"
        :classProp="'secondary'"
        class="button-right"
        @vasionButtonClicked="cancelForm"
      >
        Cancel
      </VasionButton>

      <VasionButton
        v-if="$route.params.formID"
        id="start-workflow"
        :isDisabled="!formIsValid"
        name="start-workflow"
        :classProp="'primary'"
        class="button-right"
        @vasionButtonClicked="startWorkflow"
      >
        Submit
      </VasionButton>
      <VasionButton
        v-else
        id="start-workflow"
        name="start-workflow"
        :classProp="'primary'"
        :isDisabled="!formIsValid"
        class="button-right"
        @vasionButtonClicked="startWorkflow"
      >
        Start Workflow
      </VasionButton>
    </div>
    <div v-if="!isSignedIn && !showApplySignatureDialog" id="footer-not-signed-in">
      <div class="buttons">
        <VasionButton
          id="start-workflow-not-signed-in"
          name="start-workflow-not-signed-in"
          :classProp="'primary'"
          :isDisabled="formLayout.RequiresSignature && !activeSignature"
          class="button-right"
          @vasionButtonClicked="startWorkflow"
        >
          Submit
        </VasionButton>
        <VasionButton
          v-if="formLayout.RequiresSignature"
          id="sign-file-button"
          name="sign-file-button"
          :classProp="'secondary'"
          class="button-right"
          @vasionButtonClicked="showApplySignatureDialog = !showApplySignatureDialog"
        >
          {{ SignEditSignature }}
        </VasionButton>
        <VasionButton
          v-if="supportsFileUpload"
          id="upload-file-button"
          name="upload-file-button"
          class="button-right"
          :classProp="'secondary'"
          @vasionButtonClicked="showUploadDialog = true"
        >
          Attach Files
        </VasionButton>
      </div>
      <div id="secured-by-group">
        <span>SECURED BY</span>
        <VasionWordmark id="word-mark" />
      </div>
    </div>
    <span v-show="showErrorText" class="vasion-error-text error-text">Please fill in all required fields.</span>

    <VasionFileUploadDialog
      allowMultiple
      :serverOptions="serverOptions"
      :show.sync="showUploadDialog"
      @allFilesUploaded="allFilesUploaded"
    />
  </div>
</template>
<script>
import { Form } from 'vue-formio';
import Loading from 'vue-loading-overlay';
import { toBase64 } from '@/store/helperModules/storage.module'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import MaxxFormDisplay from '@/components/forms/MaxxFormDisplay.vue'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import MultipleLookups from '@/components/vault/MultipleLookups.vue'

export default {
  name: 'StartWorkflowForm',
  components: {
    formio: Form,
    Loading,
    MaxxFormDisplay,
    MultipleLookups,
  },
  props: {
    formLayout: {
      type: Object,
      default: () => {},
      required: true,
    },
    workflowID: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data: function () {
    return {
      activeSignature: '',
      containsFormioJson: false,
      doneCreating: false,
      dueDate: null,
      dueDateLabel: '',
      filterUpdate: false,
      formioDataJSON: {},
      formioFields: {
        display: `${$formsLabel}`,
        components: [],
      },
      formIsValid: false,
      fullPage: true,
      showDatePicker: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      localFieldValues: [],
      maxxFormComponent: 0,
      refreshKey: 1,
      selectedFileUploadFieldValue: {
        name: '',
      },
      serverOptions: {
        process: this.processHandler,
      },
      showApplySignatureDialog: false,
      showErrorText: false,
      showLogo: false,
      showMultipleLookups: false,
      showUploadDialog: false,
      showWarnLabel: false,
      submitProceeded: true,
      uploadedFileFieldValue: '',
      uploadedFileName: '',
      uploadedFileNameList: [],
      uploadedFilesList: [],
      uploadedFileString: '',
      uploadedFileStringList: [],
    }
  },
  computed: {
    checkLogoImgNeedsData() {
      let returnVal
      if (this.formLayout.HeaderImageBase64) {
        if (this.formLayout.HeaderImageBase64.startsWith('data:image')) {
          returnVal = this.formLayout.HeaderImageBase64
        } else {
          returnVal = `data:image/png;base64, ${this.formLayout.HeaderImageBase64}`
          // I know this isn't going to work for all cases but I think moving forward we should just return the entire string including the prefix.
        }
      }
      return returnVal
    },
    fileUploadedFieldOptions() {
      const options = []
      if (this.supportsFileUploadField === false || !this.formLayout.UploadIndexFieldOptions || this.formLayout.UploadIndexFieldOptions.length === 0) {
        return options
      }
      options.push({
        name: '',
      })
      this.formLayout.UploadIndexFieldOptions.map((element) => {
        options.push({
          name: element,
        })
        return true
      })
      return options
    },
    formName() { return this.formLayout && this.formLayout.Name !== '' ? this.formLayout.Name : `Unknown ${$layoutsLabel}` },
    isSignedIn() { return this.$store.state.common.isSignedIn },
    showLookupBtn() { return this.formLayout.DBLookupID !== null && this.formLayout.ShowLookupButton },
    SignEditSignature() { return this.activeSignature ? 'Edit Signature' : 'Sign' },
    supportsFileUpload() { return this.formLayout?.AllowUploadFiles },
    supportsFileUploadField() { return this.formLayout && this.formLayout.UploadIndexFieldName && this.formLayout.UploadIndexFieldName !== '' },
  },
  watch: {
    formLayout: async function () {
      // Get image logo size and update it to fit nicely in the header
      if (document.getElementById('header-logo')) {
        const img = new Image()
        img.src = this.checkLogoImgNeedsData
        let logoWidth = img.naturalWidth
        let logoHeight = img.naturalHeight
        const updateImgSizeBy = logoHeight / 32 // This creates a ratio for the image that equals ratio 1 at 32px
        const widthIsGreaterThanHeightBy = logoWidth / logoHeight
        // Check if the width is greater than 2x the height, then it's considered rectangular
        if (widthIsGreaterThanHeightBy >= 2) { // For rectangular logos
          logoHeight /= updateImgSizeBy / 2
          logoWidth /= updateImgSizeBy / 2
        } else if (widthIsGreaterThanHeightBy < 2) { // For square or circular logos
          logoHeight /= updateImgSizeBy / 3
          logoWidth /= updateImgSizeBy / 3
          await document.getElementById('text-and-date')
          if (document.getElementById('text-and-date')) {
            document.getElementById('text-and-date').style.marginTop = '70px'
          }
        }
        document.getElementById('header-logo').width = logoWidth
        document.getElementById('header-logo').height = logoHeight
        this.showLogo = this.formLayout.HeaderImageBase64 !== ''
      }
    },
  },
  async created() {
    this.$material.locale.dateFormat = 'MM/dd/yyyy'
    if (this.workflowID) {
      const dueDateCalculation = await this.$store.dispatch('workflow/calculateDueDateAndSettings', this.workflowID)
      this.dueDate = dueDateCalculation.dueDate
      this.dueDateLabel = dueDateCalculation.dueDateLabel
      if (this.dueDate !== null) this.showDatePicker = true
    }

    await this.$store.dispatch('forms/getEForm', this.$route.params.formID)

    await this.formLayout
    await this.checkIfSignatureIsRequired()
    this.doneCreating = true
  },
  updated: function () {
    this.$store.dispatch('mainViews/changeMainNav', `my-form-${this.$route.params.formID}`)
    if (this.formLayout.FormIOJSON && this.containsFormioJson === false) {
      this.setFormioJson()
    }
  },
  methods: {
    allFilesUploaded() {
      this.showUploadDialog = false
    },
    backToSelectWorkflow() { this.$emit('backButtonClick') },
    cancelApplySignatureClick() { this.showApplySignatureDialog = false },
    cancelForm() { this.$emit('cancelButtonClick') },
    checkIfSignatureIsRequired() {
      if (this.formLayout.RequiresSignature) {
        this.setStartWorkflowButtonToDisabled()
      } else {
        this.formIsValid = true
      }
    },
    checkSubmitProceeded() {
      // If the submit didn't proceed, there was an error--assume it was required fields not being filled
      if (!this.submitProceeded) {
        this.showErrorText = true
      }
    },
    clearUploadedFileLists() {
        this.uploadedFileStringList = []
        this.uploadedFileNameList = []
    },
    okApplySignatureClick(signatureString) {
      this.activeSignature = signatureString
      this.showApplySignatureDialog = false
      this.formIsValid = true
    },
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      this.uploadedFileString = await toBase64(file)
      this.uploadedFileName = file.name
      this.uploadedFileStringList.push(this.uploadedFileString)
      this.uploadedFileNameList.push(this.uploadedFileName)
      this.uploadedFilesList.push(file)

      progress(true, 0, 1024)
      load(file.name)

      return {
        abort: () => {
          abort();
        },
      };
    },
    removeUploadedFile(index) {
      this.uploadedFileStringList.splice(index, 1)
      this.uploadedFileNameList.splice(index, 1)
      this.uploadedFilesList.splice(index, 1)
    },
    async resetFormValues() {
      this.uploadedFilesList = []
      this.activeSignature = ''
      await this.checkIfSignatureIsRequired()
      this.clearUploadedFileLists()
      this.maxxFormComponent = 1 // Used to clear any remaining form inputs
    },
    async runDBLookup() {
      this.isLoading = true
      const obj = {
        data: this.formioDataJSON.data,
      }
      const payload = {
        eformID: this.formLayout.EFormID,
        submittedFormIOJSON: JSON.stringify(obj),
      }
      const response = await this.$store.dispatch('document/runEFormLookup', payload)

      if (response && response.lookupResults.Rows.Values.length === 1) {
        this.formioFields = await JSON.parse(response.newFormIOFormJSON)
        this.refreshKey += 1
      } else if (response && response.lookupResults.Rows.Values.length > 1) {
        this.showMultipleLookups = true
        const rowsForTable = response.lookupResults.Rows.Values.map(v => {
          return v.Values
        })
        this.localFieldValues = {
          Columns: response.lookupResults.Columns.Values,
          Rows: rowsForTable,
        }
      } else {
        this.showWarnLabel = true
      }
      this.isLoading = false
    },
    setDueDate() {
      if (this.dueDate && this.dueDate !== '') {
        this.$store.dispatch('workflow/setWorkflowDueDate', this.dueDate)
      }
    },
    setFormioJson() {
      if (this.formLayout.FormIOJSON && !this.filterUpdate) {
        this.containsFormioJson = true
        this.formioFields = JSON.parse(this.formLayout.FormIOJSON)
      }
    },
    setStartWorkflowButtonToDisabled() {
      this.formIsValid = false
    },
    async startWorkflow() {
      this.setDueDate()
      if (this.containsFormioJson) {
        this.submitProceeded = false
        document.getElementsByName('data[submit]')[0].click()
        setTimeout(() => {
          this.checkSubmitProceeded()
        }, 750)

        return
      }

      if (this.fileUploadedFieldOptions.length > 0) {
        this.uploadedFileFieldValue = this.selectedFileUploadFieldValue ? this.selectedFileUploadFieldValue.name : ''
      }
      const success = await this.$refs.maxxFormComponent.submitForm(this.uploadedFileStringList, this.uploadedFileNameList, this.uploadedFileFieldValue)
      if (success) {
        this.$emit('workflowStarted')
      } else {
        this.$emit('workflowUnsuccessful')
      }

      this.resetFormValues()
    },
    async submitEForm(submission) {
      this.submitProceeded = true

      if (!submission?.data) {
        return
      }
      const submitFormPayload = {
        EFormID: this.formLayout.EFormID,
        UploadFileList: !this.uploadedFileStringList ? [] : this.uploadedFileStringList,
        UploadFileNameList: !this.uploadedFileNameList ? [] : this.uploadedFileNameList,
        UploadFieldValue: this.uploadedFileFieldValue,
        FormIOJSON: JSON.stringify({ data: submission.data }),
        ReturnDocumentID: true,
        workflowDueDate: this.dueDate,
        DefaultSignature: this.activeSignature,
      }
      this.isLoading = true
      const success = await this.$store.dispatch('maxxForms/submitMaxxForm', submitFormPayload)
      if (success) {
        this.$emit('workflowStarted')
        this.resetFormValues()
        this.containsFormioJson = false
      } else {
        this.$emit('workflowUnsuccessful')
      }
      this.isLoading = false
      // eslint-disable-next-line
      return success
    },
    async updateFormioJSON(submission) {
      this.showWarnLabel = false
      if (!submission || !submission.data) return
      this.formioDataJSON = submission
      if (!submission.changed || submission.changed.component.type !== 'select') return

      // START OF: Filter Logic
      // Get all dropdown/select fields prepped for payload
      let fieldValues = []
      let updatedFields = false
      this.formioFields.components.forEach(field => {
        if (field.type === 'select' && field.vasionUniqueFieldName) {
          Object.entries(submission.data).forEach(item => {
            if (item[0] === field.key) {
              // Final values to be used for payload
              fieldValues.push({
                Key: field.vasionUniqueFieldName,
                Value: Array.isArray(item[1]) ? item[1].join(';') : ""
              })
            }
          })
        }
      })
      // Make updates to all dropdowns/selects that need them
      let count = 0
      while (count < fieldValues.length) {
        let tempValues = [] // These values will be used to loop through the dropdowns with the correct payload
        fieldValues.forEach(field => {
          if (field !== fieldValues[count]) tempValues.push(field)
        })
        let payload = {
          IndexFormID: this.formLayout.IndexFormID,
          FilterFieldName: fieldValues[count].Key,
          BaseFieldValues: {
            Values: tempValues,
          },
        }
        let filterResult = await this.$store.dispatch('document/getFilterFields', payload)

        // Update the values
        if (filterResult.FieldValues.length > 0) {
          let resultValues = []
          filterResult.FieldValues.forEach(val => {
            resultValues.push({
              label: val,
              value: val,
            })
          })
          this.formioFields.components.forEach(field => {
            if (field.vasionUniqueFieldName && field.vasionUniqueFieldName === fieldValues[count].Key) {
              updatedFields = true
              field.data.values = resultValues
              // Auto select value in dropdowns that only have one value
              if (filterResult.FieldValues.length === 1) {
                submission.data[field.key] = filterResult.FieldValues
              }
            }
          })
        }
        count++
      }
      if (!updatedFields) return
      const updatedFormIOJSON = JSON.stringify(this.formioFields)
      if (!this.filterUpdate) {
        this.filterUpdate = true
        // eslint-disable-next-line vue/no-mutating-props
        this.formioFields = JSON.parse(updatedFormIOJSON)
      }
      setTimeout(() => { // This prevents infinite update loops
        this.filterUpdate = false
      }, 1000);
      // END OF: Filter Logic
    },
    async updateLookupValues(submission) {
      this.isLoading = true
      const obj = {
        data: this.formioDataJSON.data,
      }

      const payload = {
        eformID: this.formLayout.EFormID,
        submittedFormIOJSON: JSON.stringify(obj),
        selectedTable: {
          Columns: {
            Values: submission.Columns,
          },
          Rows: {
            Values: [
              {
                Values: submission.Rows[0],
              },
            ],
          },
        },
      }
      const response = await this.$store.dispatch('document/useEFormDBLookupResult', payload)

      if (response && response.lookupResults.Rows.Values) {
        this.formioFields = await JSON.parse(response.newFormIOFormJSON)
        this.showMultipleLookups = false
        this.refreshKey += 1
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/vasion-formio.scss';
  @import 'https://unpkg.com/formiojs@latest/dist/formio.full.min.css';
  @import '@/assets/css/variables.scss';

  .autonomous-view {
    max-height: 89vh !important;
  }
  .calc-height {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  #main-section {
    width: 100%;
    max-height: 425px;
  }
  #main-section::v-deep .formio-component-submit {
    display: none;
  }

  #footer-not-signed-in {
    margin: 0 24.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .buttons {
      position: relative;
      right: 8.5px;
      bottom: 11px;
    }
    #secured-by-group{
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      span {
        font-family: Archivo;
        font-size: 12px;
        font-weight: 500;
        color: $grey-400;
      }
      #word-mark {
        width: 79.5px;
        height: 10.8px;
        fill: $orange-300;
      }
    }
  }

  #user-form {
    overflow: auto;
    height: 100%;

    #header {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid $grey-300;
      justify-content: space-between;
      padding: 20px 20px 0px 20px;
      #header-logo-area {
        width: 100%;
        height: 100px;
        display: block;
        padding: 25px;
      }
      #header-logo {
        margin: 0 auto;
        display: block;
      }
      #text-and-date {
        display: inline-block;
        margin-top: 30px;
      }
      #header-text {
        @include Headline;
        margin-top: 10px;
      }
      #due-date-wrapper {
        width: 200px;
        overflow: hidden;
        float: right;
        margin-top: -15px;
      }
    }
  }

  #footer {
    margin: 15px;
    display: flex;
    justify-content: flex-end;
    .button-right {
      float: right;
    }
    .button-left {
      margin-right: 15px;
    }
    label {
      top: 14px;
      right: 5px;
      position: relative;
    }
    #back {
      margin-left: 0;
      padding-left: 0;
    }
    #start-workflow {
      margin-right: 0;
      padding-right: 0;
    }
  }

  @media (max-width: $tablet){
    #footer {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (max-width: $phone){
    #main-section {
      max-height: none;
      max-width: 100%;
    }
    #user-form {
      #header{
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    #footer {
      margin: 0px;
      display: flex;
      flex-direction: column;
      float: left;
    }
    .button-right{
      display: flex;
      flex-direction: column;
    }
  }

  .formio-container {
    padding: 20px;
  }

  .dataDiv :hover {
    cursor: pointer;
  }

  #main-section .uploaded-files-list {
    margin-top: 0;
    max-height: 200px;
    overflow: auto;
  }
  .uploaded-files-list {
    width: 100%;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
  }
  .uploaded-file-item {
    width: 412px;
    height: 32px;
    background-color: $grey-100;
    margin: 5px 10px;
    padding: 2px 5px 2px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .file-name {
    display: inline-block;
    margin-right: 15px;
    text-align: left;
  }
  .bytes {
    color: $grey-400;
    text-indent: 10px;
  }
  .warnLabel {
    color: red;
  }

  .error-text {
    margin: 0px 10px 10px 0px;
    text-align: right;
  }
</style>
