/* eslint-disable linebreak-style */
/* eslint-disable no-console */
import {
  deleteDocumentTemplate,
  findAndReplaceIndexData,
  generateSamlSSOLogin,
  getCaptureProfile,
  getUserSetting,
  getDocumentTemplateTypeList,
  getEmailTemplateDetails,
  getLookupBindings,
  getSamlConfig,
  getSamlSSOUrl,
  getTransactionLogAggregateData,
  loadDashboardStatistics,
  saveBarcodeIndex,
  saveCaptureProfile,
  saveEmailTemplate,
  saveOcrTemplate,
  savePasswordSettings,
  saveSamlConfig,
  setThirdPartyCode,
  setUserSetting,
 } from '@/store/apiModules/admin.api'

 import {
  buildTableData,
 } from '@/store/helperModules/adminStorageSetup.module';

function initialState() {
  return {
    defaultApp: null,
    defaultAppLocalSelection: null,
    parentFolderPermissions: {},
    samlConfig: null,
    samlSSOLogin: '',
    samlSSOUrl: '',
    saveWorkflowID: 0,
    selectedEmailTemplate: null,
  }
}

const admin = {
  namespaced: true,
  state: initialState,
  mutations: {
    defaultAppSelection(state, value) { state.defaultAppLocalSelection = value },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setDefaultApp(state, value) { state.defaultApp = value },
    setParentFolderPermissions(state, value) { state.parentFolderPermissions = value },
    setSamlConfig(state, value) { state.samlConfig = value },
    setSamlSSOLogin(state, value) { state.samlSSOLogin = value },
    setSamlSSOUrl(state, value) { state.samlSSOUrl = value },
    setSaveWorkflowID(state, value) { state.saveWorkflowID = value },
    setSelectedEmailTemplate(state, value) { state.selectedEmailTemplate = value },
  },
  actions: {
    defaultAppSelection({ commit }, appName) { commit('defaultAppSelection', appName) },
    async deleteDocumentTemplate({ rootState }, documentTemplateId) {
      const result = await deleteDocumentTemplate(rootState.common.apiToken, documentTemplateId)
      return result
    },
    async findAndReplaceIndexData({ rootState }, payload) {
      const result = await findAndReplaceIndexData(rootState.common.apiToken, payload)
      return result
    },
    async generateSamlSSOLogin({ rootState, commit }) {
      const result = await generateSamlSSOLogin(rootState.common.apiToken)
      await commit('setSamlSSOLogin', result)
    },
    async getCaptureProfile({ rootState }, profileId) {
      return getCaptureProfile(rootState.common.apiToken, profileId)
    },
    async getUserSetting({ rootState, dispatch, commit }, name) {
      const result = await getUserSetting(rootState.common.apiToken, name)
      if (name == 'defaultApp') {
        await commit('setDefaultApp', result?.Value)
      }
      else if (name == 'defaultView') {
        dispatch('workflow/setCustomFolderViewSaved', result?.Value, { root: true })
      }
      return true
    },
    async getDocumentTemplateTypeList({ rootState }) { return getDocumentTemplateTypeList(rootState.common.apiToken) },
    async getLookupBindings({ rootState }) {
      return getLookupBindings(rootState.common.apiToken)
    },
    async getSamlConfig({ rootState, commit }) {
      const result = await getSamlConfig(rootState.common.apiToken)
      commit('setSamlConfig', result)
    },
    async getSamlSSOUrl({ rootState, commit }) {
      const result = await getSamlSSOUrl(rootState.common.apiToken)
      await commit('setSamlSSOUrl', result)
    },
    async getTransactionData({ rootState }, payload) { return getTransactionLogAggregateData(rootState.common.apiToken, payload) },
    async loadDashboardStatistics({ rootState }) { return loadDashboardStatistics(rootState.common.apiToken) },
    async loadSamlConfig({ rootState, commit }) {
      const result = await getSamlConfig(rootState.common.apiToken)
      commit('setSamlConfig', result)
    },
    async saveBarcodeIndex({ rootState }, payload) {
      const response = await saveBarcodeIndex(rootState.common.apiToken, payload)
      return response
    },
    async saveCaptureProfile({ rootState }, payload) {
      const response = await saveCaptureProfile(rootState.common.apiToken, payload)
      return response
    },
    async setUserSetting({ rootState, dispatch }, payload) {
      const response = await setUserSetting(rootState.common.apiToken, payload)
      if (payload.settingName == 'defaultApp') {
        dispatch('getDefaultApp')
      }
      if (payload.settingName == 'defaultView') {
        dispatch('workflow/setCustomFolderViewSaved', payload.settingValue, { root: true })
      }
      return response
    },
    async saveEmailTemplate({ rootState }, payload) {
      const result = await saveEmailTemplate(rootState.common.apiToken, payload)
      return result
    },
    async saveOcrTemplate({ rootState }, payload) {
      const response = await saveOcrTemplate(rootState.common.apiToken, payload)
      return response
    },
    async savePasswordSettings({ rootState }, payload) {
      const response = await savePasswordSettings(rootState.common.apiToken, payload)
      return response.Value
    },
    async saveSamlConfig({ rootState, commit }, payload) {
      const result = await saveSamlConfig(rootState.common.apiToken, payload)
      if (result) {
        commit('setSamlConfig', result)
        return true
      }

      return false
    },
    async setEmailSelectedEmailTemplate({ commit, rootState }, emailTemplateID) {
      let template = null
      if (emailTemplateID !== undefined && emailTemplateID > 0) {
        template = await getEmailTemplateDetails(rootState.common.apiToken, emailTemplateID)
      } else {
        template = {
          templateID: 0,
          templateName: '',
          subject: '',
          body: '',
          docLinkMaxViews: 0,
          docLinkExpirationHours: 0,
          docLinkMaxDownloads: 0,
          docLinkUserID: 0,
        }
      }

      try {
        commit('setSelectedEmailTemplate', template)
      } catch (error) {
        console.warn(error)
      }
    },
    setParentFolderPermissions({ commit }, payload) { commit('setParentFolderPermissions', payload) },
    async setSaveWorkflowID({ commit }, workflowID) { await commit('setSaveWorkflowID', workflowID) },
    // eslint-disable-next-line no-empty-pattern
    async setTableData({}, data) {
      const response = await buildTableData(data)
      return response
    },
    async setThirdPartyCode({ rootState }, payload) {
      const result = await setThirdPartyCode(rootState.common.apiToken, payload)
      return result
    },
  },
}

export default admin
